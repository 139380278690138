import BreadCrumb from "Common/BreadCrumb";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Nav,
  Row,
  Spinner,
  Tab,
} from "react-bootstrap";
import withRouter from "Common/withRouter";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import {
  attachPaymentMethodIdThunk,
  configAutoRechargeThunk,
  createCustomerThunk,
  deletePaymentMethodIdThunk,
  getAutoRechargeConfigThunk,
  getPaymentMethodThunk,
  openModal,
  updateAutoRechargeConfigThunk,
  updateAuthUserThunk,
} from "slices/thunk";
import {
  customSelectTheme,
  getChangedValues,
  styleObj,
  US_STATES,
} from "helpers/common";
import { toast } from "react-toastify";
import CardElement from "Common/modals/CardElement";
import { createPaymentIntentFailed } from "slices/billing/reducer";
import Loader from "assets/images/spinner-dark.svg";
import { onCloseSubject, submitLoadingSubject } from "Common/modals/Modal";

const AddPaymentMethod = (props: any) => {
  document.title = "Signal House Portal Add Payment Method";
  const dispatch = useDispatch<any>();
  const tilled = useRef<any>(null);
  const inputRef = useRef<any>(null);

  const [isDisable, setIsDisable] = useState(false);
  const [deleteType, setDeleteType] = useState("");
  const [selectedPrimaryPayMethod, setSelectedPrimaryPayMethod] = useState<any>(
    {}
  );
  const [selectedDefaultPayMethod, setSelectedDefaultPayMethod] = useState<any>(
    {}
  );

  const selectProfile = createSelector(
    (state: any) => state.AuthUser,
    (state: any) => state.Billing,
    (user, billing) => ({
      loading: user.loading,
      authUser: user?.authUser,
      loading2: billing.loading,
      loading3: billing.loading2,
      autoRechargeData: billing.autoRechargeData,
      paymentMethods: billing.paymentMethods,
      error: billing.error,
    })
  );

  const { authUser, loading2, loading3, autoRechargeData, paymentMethods } =
    useSelector(selectProfile);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      address_country: authUser?.billingAddress?.country || "US",
      address_state: authUser?.billingAddress?.state || "",
      address_line1: authUser?.billingAddress?.addressLine1 || "",
      address_line2: authUser?.billingAddress?.addressLine2 || "",
      address_city: authUser?.billingAddress?.city || "",
      address_zip: authUser?.billingAddress?.postalCode || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      address_country: Yup.string().required("Please Select Country"),
      address_state: Yup.string().required("Please Select State"),
      address_line1: Yup.string().required("Please Enter Address"),
      address_city: Yup.string().required("Please Enter City"),
      address_zip: Yup.string().required("Please Enter Postal Code"),
    }),
    onSubmit: async (values: any, { resetForm }: any) => {
      const cb2 = () => {
        resetForm();
        const userInitValues = {
          billingAddress: {
            addressLine1: validation.initialValues.address_line1 || "",
            addressLine2: validation.initialValues.address_line2 || "",
            city: validation.initialValues.address_city || "",
            state: validation.initialValues.address_state || "",
            country: validation.initialValues.address_country,
            postalCode: validation.initialValues.address_zip || "",
          },
        };
        const userValues = {
          billingAddress: {
            addressLine1: values.address_line1 || "",
            addressLine2: values.address_line2 || "",
            city: values.address_city || "",
            state: values.address_state || "",
            country: values.address_country,
            postalCode: values.address_zip || "",
          },
        };
        const userChangedData = getChangedValues(userInitValues, userValues);
        dispatch(updateAuthUserThunk(userChangedData, () => {}, true));
      };

      const cb = async () => {
        try {
          await tilled?.current
            ?.createPaymentMethod({
              type: "card",
              billing_details: {
                name: `${authUser.firstName} ${authUser.lastName}`,
                address: {
                  country: values.address_country,
                  zip: values.address_zip,
                  state: values.address_state,
                  city: values.address_city,
                  street:
                    values.address_line1 + values.address_line2
                      ? values.address_line1 + "," + values.address_line2
                      : "",
                },
              },
            })
            .then(
              (paymentMethod: any) => {
                if (paymentMethod?.id) {
                  dispatch(attachPaymentMethodIdThunk(paymentMethod?.id, cb2));
                }
              },
              (error: any) => {
                dispatch(createPaymentIntentFailed({}));
                toast.error(error.message || error);
              }
            );
        } catch (error: any) {
          toast.error(error.message || error);
          dispatch(createPaymentIntentFailed({}));
        }
      };

      // cb();
      dispatch(createCustomerThunk(cb));
    },
  });

  const validation2: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      thresholdAmount: autoRechargeData?.thresholdAmount || 0,
      rechargeAmount: autoRechargeData?.rechargeAmount || 0,
      primaryPaymentMethodId: autoRechargeData?.defaultPaymentMethod?.id || "",
      defaultPaymentMethodID: autoRechargeData?.primaryPaymentMethod?.id || "",
    },
    validationSchema: Yup.object({
      thresholdAmount: Yup.number()
        .min(20, "Amount must be greater than or equal to $20")
        .max(20000)
        .required("Amount must be greater than or equal to $20"),
      rechargeAmount: Yup.number()
        .min(20, "Amount must be greater than or equal to $20")
        .max(20000)
        .required("Amount must be greater than or equal to $20"),
      primaryPaymentMethodId: Yup.string().required(
        "Please Select Payment Method"
      ),
      defaultPaymentMethodID: Yup.string().required(
        "Please Select Payment Method"
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      const cb = () => {
        resetForm();
      };

      if (
        autoRechargeData?.enabled !== true &&
        autoRechargeData?.enabled !== false
      ) {
        dispatch(configAutoRechargeThunk(values, cb));
      } else {
        dispatch(updateAutoRechargeConfigThunk(values, cb));
      }
    },
  });

  useEffect(() => {
    dispatch(getAutoRechargeConfigThunk());
    dispatch(getPaymentMethodThunk());
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      const autocomplete = new google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["geocode"],
          componentRestrictions: { country: "US" }, // Restrict to USA only
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.address_components) {
          const addressComponents = place.address_components;
          const addressDetails = {
            street: "",
            area: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
          };

          addressComponents?.forEach((component: any) => {
            const types = component.types;
            if (types.includes("sublocality_level_1")) {
              addressDetails.area = component.long_name;
            } else if (types.includes("locality")) {
              addressDetails.city = component.long_name;
            } else if (types.includes("administrative_area_level_1")) {
              addressDetails.state = component.short_name;
            } else if (types.includes("country")) {
              addressDetails.country = component.short_name;
            } else if (types.includes("postal_code")) {
              addressDetails.zipcode = component.long_name;
            } else if (
              types.includes("street_number") ||
              types.includes("route")
              // || types.includes("neighborhood")
            ) {
              addressDetails.street += ` ${component.long_name}`;
            }
          });
          validation.setFieldValue(
            "address_line1",
            addressDetails.street?.trim() || ""
          );
          validation.setFieldValue("address_line2", addressDetails.area || "");
          validation.setFieldValue("address_city", addressDetails.city || "");
          validation.setFieldValue("address_state", addressDetails.state || "");
          validation.setFieldValue(
            "address_country",
            addressDetails.country || ""
          );
          validation.setFieldValue("address_zip", addressDetails.zipcode || "");
        }
      });
    }

    return () => {
      if (inputRef.current) {
        google.maps.event.clearInstanceListeners(inputRef.current);
      }
    };
  }, [inputRef.current]);

  useEffect(() => {
    if (
      paymentMethods?.length > 0 &&
      validation2.values.primaryPaymentMethodId
    ) {
      const temp = paymentMethods?.find(
        (dt: { id: string }) =>
          dt?.id === validation2.values.primaryPaymentMethodId
      );
      setSelectedPrimaryPayMethod(temp);
    } else {
      setSelectedPrimaryPayMethod({});
    }
  }, [paymentMethods, validation2.values.primaryPaymentMethodId]);

  useEffect(() => {
    if (
      paymentMethods?.length > 0 &&
      validation2.values.defaultPaymentMethodID
    ) {
      const temp = paymentMethods?.find(
        (dt: { id: string }) =>
          dt?.id === validation2.values.defaultPaymentMethodID
      );
      setSelectedDefaultPayMethod(temp);
    } else {
      setSelectedDefaultPayMethod({});
    }
  }, [paymentMethods, validation2.values.defaultPaymentMethodID]);

  const handleDelete = (e: any, id: string, type: string) => {
    if (!loading2) {
      setDeleteType(type);
      e.preventDefault();
      e.stopPropagation();
      dispatch(
        openModal({
          modalbody: "Are you sure you want to delete this payment method?",
          data: {
            title: "Delete Payment Method",
            footer: true,
            cancelBtn: true,
            buttonText: {
              submit: "Delete",
              cancel: "Cancel",
            },
            onSubmit: () => {
              submitLoadingSubject.next(true);
              dispatch(deletePaymentMethodIdThunk(id))
                .then(() => {
                  onCloseSubject.next({});
                })
                .finally(() => {
                  submitLoadingSubject.next(false);
                });
            },
          },
        })
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Payment Settings"
            isBack={true}
            backClick={() => props.router.navigate("/dashboard/add-funds")}
          />
          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="">
                <Tab.Container defaultActiveKey="add-payment-method">
                  <Nav
                    as="ul"
                    variant="tabs"
                    className="nav-tabs-custom nav-primary nav-justified mb-3"
                  >
                    <Nav.Item as="li">
                      <Nav.Link eventKey="add-payment-method">
                        Add Payment Method
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link eventKey="auto-recharge">
                        Auto Recharge
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className="text-muted">
                    <Tab.Pane eventKey="add-payment-method">
                      <Row>
                        <Col lg={6}>
                          <Form
                            action="#"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                            }}
                          >
                            <div className="mb-3">
                              <Form.Label htmlFor="name">
                                Name on credit card{" "}
                                <span className="text-primary">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="name"
                                name="name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                isInvalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                              validation.errors.name ? (
                                <Form.Control.Feedback type="invalid">
                                  {validation.errors.name}
                                </Form.Control.Feedback>
                              ) : null}
                            </div>
                            <CardElement
                              tilled={tilled}
                              setIsDisable={setIsDisable}
                            />

                            <div className="mb-1">
                              <Form.Label>Billing address</Form.Label>
                            </div>
                            <div className="mb-3">
                              <Form.Label htmlFor="address_country">
                                Country <span className="text-primary">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="address_country"
                                value="United States"
                                disabled
                              />
                              <Form.Control
                                type="hidden"
                                name="address_country"
                                value="US"
                                onChange={validation.handleChange}
                              />
                            </div>
                            <div className="mb-3">
                              <Form.Label htmlFor="address_state">
                                State <span className="text-primary">*</span>
                              </Form.Label>
                              <Select
                                name="address_state"
                                styles={styleObj(
                                  validation.touched.address_state &&
                                    validation.errors.address_state
                                )}
                                theme={customSelectTheme}
                                options={US_STATES.map((state) => ({
                                  label: state.name,
                                  value: state.isoCode,
                                }))}
                                onChange={(e: { value: string }) => {
                                  validation.setFieldValue(
                                    "address_state",
                                    e.value
                                  );
                                }}
                                value={
                                  US_STATES.filter(
                                    (state) =>
                                      state.isoCode ===
                                      validation.values.address_state
                                  ).map((state) => ({
                                    label: state.name,
                                    value: state.isoCode,
                                  }))[0] || null
                                }
                              />
                              {validation.touched.address_state &&
                              validation.errors.address_state ? (
                                <Form.Control.Feedback
                                  type="invalid"
                                  className="d-block"
                                >
                                  {validation.errors.address_state}
                                </Form.Control.Feedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Form.Label htmlFor="address_line1">
                                Address Line 1{" "}
                                <span className="text-primary">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                autoComplete="off"
                                ref={inputRef}
                                id="address_line1"
                                name="address_line1"
                                placeholder="Search for address"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation?.values?.address_line1 || ""}
                                isInvalid={
                                  validation?.touched?.address_line1 &&
                                  validation?.errors?.address_line1
                                    ? true
                                    : false
                                }
                              />
                              {validation?.touched?.address_line1 &&
                              validation?.errors?.address_line1 ? (
                                <Form.Control.Feedback type="invalid">
                                  {validation?.errors?.address_line1}
                                </Form.Control.Feedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Form.Label htmlFor="address_line2">
                                Address Line 2
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="address_line2"
                                name="address_line2"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address_line2 || ""}
                                isInvalid={
                                  validation.touched.address_line2 &&
                                  validation.errors.address_line2
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.address_line2 &&
                              validation.errors.address_line2 ? (
                                <Form.Control.Feedback type="invalid">
                                  {validation.errors.address_line2}
                                </Form.Control.Feedback>
                              ) : null}
                            </div>
                            <Row>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Form.Label htmlFor="address_city">
                                    City <span className="text-primary">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    id="address_city"
                                    name="address_city"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.address_city || ""}
                                    isInvalid={
                                      validation.touched.address_city &&
                                      validation.errors.address_city
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.address_city &&
                                  validation.errors.address_city ? (
                                    <Form.Control.Feedback type="invalid">
                                      {validation.errors.address_city}
                                    </Form.Control.Feedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Form.Label htmlFor="address_zip">
                                    Postal Code{" "}
                                    <span className="text-primary">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    id="address_zip"
                                    name="address_zip"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.address_zip || ""}
                                    isInvalid={
                                      validation.touched.address_zip &&
                                      validation.errors.address_zip
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.address_zip &&
                                  validation.errors.address_zip ? (
                                    <Form.Control.Feedback type="invalid">
                                      {validation.errors.address_zip}
                                    </Form.Control.Feedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Button
                              variant="primary"
                              type="submit"
                              disabled={loading2 || isDisable}
                            >
                              {loading2 && (
                                <Spinner size="sm" animation="border" />
                              )}{" "}
                              Add card
                            </Button>
                          </Form>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="auto-recharge">
                      <div>
                        <p>
                          Keep your Signal House services running smoothly by
                          automatically setting your account balance to be
                          recharged when it runs low.
                        </p>
                        <Row>
                          <Col lg={6}>
                            <Form
                              action="#"
                              onSubmit={(e) => {
                                e.preventDefault();
                                validation2.handleSubmit();
                              }}
                            >
                              <div className="mb-3">
                                <Form.Label htmlFor="thresholdAmount">
                                  When balance goes below{" "}
                                  <span className="text-primary">*</span>
                                </Form.Label>
                                <InputGroup>
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="number"
                                    id="thresholdAmount"
                                    name="thresholdAmount"
                                    min={20}
                                    max={20000}
                                    onChange={validation2.handleChange}
                                    onBlur={validation2.handleBlur}
                                    value={parseFloat(
                                      validation2.values.thresholdAmount
                                    ).toFixed(2)}
                                    isInvalid={
                                      validation2.touched.thresholdAmount &&
                                      validation2.errors.thresholdAmount
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation2.touched.thresholdAmount &&
                                  validation2.errors.thresholdAmount ? (
                                    <Form.Control.Feedback type="invalid">
                                      {validation2.errors.thresholdAmount}
                                    </Form.Control.Feedback>
                                  ) : null}
                                </InputGroup>
                                <p className="text-muted mt-1">
                                  Enter an amount of at least $20
                                </p>
                              </div>
                              <div className="mb-3">
                                <Form.Label htmlFor="rechargeAmount">
                                  Bring my balance back up to{" "}
                                  <span className="text-primary">*</span>
                                </Form.Label>
                                <InputGroup>
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    type="number"
                                    id="rechargeAmount"
                                    name="rechargeAmount"
                                    min={20}
                                    max={20000}
                                    onChange={validation2.handleChange}
                                    onBlur={validation2.handleBlur}
                                    value={parseFloat(
                                      validation2.values.rechargeAmount
                                    ).toFixed(2)}
                                    isInvalid={
                                      validation2.touched.rechargeAmount &&
                                      validation2.errors.rechargeAmount
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation2.touched.rechargeAmount &&
                                  validation2.errors.rechargeAmount ? (
                                    <Form.Control.Feedback type="invalid">
                                      {validation2.errors.rechargeAmount}
                                    </Form.Control.Feedback>
                                  ) : null}
                                </InputGroup>
                                <p className="text-muted mt-1">
                                  Enter an amount of at least $20
                                </p>
                              </div>
                              <div className="mb-3">
                                <Form.Label htmlFor="primaryPaymentMethodId">
                                  Using this primary payment method{" "}
                                  <span className="text-primary">*</span>
                                  {loading2 && deleteType === "primary" ? (
                                    <div className="d-inline">
                                      <img
                                        src={Loader}
                                        alt="loading"
                                        height={40}
                                      />
                                    </div>
                                  ) : null}
                                </Form.Label>
                                <Select
                                  isClearable={true}
                                  name="primaryPaymentMethodId"
                                  styles={styleObj(
                                    validation2.touched
                                      .primaryPaymentMethodId &&
                                      validation2.errors.primaryPaymentMethodId
                                  )}
                                  theme={customSelectTheme}
                                  options={
                                    paymentMethods?.length > 0
                                      ? paymentMethods?.map(
                                          (dt: {
                                            id: string;
                                            card: {
                                              brand: string;
                                              last4: string;
                                            };
                                          }) => ({
                                            label: (
                                              <div className="d-flex align-items-center justify-content-between">
                                                <span className="d-flex align-items-center">
                                                  <i
                                                    className={`ri ri-${dt?.card?.brand}-fill fs-4xl me-2`}
                                                  />
                                                  {dt?.card?.last4
                                                    ? // ? `**** **** **** ${dt?.card?.last4} ${dt?.billing_details?.name} ${dt?.billing_details?.email}`
                                                      `**** **** **** ${dt?.card?.last4}`
                                                    : ""}
                                                </span>
                                                <i
                                                  className={`ri-delete-bin-5-line text-${
                                                    dt?.id ===
                                                    validation2.values
                                                      .primaryPaymentMethodId
                                                      ? "white"
                                                      : "primary"
                                                  } fs-4 cursor-pointer`}
                                                  onClick={(e) =>
                                                    handleDelete(
                                                      e,
                                                      dt?.id,
                                                      "primary"
                                                    )
                                                  }
                                                />
                                              </div>
                                            ),
                                            value: dt?.id,
                                          })
                                        )
                                      : []
                                  }
                                  onChange={(
                                    e: { value: string },
                                    option?: { action?: string }
                                  ) => {
                                    if (option?.action === "clear") {
                                      validation2.setFieldValue(
                                        "primaryPaymentMethodId",
                                        ""
                                      );
                                    } else {
                                      validation2.setFieldValue(
                                        "primaryPaymentMethodId",
                                        e.value
                                      );
                                    }
                                  }}
                                  value={{
                                    label: (
                                      <span className="d-flex align-items-center">
                                        <i
                                          className={`ri ri-${selectedPrimaryPayMethod?.card?.brand}-fill fs-4xl me-2`}
                                        />
                                        {selectedPrimaryPayMethod?.card?.last4
                                          ? // ? `**** **** **** ${selectedPrimaryPayMethod?.card?.last4} ${selectedPrimaryPayMethod?.billing_details?.name} ${selectedPrimaryPayMethod?.billing_details?.email}`
                                            `**** **** **** ${selectedPrimaryPayMethod?.card?.last4}`
                                          : ""}
                                      </span>
                                    ),
                                    value:
                                      validation2.values.primaryPaymentMethodId,
                                  }}
                                />
                                {validation2.touched.primaryPaymentMethodId &&
                                validation2.errors.primaryPaymentMethodId ? (
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    {validation2.errors.primaryPaymentMethodId}
                                  </Form.Control.Feedback>
                                ) : null}
                                <p className="text-muted mt-1">
                                  Only payment methods available for auto
                                  recharge show here.
                                </p>
                              </div>
                              <div className="mb-3">
                                <Form.Label htmlFor="defaultPaymentMethodID">
                                  Or this backup payment method{" "}
                                  <span className="text-primary">*</span>
                                  {loading2 && deleteType === "default" ? (
                                    <div className="d-inline">
                                      <img
                                        src={Loader}
                                        alt="loading"
                                        height={40}
                                      />
                                    </div>
                                  ) : null}
                                </Form.Label>
                                <Select
                                  isClearable={true}
                                  name="defaultPaymentMethodID"
                                  styles={styleObj(
                                    validation2.touched
                                      .defaultPaymentMethodID &&
                                      validation2.errors.defaultPaymentMethodID
                                  )}
                                  theme={customSelectTheme}
                                  options={
                                    paymentMethods?.length > 0
                                      ? paymentMethods?.map(
                                          (dt: {
                                            id: string;
                                            card: {
                                              brand: string;
                                              last4: string;
                                            };
                                          }) => ({
                                            label: (
                                              <div className="d-flex align-items-center justify-content-between">
                                                <span className="d-flex align-items-center">
                                                  <i
                                                    className={`ri ri-${dt?.card?.brand}-fill fs-4xl me-2`}
                                                  />
                                                  {dt?.card?.last4
                                                    ? // ? `**** **** **** ${dt?.card?.last4} ${dt?.billing_details?.name} ${dt?.billing_details?.email}`
                                                      `**** **** **** ${dt?.card?.last4}`
                                                    : ""}
                                                </span>
                                                <i
                                                  className={`ri-delete-bin-5-line text-${
                                                    dt?.id ===
                                                    validation2.values
                                                      .defaultPaymentMethodID
                                                      ? "white"
                                                      : "primary"
                                                  } fs-4 cursor-pointer`}
                                                  onClick={(e) =>
                                                    handleDelete(
                                                      e,
                                                      dt?.id,
                                                      "default"
                                                    )
                                                  }
                                                />
                                              </div>
                                            ),
                                            value: dt?.id,
                                          })
                                        )
                                      : []
                                  }
                                  onChange={(
                                    e: { value: string },
                                    option?: { action?: string }
                                  ) => {
                                    if (option?.action === "clear") {
                                      validation2.setFieldValue(
                                        "defaultPaymentMethodID",
                                        ""
                                      );
                                    } else {
                                      validation2.setFieldValue(
                                        "defaultPaymentMethodID",
                                        e.value
                                      );
                                    }
                                  }}
                                  value={{
                                    label: (
                                      <span className="d-flex align-items-center">
                                        <i
                                          className={`ri ri-${selectedDefaultPayMethod?.card?.brand}-fill fs-4xl me-2`}
                                        />
                                        {selectedDefaultPayMethod?.card?.last4
                                          ? // ? `**** **** **** ${selectedDefaultPayMethod?.card?.last4} ${selectedDefaultPayMethod?.billing_details?.name} ${selectedDefaultPayMethod?.billing_details?.email}`
                                            `**** **** **** ${selectedDefaultPayMethod?.card?.last4}`
                                          : ""}
                                      </span>
                                    ),
                                    value:
                                      validation2.values.defaultPaymentMethodID,
                                  }}
                                />
                                {validation2.touched.defaultPaymentMethodID &&
                                validation2.errors.defaultPaymentMethodID ? (
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    {validation2.errors.defaultPaymentMethodID}
                                  </Form.Control.Feedback>
                                ) : null}
                                <p className="text-muted mt-1">
                                  We will use this payment method if your
                                  primary payment method does not work.
                                </p>
                              </div>
                              <Button
                                variant="primary"
                                type="submit"
                                disabled={loading3}
                              >
                                {loading3 && (
                                  <Spinner size="sm" animation="border" />
                                )}{" "}
                                Save
                              </Button>
                            </Form>
                          </Col>
                        </Row>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AddPaymentMethod);
