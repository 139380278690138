import React, { useEffect, useState } from "react";
import { Container, Card, Row, Col, Accordion } from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  capitalizeString,
  customSelectTheme,
  getCurrentDateTime,
  last12Months,
  removeKeyFromObj,
  sortObjectAlphabetically,
  styleObj,
} from "helpers/common";
import Loader from "assets/images/spinner-dark.svg";
import withRouter from "Common/withRouter";
import { createSelector } from "reselect";
import Select from "react-select";
// import Tooltip from "rc-tooltip";
import { getUsageSummaryThunk, getUsageTransactionsThunk, getSubTransactionDataThunk } from "slices/thunk";
import { Link, useSearchParams } from "react-router-dom";
import { setLocal } from "helpers/services/auth/auth";
import { setSubTransactionData, toggleSubTransactionActiveKey } from "slices/UsageSummary/reducer";

const selectOpts = [...last12Months()];

const tableData = ({ key, dt1, label, link, props }: any) => {
  return label ? (
    <Col key={key} xs={6} sm={3} md={6} lg={3} xl={2} className="mb-2">
      <Link
        to={link || "#"}
        onClick={() => {
          dt1?.toLowerCase() === "number" &&
            setLocal(
              "numberSortingRoute",
              `${props.router.location?.pathname}${props.router.location?.search}`
            );
        }}
      >
        {label}
      </Link>
    </Col>
  ) : null;
};

const formatLabel = (str: string) => {
  // Special cases for acronyms
  if (str.includes('2FA')) {
    return str.replace('2FARenew', '2FA Renew');
  }
  
  // Handle specific acronyms
  if (str === 'SMS' || str === 'MMS') {
    return str;
  }

  // Handle underscore separated uppercase strings
  if (/^[A-Z_]+$/.test(str)) {
    return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
  
  // For other cases, split by capital letters and format
  return str
    .split(/(?=[A-Z])/)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

// Helper function to get transaction display config based on service type
const getTransactionConfig = (serviceType: string, data: any) => {
  const configs: any = {
    sms: {
      label: data?.sid,
      link: `/sms-logs-details/${data?.sid}`,
    },
    mms: {
      label: data?.sid,
      link: `/mms-logs-details/${data?.sid}`,
    },
    brand: {
      label: data?.transactionMetadata?.brandId,
      link: `/brands/${data?.transactionMetadata?.brandId}`,
    },
    campaign: {
      label: data?.transactionMetadata?.campaignUuid || data?.transactionMetadata?.campaignId,
      link: data?.transactionMetadata?.campaignUuid
        ? `/campaigns/${data?.transactionMetadata?.campaignUuid}`
        : `/campaigns/${data?.transactionMetadata?.campaignId}`,
    },
    number: {
      label: data?.transactionMetadata?.phoneNumbers?.[0],
      link: `/mynumbers/${data?.transactionMetadata?.phoneNumbers?.[0]}/${data?.sid}`,
    },
  };

  return configs[serviceType.toLowerCase()] || null;
};

const Summary = (props: any) => {
  document.title = "Signal House Portal Usage Summary";
  const dispatch = useDispatch<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const month2 = searchParams.get("month");

  const [month, setMonth] = useState(
    month2 || `${moment().format("MM")}-${moment().format("YYYY")}`
  );
  const [summaryObj, setSummaryObj] = useState<any>({});
  const [activeAcc, setActiveAcc] = useState<any>("");

  const selectProfile = createSelector(
    (state: any) => state.UsageSummary,
    (usageSummary) => ({
      loading: usageSummary.loading,
      loading2: usageSummary.loading2,
      usageSummaryObj: usageSummary.usageSummaryObj,
      usageTransactionsObj: usageSummary.usageTransactionsObj,
      subTransactions: usageSummary.subTransactions,
      loadingSubTransactions: usageSummary.loadingSubTransactions,
      error: usageSummary.error,
    })
  );

  const { loading, loading2, usageSummaryObj, usageTransactionsObj, subTransactions, loadingSubTransactions } =
    useSelector(selectProfile);

  useEffect(() => {
    if (month) {
      const monthTemp: any = month?.split("-");
      const startDate = moment([monthTemp?.[1], monthTemp?.[0] - 1]);
      const endDate = moment(startDate).add(1, "month").subtract(1, "day");
      const formattedStartDate = moment(startDate, "MM-DD-YYYY").format(
        "YYYY-MM-DD"
      );
      const formattedEndDate = moment(endDate, "MM-DD-YYYY").format(
        "YYYY-MM-DD"
      );
      dispatch(getUsageSummaryThunk(formattedStartDate, formattedEndDate));
    }
  }, [month]);

  useEffect(() => {
    if (Object.keys(usageSummaryObj).length > 0) {
      const temp = removeKeyFromObj(usageSummaryObj?.total, [
        "total",
        "transactions",
      ]);
      setSummaryObj(temp);
    }
  }, [usageSummaryObj]);

  useEffect(() => {
    activeAcc &&
      month &&
      activeAcc !== "SMS" &&
      activeAcc !== "MMS" &&
      handleGetData(1, 50);
  }, [activeAcc]);

  const handleGetData = (page: number, perPage: number) => {
    const monthTemp: any = month?.split("-");
    const startDate = moment([monthTemp?.[1], monthTemp?.[0] - 1]);
    const endDate = moment(startDate).add(1, "month").subtract(1, "day");
    const formattedStartDate = moment(startDate, "MM-DD-YYYY").format(
      "YYYY-MM-DD"
    );
    const formattedEndDate = moment(endDate, "MM-DD-YYYY").format("YYYY-MM-DD");
    dispatch(
      getUsageTransactionsThunk(
        activeAcc || "SMS",
        formattedStartDate,
        formattedEndDate,
        page,
        perPage
      )
    );
  };

  const handleSubAccordionClick = (mainCategory: string, subCategory: string) => {
    // Skip for SMS and MMS
    if (mainCategory === 'SMS' || mainCategory === 'MMS') {
      return;
    }

    const serviceType = `${mainCategory}.${subCategory}`.toLowerCase();
    
    if (subTransactions[serviceType]?.activeKey === subCategory) {
      dispatch(toggleSubTransactionActiveKey({ key: serviceType, activeKey: '' }));
    } else {
      dispatch(toggleSubTransactionActiveKey({ key: serviceType, activeKey: subCategory }));
      const monthTemp: any = month?.split("-");
      const startDate = moment([monthTemp[1], monthTemp[0] - 1])
        .format("YYYY-MM-DD");
      const endDate = moment(startDate)
        .add(1, "month")
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      dispatch(getSubTransactionDataThunk(mainCategory, subCategory, startDate, endDate));
    }
  };

  // console.log("usageTransactionsObj", usageTransactionsObj);
  useEffect(() => {
    if (usageTransactionsObj?.records) {
      const currentActiveKey = Object.keys(subTransactions).find(key => 
        subTransactions[key]?.activeKey
      );
      
      if (currentActiveKey) {
        dispatch(setSubTransactionData({ 
          key: currentActiveKey, 
          data: {
            ...usageTransactionsObj,
            activeKey: currentActiveKey
          }
        }));
      }
    }
  }, [usageTransactionsObj]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="All Product Usage"
            isBack={true}
            backClick={() => props.router.navigate("/settings/billing")}
          />
          <div className="wrapper">
            <Card>
              <Card.Body className="">
                <p className="mb-1 d-flex align-items-center">
                  Cost listed here may exclude taxes, fees, support fees, expert
                  services costs and certain other products.
                  {/* <Tooltip placement="bottomRight" overlay=""> */}
                  {/* </Tooltip> */}
                </p>

                <div className="mb-4 d-flex flex-row justify-content-between">
                  <div>
                    <Select
                      styles={styleObj(false)}
                      theme={customSelectTheme}
                      options={selectOpts}
                      onChange={(e: { value: string }) => {
                        setMonth(e.value);
                        setSearchParams({
                          month: e.value,
                        });
                      }}
                      value={selectOpts?.find((dt) => dt?.value === month)}
                    />
                  </div>
                  <div>
                    <p className="mb-0">As of {getCurrentDateTime()}</p>
                  </div>
                </div>
                {loading ? (
                  <div style={{ height: 60 }} className="position-relative">
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                      alt="loading"
                    />
                  </div>
                ) : (
                  <>
                    <Row
                      style={{
                        borderBottom: "1px solid rgba(0,0,0,.12)",
                        margin: 0,
                      }}
                    >
                      <Col className="mb-2 fs-md fw-semibold">Product</Col>
                      <Col
                        xs={3}
                        sm={3}
                        md={2}
                        className="mb-2 fs-md fw-semibold text-end"
                      >
                        Quantity
                      </Col>
                      <Col
                        xs={3}
                        sm={3}
                        md={2}
                        className="mb-2 fs-md fw-semibold text-end"
                      >
                        Unit Price
                      </Col>
                      <Col
                        xs={3}
                        sm={3}
                        md={2}
                        className="mb-2 fs-md fw-semibold text-end"
                      >
                        Cost
                      </Col>
                    </Row>
                    {Object.keys(summaryObj)?.length > 0 ? (
                      Object.keys(sortObjectAlphabetically(summaryObj)).map(
                        (dt1, i1) =>
                          typeof summaryObj?.[dt1] === "object" &&
                          !Array.isArray(summaryObj?.[dt1]) &&
                          summaryObj?.[dt1] !== null ? (
                            <Accordion
                              alwaysOpen={false}
                              activeKey={activeAcc}
                              className="accordion-flush summary-accordian"
                              key={i1}
                            >
                              <Accordion.Item eventKey={dt1}>
                                <Accordion.Header
                                  id={dt1}
                                  style={{
                                    borderBottom: "1px solid rgba(0,0,0,.12)",
                                  }}
                                  className="lefticon-accordion"
                                  onClick={() => {
                                    if (activeAcc === dt1) {
                                      setActiveAcc("");
                                    } else {
                                      setActiveAcc(dt1);
                                    }
                                  }}
                                >
                                  <Row>
                                    <Col>
                                      <div>
                                        <Link
                                          to={`/settings/usage-transactions?type=${dt1}&month=${month}`}
                                        >
                                          {dt1 === "SMS" || dt1 === "MMS"
                                            ? dt1.toUpperCase()
                                            : capitalizeString(dt1)}
                                        </Link>
                                      </div>
                                    </Col>
                                    <Col
                                      xs={3}
                                      sm={3}
                                      md={2}
                                      className="text-end"
                                    >
                                      {
                                        usageSummaryObj?.total?.[dt1]
                                          ?.transactions
                                      }
                                    </Col>
                                    <Col
                                      xs={3}
                                      sm={3}
                                      md={2}
                                      className="text-end"
                                    ></Col>
                                    <Col
                                      xs={3}
                                      sm={3}
                                      md={2}
                                      className="text-end"
                                    >
                                      $
                                      {usageSummaryObj?.total?.[dt1]?.total
                                        ? (+usageSummaryObj?.total?.[dt1]
                                            ?.total)?.toFixed(2)
                                        : 0}
                                    </Col>
                                  </Row>
                                </Accordion.Header>
                                <Accordion.Body className="p-0">
                                  {Object.keys(summaryObj?.[dt1])?.length > 0
                                    ? Object.keys(summaryObj?.[dt1]).map(
                                        (dt2, i2) => {
                                          const itemData = usageSummaryObj?.total?.[dt1]?.[dt2];
                                          const subKey = `${dt1}.${dt2}`?.toLowerCase();

                                          // console.log("subTransactions",subKey,subTransactions?.[subKey]?.records, subTransactions?.[subKey]?.activeKey);
                                          
                                          return (
                                            <Accordion
                                              key={i2}
                                              activeKey={dt1 === 'SMS' || dt1 === 'MMS' ? '' : subTransactions[subKey]?.activeKey}
                                              className={`nested-accordion ${dt1 === 'SMS' || dt1 === 'MMS' ? 'disabled-accordion' : ''}`}
                                            >
                                              <Accordion.Item eventKey={dt2}>
                                                <Accordion.Header
                                                  onClick={() => {
                                                    // Only handle sub-accordion click if not SMS/MMS
                                                    if (dt1 !== 'SMS' && dt1 !== 'MMS') {
                                                      handleSubAccordionClick(dt1, dt2);
                                                    }
                                                  }}
                                                >
                                                  <Row className="w-100">
                                                    <Col>
                                                      {formatLabel(dt2)}
                                                    </Col>
                                                    <Col xs={3} sm={3} md={2} className="text-end">
                                                      {itemData?.transactions}
                                                    </Col>
                                                    <Col xs={3} sm={3} md={2} className="text-end">
                                                      ${itemData?.unitPrice ? (+itemData?.unitPrice)?.toFixed(2) : 0}
                                                      {itemData?.renewalTransactions ? "/mo" : ""}
                                                    </Col>
                                                    <Col xs={3} sm={3} md={2} className="text-end">
                                                      ${itemData?.total ? (+itemData?.total)?.toFixed(2) : 0}
                                                    </Col>
                                                  </Row>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                  {loadingSubTransactions[subKey] ? (
                                                    <div style={{ height: 60 }} className="position-relative">
                                                      <img
                                                        src={Loader}
                                                        className={`position-absolute top-50 start-50 translate-middle`}
                                                        alt="loading"
                                                      />
                                                    </div>
                                                  ) : (
                                                    <Row className="p-3 pe-0 me-0">
                                                      {subTransactions[subKey]?.records?.length > 0 ? (
                                                        <Row>
                                                          {subTransactions[subKey].records.map((dt3: any, i3: number) => {
                                                            const config = getTransactionConfig(dt1, dt3);
                                                            return config && config.label ? (
                                                              <Col key={i3} xs={6} sm={3} md={6} lg={3} xl={2} className="mb-2">
                                                                <Link
                                                                  to={config.link}
                                                                  onClick={() => {
                                                                    dt1?.toLowerCase() === "number" &&
                                                                      setLocal(
                                                                        "numberSortingRoute",
                                                                        `${props.router.location?.pathname}${props.router.location?.search}`
                                                                      );
                                                                  }}
                                                                >
                                                                  {config.label}
                                                                </Link>
                                                              </Col>
                                                            ) : null;
                                                          })}
                                                        </Row>
                                                      ) : (
                                                        <div className="mb-2">No Data Found</div>
                                                      )}
                                                      {subTransactions[subKey]?.total > 50 && (
                                                        <Link
                                                          to={`/settings/usage-transactions?type=${subKey}&month=${month}`}
                                                          className="text-decoration-underline mt-2"
                                                        >
                                                          View All
                                                        </Link>
                                                      )}
                                                    </Row>
                                                  )}
                                                </Accordion.Body>
                                              </Accordion.Item>
                                            </Accordion>
                                          );
                                        }
                                      )
                                    : null}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          ) : (
                            <div>{dt1}</div>
                          )
                      )
                    ) : (
                      <p className="mt-3">No Record Found</p>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
      <style>
        {`
          .nested-accordion {
            margin-left: 1.5rem;
          }
          .nested-accordion .accordion-button {
            padding-left: 1.5rem;
          }
          .nested-accordion .accordion-body {
            background-color: rgba(0,0,0,.02);
            padding: 0;
          }
          .nested-accordion .accordion-button::after {
            position: absolute;
            left: 0;
          }
          .nested-accordion .accordion-item {
            border: none;
          }
          .disabled-accordion .accordion-button {
            pointer-events: none;
          }
          .disabled-accordion .accordion-button::after {
            display: none;
          }
        `}
      </style>
    </React.Fragment>
  );
};

export default withRouter(Summary);
