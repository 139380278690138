import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import CountUp from "react-countup";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import Loader from "assets/images/spinner-dark.svg";
import { useNavigate } from "react-router-dom";

const generateRandomNumber = () => {
  const min = 20;
  const max = 100;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const Widgets = ({ dashboardAnalyticsData, validation }: any) => {
  const selectProfile = createSelector(
    (state: any) => state.UsageSummary,
    (state: any) => state.Analytics,
    (state: any) => state.Campaigns,
    (usageSummary, analytics, campaigns) => ({
      loading: usageSummary.loading,
      loading2: campaigns.loading,
      loading3: analytics.loading,
      campaignsData: campaigns.campaignsData,
    })
  );

  const { loading, loading2, loading3, campaignsData } =
    useSelector(selectProfile);

  const navigate = useNavigate();

  const appendUrl = (url: string) => {
    const temp1 = { ...validation.values };
    if (url.startsWith("/campaigns?")) {
      delete temp1.startDate;
      delete temp1.endDate;
      delete temp1.createdAt;
    }

    let paramKeys: any = [];

    Object.keys(temp1).map((key) => {
      paramKeys.push(
        key +
          "=" +
          (typeof temp1[key] === "string"
            ? temp1[key]
            : JSON.stringify(temp1[key]))
      );
      return paramKeys;
    });

    const queryString =
      paramKeys && paramKeys.length ? paramKeys.join("&") : "";

    return navigate(`${url}&isFilter=true&${queryString}`);
  };

  return (
    <React.Fragment>
      {loading || loading2 || loading3 ? (
        <div className="position-relative" style={{ height: 200 }}>
          <img
            src={Loader}
            className="position-absolute top-50 start-50 translate-middle"
          />
        </div>
      ) : (
        <Row>
          <Col xxl sm={6} className="mb-4">
            <Card className="overflow-hidden mb-0 h-100">
              <Card.Body>
                <div className="avatar-sm float-end">
                  <div
                    className="avatar-title bg-primary-subtle text-white fs-2xl rounded subContent"
                    onClick={() => appendUrl("/analytics?type=all")}
                  >
                    <i className="ri-question-answer-line text-primary"></i>
                  </div>
                </div>
                <p
                  onClick={() => appendUrl("/analytics?type=all")}
                  className="text-muted mb-3 subContent"
                >
                  Messaging
                </p>
                <h4>
                  <span className="counter-value">
                    <CountUp
                      start={0}
                      end={
                        dashboardAnalyticsData?.messagingSummary
                          ?.totalMessages || 0
                      }
                    />
                  </span>
                </h4>
                <p
                  onClick={() => appendUrl("/analytics?type=sms_outbound")}
                  className="text-muted mb-1 subContent"
                >
                  SMS outbound:{" "}
                  {dashboardAnalyticsData?.messagingSummary?.sms?.outbound ||
                    "0"}
                </p>
                <p
                  onClick={() => appendUrl("/analytics?type=sms_inbound")}
                  className="text-muted mb-1 subContent"
                >
                  SMS inbound:{" "}
                  {dashboardAnalyticsData?.messagingSummary?.sms?.inbound ||
                    "0"}
                </p>
                <p
                  onClick={() => appendUrl("/analytics?type=mms_outbound")}
                  className="text-muted mb-1 subContent"
                >
                  MMS outbound:{" "}
                  {dashboardAnalyticsData?.messagingSummary?.mms?.outbound ||
                    "0"}
                </p>
                <p
                  onClick={() => appendUrl("/analytics?type=mms_inbound")}
                  className="text-muted mb-0 subContent"
                >
                  MMS inbound:{" "}
                  {dashboardAnalyticsData?.messagingSummary?.mms?.inbound ||
                    "0"}
                </p>
              </Card.Body>
              <div
                className="progress progress-sm rounded-0"
                role="progressbar"
              >
                <div
                  className="progress-bar bg-primary"
                  style={{ width: `${generateRandomNumber()}%` }}
                ></div>
              </div>
            </Card>
          </Col>
          <Col xxl sm={6} className="mb-4">
            <Card className="overflow-hidden mb-0 h-100">
              <Card.Body>
                <div className="avatar-sm float-end">
                  <div
                    className="avatar-title bg-secondary-subtle text-white fs-2xl rounded subContent"
                    onClick={() => appendUrl("/analytics?type=all-d-rate")}
                  >
                    <i className="ri-arrow-right-down-line text-secondary"></i>
                  </div>
                </div>
                <p
                  onClick={() => appendUrl("/analytics?type=all-d-rate")}
                  className="text-muted mb-3 subContent"
                >
                  Delivery Rate
                </p>
                <h4>
                  <span className="counter-value">
                    <CountUp
                      suffix="%"
                      decimals={2}
                      start={0}
                      end={
                        dashboardAnalyticsData?.metrics?.deliveryRate?.total ||
                        0
                      }
                    />
                  </span>
                </h4>
                {Object.keys(dashboardAnalyticsData?.metrics?.deliveryRate)
                  ?.length > 0
                  ? Object.keys(
                      dashboardAnalyticsData?.metrics?.deliveryRate
                    )?.map(
                      (dt, i) =>
                        dt !== "All" &&
                        dt !== "total" && (
                          <p
                            className="text-muted mb-1 subContent"
                            key={i}
                            style={{ textTransform: "uppercase" }}
                            onClick={() =>
                              dt?.toLowerCase() === "sms"
                                ? appendUrl(
                                    "/analytics?type=sms_outbound_d-rate"
                                  )
                                : appendUrl(
                                    "/analytics?type=mms_outbound_d-rate"
                                  )
                            }
                          >
                            {dt || ""}:{" "}
                            {dashboardAnalyticsData?.metrics?.deliveryRate?.[
                              dt
                            ] || 0}
                            %
                          </p>
                        )
                    )
                  : null}
              </Card.Body>
              <div
                className="progress progress-sm rounded-0"
                role="progressbar "
              >
                <div
                  className="progress-bar bg-secondary"
                  style={{ width: `${generateRandomNumber()}%` }}
                ></div>
              </div>
            </Card>
          </Col>
          <Col xxl sm={6} className="mb-4">
            <Card className="overflow-hidden mb-0 h-100">
              <Card.Body>
                <div className="avatar-sm float-end">
                  <div
                    className="avatar-title bg-primary-subtle text-white fs-2xl rounded subContent"
                    onClick={() => appendUrl("/analytics?type=optout-rate")}
                  >
                    <i className="bi bi-send text-primary"></i>
                  </div>
                </div>
                <p
                  onClick={() => appendUrl("/analytics?type=optout-rate")}
                  className="text-muted mb-3 subContent"
                >
                  Opt Out Rate
                </p>
                <h4>
                  <span className="counter-value">
                    <CountUp
                      suffix="%"
                      decimals={2}
                      start={0}
                      end={
                        dashboardAnalyticsData?.metrics?.optOutRate?.total || 0
                      }
                    />
                  </span>
                </h4>
                {Object.keys(dashboardAnalyticsData?.metrics?.optOutRate)
                  ?.length > 0
                  ? Object.keys(
                      dashboardAnalyticsData?.metrics?.optOutRate
                    )?.map(
                      (dt, i) =>
                        dt !== "All" &&
                        dt !== "total" && (
                          <p
                            className="text-muted mb-1 subContent"
                            key={i}
                            style={{ textTransform: "uppercase" }}
                            onClick={() =>
                              dt?.toLowerCase() === "sms"
                                ? appendUrl("/analytics?type=sms_optout-rate")
                                : appendUrl("/analytics?type=mms_optout-rate")
                            }
                          >
                            {dt || ""}:{" "}
                            {dashboardAnalyticsData?.metrics?.optOutRate?.[
                              dt
                            ] || 0}
                            %
                          </p>
                        )
                    )
                  : null}
              </Card.Body>
              <div
                className="progress progress-sm rounded-0"
                role="progressbar "
              >
                <div
                  className="progress-bar bg-primary"
                  style={{ width: `${generateRandomNumber()}%` }}
                ></div>
              </div>
            </Card>
          </Col>
          <Col xxl sm={6} className="mb-4">
            <Card className="overflow-hidden mb-0 h-100">
              <Card.Body>
                <div className="avatar-sm float-end">
                  <div
                    className="avatar-title bg-secondary-subtle text-white fs-2xl rounded subContent"
                    onClick={() => appendUrl("/analytics?type=non_optout_rate")}
                  >
                    <i className="ri-line-chart-line text-secondary"></i>
                  </div>
                </div>
                <p
                  onClick={() => appendUrl("/analytics?type=non_optout_rate")}
                  className="text-muted mb-3 subContent"
                >
                  Non Opt Out Response Rate
                </p>
                <h4>
                  <span className="counter-value">
                    <CountUp
                      suffix="%"
                      decimals={2}
                      start={0}
                      end={
                        dashboardAnalyticsData?.metrics?.ResponseRate?.total ||
                        0
                      }
                    />
                  </span>
                </h4>
                {Object.keys(dashboardAnalyticsData?.metrics?.ResponseRate)
                  ?.length > 0
                  ? Object.keys(
                      dashboardAnalyticsData?.metrics?.ResponseRate
                    )?.map(
                      (dt, i) =>
                        dt !== "All" &&
                        dt !== "total" && (
                          <p
                            className="text-muted mb-1 subContent"
                            key={i}
                            style={{ textTransform: "uppercase" }}
                            onClick={() =>
                              dt?.toLowerCase() === "sms"
                                ? appendUrl(
                                    "/analytics?type=sms_non_optout_rate"
                                  )
                                : appendUrl(
                                    "/analytics?type=mms_non_optout_rate"
                                  )
                            }
                          >
                            {dt || ""}:{" "}
                            {dashboardAnalyticsData?.metrics?.ResponseRate?.[
                              dt
                            ] || 0}
                            %
                          </p>
                        )
                    )
                  : null}
              </Card.Body>
              <div
                className="progress progress-sm rounded-0"
                role="progressbar"
              >
                <div
                  className="progress-bar bg-secondary"
                  style={{ width: `${generateRandomNumber()}%` }}
                ></div>
              </div>
            </Card>
          </Col>
          <Col xxl sm={6} className="mb-4">
            <Card className="overflow-hidden mb-0 h-100">
              <Card.Body>
                <div className="avatar-sm float-end">
                  <div
                    className="avatar-title bg-primary-subtle text-white fs-2xl rounded subContent"
                    onClick={() => appendUrl("/campaigns?")}
                  >
                    <i className="ph-megaphone text-primary"></i>
                  </div>
                </div>
                <p
                  onClick={() => appendUrl("/campaigns?")}
                  className="text-muted mb-3 subContent"
                >
                  Campaign Status
                </p>
                <h4>
                  <span className="counter-value">
                    <CountUp start={0} end={campaignsData.totalRecords || 0} />
                  </span>
                </h4>
                <p
                  onClick={() =>
                    appendUrl("/campaigns?dcaComplete=underReview")
                  }
                  className="text-muted mb-1 subContent"
                >
                  Under Signal House Review:{" "}
                  {campaignsData.underSignalHouseReviewCampaigns || 0}
                </p>
                <p
                  onClick={() =>
                    appendUrl("/campaigns?dcaComplete=rejectedReview")
                  }
                  className="text-muted mb-1 subContent"
                >
                  Signal House Rejected:{" "}
                  {campaignsData.rejectedSignalHouseReviewCampaigns || 0}
                </p>
                <p
                  onClick={() =>
                    appendUrl("/campaigns?dcaComplete=approvedReview")
                  }
                  className="text-muted mb-1 subContent"
                >
                  Signal House Approved:{" "}
                  {campaignsData.approvedSignalHouseReviewCampaigns || 0}
                </p>
                <p
                  onClick={() => appendUrl("/campaigns?dcaComplete=Pending")}
                  className="text-muted mb-1 subContent"
                >
                  Pending DCA Review: {campaignsData.pendingCampaigns || 0}
                </p>
                <p
                  onClick={() => appendUrl("/campaigns?dcaComplete=Rejected")}
                  className="text-muted mb-1 subContent"
                >
                  DCA Rejected: {campaignsData.rejectedCampaigns || 0}
                </p>
                <p
                  onClick={() => appendUrl("/campaigns?dcaComplete=Approved")}
                  className="text-muted mb-0 subContent"
                >
                  DCA Approved: {campaignsData.approvedCampaigns || 0}
                </p>
              </Card.Body>
              <div
                className="progress progress-sm rounded-0"
                role="progressbar"
              >
                <div
                  className="progress-bar bg-primary"
                  style={{ width: `${generateRandomNumber()}%` }}
                ></div>
              </div>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default Widgets;
