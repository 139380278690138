import {
  brandDetailsApi,
  brandListApi,
  createBrandApi,
  updateBrandApi,
  revertBrandApi,
  deleteBrandApi,
  appealStatusApi,
  getAppealsApi,
  fileUplaoadApi,
  getEvidenceApi,
  brandAppealCategoryListApi,
  deleteAppealAttachmentApi,
  getEvidencesApi,
  // applyVettingApi,
  applyAndImportVettingApi,
  applyAndImportVettingEvpApi,
  vattingSubmitApi,
  importVattingSubmitApi,
  getVattingListApi,
  downloadAppealAttachmentApi,
  enhancedVettingReportApi,
  vettingDetailsAppealApi,
  getBrandFeedbackApi,
} from "helpers/apiroute_helper";
import {
  apiLoading,
  brandsDataSuccessful,
  brandsDataFailed,
  brandDetailsSuccessful,
  brandDetailsFailed,
  brandCreateSuccessful,
  brandCreateFailed,
  brandUpdateSuccessful,
  brandUpdateFailed,
  brandRevertSuccessful,
  brandRevertFailed,
  brandDeleteSuccessful,
  brandDeleteFailed,
  brandAppealPending,
  brandAppealSuccessful,
  brandAppealFailed,
  appealsInfoPending,
  appealsInfoSuccessful,
  appealsInfoFailed,
  fileUploadPending,
  fileUploadSuccess,
  fileUploadFailed,
  getEvidenceFilePending,
  getEvidenceFileSuccess,
  getEvidenceFileFailed,
  fetchVettingBrandSuccess,
  fetchVettingBrandFailure,
  fetchVettingBrandOptionsSuccess,
  fetchVettingBrandOptionsFailure,
  // externalVettingRequest,
  externalVettingSuccess,
  externalVettingFailure,
  ImportVettingSuccessful,
  ImportVettingFailed,
  fetchVettingBrandListSuccess,
  fetchVettingBrandListFailure,
  downloadVettingAchmentSuccess,
  downloadVettingAchmentFailure,
  brandsDataReset,
  enhancedVettingReportSuccess,
  enhancedVettingReportFailure,
  vettingDetailsAppealSuccessful,
  vettingDetailsAppealFailed,
  getBrandFeedbackSuccess,
  getBrandFeedbackFailure,
} from "./reducer";
import { toast } from "react-toastify";

export const getBrandListThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await brandListApi(page, recordsPerPage, otherParams);

      if (data?.code || data?.message) {
        dispatch(brandsDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(brandsDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(brandsDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const resetBrandListThunk = () => async (dispatch: any) => {
  dispatch(apiLoading());
  dispatch(brandsDataReset());
};

export const getBrandDetailsThunk = (id: string) => async (dispatch: any) => {
  dispatch(apiLoading());

  try {
    const data: any = await brandDetailsApi(id);

    if (data?.code || data?.message) {
      dispatch(brandDetailsFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(brandDetailsSuccessful(data));
    }
  } catch (error: any) {
    dispatch(brandDetailsFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const createbrandThunk =
  (brand: any, cb: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading());

      let data: any = await createBrandApi({
        ...brand,
      });

      if (data?.code || data?.errorCode) {
        dispatch(brandCreateFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(brandCreateSuccessful(data));
        toast.success("Brand Created successfully");
        cb && cb(data.brandId);
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(brandCreateFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const updateBrandThunk =
  (brandId: string, brandData: any, history?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading());

      const data: any = await updateBrandApi(brandId, brandData);

      if (data?.code || data?.errorCode) {
        dispatch(brandUpdateFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(brandUpdateSuccessful(data));
        toast.success("Brand updated successfully");
        history && history(`/brands/${brandId}`);
      }
    } catch (error: any) {
      dispatch(brandUpdateFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const revertBrandThunk = (brandId: string) => async (dispatch: any) => {
  try {
    dispatch(apiLoading());

    const data: any = await revertBrandApi(brandId);

    if (data?.code || data?.message) {
      dispatch(brandRevertFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(brandRevertSuccessful(data));
      toast.success("Brand resubmitted successfully");
    }
  } catch (error: any) {
    dispatch(brandRevertFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const deleteBrandThunk =
  (brandId: string, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading());

      const data: any = await deleteBrandApi(brandId);

      if (data?.code || data?.message) {
        dispatch(brandDeleteFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(brandDeleteSuccessful(data));
        toast.success("Brand deleted successfully");
        cb && cb();
      }
    } catch (error: any) {
      dispatch(brandDeleteFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const checkBrandAppealStatusThunk =
  (brandId: string, appealData: any) => async (dispatch: any) => {
    try {
      dispatch(brandAppealPending());
      const data: any = await appealStatusApi(brandId, appealData);
      if (data?.code || data?.message) {
        dispatch(brandAppealFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(brandAppealSuccessful(data));
      }
    } catch (error: any) {
      dispatch(brandAppealFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getBrandAppealHistoryThunk =
  (brandId: string) => async (dispatch: any) => {
    try {
      dispatch(appealsInfoPending());
      const data: any = await getAppealsApi(brandId);
      if (data?.code || data?.message) {
        dispatch(appealsInfoFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(appealsInfoSuccessful(data));
      }
    } catch (error: any) {
      dispatch(appealsInfoFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const uploadEvidenceThunk =
  (brandId: string, payload: any) => async (dispatch: any) => {
    dispatch(fileUploadPending());
    try {
      const response: any = await fileUplaoadApi(brandId, payload);
      if (response?.code || response?.message) {
        dispatch(fileUploadFailed(response));
        toast.error(response?.message || "");
      } else {
        dispatch(fileUploadSuccess(response));
        toast.success("Evidence files added successfully");
      }
    } catch (error: any) {
      dispatch(fileUploadFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getUploadedEvidenceThunk =
  (brandId: string) => async (dispatch: any) => {
    dispatch(getEvidenceFilePending());
    try {
      const response: any = await getEvidenceApi(brandId);
      if (response?.code || response?.errorCode) {
        dispatch(getEvidenceFileFailed(response));
        toast.error(response?.message || "");
      } else {
        dispatch(getEvidenceFileSuccess(response));
      }
    } catch (error: any) {
      dispatch(getEvidenceFileFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

// brandAppealCategoryListApi
// brand appeal category enums list thunk
export const appealCategoryOptionsThunk = () => async (dispatch: any) => {
  try {
    dispatch(apiLoading());
    const data: any = await brandAppealCategoryListApi();

    if (data?.code || data?.message) {
      dispatch(brandsDataFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(brandsDataSuccessful(data));
    }
  } catch (error: any) {
    dispatch(brandsDataFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

//brands appeals delete thunk
export const deleteBrandCampaignEvidenceThunk =
  (attachmentId: string) => async (dispatch: any) => {
    try {
      dispatch(apiLoading());

      const data: any = await deleteAppealAttachmentApi(attachmentId);

      if (data?.code || data?.message) {
        dispatch(brandDeleteFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(brandDeleteSuccessful(data));
        toast.success("attachment deleted successfully");
      }
    } catch (error: any) {
      dispatch(brandDeleteFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getUploadedEvidencesThunk =
  (attachmentId: string) => async (dispatch: any) => {
    dispatch(getEvidenceFilePending());
    try {
      const response: any = await getEvidencesApi(attachmentId);
      if (response?.code || response?.message) {
        dispatch(getEvidenceFileFailed(response));
        toast.error(response?.message || "");
      } else {
        dispatch(getEvidenceFileSuccess(response));
      }
    } catch (error: any) {
      dispatch(getEvidenceFileFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

//vetting Brand

export const applyVettingThunk = () => async (dispatch: any) => {
  dispatch(apiLoading());
  try {
    const response: any = await applyAndImportVettingApi();
    if (response?.code || response?.message) {
      dispatch(fetchVettingBrandFailure(response));
      toast.error(response?.message || "");
    } else {
      dispatch(fetchVettingBrandSuccess(response));
    }
  } catch (error: any) {
    dispatch(fetchVettingBrandFailure(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const applyVettingEvpThunk = () => async (dispatch: any) => {
  dispatch(apiLoading());
  try {
    const response: any = await applyAndImportVettingEvpApi();
    if (response?.code || response?.message) {
      dispatch(fetchVettingBrandOptionsFailure(response));
      toast.error(response?.message || "");
    } else {
      dispatch(fetchVettingBrandOptionsSuccess(response));
    }
  } catch (error: any) {
    dispatch(fetchVettingBrandOptionsFailure(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

//Vatting Submit
export const submitVettingDataThunk =
  (brandId: string, payload: any, cb?: any) => async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      const response: any = await vattingSubmitApi(brandId, payload);
      if (response?.code || response?.message) {
        dispatch(externalVettingFailure(response));
        toast.error(response?.message || "");
      } else {
        dispatch(externalVettingSuccess(response));
        cb && cb();
      }
    } catch (error: any) {
      dispatch(externalVettingFailure(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

//Import Vatting Submit
export const importVattingSubmitThunk =
  (brandId: string, payload: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading());

      const data: any = await importVattingSubmitApi(brandId, payload);

      if (data?.code || data?.message) {
        dispatch(brandRevertFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(ImportVettingSuccessful(data));
        toast.success("Brand reverted successfully");
      }
    } catch (error: any) {
      dispatch(ImportVettingFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getVattingListThunk =
  (brandId: string) => async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      const response: any = await getVattingListApi(brandId);
      if (response?.code || response?.message) {
        dispatch(fetchVettingBrandListFailure(response));
        toast.error(response?.message || "");
      } else {
        dispatch(fetchVettingBrandListSuccess(response));
      }
    } catch (error: any) {
      dispatch(fetchVettingBrandListFailure(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

// Download Brand Appeal attachment
export const getDownloadVattingAttachmentThunk =
  (path: string, fileName: string, mimeType: string, cb?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      const response: any = await downloadAppealAttachmentApi(path);

      if (response) {
        dispatch(downloadVettingAchmentSuccess(response));
        cb &&
          cb(
            new File([response], fileName, {
              type: mimeType === "text/xml" ? "application/pdf" : mimeType,
            })
          );
      } else {
        dispatch(fetchVettingBrandListFailure(response));
        toast.error(response?.message || "Something went wrong");
      }
    } catch (error: any) {
      dispatch(downloadVettingAchmentFailure(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const enhancedVettingReportThunk =
  (brandId: string) => async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      const response: any = await enhancedVettingReportApi(brandId);
      if (response?.code || response?.errorCode) {
        dispatch(enhancedVettingReportFailure(response));
        toast.error(response?.message || "");
      } else {
        dispatch(enhancedVettingReportSuccess(response));
      }
    } catch (error: any) {
      dispatch(enhancedVettingReportFailure(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getBrandFeedbackThunk =
  (brandId: string) => async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      const response: any = await getBrandFeedbackApi(brandId);

      if (response?.code || response?.errorCode) {
        dispatch(getBrandFeedbackFailure(response));
        toast.error(response?.message || "");
      } else {
        dispatch(getBrandFeedbackSuccess(response));
      }
    } catch (error: any) {
      dispatch(getBrandFeedbackFailure(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const vettingDetailsAppealThunk =
  (brandId: string, appealData: any) => async (dispatch: any) => {
    try {
      dispatch(brandAppealPending());
      const data: any = await vettingDetailsAppealApi(brandId, appealData);

      if (data?.code || data?.message) {
        dispatch(vettingDetailsAppealFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(vettingDetailsAppealSuccessful(data));
      }
    } catch (error: any) {
      dispatch(vettingDetailsAppealFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };
