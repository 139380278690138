import {
  createTokenApi,
  getTokensApi,
  revokeTokenApi,
  updateTokenDetailApi,
} from "helpers/apiroute_helper";
import { toast } from "react-toastify";
import { setError, setLoading, setNewToken, setTokens } from "./reducer";

export const getTokensThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading());
      const response: any = await getTokensApi(
        page,
        recordsPerPage,
        otherParams
      );

      if (response?.code || response?.errorCode) {
        dispatch(setError(response.message));
        toast.error(response.message || "Failed to fetch tokens");
      } else {
        dispatch(setTokens(response));
      }
    } catch (error: any) {
      dispatch(setError(error.message));
      toast.error(error?.response?.data?.message || "Failed to fetch tokens");
    }
  };

export const createTokenThunk =
  (name: string, cb?: any) => async (dispatch: any) => {
    try {
      // dispatch(setLoading());
      const response: any = await createTokenApi({ friendlyName: name });

      if (response?.errorCode) {
        dispatch(setError(response.message));
        toast.error(response.message || "Failed to create token");
      } else {
        dispatch(setNewToken(response));
        toast.success(response.message || "Token created successfully");
        cb && cb();
      }
    } catch (error: any) {
      dispatch(setError(error.message));
      toast.error(error?.response?.data?.message || "Failed to create token");
    }
  };

export const revokeTokenThunk = (tokenId: string) => async (dispatch: any) => {
  try {
    // dispatch(setLoading());
    const response: any = await revokeTokenApi(tokenId);

    if (response?.message) {
      dispatch(setError(response.message));
      toast.error(response.message || "Failed to revoke token");
    } else {
      toast.success("Token revoked successfully");
    }
  } catch (error: any) {
    dispatch(setError(error.message));
    toast.error(error?.response?.data?.message || "Failed to revoke token");
  }
};

export const updateTokenDetailThunk =
  (tokenId: string, data: any, cb?: any) => async (dispatch: any) => {
    try {
      // dispatch(setLoading());
      const response: any = await updateTokenDetailApi(tokenId, data);

      if (response?.code || response?.errorCode) {
        dispatch(setError(response.message));
        toast.error(response.message || "Failed to update data");
      } else {
        toast.success(response.message || "Token data updated successfully");
        cb && cb();
      }
    } catch (error: any) {
      dispatch(setError(error.message));
      toast.error(error?.response?.data?.message || "Failed to update data");
    }
  };
