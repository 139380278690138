// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Button,
  Row,
  Col,
  Form,
  Spinner,
  OverlayTrigger,
  Popover,
  Tab,
  Nav,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignDetailsThunk,
  getTermsAndConditionsThunk,
  updateReviewCampaignThunk,
  openModal,
  msgFileUploadThunk,
  getMsgFileThunk,
  getTermsFromUsecaseListThunk,
  nudgeReviewCampaignThunk,
  getSubgroupDetailsThunk,
  activePhoneNumbersThunk,
  createCampaignThunk,
} from "slices/thunk";
import Loader from "assets/images/spinner-dark.svg";
import Loader2 from "assets/images/spinner-white.svg";
import withRouter from "Common/withRouter";
import { createSelector } from "reselect";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import Radiobox from "Common/Radiobox";
import TagsInput from "react-tagsinput";
import * as Yup from "yup";
import { useFormik } from "formik";
import Lightbox from "react-image-lightbox";
import { onCloseSubject, submitLoadingSubject } from "Common/modals/Modal";
import { capitalizeString, removeEmptyAndNullValues } from "helpers/common";
import Tooltip from "rc-tooltip";
import Datatable from "Common/Datatable";
import { setLocal } from "helpers/services/auth/auth";

interface HoverPopoverProps {
  children: React.ReactNode;
  icon?: any;
  placement?:
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "top-start"
    | "top-end"
    | "bottom-start"
    | "bottom-end";
  className?: string;
  popoverClassName?: string; // Added for popover-specific styling
  title?: string; // Added for cases where you need a title
  width?: number | string; // Added for custom widths
  onOpen?: () => void; // Added for tracking/analytics
  onClose?: () => void; // Added for tracking/analytics
}

const ALLOWED_FILE_TYPES = [
  ".bmp",
  ".dib",
  ".gif",
  ".jpeg",
  ".jpg",
  ".m2a",
  ".m4a",
  ".m4b",
  ".m4p",
  ".m4r",
  ".m4v",
  ".mp1",
  ".mp2",
  ".mp3",
  ".mp4",
  ".mpa",
  ".oga",
  ".ogg",
  ".ogm",
  ".ogv",
  ".ogx",
  ".png",
  ".spx",
  ".txt",
  ".wav",
  ".webm",
];

const MAX_FILE_SIZE = 10485760; // 10MB in bytes

const HoverPopover: React.FC<HoverPopoverProps> = ({
  children,
  icon,
  placement = "bottom-end",
  className = "",
  popoverClassName = "",
  title,
  width,
  onOpen,
  onClose,
}) => {
  const [show, setShow] = useState(false);

  const handleMouseEnter = () => {
    setShow(true);
    onOpen?.();
  };

  const handleMouseLeave = () => {
    setShow(false);
    onClose?.();
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={className}
    >
      <OverlayTrigger
        show={show}
        placement={placement}
        overlay={
          <Popover
            id="hover-popover"
            className={`hover-popover ${popoverClassName}`}
            style={width ? { width } : undefined}
          >
            {title && <Popover.Header>{title}</Popover.Header>}
            <Popover.Body>{children}</Popover.Body>
          </Popover>
        }
      >
        {icon || (
          <i className="ri-eye-fill align-middle text-secondary fs-lg cursor-pointer"></i>
        )}
      </OverlayTrigger>
    </div>
  );
};

export const renderStatusBadge = (status: string) => {
  switch (status?.toLowerCase()) {
    case "pending":
      return (
        <span
          data-tag="allowRowEvents"
          className="badge border"
          style={{
            borderColor: "#6c757d",
            background: "#d3d3d3e0",
            color: "#6c757d",
          }}
        >
          {capitalizeString(status)}
        </span>
      );
    case "rejected":
      return (
        <span
          data-tag="allowRowEvents"
          className="badge bg-primary border border-primary text-white"
        >
          {capitalizeString(status)}
        </span>
      );
    case "approved":
      return (
        <span
          data-tag="allowRowEvents"
          className="badge bg-secondary border border-secondary text-white"
        >
          {capitalizeString(status)}
        </span>
      );
    case "signal house review":
      return (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-secondary border border-black text-black"
        >
          {capitalizeString(status)}
        </span>
      );
    case "signal house rejected":
      return (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-secondary border border-primary text-primary"
        >
          {capitalizeString(status)}
        </span>
      );
    case "signal house approved":
      return (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-secondary border border-secondary text-secondary"
        >
          {capitalizeString(status)}
        </span>
      );
    default:
      return (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-secondary border border-secondary text-secondary"
        >
          {capitalizeString(status || "-")}
        </span>
      );
  }
};

const CampaignReviewDetails = (props: any) => {
  document.title = "Signal House Portal Campaign";
  const dispatch = useDispatch<any>();
  const isRejected = props?.isRejected;

  const [isInit, setIsInit] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [prevSrc, setPrevSrc] = useState("");
  const [isOpenImgLightBox, setIsOpenImgLightBox] = useState(false);
  const [isOpenDocLightBox, setIsOpenDocLightBox] = useState(false);
  const [isOpenVideoLightBox, setIsOpenVideoLightBox] = useState(false);
  const [campConditions, setCampConditions] = useState<any>({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [attributeChanges, setAttributeChanges] = useState<any>({});
  const [show, setShow] = useState(false);
  const [isAttributeUpdating, setIsAttributeUpdating] = useState(false);
  const [currPage3, setCurrPage3] = useState(1);
  const [rowsPerPage3, setRowsPerPage3] = useState(10);

  const selectProfile = createSelector(
    (state: any) => state.Campaigns,
    (state: any) => state.Groups,
    (state: any) => state.OwnNumbers,
    (campaigns, groups, ownNumbers) => ({
      loading: campaigns.loading,
      loading2: campaigns.loading2,
      loading5: ownNumbers.loading,
      campaignDetails: campaigns.campaignDetails,
      campaignTnC: campaigns.campaignTnC,
      campaignMnoMetadata: campaigns.campaignMnoMetadata,
      campaignTerms: campaigns.campaignTerms,
      error: campaigns.error,
      subgroupDetails: groups.subgroupDetails,
      dataObj: ownNumbers.dataObj,
    })
  );

  const {
    loading,
    loading2,
    loading5,
    campaignDetails,
    campaignTnC,
    campaignMnoMetadata,
    campaignTerms,
    subgroupDetails,
    dataObj,
  } = useSelector(selectProfile);

  const validation1: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      optinKeywords: "",
      optinMessage: "",
    },
    validationSchema: Yup.object({
      optinKeywords: Yup.string().when("subscriberOptin", () => {
        return !attributeChanges?.subscriberOptin &&
          !campaignDetails?.subscriberOptin
          ? Yup.string().max(255).notRequired()
          : Yup.string().max(255).required("Please Enter Keywords");
      }),
      optinMessage: Yup.string().when("subscriberOptin", () => {
        return !attributeChanges?.subscriberOptin &&
          !campaignDetails?.subscriberOptin
          ? Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .notRequired()
          : Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .required("Please Enter Message");
      }),
    }),
    onSubmit: (values) => {
      handleUpdateCampaign(values);
    },
  });

  const validation2: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      optoutKeywords: "",
      optoutMessage: "",
    },
    validationSchema: Yup.object({
      optoutKeywords: Yup.string().when("subscriberOptout", () => {
        return !attributeChanges?.subscriberOptout &&
          !campaignDetails?.subscriberOptout
          ? Yup.string().max(255).notRequired()
          : Yup.string().max(255).required("Please Enter Keywords");
      }),
      optoutMessage: Yup.string().when("subscriberOptout", () => {
        return !attributeChanges?.subscriberOptout &&
          !campaignDetails?.subscriberOptout
          ? Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .notRequired()
          : Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .required("Please Enter Message");
      }),
    }),
    onSubmit: (values) => {
      handleUpdateCampaign(values);
    },
  });

  const validation3: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      helpKeywords: "",
      helpMessage: "",
    },
    validationSchema: Yup.object({
      helpKeywords: Yup.string().when("subscriberHelp", () => {
        return !attributeChanges?.subscriberHelp &&
          !campaignDetails?.subscriberHelp
          ? Yup.string().max(255).notRequired()
          : Yup.string().max(255).required("Please Enter Keywords");
      }),
      helpMessage: Yup.string().when("subscriberHelp", () => {
        return !attributeChanges?.subscriberHelp &&
          !campaignDetails?.subscriberHelp
          ? Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .notRequired()
          : Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .required("Please Enter Message");
      }),
    }),
    onSubmit: (values) => {
      handleUpdateCampaign(values);
    },
  });

  const validation4: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      embeddedLinkSample: "",
    },
    validationSchema: Yup.object({
      embeddedLinkSample: Yup.string().when(
        "embeddedLink",
        ([embeddedLink]) => {
          return !embeddedLink
            ? Yup.string()
                .matches(
                  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_%#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                  "Enter correct url!"
                )
                .notRequired()
            : Yup.string()
                .matches(
                  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_%#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                  "Enter correct url!"
                )
                .required("Please Enter Link");
        }
      ),
    }),
    onSubmit: (values) => {
      handleUpdateCampaign(values);
    },
  });

  const columns3 = [
    {
      fieldName: "phoneNumber",
      name: <span className="font-weight-bold fs-sm">Number</span>,
      selector: (row: { phoneNumber: number | string }) =>
        row.phoneNumber || "",
      cell: (row: { phoneNumber: number | string }) => (
        <span className="text-primary">{row.phoneNumber || ""}</span>
      ),
      minWidth: "130px",
      sortable: true,
    },
    {
      fieldName: "friendlyName",
      name: <span className="font-weight-bold fs-sm">Friendly Name</span>,
      selector: (row: { friendlyName: any }) => row.friendlyName || "",
      cell: (row: { friendlyName: string; phoneNumber: string }) => {
        const truncatedName =
          row.friendlyName && row.friendlyName.length > 15
            ? `${row.friendlyName.slice(0, 15)}...`
            : row.friendlyName;
        return (
          <span>
            {row.friendlyName && row.friendlyName.length > 15 ? (
              <Tooltip placement="top" overlay={row.friendlyName}>
                <span>{truncatedName}</span>
              </Tooltip>
            ) : (
              <span>{truncatedName}</span>
            )}
          </span>
        );
      },
      minWidth: "200px",
      sortable: true,
    },
    {
      fieldName: "tenDLCWhiteListStatus",
      name: <span className="font-weight-bold fs-sm">Messaging Status</span>,
      selector: (row: { tenDLCWhiteListStatus: any }) =>
        row.tenDLCWhiteListStatus || "",
      cell: (cell: { tenDLCWhiteListStatus: any }) => {
        const status = cell.tenDLCWhiteListStatus?.toLowerCase() || "N/A";
        switch (status) {
          case "mt":
          case "false":
          case "in-progress":
            return (
              <span className="d-flex align-items-center text-primary">
                <span className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(
                  cell.tenDLCWhiteListStatus?.replaceAll("-", " ") || ""
                )}
              </span>
            );
          case "ready":
            return (
              <span className="d-flex align-items-center text-secondary">
                <span className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(cell.tenDLCWhiteListStatus || "")}
              </span>
            );
          case "pending":
            return (
              <span className="d-flex align-items-center text-black">
                <span className="badge border border-2 border-white rounded-circle bg-black p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(cell.tenDLCWhiteListStatus || "")}
              </span>
            );
          default:
            return (
              <span className="d-flex align-items-center text-danger">
                <span className="badge border border-2 border-white rounded-circle bg-danger p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(status)}
              </span>
            );
        }
      },
      minWidth: "150px",
      sortable: true,
    },
    {
      fieldName: "numberStatus",
      name: <span className="font-weight-bold fs-sm">Number Status</span>,
      selector: (row: { numberStatus: any }) => row.numberStatus || "",
      cell: (cell: { numberStatus: any }) => {
        switch (cell.numberStatus) {
          case "Inactive":
            return (
              <span
                className="badge bg-body-secondary border border-primary text-primary"
                style={{
                  width: "80px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                Inactive
              </span>
            );
          case "Active":
            return (
              <span
                className="badge bg-body-secondary border border-secondary text-secondary"
                style={{
                  width: "80px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                Active
              </span>
            );
          case "inprogress":
            return (
              <span
                className="badge bg-warning text-black text-warning text-start fs-sm"
                style={{
                  width: "80px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                Active
              </span>
            );
          default:
            return (
              <span className="d-flex align-items-center text-danger">
                <span className="badge border border-2 border-white rounded-circle bg-danger p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(String(cell.numberStatus) || "N/A")}
              </span>
            );
        }
      },
      minWidth: "130px",
      sortable: true,
    },
    {
      fieldName: "tags",
      name: <span className="font-weight-bold fs-sm">Tags</span>,
      selector: (row: { tags: any }) => row.tags?.join(", ") || "",
      cell: (row: { tags: string[] }) => {
        const maxVisibleTags = 2;
        const maxTagLength = 10;
        const truncatedTags = row.tags?.slice(0, maxVisibleTags) || [];
        const hasMoreTags = row.tags?.length > maxVisibleTags;

        return (
          <div className="d-flex flex-wrap">
            {truncatedTags.map((tag: string, i: number) => {
              const isTagTruncated = tag.length > maxTagLength;
              const displayTag = isTagTruncated
                ? `${tag.slice(0, maxTagLength)}...`
                : tag;

              return (
                <Tooltip
                  placement="top"
                  overlay={
                    <div className="d-flex flex-wrap">
                      <span className="">{displayTag}</span>
                    </div>
                  }
                >
                  <span className="details-tag mr-1">{displayTag}</span>
                </Tooltip>
              );
            })}
            {hasMoreTags && (
              <Tooltip
                overlayStyle={{
                  borderColor: "white",
                  opacity: 1,
                }}
                showArrow={false}
                overlayInnerStyle={{
                  backgroundColor: "white",
                  opacity: 1,
                }}
                placement="top"
                overlay={
                  <div className="d-flex flex-wrap">
                    {row.tags.map((tag: string, i: number) => (
                      <span className="details-tag mr-1" key={i}>
                        {tag}
                      </span>
                    ))}
                  </div>
                }
              >
                <span className="details-tag more-tags">
                  +{row.tags.length - maxVisibleTags} more
                </span>
              </Tooltip>
            )}
          </div>
        );
      },
      minWidth: "200px",
      grow: 2,
      wrap: true,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Capabilities</span>,
      minWidth: "150px",
      selector: (row: { capabilities: any }) =>
        JSON.stringify(row.capabilities) || "",
      cell: (row: {
        capabilities?: {
          voice?: boolean;
          sms?: boolean;
          mms?: boolean;
        };
      }) => {
        return (
          <div
            className="d-flex flex-row align-items-center flex-nowrap align-content-center"
            style={{
              gap: "20px",
            }}
          >
            {row?.capabilities?.voice && (
              <Tooltip placement="bottom" overlay={"Voice"}>
                <i className="bi bi-telephone fs-lg"></i>
              </Tooltip>
            )}
            {row?.capabilities?.sms && (
              <Tooltip placement="bottom" overlay={"SMS"}>
                <i className="bi bi-chat-left-text fs-lg"></i>
              </Tooltip>
            )}
            {row?.capabilities?.mms && (
              <Tooltip placement="bottom" overlay={"MMS"}>
                <i className="bi bi-file-image fs-lg"></i>
              </Tooltip>
            )}
          </div>
        );
      },
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-sm">Actions</span>,
      minWidth: "110px",
      cell: (row: {
        phoneNumber?: number | string;
        signalHouseSID?: number | string;
      }) => {
        return (
          <Button
            className="btn btn-sm"
            variant="outline-secondary"
            onClick={() => {
              setLocal(
                "numberSortingRoute",
                `${props.router.location?.pathname}${props.router.location?.search}`
              );
              props.router.navigate(
                `/mynumbers/${row?.phoneNumber || ""}/${
                  row?.signalHouseSID || ""
                }`
              );
            }}
          >
            Configure
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getTermsAndConditionsThunk());
  }, []);

  useEffect(() => {
    if (isInit) {
      setIsInit(loading);
    }
  }, [loading]);

  useEffect(() => {
    if (campaignDetails?.optinKeywords)
      validation1.setFieldValue(
        "optinKeywords",
        campaignDetails?.optinKeywords,
        true
      );
    if (campaignDetails?.optinMessage)
      validation1.setFieldValue(
        "optinMessage",
        campaignDetails?.optinMessage,
        true
      );
    if (campaignDetails?.optoutKeywords)
      validation2.setFieldValue(
        "optoutKeywords",
        campaignDetails?.optoutKeywords,
        true
      );
    if (campaignDetails?.optoutMessage)
      validation2.setFieldValue(
        "optoutMessage",
        campaignDetails?.optoutMessage,
        true
      );
    if (campaignDetails?.helpKeywords)
      validation3.setFieldValue(
        "helpKeywords",
        campaignDetails?.helpKeywords,
        true
      );
    if (campaignDetails?.helpMessage)
      validation3.setFieldValue(
        "helpMessage",
        campaignDetails?.helpMessage,
        true
      );
    if (campaignDetails?.embeddedLinkSample)
      validation4.setFieldValue(
        "embeddedLinkSample",
        campaignDetails?.embeddedLinkSample,
        true
      );
    setselectedFiles(campaignDetails?.attachments || []);

    if (campaignDetails?.brandId && campaignDetails?.usecase)
      dispatch(
        getTermsFromUsecaseListThunk(
          campaignDetails?.brandId,
          campaignDetails?.usecase
        )
      );

    campaignDetails?.subGroupId?.[0] &&
      dispatch(getSubgroupDetailsThunk(campaignDetails?.subGroupId));

    handleGetData3(currPage3, rowsPerPage3);
  }, [campaignDetails]);

  useEffect(() => {
    const temp2 =
      campaignMnoMetadata &&
      Object.fromEntries(
        Object.entries(campaignMnoMetadata).filter(
          ([key, value]: any) => value?.tpmScope === "CAMPAIGN"
        )
      );
    setCampConditions((temp2 && Object.values(temp2)[0]) || {});
  }, [campaignMnoMetadata]);

  const handleGetData3 = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      activePhoneNumbersThunk(
        page,
        perPage,
        removeEmptyAndNullValues({
          ...otherParams,
          subGroupId:
            campaignDetails?.subGroupId?.length > 0
              ? campaignDetails?.subGroupId
              : "",
          brandId: campaignDetails?.brandId || "",
          campaignId: campaignDetails?.campaignId?.startsWith("C")
            ? campaignDetails?.campaignId
            : campaignDetails?.campaignUuid || "",
        })
      )
    );
  };

  const handlePageChange3 = (page: number) => {
    setCurrPage3(page);
    handleGetData3(page, rowsPerPage3);
  };

  const handleRowsPerPageChange3 = (rows: number) => {
    setCurrPage3(1);
    setRowsPerPage3(rows);
    handleGetData3(1, rows);
  };

  const campaignDetailsSubmit = ({ values, resetForm }: any) => {
    const cb = () => {
      onCloseSubject.next({});
      resetForm();
    };
    handleUpdateCampaign2({ obj: values, cb });
  };

  const sampleMessagesSubmit = ({ values, resetForm }: any) => {
    const cb = () => {
      onCloseSubject.next({});
      resetForm();
    };
    const tempMsg: { [key: string]: string } = {};
    values?.messages?.forEach((dt: string, i: number) => {
      tempMsg[`sample${i + 1}`] = dt;
    });

    const tempPayload = { ...tempMsg };
    handleUpdateCampaign2({ obj: tempPayload, cb });
  };

  const handleAcceptedFiles = (files: any, rejFiles: any) => {
    rejFiles?.length > 0 && console.log("rejFiles", rejFiles);
    rejFiles.forEach((file: any) => {
      file.errors.forEach((err: { code: string; message: string }) => {
        if (err.code === "file-too-large") {
          toast.error(err.message || "Something went wrong");
        }

        if (err.code === "file-invalid-type") {
          toast.error(err.message || "Something went wrong");
        }
      });
    });

    if (files?.length > 0) {
      setIsLoading(true);

      const cb = (files: any) => {
        if (typeof files[0] === "object" && files[0] !== null) {
          const cb3 = () =>
            // isRejected
            //   ? props.router.navigate(
            //       `/campaigns/${props?.router?.params?.id}`
            //     )
            //   :
            dispatch(getCampaignDetailsThunk(props?.router?.params?.id, false));

          const cb2 = (data: any) => {
            setIsLoading(true);
            data?.[0]?.path &&
              dispatch(
                updateReviewCampaignThunk(
                  props?.router?.params?.id,
                  { attachments: [...selectedFiles, data?.[0]?.path] },
                  cb3,
                  isRejected
                )
              ).then(() => {
                setIsLoading(false);
              });
          };

          const formData = new FormData();
          formData.append("cspMedia", files[0]);
          dispatch(msgFileUploadThunk(formData, true, cb2)).then(() => {
            setIsLoading(false);
          });
        }
      };

      cb(files);
    }
  };

  const handleDownloadImg = (path: string) => {
    const cb = (img: any) => {
      const temp2 = URL.createObjectURL(img);
      let link = document.createElement("a");
      link.href = temp2;
      link.download = img?.name || "img.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    dispatch(getMsgFileThunk(path, cb));
  };

  const handleViewImg = (path: string) => {
    const cb = (img: any) => {
      const temp2 = URL.createObjectURL(img);
      setPrevSrc(temp2);
      if (img?.type.startsWith("video/")) {
        setIsOpenVideoLightBox(true);
      } else if (img?.type === "application/pdf") {
        setIsOpenDocLightBox(true);
      } else if (
        img?.type === "text/plain" ||
        img?.type === "text/html" ||
        img?.type === "application/json"
      ) {
        setIsOpenDocLightBox(true);
      } else {
        setIsOpenImgLightBox(true);
      }
    };
    dispatch(getMsgFileThunk(path, cb));
  };

  const handleDeleteImg = ({ onClose, deleteId }: any) => {
    const temp = [...selectedFiles]?.filter((dt) => dt !== deleteId);
    submitLoadingSubject.next(true);
    const cb = () => {
      onClose();
      // isRejected
      //   ? props.router.navigate(
      //       `/campaigns/${props?.router?.params?.id}`
      //     )
      //   :
      dispatch(getCampaignDetailsThunk(props?.router?.params?.id, false));
    };

    dispatch(
      updateReviewCampaignThunk(
        props?.router?.params?.id,
        { attachments: temp },
        cb,
        isRejected
      )
    ).then(() => {
      submitLoadingSubject.next(false);
    });
  };

  const handleUpdateConf = (updates: any) => {
    const cb = () => {
      setIsAttributeUpdating(false);
      setIsEditMode(false);
      setAttributeChanges({});
      onCloseSubject.next({});
      dispatch(getCampaignDetailsThunk(props?.router?.params?.id, false));
    };

    dispatch(
      updateReviewCampaignThunk(
        props?.router?.params?.id,
        updates,
        cb,
        isRejected
      )
    );
  };

  const validateFile = (file: File): { isValid: boolean; error?: string } => {
    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      return { isValid: false, error: "File is larger than 10MB" };
    }

    // Get file extension
    const extension = "." + file.name.split(".").pop()?.toLowerCase();

    // Check file type
    if (!ALLOWED_FILE_TYPES.includes(extension)) {
      return {
        isValid: false,
        error: `Invalid file type. Allowed types are: ${ALLOWED_FILE_TYPES.join(
          ", "
        )}`,
      };
    }

    return { isValid: true };
  };

  const handleUpdateCampaign = (obj: any, cb?: any) => {
    const cb2 = () => {
      cb && cb();
      // isRejected
      //   ? props.router.navigate(
      //       `/campaigns/${props?.router?.params?.id}`
      //     )
      //   :
      dispatch(getCampaignDetailsThunk(props?.router?.params?.id, false));
    };
    dispatch(
      updateReviewCampaignThunk(props?.router?.params?.id, obj, cb2, isRejected)
    );
  };

  const handleUpdateCampaign2 = ({ obj, cb }: any) => {
    submitLoadingSubject.next(true);
    const cb2 = () => {
      cb();
      // isRejected
      //   ? props.router.navigate(
      //       `/campaigns/${props?.router?.params?.id}`
      //     )
      //   :
      dispatch(getCampaignDetailsThunk(props?.router?.params?.id, false));
    };
    dispatch(
      updateReviewCampaignThunk(props?.router?.params?.id, obj, cb2, isRejected)
    ).then(() => {
      submitLoadingSubject.next(false);
    });
  };

  const handleSendReviewCampaign = () => {
    dispatch(
      openModal({
        path: "SendReviewCampaign",
        data: {
          title: "Resubmit Campaign for Review",
          bodyDesc:
            "Are you sure you want to send this campaign for review? Please add a message below explaining the changes made.",
          footer: false,
          reviewSubmit,
        },
      })
    );
  };

  const reviewSubmit = ({ values, resetForm }: any) => {
    submitLoadingSubject.next(true);
    const cb = () => {
      onCloseSubject.next({});
      resetForm();
      dispatch(getCampaignDetailsThunk(props?.router?.params?.id, false));
    };
    dispatch(
      nudgeReviewCampaignThunk(
        props?.router?.params?.id,
        {
          nudgeReason: values?.description,
        },
        cb
      )
    ).then(() => {
      submitLoadingSubject.next(false);
    });
  };

  const handleAttributeChange = (key: string, value: boolean) => {
    setAttributeChanges((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSaveAttributes = () => {
    dispatch(
      openModal({
        modalbody: "Are you sure you want to save these changes?",
        data: {
          title: "Save Changes",
          footer: true,
          cancelBtn: true,
          buttonText: {
            submit: "Save",
          },
          onSubmit: () => {
            setIsAttributeUpdating(true);
            submitLoadingSubject.next(true);
            handleUpdateConf(attributeChanges);
            submitLoadingSubject.next(false);
            onCloseSubject.next({});
          },
        },
      })
    );
  };

  // Add handler to prevent clicks when not in edit mode
  const handleRadioClick = (e: React.MouseEvent) => {
    if (!isEditMode) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handlePopoverSubmit = (values: any, resetForm: any) => {
    dispatch(
      openModal({
        modalbody: "Are you sure you want to save these changes?",
        data: {
          title: "Save Changes",
          footer: true,
          cancelBtn: true,
          buttonText: {
            submit: "Save",
          },
          onSubmit: () => {
            submitLoadingSubject.next(true);
            const cb = () => {
              onCloseSubject.next({});
              resetForm();
            };
            handleUpdateCampaign2({ obj: values, cb });
          },
        },
      })
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {isOpenImgLightBox && (
          <Lightbox
            enableZoom={false}
            mainSrc={prevSrc}
            onCloseRequest={() => {
              setIsOpenImgLightBox(false);
              setPrevSrc("");
            }}
          />
        )}
        {(isOpenDocLightBox || isOpenVideoLightBox) && (
          <div
            className="position-fixed top-0 end-0 bottom-0 start-0"
            style={{
              zIndex: 1000,
              // backgroundColor: "rgba(0,0,0,0.5)",
              backgroundColor: "white",
            }}
          >
            <div
              className="position-absolute text-white cursor-pointer"
              style={{
                zIndex: 1000,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                borderRadius: "50%",
                padding: "5px 10px",
                right: 10,
                top: 40,
              }}
            >
              <i
                className="ri-close-circle-fill cursor-pointer fs-5xl text-white"
                onClick={() => {
                  setIsOpenDocLightBox(false);
                  setIsOpenVideoLightBox(false);
                  setPrevSrc("");
                }}
              />
            </div>
            {isOpenVideoLightBox ? (
              <video
                // playbackRate={2}
                autoPlay={true}
                muted={true}
                loop={true}
                style={{ background: "rgba(0, 0, 0, 0.6)" }}
                height="100%"
                width="100%"
              >
                <source src={prevSrc} type={`video/mp4`} />
              </video>
            ) : (
              <iframe
                id="iframe"
                title={prevSrc}
                src={prevSrc}
                height="100%"
                width="100%"
                style={{ background: "rgba(0, 0, 0, 0.6)" }}
              />
            )}
          </div>
        )}
        <Container fluid>
          <BreadCrumb
            title={
              <>
                <span className="me-2">{props?.router?.params?.id}</span>
                {campaignDetails?.dcaComplete &&
                  renderStatusBadge(campaignDetails?.dcaComplete)}
              </>
            }
            isBack={true}
            backClick={() => props.router.navigate(-1)}
            isFilter={true}
            filterButton={
              <>
                {isRejected && (
                  <Button
                    onClick={handleSendReviewCampaign}
                    className="btn btn-outline-primary ms-2"
                  >
                    Send for Review
                  </Button>
                )}
                <Button
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    dispatch(
                      openModal({
                        modalbody:
                          "Are you sure you want to duplicate this campaign?",
                        data: {
                          title: "Duplicate Campaign",
                          footer: true,
                          buttonText: {
                            submit: "Proceed",
                          },
                          onSubmit: async ({ onClose }: any) => {
                            submitLoadingSubject.next(true);
                            const cb = () => {
                              onClose();
                            };

                            const data = { ...campaignDetails };
                            "campaignId" in data && delete data?.campaignId;
                            "campaignUuid" in data && delete data?.campaignUuid;
                            "createdAt" in data && delete data?.createdAt;
                            "dcaComplete" in data && delete data?.dcaComplete;
                            "underReview" in data && delete data?.underReview;
                            "emailId" in data && delete data?.emailId;
                            "userId" in data && delete data?.userId;
                            "createdBy" in data && delete data?.createdBy;
                            "createDate" in data && delete data?.createDate;
                            "nextRenewalOrExpirationDate" in data &&
                              delete data?.nextRenewalOrExpirationDate;
                            "purchaseDate" in data && delete data?.purchaseDate;
                            "renewDate" in data && delete data?.renewDate;
                            "updatedAt" in data && delete data?.updatedAt;
                            "billedDate" in data && delete data?.billedDate;
                            "expirationDate" in data &&
                              delete data?.expirationDate;
                            "status" in data && delete data?.status;

                            dispatch(
                              createCampaignThunk(
                                removeEmptyAndNullValues({ ...data }),
                                cb
                              )
                            ).then(() => {
                              submitLoadingSubject.next(false);
                            });
                          },
                        },
                      })
                    );
                  }}
                >
                  Duplicate
                </Button>
              </>
            }
          />
          <div className="position-relative wrapper">
            {isInit ? (
              <div className={``}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <>
                {isRejected && campaignDetails?.rejectionReason && (
                  <div
                    className="bg-primary-subtle"
                    style={{ padding: 10, margin: "0 0 20px" }}
                  >
                    <span className="mb-0 fw-semibold">Rejection Reason:</span>{" "}
                    {campaignDetails?.rejectionReason}
                  </div>
                )}

                <Card>
                  <Card.Body>
                    <Row>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Brand Name:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {campaignDetails?.brandId} -{" "}
                              {campaignDetails?.brandName ||
                                campaignDetails?.brand?.displayName ||
                                "-"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Use-Case:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {campaignDetails?.usecase || "-"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">TCR Status:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4 text-secondary">
                              <b>Pending </b>
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Migration:</h6>
                          </Col>
                          <Col md={8}>-</Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Group Id:</h6>
                          </Col>
                          <Col md={8}>{campaignDetails?.groupId || "-"}</Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Subgroups:</h6>
                          </Col>
                          <Col md={8}>
                            <div className="d-flex flex-wrap mt-1 mt-sm-0">
                              {campaignDetails?.subGroupId?.length > 0
                                ? campaignDetails?.subGroupId?.map(
                                    (dt: string, i2: number) => {
                                      return (
                                        <span className="details-tag" key={i2}>
                                          {dt} -{" "}
                                          {subgroupDetails?.data?.subGroupData?.find(
                                            (dt2: any) =>
                                              dt2?.sub_group_id === dt
                                          )?.sub_group_name || ""}
                                        </span>
                                      );
                                    }
                                  )
                                : "-"}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      {!isRejected && (
                        <Col xl={6} className="mb-2">
                          <Row className="g-0">
                            <Col md={4}>
                              <h6 className="mb-0">
                                Phone Numbers:
                                {campaignDetails?.phoneNumbers?.length ===
                                  0 && (
                                  <i
                                    className="bi bi-pencil fs-md cursor-pointer text-secondary ms-2"
                                    style={{
                                      WebkitTextStroke: "0.5px",
                                    }}
                                    onClick={() => {
                                      dispatch(
                                        openModal({
                                          path: "UpdatePhoneNumber",
                                          data: {
                                            title: "Update Phone Numbers",
                                            cId: props?.router?.params?.id,
                                            subGroupId:
                                              campaignDetails?.subGroupId || [],
                                            // navigate: props.router.navigate,
                                            navigate: () =>
                                              dispatch(
                                                getCampaignDetailsThunk(
                                                  props?.router?.params?.id,
                                                  false
                                                )
                                              ),
                                            footer: false,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                )}
                              </h6>
                            </Col>
                            <Col md={8}>
                              <div className="d-flex flex-wrap mt-1 mt-sm-0">
                                {campaignDetails?.phoneNumbers?.length > 0
                                  ? campaignDetails?.phoneNumbers?.map(
                                      (dt: string, i2: number) => {
                                        return (
                                          <span
                                            className="details-tag"
                                            key={i2}
                                          >
                                            {dt}
                                          </span>
                                        );
                                      }
                                    )
                                  : "-"}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </Card.Body>
                </Card>

                {/* Campaign and Content Attributes */}
                <Card>
                  <Card.Header className="d-flex align-items-center justify-content-between">
                    <h6 className="card-title mb-2">
                      Campaign and Content Attributes
                    </h6>
                    <div className="d-flex gap-2">
                      {isEditMode ? (
                        <>
                          <Button
                            className="btn btn-outline-primary"
                            onClick={() => {
                              setIsEditMode(false);
                              setAttributeChanges({});
                            }}
                            disabled={isAttributeUpdating}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            onClick={handleSaveAttributes}
                            disabled={
                              Object.keys(attributeChanges).length === 0 ||
                              isAttributeUpdating
                            }
                          >
                            {isAttributeUpdating ? (
                              <>
                                <Spinner
                                  size="sm"
                                  animation="border"
                                  className="me-2"
                                />
                                Updating...
                              </>
                            ) : (
                              "Update"
                            )}
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="secondary"
                          onClick={() => setIsEditMode(true)}
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col lg={6}>
                        <ul className="list-group list-group-flush">
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Subscriber Opt-in
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="subscriberOptinYes"
                                      label="Yes"
                                      name="subscriberOptin"
                                      value="Yes"
                                      disabled={true}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "subscriberOptin"
                                        )
                                          ? attributeChanges.subscriberOptin ===
                                            true
                                          : campaignDetails?.subscriberOptin ===
                                            true
                                      }
                                      onChange={() => {
                                        if (isEditMode) {
                                          handleAttributeChange(
                                            "subscriberOptin",
                                            true
                                          );
                                        }
                                      }}
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="subscriberOptinNo"
                                      label="No"
                                      name="subscriberOptin"
                                      value="No"
                                      disabled={true}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "subscriberOptin"
                                        )
                                          ? attributeChanges.subscriberOptin ===
                                            false
                                          : campaignDetails?.subscriberOptin ===
                                            false
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "subscriberOptin",
                                          false
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  >
                                    {attributeChanges?.subscriberOptin ||
                                    campaignDetails?.subscriberOptin ? (
                                      <HoverPopover
                                        icon={
                                          <i
                                            className={`ri-eye-fill align-middle fs-lg cursor-pointer ${
                                              isAttributeUpdating
                                                ? "text-muted"
                                                : "text-secondary"
                                            }`}
                                          />
                                        }
                                      >
                                        <Form
                                          onSubmit={(e) => {
                                            e.preventDefault();
                                            validation1.handleSubmit();
                                            if (
                                              validation1.isValid &&
                                              Object.keys(validation1.touched)
                                                .length > 0
                                            ) {
                                              handlePopoverSubmit(
                                                validation1.values,
                                                validation1.resetForm
                                              );
                                            }
                                          }}
                                        >
                                          <div className="mb-3">
                                            <Form.Label htmlFor="optinKeywords">
                                              Type Opt-in Keywords here{" "}
                                              <span className="text-primary">
                                                *
                                              </span>
                                            </Form.Label>
                                            <TagsInput
                                              className="react-tagsinput"
                                              inputProps={{
                                                placeholder: "Add a keywords",
                                              }}
                                              value={
                                                validation1.values.optinKeywords
                                                  ? validation1.values.optinKeywords.split(
                                                      ","
                                                    )
                                                  : []
                                              }
                                              onChange={(e: any) => {
                                                validation1.setFieldValue(
                                                  "optinKeywords",
                                                  e.toString()
                                                );
                                              }}
                                              addOnBlur={true}
                                              onlyUnique={true}
                                            />
                                            <div>
                                              {validation1.touched
                                                ?.optinKeywords &&
                                              validation1.errors
                                                ?.optinKeywords ? (
                                                <Form.Control.Feedback
                                                  type="invalid"
                                                  className="d-block"
                                                >
                                                  {
                                                    validation1.errors
                                                      ?.optinKeywords
                                                  }
                                                </Form.Control.Feedback>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="mb-3">
                                            <Form.Label htmlFor="optinMessage">
                                              Type Opt-in Message here{" "}
                                              <span className="text-primary">
                                                *
                                              </span>
                                            </Form.Label>
                                            <textarea
                                              id="optinMessage"
                                              name="optinMessage"
                                              onChange={
                                                validation1.handleChange
                                              }
                                              onBlur={validation1.handleBlur}
                                              value={
                                                validation1.values
                                                  .optinMessage || ""
                                              }
                                              className={`form-control ${
                                                validation1.touched
                                                  ?.optinMessage &&
                                                validation1.errors?.optinMessage
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              rows={3}
                                              maxLength={4096}
                                              minLength={40}
                                            />
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div>
                                                {validation1.touched
                                                  ?.optinMessage &&
                                                validation1.errors
                                                  ?.optinMessage ? (
                                                  <Form.Control.Feedback
                                                    type="invalid"
                                                    className="d-block"
                                                  >
                                                    {
                                                      validation1.errors
                                                        ?.optinMessage
                                                    }
                                                  </Form.Control.Feedback>
                                                ) : null}
                                              </div>
                                              <div style={{ flex: "none" }}>
                                                {
                                                  validation1.values
                                                    .optinMessage?.length
                                                }{" "}
                                                / 320
                                              </div>
                                            </div>
                                          </div>
                                          <Button
                                            variant="primary"
                                            type="submit"
                                            className="d-flex m-auto"
                                            disabled={loading2}
                                            onClick={() =>
                                              validation1.handleSubmit()
                                            }
                                          >
                                            {loading2 && (
                                              <Spinner
                                                size="sm"
                                                animation="border"
                                              />
                                            )}{" "}
                                            Update
                                          </Button>
                                        </Form>
                                      </HoverPopover>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Subscriber Opt-Out{" "}
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="subscriberOptoutYes"
                                      label="Yes"
                                      name="subscriberOptout"
                                      value="Yes"
                                      disabled={true}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "subscriberOptout"
                                        )
                                          ? attributeChanges.subscriberOptout ===
                                            true
                                          : campaignDetails?.subscriberOptout ===
                                            true
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "subscriberOptout",
                                          true
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="subscriberOptoutNo"
                                      label="No"
                                      name="subscriberOptout"
                                      value="No"
                                      disabled={true}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "subscriberOptout"
                                        )
                                          ? attributeChanges.subscriberOptout ===
                                            false
                                          : campaignDetails?.subscriberOptout ===
                                            false
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "subscriberOptout",
                                          false
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  >
                                    {attributeChanges?.subscriberOptout ||
                                    campaignDetails?.subscriberOptout ? (
                                      <HoverPopover>
                                        <Form
                                          action="#"
                                          onSubmit={(e) => {
                                            e.preventDefault();
                                            validation2.handleSubmit();
                                          }}
                                        >
                                          <div className="mb-3">
                                            <Form.Label htmlFor="optoutKeywords">
                                              Type Opt-out Keywords here{" "}
                                              <span className="text-primary">
                                                *
                                              </span>
                                            </Form.Label>
                                            <TagsInput
                                              inputProps={{
                                                placeholder: "Add a keywords",
                                              }}
                                              value={
                                                validation2.values
                                                  .optoutKeywords
                                                  ? validation2.values.optoutKeywords.split(
                                                      ","
                                                    )
                                                  : []
                                              }
                                              onChange={(e: any) => {
                                                validation2.setFieldValue(
                                                  "optoutKeywords",
                                                  e.toString()
                                                );
                                              }}
                                              addOnBlur={true}
                                              onlyUnique={true}
                                            />
                                            <div>
                                              {validation2.touched
                                                ?.optoutKeywords &&
                                              validation2.errors
                                                ?.optoutKeywords ? (
                                                <Form.Control.Feedback
                                                  type="invalid"
                                                  className="d-block"
                                                >
                                                  {
                                                    validation2.errors
                                                      ?.optoutKeywords
                                                  }
                                                </Form.Control.Feedback>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="mb-3">
                                            <Form.Label htmlFor="optoutMessage">
                                              Type Opt-out Message here{" "}
                                              <span className="text-primary">
                                                *
                                              </span>
                                            </Form.Label>
                                            <textarea
                                              id="optoutMessage"
                                              name="optoutMessage"
                                              onChange={
                                                validation2.handleChange
                                              }
                                              onBlur={validation2.handleBlur}
                                              value={
                                                validation2.values
                                                  .optoutMessage || ""
                                              }
                                              className={`form-control ${
                                                validation2.touched
                                                  ?.optoutMessage &&
                                                validation2.errors
                                                  ?.optoutMessage
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              rows={3}
                                              maxLength={4096}
                                              minLength={40}
                                            />
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div>
                                                {validation2.touched
                                                  ?.optoutMessage &&
                                                validation2.errors
                                                  ?.optoutMessage ? (
                                                  <Form.Control.Feedback
                                                    type="invalid"
                                                    className="d-block"
                                                  >
                                                    {
                                                      validation2.errors
                                                        ?.optoutMessage
                                                    }
                                                  </Form.Control.Feedback>
                                                ) : null}
                                              </div>
                                              <div style={{ flex: "none" }}>
                                                {
                                                  validation2.values
                                                    .optoutMessage?.length
                                                }{" "}
                                                / 320
                                              </div>
                                            </div>
                                          </div>
                                          <Button
                                            variant="primary"
                                            type="submit"
                                            className="d-flex m-auto"
                                            disabled={loading2}
                                            onClick={() =>
                                              validation2.handleSubmit()
                                            }
                                          >
                                            {loading2 && (
                                              <Spinner
                                                size="sm"
                                                animation="border"
                                              />
                                            )}{" "}
                                            Update
                                          </Button>
                                        </Form>
                                      </HoverPopover>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Subscriber Help
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="subscriberHelpYes"
                                      label="Yes"
                                      name="subscriberHelp"
                                      value="Yes"
                                      disabled={true}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "subscriberHelp"
                                        )
                                          ? attributeChanges.subscriberHelp ===
                                            true
                                          : campaignDetails?.subscriberHelp ===
                                            true
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "subscriberHelp",
                                          true
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="subscriberHelpNo"
                                      label="No"
                                      name="subscriberHelp"
                                      value="No"
                                      disabled={true}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "subscriberHelp"
                                        )
                                          ? attributeChanges.subscriberHelp ===
                                            false
                                          : campaignDetails?.subscriberHelp ===
                                            false
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "subscriberHelp",
                                          false
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  >
                                    {attributeChanges?.subscriberHelp ||
                                    campaignDetails?.subscriberHelp ? (
                                      <HoverPopover>
                                        <Form
                                          action="#"
                                          onSubmit={(e) => {
                                            e.preventDefault();
                                            validation3.handleSubmit();
                                          }}
                                        >
                                          <div className="mb-3">
                                            <Form.Label htmlFor="helpKeywords">
                                              Type Help Keywords here{" "}
                                              <span className="text-primary">
                                                *
                                              </span>
                                            </Form.Label>
                                            <TagsInput
                                              inputProps={{
                                                placeholder: "Add a keywords",
                                              }}
                                              value={
                                                validation3.values.helpKeywords
                                                  ? validation3.values.helpKeywords.split(
                                                      ","
                                                    )
                                                  : []
                                              }
                                              onChange={(e: any) => {
                                                validation3.setFieldValue(
                                                  "helpKeywords",
                                                  e.toString()
                                                );
                                              }}
                                              addOnBlur={true}
                                              onlyUnique={true}
                                            />
                                            <div>
                                              {validation3.touched
                                                ?.helpKeywords &&
                                              validation3.errors
                                                ?.helpKeywords ? (
                                                <Form.Control.Feedback
                                                  type="invalid"
                                                  className="d-block"
                                                >
                                                  {
                                                    validation3.errors
                                                      ?.helpKeywords
                                                  }
                                                </Form.Control.Feedback>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="mb-3">
                                            <Form.Label htmlFor="helpMessage">
                                              Type Help Message here{" "}
                                              <span className="text-primary">
                                                *
                                              </span>
                                            </Form.Label>
                                            <textarea
                                              id="helpMessage"
                                              name="helpMessage"
                                              onChange={
                                                validation3.handleChange
                                              }
                                              onBlur={validation3.handleBlur}
                                              value={
                                                validation3.values
                                                  .helpMessage || ""
                                              }
                                              className={`form-control ${
                                                validation3.touched
                                                  ?.helpMessage &&
                                                validation3.errors?.helpMessage
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              rows={3}
                                              maxLength={4096}
                                              minLength={40}
                                            />
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div>
                                                {validation3.touched
                                                  ?.helpMessage &&
                                                validation3.errors
                                                  ?.helpMessage ? (
                                                  <Form.Control.Feedback
                                                    type="invalid"
                                                    className="d-block"
                                                  >
                                                    {
                                                      validation3.errors
                                                        ?.helpMessage
                                                    }
                                                  </Form.Control.Feedback>
                                                ) : null}
                                              </div>
                                              <div style={{ flex: "none" }}>
                                                {
                                                  validation3.values.helpMessage
                                                    ?.length
                                                }{" "}
                                                / 320
                                              </div>
                                            </div>
                                          </div>
                                          <Button
                                            variant="primary"
                                            type="submit"
                                            className="d-flex m-auto"
                                            disabled={loading2}
                                            onClick={() =>
                                              validation3.handleSubmit()
                                            }
                                          >
                                            {loading2 && (
                                              <Spinner
                                                size="sm"
                                                animation="border"
                                              />
                                            )}{" "}
                                            Update
                                          </Button>
                                        </Form>
                                      </HoverPopover>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Number Pooling
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="numberPoolYes"
                                      label="Yes"
                                      name="numberPool"
                                      value="Yes"
                                      disabled={!isEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "numberPool"
                                        )
                                          ? attributeChanges.numberPool === true
                                          : campaignDetails?.numberPool === true
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "numberPool",
                                          true
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="numberPoolNo"
                                      label="No"
                                      name="numberPool"
                                      value="No"
                                      disabled={
                                        !isEditMode
                                          ? true
                                          : campConditions?.reqNumberPool
                                      }
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "numberPool"
                                        )
                                          ? attributeChanges.numberPool ===
                                            false
                                          : campaignDetails?.numberPool ===
                                            false
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "numberPool",
                                          false
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Direct Lending or Loan Arrangement
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="directLendingYes"
                                      label="Yes"
                                      name="directLending"
                                      value="Yes"
                                      disabled={!isEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "directLending"
                                        )
                                          ? attributeChanges.directLending ===
                                            true
                                          : campaignDetails?.directLending ===
                                            true
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "directLending",
                                          true
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="directLendingNo"
                                      label="No"
                                      name="directLending"
                                      value="No"
                                      disabled={
                                        !isEditMode
                                          ? true
                                          : campConditions?.reqDirectLending
                                      }
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "directLending"
                                        )
                                          ? attributeChanges.directLending ===
                                            false
                                          : campaignDetails?.directLending ===
                                            false
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "directLending",
                                          false
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Col>
                      <Col lg={6}>
                        <ul className="list-group list-group-flush">
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Embedded Link
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="embeddedLinkYes"
                                      label="Yes"
                                      name="embeddedLink"
                                      value="Yes"
                                      disabled={!isEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "embeddedLink"
                                        )
                                          ? attributeChanges.embeddedLink ===
                                            true
                                          : campaignDetails?.embeddedLink ===
                                            true
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "embeddedLink",
                                          true
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="embeddedLinkNo"
                                      label="No"
                                      name="embeddedLink"
                                      value="No"
                                      disabled={
                                        !isEditMode
                                          ? true
                                          : campConditions?.noEmbeddedLink
                                      }
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "embeddedLink"
                                        )
                                          ? attributeChanges.embeddedLink ===
                                            false
                                          : campaignDetails?.embeddedLink ===
                                            false
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "embeddedLink",
                                          false
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  >
                                    {attributeChanges?.embeddedLink ||
                                    campaignDetails?.embeddedLink ? (
                                      <HoverPopover>
                                        <Form
                                          onSubmit={(e) => {
                                            e.preventDefault();
                                            validation4.handleSubmit();
                                          }}
                                        >
                                          <div className="mb-3">
                                            <Form.Label htmlFor="embeddedLinkSample">
                                              Embedded Link{" "}
                                              <span className="text-primary">
                                                *
                                              </span>
                                            </Form.Label>
                                            <Form.Control
                                              type="url"
                                              name="embeddedLinkSample"
                                              onChange={
                                                validation4.handleChange
                                              }
                                              onBlur={validation4.handleBlur}
                                              value={
                                                validation4.values
                                                  .embeddedLinkSample || ""
                                              }
                                              isInvalid={
                                                validation4?.touched
                                                  ?.embeddedLinkSample &&
                                                validation4?.errors
                                                  ?.embeddedLinkSample
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <div>
                                              {validation4.touched
                                                ?.embeddedLinkSample &&
                                              validation4.errors
                                                ?.embeddedLinkSample ? (
                                                <Form.Control.Feedback
                                                  type="invalid"
                                                  className="d-block"
                                                >
                                                  {
                                                    validation4.errors
                                                      ?.embeddedLinkSample
                                                  }
                                                </Form.Control.Feedback>
                                              ) : null}
                                            </div>
                                          </div>
                                          <Button
                                            variant="primary"
                                            type="submit"
                                            className="d-flex m-auto"
                                            disabled={loading2}
                                            onClick={() =>
                                              validation4.handleSubmit()
                                            }
                                          >
                                            {loading2 && (
                                              <Spinner
                                                size="sm"
                                                animation="border"
                                              />
                                            )}{" "}
                                            Update
                                          </Button>
                                        </Form>
                                      </HoverPopover>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Embedded Phone Number{" "}
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="embeddedPhoneYes"
                                      label="Yes"
                                      name="embeddedPhone"
                                      value="Yes"
                                      disabled={!isEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "embeddedPhone"
                                        )
                                          ? attributeChanges.embeddedPhone ===
                                            true
                                          : campaignDetails?.embeddedPhone ===
                                            true
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "embeddedPhone",
                                          true
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="embeddedPhoneNo"
                                      label="No"
                                      name="embeddedPhone"
                                      value="No"
                                      disabled={
                                        !isEditMode
                                          ? true
                                          : campConditions?.noEmbeddedPhone
                                      }
                                      checked={
                                        campaignDetails?.embeddedPhone === false
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "embeddedPhone",
                                          false
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Age Gated
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="ageGatedYes"
                                      label="Yes"
                                      name="ageGated"
                                      value="Yes"
                                      disabled={!isEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "ageGated"
                                        )
                                          ? attributeChanges.ageGated === true
                                          : campaignDetails?.ageGated === true
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange("ageGated", true)
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="ageGatedNo"
                                      label="No"
                                      name="ageGated"
                                      value="No"
                                      disabled={!isEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "ageGated"
                                        )
                                          ? attributeChanges.ageGated === false
                                          : campaignDetails?.ageGated === false
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange("ageGated", false)
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item ps-0 pb-1">
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Terms & Conditions{" "}
                                  </span>
                                  <span className="text-muted d-block">
                                    {campaignTnC?.subTerms?.[0]}
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="termsAndConditionsYes"
                                      label="Yes"
                                      name="termsAndConditions"
                                      value="Yes"
                                      disabled={true}
                                      checked={
                                        campaignDetails?.termsAndConditions ===
                                        true
                                      }
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="termsAndConditionsNo"
                                      label="No"
                                      name="termsAndConditions"
                                      value="No"
                                      disabled={true}
                                      checked={
                                        campaignDetails?.termsAndConditions ===
                                        false
                                      }
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* Campaign Details */}
                <Card>
                  <Card.Header className="d-flex align-items-center justify-content-between">
                    <h6 className="card-title mb-2">Campaign Details</h6>
                    <div className="d-flex">
                      <Button
                        variant="secondary"
                        className="ms-2"
                        onClick={() => {
                          dispatch(
                            openModal({
                              path: "EditCampaignDetails",
                              data: {
                                title: "Campaign Details",
                                campaignDetails,
                                campaignDetailsSubmit,
                                isReviewPage: true,
                                footer: false,
                              },
                            })
                          );
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Privacy Policy Link:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {campaignDetails?.privacyPolicyLink ? (
                                <a
                                  href={campaignDetails?.privacyPolicyLink}
                                  target="_blank"
                                >
                                  {campaignDetails?.privacyPolicyLink}
                                </a>
                              ) : (
                                "-"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Terms and Conditions Link:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {campaignDetails?.termsAndConditionsLink ? (
                                <a
                                  href={campaignDetails?.termsAndConditionsLink}
                                  target="_blank"
                                >
                                  {campaignDetails?.termsAndConditionsLink}
                                </a>
                              ) : (
                                "-"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Website:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {campaignDetails?.website ? (
                                <a
                                  href={campaignDetails?.website}
                                  target="_blank"
                                >
                                  {campaignDetails?.website}
                                </a>
                              ) : (
                                "-"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={2}>
                        <h6 className="mb-0">Campaign Description:</h6>
                      </Col>
                      <Col md={10}>
                        <p className="mb-0 me-md-4">
                          {campaignDetails?.description}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        <h6 className="mb-0">Call-to-Action / Message Flow:</h6>
                      </Col>
                      <Col md={10}>
                        <p className="mb-0">{campaignDetails?.messageFlow}</p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* Sample Messages */}
                <Card>
                  <Card.Header className="d-flex align-items-center justify-content-between">
                    <h6 className="card-title mb-2">Sample Messages</h6>
                    <div className="d-flex">
                      <Button
                        variant="secondary"
                        className="ms-2"
                        onClick={() => {
                          dispatch(
                            openModal({
                              path: "EditSampleMessages",
                              data: {
                                title: "Sample Messages",
                                campaignDetails,
                                sampleMessagesSubmit,
                                campaignTerms,
                                footer: false,
                              },
                            })
                          );
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    {campaignDetails?.sample1 ? (
                      <p>
                        <span className="badge bg-primary-subtle text-primary me-2 fs-md">
                          1
                        </span>
                        {campaignDetails?.sample1}
                      </p>
                    ) : null}
                    {campaignDetails?.sample2 ? (
                      <p>
                        <span className="badge bg-primary-subtle text-primary me-2 fs-md">
                          2
                        </span>
                        {campaignDetails?.sample2}
                      </p>
                    ) : null}
                    {campaignDetails?.sample3 ? (
                      <p>
                        <span className="badge bg-primary-subtle text-primary me-2 fs-md">
                          3
                        </span>
                        {campaignDetails?.sample3}
                      </p>
                    ) : null}
                    {campaignDetails?.sample4 ? (
                      <p>
                        <span className="badge bg-primary-subtle text-primary me-2 fs-md">
                          4
                        </span>
                        {campaignDetails?.sample4}
                      </p>
                    ) : null}
                    {campaignDetails?.sample5 ? (
                      <p>
                        <span className="badge bg-primary-subtle text-primary me-2 fs-md">
                          5
                        </span>
                        {campaignDetails?.sample5}
                      </p>
                    ) : null}
                  </Card.Body>
                </Card>

                {/* Sample Multimedia */}
                <Card>
                  <Card.Header>
                    <h6 className="card-title mb-2">Sample Multimedia</h6>
                  </Card.Header>
                  <Card.Body>
                    <Row className="mb-3">
                      <Col lg={6}>
                        <Dropzone
                          onDrop={(acceptedFiles: any, fileRejections: any) => {
                            if (selectedFiles?.length > 4) {
                              toast.error(
                                "Max 5 uploads per campaign is allowed"
                              );
                              return;
                            }

                            // Validate each file
                            const validFiles = acceptedFiles.filter(
                              (file: File) => {
                                const validation = validateFile(file);
                                if (!validation.isValid) {
                                  toast.error(validation.error);
                                  return false;
                                }
                                return true;
                              }
                            );

                            // Handle rejections
                            fileRejections?.forEach((file: any) => {
                              file.errors.forEach(
                                (err: { code: string; message: string }) => {
                                  if (err.code === "file-too-large") {
                                    toast.error("File is larger than 10MB");
                                  }
                                  if (err.code === "file-invalid-type") {
                                    toast.error(
                                      "Invalid file type. Please upload supported file formats only"
                                    );
                                  }
                                }
                              );
                            });

                            // Only proceed with valid files
                            if (validFiles.length > 0) {
                              handleAcceptedFiles(validFiles, fileRejections);
                            }
                          }}
                          maxSize={MAX_FILE_SIZE}
                          multiple={false}
                          accept={{
                            "image/bmp": [".bmp", ".dib"],
                            "image/gif": [".gif"],
                            "image/jpeg": [".jpeg", ".jpg"],
                            "image/png": [".png"],
                            "image/webp": [".webm"],
                            "audio/mpeg": [
                              ".m2a",
                              ".mp1",
                              ".mp2",
                              ".mp3",
                              ".mpa",
                            ],
                            "audio/mp4": [".m4a", ".m4b", ".m4p", ".m4r"],
                            "video/mp4": [".m4v", ".mp4"],
                            "audio/ogg": [
                              ".oga",
                              ".ogg",
                              ".ogm",
                              ".ogv",
                              ".ogx",
                              ".spx",
                            ],
                            "audio/wav": [".wav"],
                            "text/plain": [".txt"],
                          }}
                        >
                          {({ getRootProps }: any) => (
                            <div className="position-relative dropzone dz-clickable text-center border rounded">
                              {isLoading ? (
                                <div className="d-flex justify-content-center align-items-center position-absolute start-0 end-0 top-0 bottom-0 bg-dark bg-opacity-50">
                                  <img
                                    src={Loader2}
                                    alt="loading"
                                    className="img-thumbnail border-0 bg-transparent"
                                  />
                                </div>
                              ) : null}
                              <div
                                className="dz-message needsclick cursor-pointer"
                                {...getRootProps()}
                              >
                                <div className="mb-3">
                                  <i className="ri-camera-fill text-muted fs-8xl" />
                                  <i className="ri-video-fill text-muted fs-8xl" />
                                  <i className="ri-file-music-fill text-muted fs-8xl" />
                                </div>
                                <p className="fs-lg">
                                  Drop a multimedia file to upload or click to
                                  upload
                                </p>
                                <p className="fs-md text-muted">
                                  Maximum upload file size: 10MB
                                </p>
                              </div>
                            </div>
                          )}
                        </Dropzone>{" "}
                      </Col>
                      <Col lg={6}>
                        <div className="border rounded p-3 dropzone flex-column">
                          <h6 className="mb-3">Sample Multimedia Files</h6>
                          <ul className="vstack gap-2 list-unstyled mb-0">
                            {selectedFiles?.map((dt: string, i: number) => (
                              <li key={i}>
                                <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                                  <div className="d-flex align-items-center gap-2">
                                    <div className="avatar-xs flex-shrink-0 ronded">
                                      <div className="avatar-title bg-body-secondary text-body">
                                        <i className="ri ri-file-line fs-lg"></i>
                                      </div>
                                    </div>
                                    <div className="flex-grow-1">
                                      <h6
                                        className="mb-0"
                                        style={{ wordBreak: "break-word" }}
                                      >
                                        {dt || ""}
                                      </h6>
                                    </div>
                                    <div className="flex-shrink-0 text-muted">
                                      <i
                                        className="ri-download-2-fill cursor-pointer fs-2xl text-secondary ms-3"
                                        onClick={() => {
                                          !loading2 && handleDownloadImg(dt);
                                        }}
                                      />
                                      <i
                                        className="ri-eye-fill cursor-pointer fs-2xl text-secondary ms-3"
                                        onClick={() => {
                                          !loading2 && handleViewImg(dt);
                                        }}
                                      />
                                      <i
                                        className="ri-close-circle-fill cursor-pointer fs-2xl text-secondary ms-3"
                                        onClick={() => {
                                          if (!loading2) {
                                            dispatch(
                                              openModal({
                                                modalbody:
                                                  "Are you sure you want to delete this file?",
                                                data: {
                                                  title: "Delete File",
                                                  footer: true,
                                                  cancelBtn: true,
                                                  buttonText: {
                                                    submit: "Continue",
                                                  },
                                                  onCancel: ({
                                                    onClose,
                                                  }: any) => {
                                                    onClose();
                                                  },
                                                  onSubmit: ({
                                                    onClose,
                                                  }: any) => {
                                                    handleDeleteImg({
                                                      onClose,
                                                      deleteId: dt,
                                                    });
                                                  },
                                                },
                                              })
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* Number Provisioned */}
                <Card className="card-height-100">
                  <Card.Header className="d-flex align-items-center justify-content-between">
                    <h6 className="card-title mb-2"></h6>
                    <Button
                      variant="secondary"
                      className="ms-2"
                      onClick={() => props.router.navigate("/mynumbers")}
                    >
                      Add Number
                    </Button>
                  </Card.Header>
                  <Card.Body>
                    <Tab.Container defaultActiveKey="signal">
                      <Nav
                        as="ul"
                        variant="tabs"
                        className="nav-tabs-custom nav-primary mb-4"
                      >
                        <Nav.Item as="li">
                          <Nav.Link eventKey="current" className="disableTab">
                            Current Number Provisioned
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link eventKey="previous" className="disableTab">
                            Previous Number Provisioned
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link eventKey="signal">
                            Signal House Number
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="current">
                          <div className="position-relative">
                            These fields will be visible once the campaign is
                            approved by Signal House and pending DCA review
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="previous">
                          <div className="position-relative">
                            These fields will be visible once the campaign is
                            approved by Signal House and pending DCA review
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="signal">
                          <div className="position-relative">
                            {loading5 ? (
                              <div>
                                <img
                                  src={Loader}
                                  className={`position-absolute top-50 start-50 translate-middle`}
                                  alt="loading"
                                />
                              </div>
                            ) : (
                              <Datatable
                                data={
                                  Array.isArray(dataObj?.records)
                                    ? dataObj?.records
                                    : []
                                }
                                columns={columns3}
                                handlePageChange={handlePageChange3}
                                handleRowsPerPageChange={
                                  handleRowsPerPageChange3
                                }
                                currPage={currPage3}
                                rowsPerPage={rowsPerPage3}
                                totalRecords={dataObj?.totalRecords}
                              />
                            )}
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Card.Body>
                </Card>
              </>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CampaignReviewDetails);
