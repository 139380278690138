import { combineReducers } from "redux";

import ToastReducer from "./toast/reducer";
// Front
import LayoutReducer from "./layouts/reducer";
import ModalReducer from "./modal/reducer";
import BottomsheetReducer from "./bottomsheet/reducer";
import AccountReducer from "./auth/register/reducer";
// Authentication
import LoginReducer from "./auth/login/reducer";
import AuthUserReducer from "./auth-user/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import BrandsReducer from "./brands/reducer";
import EnumsReducer from "./enums/reducer";
import NumbersReducer from "./numbers/reducer";
import configureNumbers from "./configureNumbers/reducer";
import OwnNumberList from "./active-phone-number/reducer";
import VerifiedCaller from "./verified-caller/reducer";
import PortsReducer from "./port-request/reducer";
import CampaignsReducer from "./campaigns/reducer";
import ResellersReducer from "./reseller/reducer";
import BillingReducer from "./billing/reducer";
import MessagesReducer from "./messages/reducer";
import UsageSummaryReducer from "./UsageSummary/reducer";
import AccountPricingReducer from "./account-pricing/reducer";
import WebhookReducer from "./webhooks/reducer";
import GroupsReducer from "./groups/reducer";
import VerificationReducer from "./verification/reducer";
import AnalyticsReducer from "./analytics/reducer";
import NotificationsReducer from "./notifications/reducer";
import HelpCenterReducer from "./help-center/reducer";
import TokensReducer from "./tokens/reducer";
import MarketPlaceReducer from "./marketPlace/reducer";
import appealReducer from './appeal/reducer';

const rootReducer = combineReducers({
  Toast: ToastReducer,
  Layout: LayoutReducer,
  Modal: ModalReducer,
  Bottomsheet: BottomsheetReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  ForgetPassword: ForgetPasswordReducer,
  AuthUser: AuthUserReducer,
  Brands: BrandsReducer,
  Enums: EnumsReducer,
  Numbers: NumbersReducer,
  OwnNumbers: OwnNumberList,
  ConfigureNumbers: configureNumbers,
  VerifiedCaller: VerifiedCaller,
  Ports: PortsReducer,
  Campaigns: CampaignsReducer,
  Resellers: ResellersReducer,
  Billing: BillingReducer,
  Messages: MessagesReducer,
  UsageSummary: UsageSummaryReducer,
  AccountPricing: AccountPricingReducer,
  Webhooks: WebhookReducer,
  Groups: GroupsReducer,
  Verification: VerificationReducer,
  Analytics: AnalyticsReducer,
  Notifications: NotificationsReducer,
  HelpCenter: HelpCenterReducer,
  Tokens: TokensReducer,
  MarketPlace: MarketPlaceReducer,
  Appeal: appealReducer
});

export default rootReducer;
