import React, { useEffect, useMemo } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Loader from "assets/images/spinner-dark.svg";
import { fatchAccountPricingThunk } from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import BreadCrumb from "Common/BreadCrumb";
import Tooltip from "rc-tooltip";

const capitalizeWords = (str: string): string => {
  return str
    .split(".")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ")
    .replace(/_/g, " ")
    .replace(/([a-zA-Z])renew/gi, '$1 renew');
};

const carrierObj = {
  "Carrier SMS": {
    "AT&T outbound": 0.003,
    "T-Mobile outbound": 0.003,
    "T-Mobile inbound": 0.003,
    Verizon_outbound: 0.003,
    "US Cellular outbound": 0.005,
    All_Other_Carriers_outbound: 0.0035,
  },
  "Carrier MMS": {
    "AT&T outbound": 0.005,
    "T-Mobile outbound": 0.01,
    "T-Mobile inbound": 0.01,
    Verizon_outbound: 0.005,
    "US Cellular outbound": 0.01,
    All_Other_Carriers_outbound: 0.01,
  },
};

const AccountPricing = () => {
  const dispatch = useDispatch<any>();

  const selectProfile = createSelector(
    (state: any) => state.AccountPricing,
    (accountPricing) => ({
      loading: accountPricing.loading,
      walletPricingData: accountPricing.walletPricingData,
    })
  );

  const { loading, walletPricingData } = useSelector(selectProfile);

  useEffect(() => {
    dispatch(fatchAccountPricingThunk());
  }, []);

  const simplifyObj = useMemo(() => {
    const structuredData: any = {};
    walletPricingData &&
      Object.keys(walletPricingData)?.forEach((key) => {
        const [group, subkey] = key.split(".");
        if (!structuredData[group]) {
          structuredData[group] = {};
        }
        structuredData[group][subkey] = walletPricingData[key];
      });
    return structuredData;
  }, [walletPricingData]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Account Pricing" />

          <div className="position-relative wrapper">
            {loading ? (
              <div className={``}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <>
                <Card>
                  <Card.Header>
                    <h6 className="card-title mb-2">Platform fees</h6>
                  </Card.Header>
                  <Card.Body>
                    {Object.keys(simplifyObj)?.filter(
                      (dt) => dt === "SMS" || dt === "MMS" || dt === "Number"
                    )?.length > 0 ? (
                      Object.keys(simplifyObj)
                        ?.filter(
                          (dt) =>
                            dt === "SMS" || dt === "MMS" || dt === "Number"
                        )
                        ?.map((dt, i) => (
                          <React.Fragment key={i}>
                            <h6
                              className={`card-title mb-3 ${
                                i === 0 ? "" : "mt-4"
                              }`}
                            >
                              {dt === "SMS" || dt === "MMS"
                                ? dt.toUpperCase()
                                : capitalizeWords(dt)}
                            </h6>
                            <Row className="g-3">
                              {Object.keys(simplifyObj?.[dt])?.length > 0 ? (
                                Object.keys(simplifyObj?.[dt])?.map(
                                  (dt2, i2) => (
                                    <Col xl={3} lg={4} md={6} sm={12} key={i2}>
                                      <div className="p-3 border border-dashed rounded h-100">
                                        <div className="d-flex gap-3 h-100">
                                          <div className="avatar-xs flex-shrink-0">
                                            <div className="avatar-title bg-secondary-subtle text-secondary fs-lg rounded">
                                              {dt === "SMS" && (
                                                <i className="bi bi-chat-dots"></i>
                                              )}
                                              {dt === "MMS" && (
                                                <i className="bi bi-image"></i>
                                              )}
                                              {dt === "Number" && (
                                                <i className="bi bi-telephone"></i>
                                              )}
                                            </div>
                                          </div>
                                          <div className="d-flex flex-column justify-content-between">
                                            <p className="fs-md text-black mb-2">
                                              <b>{capitalizeWords(dt2)}</b>
                                            </p>
                                            <h4 className="mb-0 text-primary">
                                              ${simplifyObj?.[dt]?.[dt2] || 0}{" "}
                                              USD
                                            </h4>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  )
                                )
                              ) : (
                                <p className="m-0">No Record Found</p>
                              )}
                            </Row>
                          </React.Fragment>
                        ))
                    ) : (
                      <p className="m-0">No Record Found</p>
                    )}
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Header>
                    <h6 className="d-flex align-items-center card-title mb-2">
                      Passthrough fees{" "}
                      <Tooltip
                        placement="bottomRight"
                        overlay={
                          "These charges are passed through from Signal House's upstream partners at no additional margin."
                        }
                      >
                        <i className="ri-information-line text-secondary fs-3xl fw-medium ms-2"></i>
                      </Tooltip>
                    </h6>
                  </Card.Header>
                  <Card.Body>
                    {Object.keys({ ...carrierObj, ...simplifyObj })?.filter(
                      (dt) => dt !== "SMS" && dt !== "MMS" && dt !== "Number"
                    )?.length > 0 ? (
                      Object.keys({ ...carrierObj, ...simplifyObj })
                        ?.filter(
                          (dt) =>
                            dt !== "SMS" && dt !== "MMS" && dt !== "Number"
                        )
                        ?.map((dt, i) => (
                          <React.Fragment key={i}>
                            <h6
                              className={`card-title mb-3 ${
                                i === 0 ? "" : "mt-4"
                              }`}
                            >
                              {dt === "Carrier SMS" || dt === "Carrier MMS"
                                ? dt
                                : capitalizeWords(dt)}
                            </h6>
                            <Row className="g-3">
                              {Object.keys(
                                { ...carrierObj, ...simplifyObj }?.[dt]
                              )?.length > 0 ? (
                                Object.keys(
                                  { ...carrierObj, ...simplifyObj }?.[dt]
                                )?.map((dt2, i2) => (
                                  <Col xl={3} lg={4} md={6} sm={12} key={i2}>
                                    <div className="p-3 border border-dashed rounded h-100">
                                      <div className="d-flex gap-3 h-100">
                                        <div className="avatar-xs flex-shrink-0">
                                          <div className="avatar-title bg-secondary-subtle text-secondary fs-lg rounded">
                                            {dt === "Carrier SMS" && (
                                              <i className="bi bi-chat-dots"></i>
                                            )}
                                            {dt === "Carrier MMS" && (
                                              <i className="bi bi-image"></i>
                                            )}
                                            {dt === "campaign" && (
                                              <i className="bi bi-megaphone"></i>
                                            )}
                                            {dt === "brand" && (
                                              <i className="bi bi-briefcase"></i>
                                            )}
                                          </div>
                                        </div>
                                        <div className="d-flex flex-column justify-content-between">
                                          <p className="fs-md text-black mb-2">
                                            <b>
                                              {dt2 === "AT&T outbound" ||
                                              dt2 === "T-Mobile outbound" ||
                                              dt2 === "T-Mobile inbound" ||
                                              dt2 === "US Cellular outbound"
                                                ? dt2
                                                : capitalizeWords(dt2)}
                                            </b>
                                          </p>
                                          <h4 className="mb-0 text-primary">
                                            $
                                            {{
                                              ...carrierObj,
                                              ...simplifyObj,
                                            }?.[dt]?.[dt2] || 0}{" "}
                                            USD
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                ))
                              ) : (
                                <p className="m-0">No Record Found</p>
                              )}
                            </Row>
                          </React.Fragment>
                        ))
                    ) : (
                      <p className="m-0">No Record Found</p>
                    )}
                  </Card.Body>
                </Card>
              </>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default AccountPricing;
