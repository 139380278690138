import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import Loader from "assets/images/spinner-dark.svg";
import { useEffect, useState, useCallback } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getSubgroupListThunk, openModal } from "slices/thunk";
import Datatable from "../../../../Common/Datatable";
import {
  asyncActivePhoneNumberList,
  asyncBrandList,
  asyncCampaignList,
  asyncPortRequestList,
  asyncSubgroupList,
  capitalizeString,
  customSelectTheme,
  dateFormat,
  getEndOfDay,
  getStartOfDay,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
// import maintain from "assets/images/auth/maintenance.png";
import { Link, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { AsyncPaginate } from "react-select-async-paginate";
import Select from "react-select";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { debounce } from "lodash";
import { getSubgroupSearchThunk } from "slices/groups/thunk";
import { GroupSelect } from "Common/filter/group-select";
import { BrandSelect } from "Common/filter/brand-select";

const SubGroupsList = (props: any) => {
  document.title = "Signal House Portal Sub Groups List";
  const dispatch = useDispatch<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const currPage = Number(searchParams.get("currPage") || 1);
  const rowsPerPage = Number(searchParams.get("rowsPerPage") || 10);
  const isFilter = searchParams.get("isFilter");

  const [open, setOpen] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const selector = createSelector(
    (state: any) => state.Groups,
    (groups) => ({
      loading4: groups.loading4,
      subgroupsData: groups.subgroupsData,
      error: groups.error,
      groupDetails: groups.AllGroups,
    })
  );

  const { loading4, subgroupsData, groupDetails } = useSelector(selector);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Sub Group ID</span>,
      selector: (row: { sub_group_id: string }) => row.sub_group_id || "",
      sortable: true,
      sortFunction: (
        a: { sub_group_id: string },
        b: { sub_group_id: string }
      ) => (a.sub_group_id || "").localeCompare(b.sub_group_id || ""),
    },
    {
      name: <span className="font-weight-bold fs-sm">Name</span>,
      selector: (row: { sub_group_name: string }) => row.sub_group_name || "",
      sortable: true,
      sortFunction: (
        a: { sub_group_name: string },
        b: { sub_group_name: string }
      ) => (a.sub_group_name || "").localeCompare(b.sub_group_name || ""),
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      minWidth: "130px",
      selector: (row: { status: string }) => row.status || "",
      cell: (row: { status: string }) => {
        switch (row.status) {
          case "Active":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-secondary-subtle text-secondary"
                style={{
                  width: "80px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                Active
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-primary-subtle text-primary"
                style={{
                  width: "80px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                {capitalizeString(String(row.status) || "Inactive")}
              </span>
            );
        }
      },
      sortable: true,
      sortFunction: (a: { status: string }, b: { status: string }) =>
        (a.status || "").localeCompare(b.status || ""),
    },
    {
      name: <span className="font-weight-bold fs-sm">Created Date</span>,
      minWidth: "220px",
      selector: (row: { created_datetime: string }) =>
        row.created_datetime || "",
      cell: (row: { created_datetime: string }) => (
        <span className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.created_datetime, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      sortFunction: (
        a: { created_datetime: string },
        b: { created_datetime: string }
      ) =>
        new Date(a.created_datetime || "").getTime() -
        new Date(b.created_datetime || "").getTime(),
    },
    {
      name: <span className="font-weight-bold fs-sm">Action</span>,
      cell: (row: { sub_group_id: string }) => {
        return (
          <>
            <Button
              className="btn btn-sm"
              variant="outline-secondary"
              onClick={() =>
                props.router.navigate(
                  `/groups/${props?.router?.params?.groupId}/subgroups/${
                    row.sub_group_id || ""
                  }/edit`
                )
              }
            >
              Edit
            </Button>
          </>
        );
      },
      sortable: false,
    },
  ];

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      subGroupId: [],
      brandId: [],
      campaignId: [],
      phoneNumber: [],
      portingId: "",
      status: "",
      createdAt: "",
      startDate: "",
      endDate: "",
    },
    onSubmit: (values) => {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        ...values,
        subGroupId: JSON.stringify(values.subGroupId),
        brandId: JSON.stringify(values.brandId),
        campaignId: JSON.stringify(values.campaignId),
        phoneNumber: JSON.stringify(values.phoneNumber),
        currPage: String(1),
        isFilter: "true",
      });
      setOpen(!open);
      handleGetData(1, rowsPerPage, values);
    },
  });

  useEffect(() => {
    if (
      isFilter !== "true" &&
      props?.router?.params?.groupId &&
      props?.router?.params?.groupId !== "undefined"
    ) {
      handleGetData(currPage, rowsPerPage);
    }
  }, [props?.router?.params?.groupId]);

  useEffect(() => {
    if (isFilter === "true") {
      const temp = { ...Object.fromEntries(searchParams.entries()) };
      const temp2 = {
        ...validation.initialValues,
        subGroupId: temp?.subGroupId ? JSON.parse(temp?.subGroupId) : [],
        brandId: temp?.brandId ? JSON.parse(temp?.brandId) : [],
        campaignId: temp?.campaignId ? JSON.parse(temp?.campaignId) : [],
        phoneNumber: temp?.phoneNumber ? JSON.parse(temp?.phoneNumber) : [],
        portingId: temp?.portingId || "",
        status: temp?.status || "",
        createdAt: temp?.createdAt || "",
        startDate: temp?.startDate || "",
        endDate: temp?.endDate || "",
      };
      validation.setValues(temp2);
      handleGetData(currPage, rowsPerPage, temp2);
    }
  }, [isFilter]);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    const temp = {
      ...otherParams,
      groupId: props?.router?.params?.groupId,
      subGroupId:
        otherParams?.subGroupId?.length > 0 ? otherParams.subGroupId : "",
      brandId:
        otherParams?.brandId?.length > 0
          ? otherParams.brandId?.map((dt: any) => dt.brandId)
          : "",
      campaignId:
        otherParams?.campaignId?.length > 0
          ? otherParams.campaignId?.map((dt: any) => dt.campaignId)
          : "",
      phoneNumber:
        otherParams?.phoneNumber?.length > 0
          ? otherParams.phoneNumber?.map((dt: any) => dt.phoneNumber)
          : "",
      createdAt: "",
      startDate: otherParams?.startDate
        ? moment(otherParams.startDate).toISOString()
        : "",
      endDate: otherParams?.endDate
        ? moment(otherParams.endDate).toISOString()
        : "",
    };

    dispatch(
      getSubgroupListThunk(page, perPage, removeEmptyAndNullValues(temp))
    );
  };

  const handleRowClick = (row: {
    sub_group_id: string;
    sub_group_name: string;
  }) => {
    setTimeout(() => {
      const formattedSubGroupName = row.sub_group_name
        .replace(/\s+/g, "-")
        .toLowerCase();
      props.router.navigate(
        `/groups/${props?.router?.params?.groupId}/subgroups/${row.sub_group_id}/${formattedSubGroupName}`
      );
    }, 100);
  };

  const handlePageChange = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      currPage: String(page),
    });
    handleGetData(page, rowsPerPage, validation.values);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      currPage: String(1),
      rowsPerPage: String(rows),
    });
    handleGetData(1, rows, validation.values);
  };

  const debouncedSearch = useCallback(
    debounce(async (term: string) => {
      if (term?.trim()) {
        dispatch(getSubgroupSearchThunk(term));
      } else {
        handleGetData(1, rowsPerPage);
      }
    }, 500),
    [rowsPerPage]
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={`${groupDetails?.records?.[0]?.group_name || ""} ${
            groupDetails?.records?.[0]?.group_id
              ? `(${groupDetails?.records?.[0]?.group_id})`
              : ""
          }`}
          isFilter={true}
          filterButton={
            <div className="d-flex align-items-center">
              <Form.Control
                type="search"
                placeholder="Search by Brand ID, Campaign ID, Phone Number, or Porting ID"
                className="me-2"
                style={{ width: "400px" }}
                value={searchTerm}
                onChange={handleSearch}
              />
              <Dropdown
                align="end"
                className="ms-2 filterDropDown2"
                show={open}
                onToggle={() => {
                  if (!datePickerModal) {
                    setOpen(!open);
                  }
                }}
              >
                <Dropdown.Toggle variant="secondary">
                  <i className="bx bx-filter-alt me-1" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                    }}
                  >
                    <Row>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
                        <GroupSelect
                          isDisabled={!groupDetails?.records?.[0]?.group_id}
                          key={groupDetails?.records?.[0]?.group_id}
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.subGroupId &&
                              validation?.errors?.subGroupId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.subGroupId?.length > 0
                              ? validation.values.subGroupId.map(
                                  (dt: string) => ({
                                    sub_group_id: dt,
                                  })
                                )
                              : null
                          }
                          name="subGroupId"
                          loadOptions={asyncSubgroupList(
                            removeEmptyAndNullValues({
                              groupId:
                                groupDetails?.records?.[0]?.group_id || "",
                            }),
                            "subGroupId"
                          )}
                          getOptionValue={(option: any) => option?.sub_group_id}
                          getOptionLabel={(option: any) =>
                            option?.sub_group_name +
                            " - " +
                            option?.sub_group_id
                          }
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.sub_group_id !==
                                validation.values.subGroupId
                            ) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.map((dt: any) => dt.sub_group_id)
                              );
                              validation.setFieldValue("brandId", []);
                              validation.setFieldValue("campaignId", []);
                              validation.setFieldValue("phoneNumber", []);
                            } else if (!option) {
                              validation.setFieldValue("subGroupId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="brandId">Brand</Form.Label>
                        <BrandSelect
                          key={
                            validation.values.subGroupId.toString() ||
                            groupDetails?.records?.[0]?.group_id ||
                            "brandId"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.brandId &&
                              validation?.errors?.brandId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.brandId?.length > 0
                              ? validation.values.brandId
                              : null
                          }
                          loadOptions={asyncBrandList(
                            removeEmptyAndNullValues({
                              groupId:
                                groupDetails?.records?.[0]?.group_id || "",
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                            }),
                            "brandId"
                          )}
                          getOptionValue={(option: any) => option?.brandId}
                          getOptionLabel={(option: any) =>
                            option?.displayName + " - " + option?.brandId
                          }
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.brandId === validation.values.brandId
                            ) {
                              return;
                            }
                            if (option) {
                              const tempSubgrpIds = option.reduce(
                                (acc: string[], dt: any) => {
                                  if (
                                    dt?.subGroupId &&
                                    Array.isArray(dt.subGroupId)
                                  ) {
                                    dt.subGroupId.forEach(
                                      (subGroup: string) => {
                                        if (
                                          !acc.includes(subGroup) &&
                                          !validation.values.subGroupId.includes(
                                            subGroup
                                          )
                                        ) {
                                          acc.push(subGroup);
                                        }
                                      }
                                    );
                                  }
                                  return acc;
                                },
                                [...validation.values.subGroupId]
                              );
                              validation.setFieldValue(
                                "subGroupId",
                                tempSubgrpIds
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  displayName: dt?.displayName || "",
                                }))
                              );
                              validation.setFieldValue("campaignId", []);
                              validation.setFieldValue("phoneNumber", []);
                            } else {
                              validation.setFieldValue("brandId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="campaignId">Campaign</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.brandId
                              ?.map((dt: any) => dt.brandId)
                              .toString() ||
                            validation.values.subGroupId.toString() ||
                            groupDetails?.records?.[0]?.group_id ||
                            "campaignId"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.campaignId &&
                              validation?.errors?.campaignId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.campaignId?.length > 0
                              ? validation.values.campaignId.map((dt: any) =>
                                  dt?.campaignId?.startsWith("C")
                                    ? { campaignId: dt?.campaignId }
                                    : { campaignUuid: dt?.campaignId }
                                )
                              : null
                          }
                          loadOptions={asyncCampaignList(
                            removeEmptyAndNullValues({
                              groupId:
                                groupDetails?.records?.[0]?.group_id || "",
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                              brandId:
                                validation.values.brandId.length > 0
                                  ? validation.values.brandId?.map(
                                      (dt: any) => dt.brandId
                                    )
                                  : "",
                            }),
                            "campaignId",
                            true,
                            true
                          )}
                          getOptionValue={(option: any) =>
                            option?.campaignId?.startsWith("C")
                              ? option?.campaignId
                              : option?.campaignUuid
                          }
                          getOptionLabel={(option: any) =>
                            option?.campaignId?.startsWith("C")
                              ? option?.campaignId
                              : option?.campaignUuid
                          }
                          onChange={(option: any) => {
                            if (
                              option &&
                              (option.campaignId ===
                                validation.values.campaignId ||
                                option.campaignUuid ===
                                  validation.values.campaignId)
                            ) {
                              return;
                            }
                            if (option) {
                              const tempSubgrpIds = option.reduce(
                                (acc: string[], dt: any) => {
                                  if (
                                    dt?.subGroupId &&
                                    Array.isArray(dt.subGroupId)
                                  ) {
                                    dt.subGroupId.forEach(
                                      (subGroup: string) => {
                                        if (
                                          !acc.includes(subGroup) &&
                                          !validation.values.subGroupId.includes(
                                            subGroup
                                          )
                                        ) {
                                          acc.push(subGroup);
                                        }
                                      }
                                    );
                                  }
                                  return acc;
                                },
                                [...validation.values.subGroupId]
                              );
                              validation.setFieldValue(
                                "subGroupId",
                                tempSubgrpIds
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.brandId.find(
                                        (item: any) =>
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                        campaignId: dt?.campaignId?.startsWith(
                                          "C"
                                        )
                                          ? dt?.campaignId
                                          : dt?.campaignUuid || "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.brandId]
                                )
                              );
                              validation.setFieldValue(
                                "campaignId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  campaignId: dt?.campaignId?.startsWith("C")
                                    ? dt?.campaignId
                                    : dt?.campaignUuid || "",
                                }))
                              );
                              validation.setFieldValue("phoneNumber", []);
                            } else {
                              validation.setFieldValue("campaignId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="phoneNumber">
                          Phone Number
                        </Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.campaignId
                              ?.map((dt: any) => dt.campaignId)
                              .toString() ||
                            validation.values.brandId
                              ?.map((dt: any) => dt.brandId)
                              .toString() ||
                            validation.values.subGroupId.toString() ||
                            groupDetails?.records?.[0]?.group_id ||
                            "phoneNumber"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.phoneNumber &&
                              validation?.errors?.phoneNumber
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.phoneNumber
                              ? validation.values.phoneNumber
                              : null
                          }
                          loadOptions={asyncActivePhoneNumberList(
                            removeEmptyAndNullValues({
                              groupId:
                                groupDetails?.records?.[0]?.group_id || "",
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                              brandId:
                                validation.values.brandId.length > 0
                                  ? validation.values.brandId?.map(
                                      (dt: any) => dt.brandId
                                    )
                                  : "",
                              campaignId:
                                validation.values.campaignId.length > 0
                                  ? validation.values.campaignId?.map(
                                      (dt: any) => dt.campaignId
                                    )
                                  : "",
                              tenDLCStatus: "Ready",
                            }),
                            "phoneNumber"
                          )}
                          getOptionValue={(option: any) => option?.phoneNumber}
                          getOptionLabel={(option: any) => option?.phoneNumber}
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.phoneNumber ===
                                validation.values.phoneNumber
                            ) {
                              return;
                            }

                            if (option) {
                              const tempSubgrpIds = option.reduce(
                                (acc: string[], dt: any) => {
                                  if (
                                    dt?.subGroupId &&
                                    Array.isArray(dt.subGroupId)
                                  ) {
                                    dt.subGroupId.forEach(
                                      (subGroup: string) => {
                                        if (
                                          !acc.includes(subGroup) &&
                                          !validation.values.subGroupId.includes(
                                            subGroup
                                          )
                                        ) {
                                          acc.push(subGroup);
                                        }
                                      }
                                    );
                                  }
                                  return acc;
                                },
                                [...validation.values.subGroupId]
                              );
                              validation.setFieldValue(
                                "subGroupId",
                                tempSubgrpIds
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.brandId.find(
                                        (item: any) =>
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.brandId]
                                )
                              );
                              validation.setFieldValue(
                                "campaignId",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.campaignId.find(
                                        (item: any) =>
                                          item.campaignId === dt.campaignId &&
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                        campaignId: dt.campaignId || "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.campaignId]
                                )
                              );
                              validation.setFieldValue(
                                "phoneNumber",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  campaignId: dt.campaignId || "",
                                  phoneNumber: dt?.phoneNumber || "",
                                }))
                              );
                            } else {
                              validation.setFieldValue("phoneNumber", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="portingId">Porting Id</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.phoneNumber
                              ?.map((dt: any) => dt.phoneNumber)
                              .toString() ||
                            validation.values.campaignId
                              ?.map((dt: any) => dt.campaignId)
                              .toString() ||
                            validation.values.brandId
                              ?.map((dt: any) => dt.brandId)
                              .toString() ||
                            validation.values.subGroupId.toString() ||
                            groupDetails?.records?.[0]?.group_id ||
                            "portingId"
                          }
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.portingId &&
                              validation?.errors?.portingId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.portingId
                              ? { portingId: validation.values.portingId }
                              : null
                          }
                          loadOptions={asyncPortRequestList(
                            removeEmptyAndNullValues({
                              // groupId: groupDetails?.records?.[0]?.group_id || "",
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                              brandId:
                                validation.values.brandId.length > 0
                                  ? validation.values.brandId?.map(
                                      (dt: any) => dt.brandId
                                    )
                                  : "",
                              campaignId:
                                validation.values.campaignId.length > 0
                                  ? validation.values.campaignId?.map(
                                      (dt: any) => dt.campaignId
                                    )
                                  : "",
                              phoneNumber:
                                validation.values.phoneNumber.length > 0
                                  ? validation.values.phoneNumber?.map(
                                      (dt: any) => dt.phoneNumber
                                    )
                                  : "",
                            }),
                            "phoneNumber"
                          )}
                          getOptionValue={(option: any) => option?.portingId}
                          getOptionLabel={(option: any) => option?.portingId}
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.portingId === validation.values.portingId
                            ) {
                              return;
                            }

                            if (option) {
                              const tempSubgrpIds = [option].reduce(
                                (acc: string[], dt: any) => {
                                  if (
                                    dt?.subGroupId &&
                                    Array.isArray(dt.subGroupId)
                                  ) {
                                    dt.subGroupId.forEach(
                                      (subGroup: string) => {
                                        if (
                                          !acc.includes(subGroup) &&
                                          !validation.values.subGroupId.includes(
                                            subGroup
                                          )
                                        ) {
                                          acc.push(subGroup);
                                        }
                                      }
                                    );
                                  }
                                  return acc;
                                },
                                [...validation.values.subGroupId]
                              );
                              validation.setFieldValue(
                                "subGroupId",
                                tempSubgrpIds
                              );
                              validation.setFieldValue(
                                "brandId",
                                [option].reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.brandId.find(
                                        (item: any) =>
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.brandId]
                                )
                              );
                              validation.setFieldValue(
                                "campaignId",
                                [option].reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.campaignId.find(
                                        (item: any) =>
                                          item.campaignId === dt.campaignId &&
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                        campaignId: dt.campaignId || "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.campaignId]
                                )
                              );
                              // validation.setFieldValue(
                              //   "phoneNumber",
                              //   option.phoneNumber
                              // );
                              validation.setFieldValue(
                                "portingId",
                                option.portingId
                              );
                            } else {
                              validation.setFieldValue("portingId", "");
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="status">Status</Form.Label>
                        <Select
                          name="status"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.status &&
                              validation?.errors?.status
                          )}
                          theme={customSelectTheme}
                          options={[
                            {
                              label: "Active",
                              value: "Active",
                            },
                            {
                              label: "Inactive",
                              value: "Inactive",
                            },
                          ]}
                          onChange={(e: { value: string }) => {
                            if (e) {
                              validation.setFieldValue("status", e.value);
                            } else {
                              validation.setFieldValue("status", "");
                            }
                          }}
                          value={
                            validation.values.status
                              ? {
                                  label: validation.values.status,
                                  value: validation.values.status,
                                }
                              : null
                          }
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="createdAt">Created At</Form.Label>
                        <Flatpickr
                          className="form-control"
                          name="createdAt"
                          placeholder="Created At"
                          options={{
                            enableTime: false,
                            onOpen: () => {
                              setDatePickerModal(true);
                            },
                            onClose: () => {
                              setDatePickerModal(false);
                            },
                            altFormat: "F j, Y",
                            dateFormat: "m-d-Y",
                            mode: "range",
                            onChange: (
                              value: any,
                              dateStr: string,
                              instance: any
                            ) => {
                              validation.setFieldValue(
                                "createdAt",
                                dateStr || ""
                              );
                              value?.[0] &&
                                validation.setFieldValue(
                                  "startDate",
                                  getStartOfDay(value?.[0])
                                );
                              value?.[1] &&
                                validation.setFieldValue(
                                  "endDate",
                                  getEndOfDay(value?.[1])
                                );
                            },
                          }}
                          value={validation.values.createdAt || ""}
                        />
                      </Col>
                    </Row>
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        className="btn btn-secondary me-2"
                        type="button"
                        disabled={loading4}
                        onClick={() => {
                          validation.resetForm();
                          setDatePickerModal(false);
                          setSearchParams({
                            ...Object.fromEntries(searchParams.entries()),
                            ...validation.initialValues,
                            currPage: String(1),
                            isFilter: "true",
                          });
                          handleGetData(1, rowsPerPage);
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading4}
                      >
                        {loading4 && <Spinner size="sm" animation="border" />}{" "}
                        Apply
                      </Button>
                    </div>
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          }
          isCreate={
            props?.router?.params?.groupId &&
            props?.router?.params?.groupId !== "undefined"
              ? true
              : false
          }
          createLabel="Create Sub Group"
          createClick={() => {
            dispatch(
              openModal({
                path: "AddSubgroup",
                data: {
                  title: "Create Sub Group",
                  footer: false,
                  groupId: props?.router?.params?.groupId,
                  handleGetData: () => handleGetData(currPage, rowsPerPage),
                },
              })
            );
          }}
        />

        <div className="position-relative">
          <Card className="wrapper">
            <Card.Body className="listing-table">
              {groupDetails?.records?.[0]?.group_id &&
              props?.router?.params?.groupId !== "undefined" ? (
                loading4 ? (
                  <div className={``}>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                      alt="loading"
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(subgroupsData?.records)
                        ? subgroupsData?.records
                        : []
                    }
                    columns={columns}
                    handleRowClick={handleRowClick}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={subgroupsData?.totalRecords}
                  />
                )
              ) : (
                <div className="d-flex flex-column align-items-center justify-content-center text-center h-100">
                  {/* <div className="">
                    <img src={maintain} alt="" height={100} />
                  </div> */}
                  <div
                  // className="mt-4 pt-3"
                  >
                    <h5>Please complete profile to see sub groups.</h5>
                    <Link to={"/settings/general"}>Go to profile page</Link>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(SubGroupsList);
