import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  dataObj: {},
  data: [],
  releasedPhoneNumberObj: {},
  numberDetails: {},
  message: null,
  total: null,
  loading: false,
  success: false,
  successMsg: "",
  error: false,
  errorMsg: "",
  attachmentId: null,
  whitelistRequestStatus: null,
  EventLogs: {},
  numVerifyLookUpObj: {},
  phoneNumberLookupLogs: {},
};

const numbersSlice = createSlice({
  name: "numbers",
  initialState,
  reducers: {
    apiLoading(state) {
      state.error = false;
      state.loading = true;
    },
    fetchNumbersSuccessful(state, action) {
      state.dataObj = action.payload;
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
    },
    fetchNumbersFailed(state, action) {
      state.dataObj = {};
      state.error = true;
      state.message = action.payload;
      state.loading = false;
    },
    purchaseNumberSuccessful(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    purchaseNumberFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    releasedPhoneNumberDataSuccessful(state, action) {
      state.loading = false;
      state.releasedPhoneNumberObj = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    releasedPhoneNumberDataFailed(state, action) {
      state.loading = false;
      state.releasedPhoneNumberObj = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    ownNumberDetailApiSuccess(state, action) {
      state.loading = false;
      state.numberDetails = action.payload;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    ownNumberDetailApiFailed(state, action) {
      state.loading = false;
      state.numberDetails = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    ownNumberDetailInitialState(state) {
      state.loading = false;
      state.numberDetails = {};
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    phoneNumberLookupSuccessful(state, action) {
      state.loading = false;
      state.releasedPhoneNumberObj = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    phoneNumberLookupFailed(state, action) {
      state.loading = false;
      state.releasedPhoneNumberObj = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    attachmentSuccess(state, action) {
      state.loading = false;
      state.attachmentId = action.payload.importFileResourceId;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    attachmentFailure(state, action) {
      state.loading = false;
      state.error = action.payload.message;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    whitelistSuccess(state, action) {
      state.loading = false;
      state.whitelistRequestStatus = action.payload.message;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    whitelistFailure(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    getNumVerifyLookUpSuccessful(state, action) {
      state.loading = false;
      state.numVerifyLookUpObj = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    getNumVerifyLookUpFailed(state, action) {
      state.loading = false;
      state.numVerifyLookUpObj = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    resetNumVerifyLookUpData(state) {
      state.loading = false;
      state.numVerifyLookUpObj = {};
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    // number lookup logs
    getNumberLookupLogsSuccessful(state, action) {
      state.loading = false;
      state.phoneNumberLookupLogs = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    getNumberLookupLogsFailed(state, action) {
      state.loading = false;
      state.successMsg = "";
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    resetNumberLookupLogsData(state) {
      state.loading = false;
      state.EventLogs = {};
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
  },
});

export const {
  apiLoading,
  fetchNumbersSuccessful,
  fetchNumbersFailed,
  purchaseNumberSuccessful,
  purchaseNumberFailed,
  releasedPhoneNumberDataSuccessful,
  releasedPhoneNumberDataFailed,
  ownNumberDetailApiSuccess,
  ownNumberDetailApiFailed,
  ownNumberDetailInitialState,
  phoneNumberLookupSuccessful,
  phoneNumberLookupFailed,
  attachmentSuccess,
  attachmentFailure,
  whitelistSuccess,
  whitelistFailure,
  getNumVerifyLookUpSuccessful,
  getNumVerifyLookUpFailed,
  resetNumVerifyLookUpData,
  getNumberLookupLogsSuccessful,
  getNumberLookupLogsFailed,
  resetNumberLookupLogsData,
} = numbersSlice.actions;

export default numbersSlice.reducer;
