import { createSlice } from '@reduxjs/toolkit';

interface AppealFile {
  uuid: string;
  name: string;
  path: string;
  size: number;
  type: string;
}

interface AppealDetails {
  status?: string;
  appealCategories?: string[];
  explanation?: string;
  attachmentUuids?: string[];
  createdAt?: string;
  updatedAt?: string;
}

interface AppealState {
  loading: boolean;
  error: string | null;
  uploadedFiles: AppealFile[];
  appealDetails: AppealDetails | null;
  submitSuccess: boolean;
  uploadLoading: boolean;
  deleteLoading: boolean;
  evidenceLoading: boolean;
  submitLoading: boolean;
}

const initialState: AppealState = {
  loading: false,
  error: null,
  uploadedFiles: [],
  appealDetails: null,
  submitSuccess: false,
  uploadLoading: false,
  deleteLoading: false,
  evidenceLoading: false,
  submitLoading: false
};

const appealSlice = createSlice({
  name: 'appeal',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
      if (action.payload) {
        state.error = null;
      }
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.submitSuccess = false;
    },
    addUploadedFile: (state, action) => {
      state.uploadedFiles.push(action.payload);
      state.error = null;
    },
    removeUploadedFile: (state, action) => {
      state.uploadedFiles = state.uploadedFiles.filter(
        file => file.uuid !== action.payload
      );
    },
    setAppealDetails: (state, action) => {
      state.appealDetails = action.payload;
      state.error = null;
    },
    clearAppealDetails: (state) => {
      state.appealDetails = null;
      state.error = null;
    },
    setSubmitSuccess: (state, action) => {
      state.submitSuccess = action.payload;
      if (action.payload) {
        state.error = null;
      }
    },
    resetAppealState: (state) => {
      state.loading = false;
      state.error = null;
      state.uploadedFiles = [];
      state.appealDetails = null;
      state.submitSuccess = false;
    },
    setUploadLoading: (state, action) => {
      state.uploadLoading = action.payload;
    },
    setDeleteLoading: (state, action) => {
      state.deleteLoading = action.payload;
    },
    setEvidenceLoading: (state, action) => {
      state.evidenceLoading = action.payload;
    },
    setSubmitLoading: (state, action) => {
      state.submitLoading = action.payload;
    }
  }
});

export const {
  setLoading,
  setError,
  addUploadedFile,
  removeUploadedFile,
  setAppealDetails,
  clearAppealDetails,
  setSubmitSuccess,
  resetAppealState,
  setUploadLoading,
  setDeleteLoading,
  setEvidenceLoading,
  setSubmitLoading
} = appealSlice.actions;

// Selectors
export const selectAppealLoading = (state: { Appeal: AppealState }) => state.Appeal.loading;
export const selectAppealError = (state: { Appeal: AppealState }) => state.Appeal.error;
export const selectUploadedFiles = (state: { Appeal: AppealState }) => state.Appeal.uploadedFiles;
export const selectAppealDetails = (state: { Appeal: AppealState }) => state.Appeal.appealDetails;
export const selectSubmitSuccess = (state: { Appeal: AppealState }) => state.Appeal.submitSuccess;
export const selectUploadLoading = (state: { Appeal: AppealState }) => state.Appeal.uploadLoading;
export const selectDeleteLoading = (state: { Appeal: AppealState }) => state.Appeal.deleteLoading;
export const selectEvidenceLoading = (state: { Appeal: AppealState }) => state.Appeal.evidenceLoading;
export const selectSubmitLoading = (state: { Appeal: AppealState }) => state.Appeal.submitLoading;

export default appealSlice.reducer;