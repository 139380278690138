import React, { useState, useEffect } from "react";
import { Card, Form, Button, Image } from "react-bootstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "Common/withRouter";
import avatar1 from "../../assets/images/users/avatar-1.jpg"; // Default avatar
import { authUserLogoutThunk, getFileThunk } from "slices/thunk";
import { getUserStepsThunk } from "slices/thunk";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getInitials } from "helpers/common";
import {
  browserName,
  browserVersion,
  deviceType,
  mobileModel,
  mobileVendor,
  osName,
} from "react-device-detect";
import { getLocationThunk } from "slices/thunk";
import Cookies from "js-cookie";
// import { getInitials } from "Common/utils";

interface LockScreenProps {
  showLockScreen: boolean;
  setShowLockScreen: (state: boolean) => void;
  router?: any;
}

const LockScreen: React.FC<any> = ({
  showLockScreen,
  setShowLockScreen,
  router,
}) => {
  // document.title = " Lock Screen | Steex Admin & Dashboard Template";

  const dispatch = useDispatch<any>();
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [profileImage, setProfileImage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Get user data from Redux store
  const { user }: any = useSelector((state: any) => ({
    user: state.AuthUser.authUser,
  }));

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      emailId: "",
      password: "Pass@2025",
      location: "",
      device: "",
      deviceInfo: {},
      ip: "",
    },
    validationSchema: Yup.object({
      emailId: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: () => {
      // recaptchaRef.current.execute();
    },
  });

  useEffect(() => {
    if (user) dispatch(getUserStepsThunk(user?.completedSteps));

    if (user?.profilePictureLink) {
      const cb = (img: Blob | MediaSource) => {
        const temp = URL.createObjectURL(img);
        setProfileImage(temp);
      };
      dispatch(getFileThunk(encodeURIComponent(user?.profilePictureLink), cb));
    } else setProfileImage("");

    if (user?.emailId) validation.setFieldValue("emailId", user?.emailId);

    // Get location data when component mounts
    const locationCallback = (data: {
      city?: string;
      region_name?: string;
      country_name?: string;
      zip?: string;
      ip?: string;
    }) => {
      validation.setFieldValue(
        "location",
        `${data?.city || ""}, ${data?.region_name || ""}, ${
          data?.country_name || ""
        }, ${data?.zip || ""}`
      );
      validation.setFieldValue("ip", data?.ip);
    };

    dispatch(getLocationThunk(locationCallback));
  }, [user]);

  const handleUnlock = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!password) {
      setError("Please enter your password");
      return;
    }

    setIsLoading(true);
    try {
      // Validate credentials and get new tokens
      const loginResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            emailId: user?.emailId,
            password: password,
            device: `${osName || ""} ${browserName || ""}`,
            deviceInfo: {
              osName,
              browserVersion,
              browserName,
              mobileVendor,
              mobileModel,
              deviceType,
            },
            location: validation.values.location,
            ip: validation.values.ip,
          }),
        }
      );

      if (!loginResponse.ok) {
        throw new Error("Invalid credentials");
      }

      const newLoginData = await loginResponse.json();

      // If credentials are valid, logout the current session
      const currentLoginData = localStorage.getItem("loginData");
      const currentAuthToken = currentLoginData
        ? JSON.parse(currentLoginData).authToken
        : null;
      const currentApiKey = currentLoginData
        ? JSON.parse(currentLoginData).apiKey
        : null;

      if (currentAuthToken && currentApiKey) {
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/logout`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authToken: currentAuthToken,
            apiKey: currentApiKey,
          },
        });
      }

      // Update localStorage with new tokens
      if (newLoginData?.apiKey && newLoginData?.authToken) {
        localStorage.setItem(
          "loginData",
          JSON.stringify({
            apiKey: newLoginData.apiKey,
            authToken: newLoginData.authToken,
          })
        );

        // Clear lock screen state
        // setShowLockScreen(false);
        setError("");
        setPassword("");

        Cookies.remove("screenLock");
        // Perform hard reload to reinitialize app with new tokens
        window.location.reload();
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error: any) {
      setError(error.message || "Login failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignOut = () => {
    // Clear any auth tokens or state
    dispatch(authUserLogoutThunk(true));
  };

  return (
    <ParticlesAuth>
      <div className="col-xxl-6 mx-auto">
        <Card className="card mb-0 border-0 shadow-none mb-0">
          <Card.Body className="p-sm-5 m-lg-4">
            <div className="text-center">
              {/* <h5 className="fs-3xl">Lock Screen</h5>
              <p className="text-muted mb-4">
                Enter your password to unlock the screen!
              </p> */}
            </div>
            <div className="user-thumb text-center">
              <div className="avatar-lg mx-auto">
                {profileImage ? (
                  <Image
                    src={profileImage}
                    alt="User Avatar"
                    className="rounded-circle img-thumbnail user-profile-image"
                    onError={() => {
                      setProfileImage("");
                    }}
                  />
                ) : (
                  <div className="avatar-lg rounded-circle p-1 img-thumbnail m-auto">
                    <div className="avatar-title rounded-circle bg-secondary fs-5xl">
                      {getInitials(
                        `${user?.firstName || ""} ${user?.lastName || ""}`
                      )}
                    </div>
                  </div>
                )}
              </div>
              <h5 className="font-size-15 mt-3">
                Welcome back, {user?.firstName || "User"}!
              </h5>
            </div>
            <div className="p-2 mt-4">
              <Form onSubmit={handleUnlock}>
                <div className="mb-3">
                  <Form.Label htmlFor="password">
                    Password <span className="text-primary">*</span>
                  </Form.Label>
                  <div className="position-relative auth-pass-inputgroup mb-3">
                    <Form.Control
                      type={passwordShow ? "text" : "password"}
                      id="password"
                      name="password"
                      className={`form-control pe-5 password-input ${
                        error ? "is-invalid" : ""
                      }`}
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      autoComplete="current-password"
                    />
                    <Button
                      variant="link"
                      className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                      type="button"
                      id="password-addon"
                      onClick={() => setPasswordShow(!passwordShow)}
                    >
                      <i
                        className={`ri-${
                          passwordShow ? "eye-off" : "eye"
                        }-fill align-middle`}
                      ></i>
                    </Button>
                  </div>
                  {error && (
                    <div className="invalid-feedback d-block">{error}</div>
                  )}
                </div>
                <div className="mb-2 mt-4">
                  <Button
                    className="btn btn-primary w-100"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? "Unlocking..." : "Unlock"}
                  </Button>
                </div>
              </Form>
            </div>
            <div className="mt-4 text-center">
              <p className="mb-0">
                Not you ? return{" "}
                <button
                  className="fw-semibold text-primary text-decoration-underline border-0 bg-transparent"
                  type="button"
                  onClick={handleSignOut}
                >
                  Signin
                </button>
              </p>
            </div>
          </Card.Body>
        </Card>
      </div>
    </ParticlesAuth>
  );
};

export default withRouter(LockScreen);
