import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  Row,
  Col,
  Form,
  Spinner,
  Container,
  Dropdown,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import Flatpickr from "react-flatpickr";
import {
  dateRangeArr,
  getEndOfDay,
  getStartOfDay,
  handleDateFunction,
  selectedTypeArr,
  selectedTypeArr2,
  styleObj,
} from "helpers/common";
import { customSelectTheme } from "helpers/common";
import withRouter from "Common/withRouter";
import Select from "react-select";
import CommonAnalytics from "pages/Analytics/CommonAnalytics";
import * as Yup from "yup";

const NumberAnalytics = (props: any) => {
  const analyticsRef = useRef<any>();

  const selectProfile = createSelector(
    (state: any) => state.Analytics,
    (state: any) => state.Numbers,
    (analytics, number) => ({
      loading: analytics.loading,
      numberDetails: number?.numberDetails,
    })
  );

  const { loading, numberDetails } = useSelector(selectProfile);

  const [selectedType, setSelectedType] = useState(selectedTypeArr[0]);
  const [selectedType2, setSelectedType2] = useState(selectedTypeArr2[1]);
  const [selectedSubType, setSelectedSubType] = useState("totalMessages");
  const [selectedSubTypeLabel, setSelectedSubTypeLabel] = useState(
    "Total messages sent"
  );
  const [open, setOpen] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [dateRangeTemp, setDateRangeTemp] = useState(dateRangeArr[1]);
  const [filterObj, setFilterObj] = useState<any>({
    groupId: numberDetails?.groupId || "",
    subGroupId: numberDetails?.subGroupId || [],
    brandId: numberDetails?.brandId
      ? [{ brandId: numberDetails?.brandId }]
      : [],
    campaignId: numberDetails?.campaignId
      ? [{ campaignId: numberDetails?.campaignId }]
      : [],
    phoneNumber: props.phoneNumber ? [{ phoneNumber: props.phoneNumber }] : [],
    createdAt: "",
    ...handleDateFunction(dateRangeArr[1]?.value),
  });

  const validation: any = useFormik({
    enableReinitialize: false,
    initialValues: {
      groupId: numberDetails?.groupId || "",
      subGroupId: numberDetails?.subGroupId || [],
      brandId: numberDetails?.brandId
        ? [{ brandId: numberDetails?.brandId }]
        : [],
      campaignId: numberDetails?.campaignId
        ? [{ campaignId: numberDetails?.campaignId }]
        : [],
      phoneNumber: props.phoneNumber
        ? [{ phoneNumber: props.phoneNumber }]
        : [],
      createdAt: "",
      ...handleDateFunction(dateRangeArr[1]?.value),
    },
    validationSchema: Yup.object({
      groupId: Yup.string().required("Please Select Group"),
    }),
    onSubmit: (values) => {
      setOpen(!open);
      analyticsRef?.current?.handleGetData(values);
      setFilterObj(values);
    },
  });

  useEffect(() => {
    if (numberDetails?.groupId) {
      setDateRangeTemp(dateRangeArr[1]);
      const { startDate, endDate } = handleDateFunction(dateRangeArr[1]?.value);
      const createdAt = `${startDate} to ${endDate}`;

      validation.setFieldValue("startDate", startDate);
      validation.setFieldValue("endDate", endDate);
      validation.setFieldValue("createdAt", createdAt);

      validation.setValues({
        ...validation.values,
        startDate,
        endDate,
        createdAt,
      });

      setFilterObj({
        ...validation.values,
        startDate,
        endDate,
        createdAt,
      });

      analyticsRef?.current?.handleGetData({
        ...validation.values,
        startDate,
        endDate,
      });
      setIsInit(false);
    }
  }, [numberDetails?.groupId]);

  useEffect(() => {
    if (numberDetails?.groupId && !isInit) {
      analyticsRef?.current?.handleGetData({ ...filterObj });
    }
  }, [selectedType]);

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center mb-1 me-3">
                <h4 className="mb-0 me-3">Analytics</h4>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Analytics filters"
                >
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Message type"
                  >
                    {selectedTypeArr
                      ?.filter((dt) => dt !== "All")
                      ?.map((dt, i) => (
                        // <Tooltip placement="bottom" overlay={dt} key={i}>
                        <Button
                          key={i}
                          className={`btn me-1 ${
                            selectedType === dt || selectedType === "All"
                              ? "btn-secondary "
                              : "btn-outline-secondary hover-button"
                          }`}
                          onClick={() => {
                            if (selectedType === dt || selectedType === "All") {
                              const otherType = selectedTypeArr.find(
                                (t) => t !== "All" && t !== dt
                              );
                              setSelectedType(otherType || "All");
                            } else {
                              setSelectedType(
                                selectedType === "All" ? dt : "All"
                              );
                            }
                          }}
                        >
                          {dt === "SMS" ? (
                            <i className="bi bi-chat-left-text" />
                          ) : (
                            <i className="bi bi-file-image" />
                          )}
                          <span className="ms-1 d-none d-sm-inline">{dt}</span>
                        </Button>
                        // </Tooltip>
                      ))}
                  </div>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Message direction"
                  >
                    {selectedTypeArr2
                      ?.filter((dt) => dt !== "All")
                      ?.map((dt, i) => (
                        // <Tooltip placement="bottom" overlay={dt} key={i}>
                        <Button
                          key={i}
                          className={`btn me-1 d-flex ${
                            selectedType2 === dt || selectedType2 === "All"
                              ? "btn-secondary"
                              : "btn-outline-secondary hover-button"
                          }`}
                          onClick={() => {
                            const setTypes = (type: any) => {
                              if (type === "Inbound") {
                                setSelectedSubType("totalInbound");
                                setSelectedSubTypeLabel(
                                  "Total messages received"
                                );
                              } else {
                                setSelectedSubType("deliveryRate");
                                setSelectedSubTypeLabel("Delivered");
                              }
                            };

                            if (
                              selectedType2 === dt ||
                              selectedType2 === "All"
                            ) {
                              const otherType = selectedTypeArr2.find(
                                (t) => t !== "All" && t !== dt
                              );
                              setSelectedType2(otherType || "All");
                              setTypes(otherType);
                            } else {
                              setSelectedType2(
                                selectedType2 === "All" ? dt : "All"
                              );
                              setTypes(dt);
                            }
                          }}
                        >
                          <div
                            style={
                              dt === "Inbound"
                                ? {
                                    transform: "rotate(180deg)",
                                  }
                                : {}
                            }
                          >
                            <i className="bi bi-send" />
                          </div>
                          <span className="ms-1 d-none d-sm-inline">{dt}</span>
                        </Button>
                        // </Tooltip>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="d-flex align-items-center justify-content-end"
              style={{ flex: "1 1 0%" }}
            >
              <div className="d-flex align-items-center flex-wrap">
                {dateRangeTemp?.value ? (
                  dateRangeTemp?.value === "Custom" ? (
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      Date Range: {validation.values.startDate}
                      {validation.values.endDate
                        ? ` to ${validation.values.endDate}`
                        : ""}
                    </span>
                  ) : (
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      Date Range: {dateRangeTemp?.value}
                    </span>
                  )
                ) : null}
              </div>
              <Dropdown
                align="end"
                className="ms-2 filterDropDown2"
                show={open}
                onToggle={() => {
                  if (!datePickerModal) {
                    setOpen(!open);
                  }
                }}
              >
                <Dropdown.Toggle variant="secondary">
                  <i className="bx bx-filter-alt me-1" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                    }}
                  >
                    <Row>
                      <div>
                        {validation.touched.groupId &&
                        validation.errors.groupId ? (
                          <p className="text-primary">
                            {validation.errors.groupId}
                          </p>
                        ) : null}
                      </div>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="dateRange">Date Range</Form.Label>
                        <Select
                          isClearable={true}
                          styles={styleObj(false)}
                          theme={customSelectTheme}
                          options={dateRangeArr}
                          onChange={(e: any) => {
                            setDateRangeTemp(e);
                            const { startDate, endDate } = handleDateFunction(
                              e.value
                            );
                            validation.setFieldValue("createdAt", "");
                            validation.setFieldValue("startDate", startDate);
                            validation.setFieldValue("endDate", endDate);
                          }}
                          value={dateRangeTemp}
                        />
                      </Col>
                      {dateRangeTemp?.value === "Custom" ? (
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="createdAt">
                            Select Range
                          </Form.Label>
                          <Flatpickr
                            className="form-control"
                            name="createdAt"
                            placeholder="Select Range"
                            options={{
                              enableTime: false,
                              onOpen: () => {
                                setDatePickerModal(true);
                              },
                              onClose: () => {
                                setDatePickerModal(false);
                              },
                              altFormat: "F j, Y",
                              dateFormat: "m-d-Y",
                              mode: "range",
                              onChange: (
                                value: any,
                                dateStr: string,
                                instance: any
                              ) => {
                                validation.setFieldValue(
                                  "createdAt",
                                  dateStr || ""
                                );
                                value?.[0] &&
                                  validation.setFieldValue(
                                    "startDate",
                                    getStartOfDay(value?.[0])
                                  );
                                value?.[1] &&
                                  validation.setFieldValue(
                                    "endDate",
                                    getEndOfDay(value?.[1])
                                  );
                              },
                            }}
                            value={validation.values.createdAt || ""}
                          />
                        </Col>
                      ) : null}
                    </Row>
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        className="btn btn-secondary me-2"
                        type="button"
                        disabled={loading}
                        onClick={() => {
                          let values = {
                            ...validation.values,
                            createdAt: "",
                            ...handleDateFunction(dateRangeArr[1]?.value),
                          };
                          validation.setValues(values);
                          setFilterObj(values);
                          analyticsRef?.current?.handleGetData(values);
                          setDatePickerModal(false);
                          setDateRangeTemp(dateRangeArr[1]);
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                      >
                        {loading && <Spinner size="sm" animation="border" />}{" "}
                        Apply
                      </Button>
                    </div>
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <CommonAnalytics
            ref={analyticsRef}
            isInit={isInit}
            filterObj={filterObj}
            selectedType={selectedType}
            selectedType2={selectedType2}
            selectedSubType={selectedSubType}
            setSelectedSubType={setSelectedSubType}
            selectedSubTypeLabel={selectedSubTypeLabel}
            setSelectedSubTypeLabel={setSelectedSubTypeLabel}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(NumberAnalytics);
