import React, { useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  Card,
  Button,
  Form,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Datatable from "../../Common/Datatable";
import {
  capitalizeString,
  dateFormat,
  removeEmptyAndNullValues,
  humanizeString,
  customSelectTheme,
  styleObj,
  asyncBrandList,
  asyncSubgroupList,
  getStartOfDay,
  getEndOfDay,
} from "helpers/common";
import Loader from "assets/images/spinner-dark.svg";
import withRouter from "Common/withRouter";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import Select from "react-select";
import {
  getCountryListThunk,
  getEntityTypeListThunk,
  getBrandListThunk,
  deleteBrandThunk,
  openModal,
} from "slices/thunk";
import { submitLoadingSubject } from "Common/modals/Modal";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { BrandSelect } from "Common/filter/brand-select";
import { GroupSelect } from "Common/filter/group-select";
import Tooltip from "rc-tooltip";

const BrandsList = (props: any) => {
  document.title = "Signal House Portal Brands";
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const currPage = Number(searchParams.get("currPage") || 1);
  const rowsPerPage = Number(searchParams.get("rowsPerPage") || 10);
  const isFilter = searchParams.get("isFilter");
  const groupId = searchParams.get("groupId");
  const subGroupId = searchParams.get("subGroupId");
  const subgroupnames = searchParams.get("subgroupnames");
  const brandId = searchParams.get("brandId");

  const [open, setOpen] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);

  const selectProfile = createSelector(
    (state: any) => state.Brands,
    (state: any) => state.Enums,
    (state: any) => state.Groups,
    (brands, enums, groups) => ({
      loading: brands.loading,
      brandsData: brands.brandsData,
      entityTypeArr: enums.entityTypeArr,
      groupDetails: groups.AllGroups,
      error: brands.error,
    })
  );

  const { loading, brandsData, entityTypeArr, groupDetails } =
    useSelector(selectProfile);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Create Date</span>,
      minWidth: "220px",
      selector: (row: { createDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.createDate, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (
          new Date(a.createDate).getTime() - new Date(b.createDate).getTime()
        );
      },
    },
    {
      name: (
        <span data-tag="allowRowEvents" className="font-weight-bold fs-sm">
          Brand ID
        </span>
      ),
      minWidth: "110px",
      selector: (row: { brandId: string }) => (
        <span data-tag="allowRowEvents" className="text-primary">
          <b data-tag="allowRowEvents">{row.brandId}</b>
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => a.brandId.localeCompare(b.brandId),
    },
    {
      name: <span className="font-weight-bold fs-sm">Name</span>,
      minWidth: "150px",
      selector: (row: { displayName: string }) =>
        capitalizeString(row.displayName),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        a.displayName.localeCompare(b.displayName),
    },
    {
      name: <span className="font-weight-bold fs-sm">Country</span>,
      selector: (row: { country: string }) => row.country,
      sortable: true,
      sortFunction: (a: any, b: any) => a.country.localeCompare(b.country),
    },
    {
      name: <span className="font-weight-bold fs-sm">Entity Type</span>,
      minWidth: "180px",
      selector: (row: { entityType: string }) => (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-secondary border border-secondary text-secondary"
        >
          {humanizeString(row.entityType)}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        a.entityType.localeCompare(b.entityType),
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      minWidth: "200px",
      selector: (cell: {
        brandIdentityStatus: string;
        brandFeedBack?: any;
      }) => {
        switch (cell.brandIdentityStatus.toLowerCase()) {
          case "verified":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {humanizeString(cell.brandIdentityStatus)}
              </span>
            );
          case "unverified":
            return (
              <div className="d-flex align-items-center">
                <span
                  data-tag="allowRowEvents"
                  className="d-flex align-items-center text-primary"
                >
                  <span
                    data-tag="allowRowEvents"
                    className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2"
                  >
                    <span></span>
                  </span>
                  {humanizeString(cell.brandIdentityStatus)}
                </span>
                {cell?.brandFeedBack?.length > 0 ? (
                  <Tooltip
                    overlay={
                      <ul className="vstack gap-2 list-unstyled mb-0">
                        {cell?.brandFeedBack?.map((dt: any, i: number) =>
                          dt?.errors?.length > 0 ? (
                            dt?.errors?.map((dt2: any, i2: number) => (
                              <li key={i2}>
                                {dt?.id} - {dt2?.message || "-"}
                              </li>
                            ))
                          ) : (
                            <li key={i}>
                              {dt?.id} - {dt?.description || "-"}
                            </li>
                          )
                        )}
                      </ul>
                    }
                  >
                    <i className="ri-information-line align-middle text-secondary fs-2xl cursor-pointer ms-2" />
                  </Tooltip>
                ) : null}
              </div>
            );
          case "vetted_verified":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-dark"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-dark p-1 me-2"
                >
                  <span></span>
                </span>
                {humanizeString(cell.brandIdentityStatus)}
              </span>
            );
          case "self_declared":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-dark"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-dark p-1 me-2"
                >
                  <span></span>
                </span>
                {humanizeString(cell.brandIdentityStatus)}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center"
                style={{ color: "#6c757d" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ backgroundColor: "#6c757d" }}
                >
                  <span></span>
                </span>
                {humanizeString(cell.brandIdentityStatus)}
              </span>
            );
        }
      },
      sortable: true,
      sortFunction: (a: any, b: any) =>
        a.brandIdentityStatus.localeCompare(b.brandIdentityStatus),
    },
    {
      name: <span className="font-weight-bold fs-sm">Action</span>,
      cell: (cell: { brandId: string }) => {
        return (
          <>
            <i
              className="ri-pencil-fill align-middle me-3 text-muted fs-xl"
              onClick={() => navigate(`/brands/edit/${cell.brandId}`)}
            />
            <i
              className="ri-delete-bin-fill align-middle text-muted fs-xl"
              onClick={() => {
                dispatch(
                  openModal({
                    modalbody:
                      "Are you sure you want to delete this brand? This action is irrevocable.",
                    data: {
                      title: "Delete Brand",
                      footer: true,
                      cancelBtn: true,
                      buttonText: {
                        submit: "Delete",
                      },
                      onSubmit: ({ onClose }: any) => {
                        const cb = () => {
                          onClose();
                          handleGetData(
                            currPage,
                            rowsPerPage,
                            validation.values
                          );
                        };
                        submitLoadingSubject.next(true);
                        dispatch(deleteBrandThunk(cell.brandId, cb)).then(
                          () => {
                            submitLoadingSubject.next(false);
                          }
                        );
                      },
                    },
                  })
                );
              }}
            />
          </>
        );
      },
    },
  ];

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      subGroupId: [],
      brandId: [],
      entityType: "",
      ein: "",
      universalEin: "",
      identityStatus: "",
      // referenceId: "",
      createdAt: "",
      startDate: "",
      endDate: "",
    },
    onSubmit: (values) => {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        ...values,
        subGroupId: JSON.stringify(values.subGroupId),
        brandId: JSON.stringify(values.brandId),
        currPage: String(1),
        isFilter: "true",
      });
      handleGetData(1, rowsPerPage, values);
    },
  });

  useEffect(() => {
    dispatch(getCountryListThunk());
    dispatch(getEntityTypeListThunk());
    isFilter !== "true" &&
      handleGetData(currPage, rowsPerPage, {
        ...validation.values,
        subGroupId: subGroupId ? [subGroupId] : [],
        brandId: brandId
          ? [
              {
                subGroupId: subGroupId || "",
                brandId: brandId || "",
              },
            ]
          : [],
      });
  }, []);

  useEffect(() => {
    if (isFilter !== "true") {
      subGroupId && validation.setFieldValue("subGroupId", [subGroupId]);
      brandId &&
        validation.setFieldValue("brandId", [
          {
            subGroupId: subGroupId || "",
            brandId: brandId || "",
          },
        ]);
    }
  }, [subGroupId, brandId]);

  useEffect(() => {
    if (isFilter === "true") {
      const temp = { ...Object.fromEntries(searchParams.entries()) };
      const temp2 = {
        ...validation.initialValues,
        subGroupId: temp?.subGroupId ? JSON.parse(temp?.subGroupId) : [],
        brandId: temp?.brandId ? JSON.parse(temp?.brandId) : [],
        entityType: temp?.entityType || "",
        ein: temp?.ein || "",
        universalEin: temp?.universalEin || "",
        identityStatus: temp?.identityStatus || "",
        createdAt: temp?.createdAt || "",
        startDate: temp?.startDate || "",
        endDate: temp?.endDate || "",
      };
      validation.setValues(temp2);
      handleGetData(currPage, rowsPerPage, temp2);
    }
  }, [isFilter]);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      getBrandListThunk(
        page,
        perPage,
        removeEmptyAndNullValues({
          ...otherParams,
          subGroupId:
            otherParams?.subGroupId?.length > 0 ? otherParams?.subGroupId : "",
          brandId:
            otherParams?.brandId?.length > 0
              ? otherParams?.brandId?.map((dt: any) => dt.brandId)
              : "",
          createdAt: "",
          startDate: otherParams?.startDate
            ? moment(otherParams?.startDate).toISOString()
            : "",
          endDate: otherParams?.endDate
            ? moment(otherParams?.endDate).toISOString()
            : "",
        })
      )
    );
  };

  const handleRowClick = (row: { brandId: string }) => {
    props.router.navigate(`/brands/${row.brandId}`);
  };

  const handlePageChange = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      currPage: String(page),
    });
    handleGetData(page, rowsPerPage, validation.values);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      currPage: String(1),
      rowsPerPage: String(rows),
    });
    handleGetData(1, rows, validation.values);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Brands"
            isBack={Boolean(subGroupId && isFilter !== "true")}
            backClick={() =>
              props.router.navigate(
                subGroupId
                  ? `/groups/${groupId}/subgroups/${subGroupId}/${subgroupnames}`
                  : -1
              )
            }
            isFilter={true}
            filterButton={
              <Dropdown
                align="end"
                className="filterDropDown2"
                show={open}
                onToggle={() => {
                  if (!datePickerModal) {
                    setOpen(!open);
                  }
                }}
              >
                <Dropdown.Toggle variant="secondary">
                  <i className="bx bx-filter-alt me-1"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setOpen(!open);
                      validation.handleSubmit();
                    }}
                  >
                    <Row>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
                        <GroupSelect
                          isDisabled={!groupDetails?.records?.[0]?.group_id}
                          key={groupDetails?.records?.[0]?.group_id}
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.subGroupId &&
                              validation?.errors?.subGroupId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.subGroupId?.length > 0
                              ? validation.values.subGroupId.map(
                                  (dt: string) => ({
                                    sub_group_id: dt,
                                  })
                                )
                              : null
                          }
                          name="subGroupId"
                          loadOptions={asyncSubgroupList(
                            removeEmptyAndNullValues({
                              groupId:
                                groupDetails?.records?.[0]?.group_id || "",
                            }),
                            "subGroupId"
                          )}
                          getOptionValue={(option: any) => option?.sub_group_id}
                          getOptionLabel={(option: any) =>
                            option?.sub_group_name +
                            " - " +
                            option?.sub_group_id
                          }
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.sub_group_id !==
                                validation.values.subGroupId
                            ) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.map((dt: any) => dt.sub_group_id)
                              );
                              validation.setFieldValue("brandId", []);
                            } else if (!option) {
                              validation.setFieldValue("subGroupId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="brandId">Brand</Form.Label>
                        <BrandSelect
                          key={
                            validation.values.subGroupId.toString() ||
                            groupDetails?.records?.[0]?.group_id ||
                            "brandId"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.brandId &&
                              validation?.errors?.brandId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.brandId?.length > 0
                              ? validation.values.brandId
                              : null
                          }
                          loadOptions={asyncBrandList(
                            removeEmptyAndNullValues({
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                            }),
                            "brandId"
                          )}
                          getOptionValue={(option: any) => option?.brandId}
                          getOptionLabel={(option: any) =>
                            option?.displayName + " - " + option?.brandId
                          }
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  displayName: dt?.displayName || "",
                                }))
                              );
                            } else {
                              validation.setFieldValue("brandId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="entityType">
                          Entity Type
                        </Form.Label>
                        <Select
                          name="entityType"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.entityType &&
                              validation?.errors?.entityType
                          )}
                          theme={customSelectTheme}
                          options={
                            Array.isArray(entityTypeArr) &&
                            entityTypeArr?.length > 0
                              ? entityTypeArr?.map((dt: string) => ({
                                  label: humanizeString(dt),
                                  value: dt,
                                }))
                              : []
                          }
                          onChange={(e: { value: string }) => {
                            if (e) {
                              validation.setFieldValue("entityType", e.value);
                            } else {
                              validation.setFieldValue("entityType", "");
                            }
                          }}
                          value={
                            validation?.values?.entityType
                              ? {
                                  label: humanizeString(
                                    validation?.values?.entityType
                                  ),
                                  value: validation?.values?.entityType,
                                }
                              : null
                          }
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="ein">EIN/Tax Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="ein"
                          onChange={(e) => {
                            validation.setFieldValue("ein", e.target.value);
                          }}
                          value={validation.values.ein}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="universalEin">
                          Universal EIN
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="universalEin"
                          onChange={(e) => {
                            validation.setFieldValue(
                              "universalEin",
                              e.target.value
                            );
                          }}
                          value={validation.values.universalEin}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="identityStatus">
                          Identity Status
                        </Form.Label>
                        <Select
                          name="identityStatus"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.identityStatus &&
                              validation?.errors?.identityStatus
                          )}
                          theme={customSelectTheme}
                          options={[
                            {
                              label: humanizeString("SELF_DECLARED"),
                              value: "SELF_DECLARED",
                            },
                            {
                              label: humanizeString("UNVERIFIED"),
                              value: "UNVERIFIED",
                            },
                            {
                              label: humanizeString("VERIFIED"),
                              value: "VERIFIED",
                            },
                            {
                              label: humanizeString("VETTED_VERIFIED"),
                              value: "VETTED_VERIFIED",
                            },
                            {
                              label: humanizeString("Pending"),
                              value: "Pending",
                            },
                          ]}
                          onChange={(e: { value: string }) => {
                            if (e) {
                              validation.setFieldValue(
                                "identityStatus",
                                e.value
                              );
                            } else {
                              validation.setFieldValue("identityStatus", "");
                            }
                          }}
                          value={
                            validation.values.identityStatus
                              ? {
                                  label: humanizeString(
                                    validation.values.identityStatus
                                  ),
                                  value: validation.values.identityStatus,
                                }
                              : null
                          }
                        />
                      </Col>
                      {/* <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="referenceId">
                          Reference ID
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="referenceId"
                          onChange={(e) => {
                            validation.setFieldValue(
                              "referenceId",
                              e.target.value
                            );
                          }}
                          value={validation.values.referenceId}
                        />
                      </Col> */}
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="createdAt">Created At</Form.Label>
                        <Flatpickr
                          className="form-control"
                          name="createdAt"
                          placeholder="Created At"
                          options={{
                            enableTime: false,
                            onOpen: () => {
                              setDatePickerModal(true);
                            },
                            onClose: () => {
                              setDatePickerModal(false);
                            },
                            altFormat: "F j, Y",
                            dateFormat: "m-d-Y",
                            mode: "range",
                            onChange: (
                              value: any,
                              dateStr: string,
                              instance: any
                            ) => {
                              validation.setFieldValue(
                                "createdAt",
                                dateStr || ""
                              );
                              value?.[0] &&
                                validation.setFieldValue(
                                  "startDate",
                                  getStartOfDay(value?.[0])
                                );
                              value?.[1] &&
                                validation.setFieldValue(
                                  "endDate",
                                  getEndOfDay(value?.[1])
                                );
                            },
                          }}
                          value={validation.values.createdAt || ""}
                        />
                      </Col>
                    </Row>
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        className="btn btn-secondary me-2"
                        type="button"
                        disabled={loading}
                        onClick={() => {
                          validation.resetForm();
                          setDatePickerModal(false);
                          setSearchParams({
                            ...Object.fromEntries(searchParams.entries()),
                            ...validation.initialValues,
                            currPage: String(1),
                            isFilter: "true",
                          });
                          handleGetData(1, rowsPerPage);
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                      >
                        {loading && <Spinner size="sm" animation="border" />}{" "}
                        Apply
                      </Button>
                    </div>
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            }
            isCreate={true}
            createLabel="Add new brand"
            createLink="/brands/create"
          />

          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="listing-table">
                {loading ? (
                  <div>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(brandsData?.records)
                        ? brandsData?.records
                        : []
                    }
                    columns={columns}
                    handleRowClick={handleRowClick}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={brandsData?.totalRecords}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(BrandsList);
