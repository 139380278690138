import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Button,
  Row,
  Col,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Spinner,
  Collapse,
  Dropdown,
  OverlayTrigger,
  Popover,
  Badge,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  getBrandDetailsThunk,
  getCampaignListThunk,
  getUsecaseListThunk,
  BrandSuspendedMnoThunk,
  checkBrandAppealStatusThunk,
  getBrandAppealHistoryThunk,
  uploadEvidenceThunk,
  getUploadedEvidenceThunk,
  deleteBrandCampaignEvidenceThunk,
  appealCategoryOptionsThunk,
  revertBrandThunk,
  applyVettingThunk,
  applyVettingEvpThunk,
  submitVettingDataThunk,
  importVattingSubmitThunk,
  getVattingListThunk,
  getDownloadVattingAttachmentThunk,
  getCspWebhookEventsListThunk,
  openModal,
  getActiveCampaignListThunk,
  fatchAccountPricingThunk,
  getSubgroupDetailsThunk,
  enhancedVettingReportThunk,
  getBrandFeedbackThunk,
} from "slices/thunk";
import Datatable from "../../Common/Datatable";
import {
  capitalizeString,
  customSelectTheme,
  dateFormat,
  humanizeString,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import Loader from "assets/images/spinner-dark.svg";
import withRouter from "Common/withRouter";
import { createSelector } from "reselect";
import { useFormik } from "formik";
import Select from "react-select";
import Tooltip from "rc-tooltip";
// import moment from "moment";
import { Link, useSearchParams } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import { submitLoadingSubject } from "Common/modals/Modal";
import { toast } from "react-toastify";

interface appealFormTypes {
  evpUid: string;
  appealCategories: string[];
  explanation: string;
  files: any[];
}

interface HoverPopoverProps {
  children: React.ReactNode;
  placement?:
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "top-start"
    | "top-end"
    | "bottom-start"
    | "bottom-end";
  className?: string;
  popoverClassName?: string; // Added for popover-specific styling
  width?: number | string; // Added for custom widths
  onOpen?: () => void; // Added for tracking/analytics
  onClose?: () => void; // Added for tracking/analytics
  showTitle?: boolean;
}

const HoverPopover: React.FC<HoverPopoverProps> = ({
  children,
  placement = "bottom-end",
  className = "",
  popoverClassName = "",
  width,
  onOpen,
  onClose,
  showTitle = false,
}) => {
  const [show, setShow] = useState(false);

  const handleMouseEnter = () => {
    setShow(true);
    onOpen?.();
  };

  const handleMouseLeave = () => {
    setShow(false);
    onClose?.();
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={className}
    >
      <OverlayTrigger
        show={show}
        placement={placement}
        overlay={
          <Popover
            id="hover-popover"
            className={`hover-popover ${popoverClassName}`}
            style={width ? { width } : undefined}
          >
            {showTitle && (
              <Popover.Header>
                Please see the vetting details below:
              </Popover.Header>
            )}
            <Popover.Body>{children}</Popover.Body>
          </Popover>
        }
      >
        <i className="ri-eye-fill align-middle text-secondary fs-lg cursor-pointer ms-2"></i>
      </OverlayTrigger>
    </div>
  );
};

const showStatus = (status: string) => {
  switch (status?.toLowerCase()) {
    case "verified":
      return (
        <span
          data-tag="allowRowEvents"
          className="d-flex align-items-center text-secondary"
        >
          <span
            data-tag="allowRowEvents"
            className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
          >
            <span></span>
          </span>
          {humanizeString(status)}
        </span>
      );
    case "unverified":
      return (
        <span
          data-tag="allowRowEvents"
          className="d-flex align-items-center text-primary"
        >
          <span
            data-tag="allowRowEvents"
            className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2"
          >
            <span></span>
          </span>
          {humanizeString(status)}
        </span>
      );
    case "vetted_verified":
      return (
        <span
          data-tag="allowRowEvents"
          className="d-flex align-items-center text-dark"
        >
          <span
            data-tag="allowRowEvents"
            className="badge border border-2 border-white rounded-circle bg-dark p-1 me-2"
          >
            <span></span>
          </span>
          {humanizeString(status)}
        </span>
      );
    case "self_declared":
      return (
        <span
          data-tag="allowRowEvents"
          className="d-flex align-items-center text-dark"
        >
          <span
            data-tag="allowRowEvents"
            className="badge border border-2 border-white rounded-circle bg-dark p-1 me-2"
          >
            <span></span>
          </span>
          {humanizeString(status)}
        </span>
      );
    default:
      return (
        <span
          data-tag="allowRowEvents"
          className="d-flex align-items-center"
          style={{ color: "#6c757d" }}
        >
          <span
            data-tag="allowRowEvents"
            className="badge border border-2 border-white rounded-circle p-1 me-2"
            style={{ backgroundColor: "#6c757d" }}
          >
            <span></span>
          </span>
          {humanizeString(status || "-")}
        </span>
      );
  }
};

const renderYesNo = (value: boolean) => (
  <Badge bg={value ? "primary" : "secondary"} style={{ minWidth: "40px" }}>
    {value ? "Yes" : "No"}
  </Badge>
);

const BrandDetails = (props: any) => {
  document.title = "Signal House Portal Brands";
  const dispatch = useDispatch<any>();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get("groupId");
  const subGroupId = searchParams.get("subGroupId");
  const subgroupnames = searchParams.get("subgroupnames");

  const MAX_WORDS = 1024;

  const selectProfile = createSelector(
    (state: any) => state.Brands,
    (state: any) => state.Campaigns,
    (state: any) => state.Enums,
    (state: any) => state.AccountPricing,
    (state: any) => state.Groups,
    (brands, campaigns, enums, accountPricing, groups) => ({
      loading: brands.loading,
      loading2: campaigns.loading,
      loading3: campaigns.loading3,
      brandDetails: brands.brandDetails,
      appeals: brands.appeals,
      evidence: brands.evidence,
      brandsData: brands.brandsData,
      evidenceUploading: brands.evidenceUploading,
      evidenceUploadError: brands.evidenceUploadError,
      vettingBrand: brands.vettingBrand,
      vettingBrandOptions: brands.vettingBrandOptions,
      vettingBrandList: brands.vettingBrandList,
      vettingReport: brands.vettingReport,
      brandFeedback: brands.brandFeedback,
      campaignsData: campaigns.campaignsData,
      activeCampaignsData: campaigns.activeCampaignsData,
      cspWebhookEventsData: campaigns.cspWebhookEventsData,
      usecaseObj: enums.usecaseObj,
      walletPricingData: accountPricing.walletPricingData,
      error: brands.error,
      subgroupDetails: groups.subgroupDetails,
    })
  );

  const {
    loading,
    loading2,
    loading3,
    brandDetails,
    appeals,
    evidence,
    brandsData,
    evidenceUploading,
    evidenceUploadError,
    vettingBrand,
    vettingBrandOptions,
    vettingBrandList,
    vettingReport,
    brandFeedback,
    campaignsData,
    activeCampaignsData,
    cspWebhookEventsData,
    usecaseObj,
    walletPricingData,
    subgroupDetails,
  } = useSelector(selectProfile);

  const filteredBrand = subgroupDetails?.data?.brands?.filter(
    (dt: any) => dt?.brandId === props?.router?.params?.id
  );
  const campaigns = filteredBrand?.flatMap((dt: any) => dt.campaigns || []);
  const ownNumbers = campaigns?.flatMap((dt: any) => dt.ownNumbers || []);

  const columns1 = [
    {
      name: <span className="font-weight-bold fs-sm">Vetting Status</span>,
      minWidth: "160px",
      selector: (row: { appealStatus: string }) => row.appealStatus,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Request Date</span>,
      minWidth: "220px",
      selector: (row: { createDate: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-calendar3 me-2 fs-lg text-secondary" />
          {dateFormat(row.createDate, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Status Update Date</span>,
      minWidth: "200px",
      selector: (row: { appealStatusUpdateDate: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-calendar3 me-2 fs-lg text-secondary" />
          {dateFormat(row.appealStatusUpdateDate)}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Details</span>,
      minWidth: "150px",
      cell: (row: any) => (
        <Button
          style={{
            background: "none",
            border: "none",
            color: "#e41cfd",
          }}
          onClick={() => handleDetailsClick(row)}
        >
          Show Details
        </Button>
      ),
    },
  ];

  const columns2 = [
    {
      name: <span className="font-weight-bold fs-sm">Vetting Type</span>,
      minWidth: "160px",
      selector: (row: { vettingClass: string }) => row.vettingClass,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Vetting Partner</span>,
      minWidth: "160px",
      selector: (row: { evpId: string }) => row.evpId,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Vetting Status</span>,
      minWidth: "160px",
      selector: (row: { vettingStatus: string }) => row.vettingStatus,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Date</span>,
      minWidth: "220px",
      selector: (row: { createDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.createDate, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Outcome</span>,
      minWidth: "100px",
      selector: (row: {
        vettingScore: number;
        reasons: any;
        vettingId: string;
        vettingToken: string;
      }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          {row.vettingScore}
          <HoverPopover showTitle={true}>
            {row.reasons?.length > 0 ? (
              <>
                <ul className="ps-3 mb-0">
                  {row.reasons?.map((dt: string, i: number) => (
                    <li key={i} className="mb-2">
                      {dt}
                    </li>
                  ))}
                </ul>
                <hr className="dropdown-divider border-top mb-3" />
              </>
            ) : null}

            <p className="mb-1">
              <b>Vetting ID</b>:
              {row.vettingId && (
                <span
                  className="text-decoration-underline text-primary ms-1 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(row.vettingId);
                    toast.success("ID copied!");
                  }}
                >
                  Copy
                </span>
              )}
            </p>
            <p className="mb-2 text-truncate-two-lines">
              {row.vettingId || "-"}
            </p>
            <p className="mb-1">
              <b>Vetting Token</b>:
              {row.vettingToken && (
                <span
                  className="text-decoration-underline text-primary ms-1 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(row.vettingToken);
                    toast.success("Token copied!");
                  }}
                >
                  Copy
                </span>
              )}
            </p>
            <p className="mb-0 text-truncate-two-lines">
              {row.vettingToken || "-"}
            </p>
          </HoverPopover>
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Action</span>,
      minWidth: "150px",
      cell: (row: any) => (
        <div className="d-flex justify-content-center">
          <Dropdown>
            <Dropdown.Toggle
              variant="link"
              className="btn-icon rounded-circle"
              style={{
                width: "32px",
                height: "32px",
                padding: 0,
              }}
            >
              <i className="bi bi-three-dots-vertical text-secondary"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu
              align="end"
              className="shadow-sm"
              style={{
                minWidth: "150px",
                padding: "8px 0",
                border: "1px solid #E9ECEF",
                borderRadius: "6px",
                backgroundColor: "#fff",
                zIndex: 1000,
              }}
              rootCloseEvent="click"
              renderOnMount
              popperConfig={{
                strategy: "fixed",
                modifiers: [
                  {
                    name: "preventOverflow",
                    options: {
                      altAxis: true,
                      padding: 8,
                    },
                  },
                ],
              }}
            >
              {row.vettingClass === "ENHANCED" ? (
                <>
                  <Dropdown.Item
                    onClick={() =>
                      !loading &&
                      handleDownloadAttachment(vettingReport?.[0], false)
                    }
                    className="px-3 py-2"
                    style={{ fontSize: "14px" }}
                  >
                    View Report
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      !loading &&
                      handleDownloadAttachment(vettingReport?.[0], true)
                    }
                    className="px-3 py-2"
                    style={{ fontSize: "14px" }}
                  >
                    Download Report
                  </Dropdown.Item>
                </>
              ) : (
                <>
                  <Dropdown.Item
                    onClick={() => handleAppeal(row)}
                    className="px-3 py-2"
                    style={{ fontSize: "14px" }}
                  >
                    Appeal
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleViewAppealHistory(row)}
                    className="px-3 py-2"
                    style={{ fontSize: "14px" }}
                  >
                    View Appeal History
                  </Dropdown.Item>
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
    },
  ];

  const columns3 = [
    {
      name: <span className="font-weight-bold fs-sm">Campaign ID</span>,
      minWidth: "160px",
      selector: (row: { campaignId: string; campaignUuid: string }) =>
        row.campaignId.toLowerCase() === "pending" ||
        row.campaignId.toLowerCase() === "rejected" ? (
          <span
            data-tag="allowRowEvents"
            className="badge bg-body-secondary border border-primary text-primary"
          >
            {row.campaignUuid}
          </span>
        ) : row.campaignId?.toLowerCase().startsWith("temp") ? (
          <span
            data-tag="allowRowEvents"
            className="badge bg-body-primary border border-primary text-primary"
          >
            <b data-tag="allowRowEvents">{row.campaignId || "-"}</b>
          </span>
        ) : (
          <span data-tag="allowRowEvents" className="text-primary">
            <b data-tag="allowRowEvents">{row.campaignId || "-"}</b>
          </span>
        ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Use Case</span>,
      minWidth: "200px",
      selector: (row: { usecase: string }) => (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-secondary border border-secondary text-secondary"
        >
          {row.usecase
            .split("_")
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ")}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Registered On</span>,
      minWidth: "220px",
      selector: (row: { createDate: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-calendar3 me-2 fs-lg text-secondary" />
          {dateFormat(row.createDate, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-sm">Campaign Description</span>
      ),
      minWidth: "250px",
      grow: 2,
      wrap: true,
      style: { textAlign: "left" },
      selector: (row: { description: string }) => (
        <span data-tag="allowRowEvents" className="text-truncate-two-lines">
          {row.description || "-"}
        </span>
      ),
      sortable: true,
    },
  ];

  const columns4 = [
    {
      name: <span className="font-weight-bold fs-sm">CSP Id</span>,
      minWidth: "110px",
      selector: (row: { eventData: { cspId?: string } }) =>
        row.eventData?.cspId,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">CSP Name</span>,
      minWidth: "150px",
      selector: (row: { eventData: { cspName?: string } }) =>
        row.eventData?.cspName,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Description</span>,
      selector: (row: { eventData: { description: string } }) => (
        <Tooltip
          placement="bottom"
          overlay={<span>{row.eventData.description}</span>}
        >
          <i className="ri-eye-fill align-middle text-secondary fs-lg cursor-pointer" />
        </Tooltip>
      ),
    },
    {
      name: <span className="font-weight-bold fs-sm">Event Type</span>,
      minWidth: "230px",
      selector: (row: { eventData: { eventType: string } }) => (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-secondary border border-secondary text-secondary"
        >
          {row.eventData.eventType
            .split("_")
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ")}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      minWidth: "150px",
      selector: (row: { eventData: { brandIdentityStatus?: string } }) => {
        switch (row.eventData.brandIdentityStatus?.toLowerCase()) {
          case "re-open":
            return (
              <span className="d-flex align-items-center text-info">
                <span className="badge border border-2 border-white rounded-circle bg-info p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(row.eventData.brandIdentityStatus)}
              </span>
            );
          case "on-hold":
            return (
              <span className="d-flex align-items-center text-secondary">
                <span className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(row.eventData.brandIdentityStatus)}
              </span>
            );
          case "closed":
            return (
              <span className="d-flex align-items-center text-primary">
                <span className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(row.eventData.brandIdentityStatus)}
              </span>
            );
          case "failed":
            return (
              <span className="d-flex align-items-center text-primary">
                <span className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(row.eventData.brandIdentityStatus)}
              </span>
            );
          case "Inprogress":
            return (
              <span className="d-flex align-items-center text-dark">
                <span className="badge border border-2 border-white rounded-circle bg-dark p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(row.eventData.brandIdentityStatus)}
              </span>
            );
          case "in_progress":
            return (
              <span className="d-flex align-items-center text-dark">
                <span className="badge border border-2 border-white rounded-circle bg-dark p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(row.eventData.brandIdentityStatus)}
              </span>
            );
          case "open":
            return (
              <span className="d-flex align-items-center text-primary">
                <span className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(row.eventData.brandIdentityStatus)}
              </span>
            );
          case "new":
            return (
              <span className="d-flex align-items-center text-success">
                <span className="badge border border-2 border-white rounded-circle bg-success p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(row.eventData.brandIdentityStatus)}
              </span>
            );
          case "verified":
            return (
              <span className="d-flex align-items-center text-secondary">
                <span className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(row.eventData.brandIdentityStatus)}
              </span>
            );
          default:
            return (
              <span className="d-flex align-items-center text-dark">
                <span className="badge border border-2 border-white rounded-circle bg-dark p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString("In Progress")}
              </span>
            );
        }
      },
      sortable: true,
    },
  ];

  const [isInit, setIsInit] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modal1, setModal1] = useState<boolean>(false);
  const [modal2, setModal2] = useState<boolean>(false);
  const [modal3, setModal3] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [currPage2, setCurrPage2] = useState(1);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [modal4, setModal4] = useState<any>({});
  const [appealForm, setAppealForm] = useState<appealFormTypes>({
    evpUid: "",
    appealCategories: [],
    explanation: "",
    files: [],
  });
  const [vettingImportForm, setVettingImportForm] = useState({
    vettingType: "",
  });
  const [ventingForm, setVettingForm] = useState({
    vettingType: "",
    vettingPartner: "",
  });
  const [prevSrc, setPrevSrc] = useState("");
  const [isOpenImgLightBox, setIsOpenImgLightBox] = useState(false);
  const [isOpenDocLightBox, setIsOpenDocLightBox] = useState(false);
  const [isOpenVideoLightBox, setIsOpenVideoLightBox] = useState(false);

  const vettingTypeOptions = vettingBrand
    ?.filter(
      (dt: any) => dt.enabled && (dt.id === "ENHANCED" || dt.id === "STANDARD")
    )
    ?.map((item: any) => {
      return {
        label: item.displayName,
        value: item.id,
      };
    });

  const vettingPartnerOptions = vettingBrandOptions?.filter(
    (item: any) =>
      ventingForm.vettingType &&
      item.vettingClasses.some((x: string) => x === ventingForm.vettingType)
  );

  const vettingImportFields =
    vettingBrandOptions &&
    vettingImportForm.vettingType &&
    JSON.parse(
      vettingBrandOptions?.filter(
        (vett: { evpId: string }) =>
          vett.evpId === vettingImportForm?.vettingType
      )[0]?.vettingInstruction
    );

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      usecase: "",
      description: "",
    },
    onSubmit: (values) => {
      const temp = removeEmptyAndNullValues({ ...values });
      handleGetData(currPage, rowsPerPage, temp);
    },
  });

  useEffect(() => {
    dispatch(fatchAccountPricingThunk());
    dispatch(getUsecaseListThunk());
    dispatch(appealCategoryOptionsThunk());
    dispatch(applyVettingThunk());
    dispatch(applyVettingEvpThunk());
    dispatch(BrandSuspendedMnoThunk());
  }, []);

  useEffect(() => {
    if (props?.router?.params?.id) {
      dispatch(
        getActiveCampaignListThunk(1, 100, {
          brandId: props?.router?.params?.id,
          status: "ACTIVE",
        })
      );
      dispatch(getBrandDetailsThunk(props?.router?.params?.id)).then(() => {
        setIsInit(false);
      });
      dispatch(getBrandAppealHistoryThunk(props?.router?.params?.id));
      dispatch(getUploadedEvidenceThunk(props?.router?.params?.id));
      dispatch(getVattingListThunk(props?.router?.params?.id));
      dispatch(enhancedVettingReportThunk(props?.router?.params?.id));
      dispatch(getBrandFeedbackThunk(props?.router?.params?.id));
      handleGetData(currPage, rowsPerPage);
      handleGetData2(currPage2, rowsPerPage2);
    }
  }, [props?.router?.params?.id]);

  useEffect(() => {
    brandDetails?.subGroupId?.[0] &&
      dispatch(getSubgroupDetailsThunk(brandDetails?.subGroupId?.[0]));
  }, [brandDetails?.subGroupId]);

  useEffect(() => {
    if (!evidenceUploadError && !evidenceUploading) {
      dispatch(getUploadedEvidenceThunk(props?.router?.params?.id));
    }
  }, [evidenceUploading]);

  const handleSubmitAppeal = () => {
    const updatedPayload = {
      evpUid: "AEGIS",
      appealCategories: appealForm.appealCategories,
      explanation: appealForm.explanation,
      attachmentUuids: [],
    };
    dispatch(
      checkBrandAppealStatusThunk(props?.router?.params?.id, updatedPayload)
    );
  };

  const handleDetailsClick = (row: any) => {
    setModal4(row);
  };

  const handleFileChange = async (event: any) => {
    const files: any = Array.from(event?.target?.files);
    if (!files || files.length === 0) {
      return;
    }

    // Validate number of uploads
    if (files.length > 10) {
      console.error("Exceeded maximum number of uploads (max: 10)");
      return;
    }

    const totalSize = files.reduce(
      (acc: number, file: { size: number }) => acc + file.size,
      0
    );
    // Validate total size
    if (totalSize > 30 * 1024 * 1024) {
      // Convert MB to bytes
      console.error("Exceeded maximum total size (max: 30MB)");
      return;
    }

    const formData = new FormData();
    files?.map((file: any) => {
      formData.append("file", file);
    });

    event.target.value = null;
    dispatch(uploadEvidenceThunk(props?.router?.params?.id, formData));
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const openResubmitBrandModal = () => {
    dispatch(
      openModal({
        modalbody: `Are you sure? You will be charged $${
          walletPricingData["brand.create"] || "4.00"
        } every time you resubmit a Brand for identity verification.`,
        data: {
          title: "Resubmit Brand",
          footer: true,
          cancelBtn: true,
          buttonText: {
            submit: "Proceed",
          },
          onSubmit: ({ onClose }: any) => {
            dispatch(revertBrandThunk(props?.router?.params?.id)).then(() => {
              submitLoadingSubject.next(false);
              onClose();
            });
          },
        },
      })
    );
  };

  const handleExplanationChange = (event: any) => {
    setAppealForm({
      ...appealForm,
      explanation: event.target.value,
    });
  };

  const handleDeleteAttachment = (
    selectedProductUuid: string,
    onClose: any
  ) => {
    if (selectedProductUuid) {
      submitLoadingSubject.next(true);
      let sanitizedUid = selectedProductUuid.replaceAll("/", "%2F");
      sanitizedUid = encodeURI(sanitizedUid);
      dispatch(deleteBrandCampaignEvidenceThunk(sanitizedUid)).then(() => {
        submitLoadingSubject.next(false);
        onClose();
        dispatch(getUploadedEvidenceThunk(props?.router?.params?.id));
      });
    } else {
      console.error("Selected product UUID is not defined.");
    }
  };

  const handleDownloadAttachment = (file: any, isDownload: boolean) => {
    if (file?.uuid) {
      let sanitizedUid = file?.uuid.replaceAll("/", "%2F");
      sanitizedUid = encodeURI(sanitizedUid);

      const cb = (img: any) => {
        const temp2 = URL.createObjectURL(img);
        if (isDownload) {
          let link = document.createElement("a");
          link.href = temp2;
          link.download = file?.fileName || "img.png";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          setPrevSrc(temp2);
          if (img?.type.startsWith("video/")) {
            setIsOpenVideoLightBox(true);
          } else if (img?.type === "application/pdf") {
            setIsOpenDocLightBox(true);
          } else if (
            img?.type === "text/plain" ||
            img?.type === "text/html" ||
            img?.type === "application/json"
          ) {
            setIsOpenDocLightBox(true);
          } else {
            setIsOpenImgLightBox(true);
          }
        }
      };

      dispatch(
        getDownloadVattingAttachmentThunk(
          sanitizedUid,
          file?.fileName,
          file?.mimeType,
          cb
        )
      );
    } else {
      console.error("Selected product UUID is not defined.");
    }
  };

  const handleVettingFormSubmit = () => {
    const cb = () => {
      setModal2(false);
      dispatch(getVattingListThunk(props?.router?.params?.id));
    };

    const updatedPayload = {
      evpId: ventingForm.vettingPartner,
      vettingClass: ventingForm.vettingType,
    };
    dispatch(
      submitVettingDataThunk(props?.router?.params?.id, updatedPayload, cb)
    );
  };

  const handleImportVattingSubmit = () => {
    let updatedPayload = {};
    vettingImportForm &&
      Object.entries(vettingImportForm)?.map((field) => {
        if (field[0] === "vettingType") {
          updatedPayload = {
            ...updatedPayload,
            evpId: vettingImportForm.vettingType,
          };
        } else {
          updatedPayload = { ...updatedPayload, [field[0]]: field[1] };
        }
      });
    dispatch(
      importVattingSubmitThunk(props?.router?.params?.id, updatedPayload)
    );
  };

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      getCampaignListThunk(page, perPage, {
        ...otherParams,
        brandId: props?.router?.params?.id,
      })
    );
  };

  const handleRowClick = (row: {
    campaignId: string;
    campaignUuid: string;
    campaignType: string;
  }) => {
    row.campaignId.toLowerCase() === "pending" ||
    row.campaignId.toLowerCase() === "rejected"
      ? props.router.navigate(`/campaigns/${row.campaignUuid}`)
      : row.campaignType.toLowerCase() === "own"
      ? props.router.navigate(`/campaigns/${row.campaignId}`)
      : props.router.navigate(`/campaigns-partner/${row.campaignId}`);
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows);
  };

  const handleGetData2 = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      getCspWebhookEventsListThunk(page, perPage, {
        ...otherParams,
        brandId: props?.router?.params?.id,
      })
    );
  };

  const handlePageChange2 = (page: number) => {
    setCurrPage2(page);
    handleGetData2(page, rowsPerPage2);
  };

  const handleRowsPerPageChange2 = (rows: number) => {
    setCurrPage2(1);
    setRowsPerPage2(rows);
    handleGetData2(1, rows);
  };

  const handleAppeal = (row: any) => {
    dispatch(
      openModal({
        path: "VettingDetailsAppeal",
        data: {
          title: "Standard Vet Appeal Request",
          brandId: props?.router?.params?.id,
          row,
          evidence,
          MAX_WORDS,
          handleDownloadAttachment,
          footer: false,
        },
      })
    );
  };

  const handleViewAppealHistory = (row: any) => {
    // Implement view appeal history logic
    console.log("View appeal history clicked for:", row);
    setModal4(row);
  };

  const openAppealModal = () => {
    if (brandDetails?.identityStatus === "UNVERIFIED") {
      dispatch(
        openModal({
          modalbody: `<div class="text-center">
              <p class="mb-3">
                Once a Brand's Legal Company Name, Tax Number/ID/EIN, Tax
                Number/ID/EIN Issuing Country, or Entity Type have been changed
                you must Resubmit the Brand before Appealing. This is to ensure
                our verification provider has the most up to date Brand
                information.
              </p>
              <p class="mb-0">
                Once the Brand is resubmitted you will be able to Appeal if all
                other Appeal requirements are met. Appeal requirements can be
                found in the Knowledge Base Brand Registration tab.
              </p>
            </div>`,
          data: {
            title: "Identity Status Appeal",
            footer: true,
          },
        })
      );
    } else if (
      (brandDetails?.entityType === "PUBLIC_PROFIT" &&
        brandDetails?.identityStatus === "VERIFIED") ||
      (brandDetails?.entityType === "PUBLIC_PROFIT" &&
        brandDetails?.identityStatus === "VETTED_VERIFIED") ||
      (brandDetails?.entityType === "PRIVATE_PROFIT" &&
        brandDetails?.identityStatus === "VERIFIED") ||
      (brandDetails?.entityType === "PRIVATE_PROFIT" &&
        brandDetails?.identityStatus === "VETTED_VERIFIED") ||
      (brandDetails?.entityType === "GOVERNMENT" &&
        brandDetails?.identityStatus === "VETTED_VERIFIED") ||
      (brandDetails?.entityType === "NON_PROFIT" &&
        brandDetails?.identityStatus === "VETTED_VERIFIED")
    ) {
      dispatch(
        openModal({
          modalbody: `${brandDetails?.entityType} Entity Type cannot submit Identity Status Appeals with an Identity Status: ${brandDetails?.identityStatus}.`,
          data: {
            title: "Identity Status Appeal",
            footer: true,
          },
        })
      );
    } else {
      setModal1(!modal1);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {isOpenImgLightBox && (
          <Lightbox
            enableZoom={false}
            mainSrc={prevSrc}
            onCloseRequest={() => {
              setIsOpenImgLightBox(false);
              setPrevSrc("");
            }}
          />
        )}
        {(isOpenDocLightBox || isOpenVideoLightBox) && (
          <div
            className="position-fixed top-0 end-0 bottom-0 start-0"
            style={{
              zIndex: 1060,
              // backgroundColor: "rgba(0,0,0,0.5)",
              backgroundColor: "white",
            }}
          >
            <div
              className="position-absolute text-white cursor-pointer"
              style={{
                zIndex: 1000,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                borderRadius: "50%",
                padding: "5px 10px",
                right: 10,
                top: 40,
              }}
            >
              <i
                className="ri-close-circle-fill cursor-pointer fs-5xl text-white"
                onClick={() => {
                  setIsOpenDocLightBox(false);
                  setIsOpenVideoLightBox(false);
                  setPrevSrc("");
                }}
              />
            </div>
            {isOpenVideoLightBox ? (
              <video
                // playbackRate={2}
                autoPlay={true}
                muted={true}
                loop={true}
                style={{ background: "rgba(0, 0, 0, 0.6)" }}
                height="100%"
                width="100%"
              >
                <source src={prevSrc} type={`video/mp4`} />
              </video>
            ) : (
              <iframe
                id="iframe"
                title={prevSrc}
                src={prevSrc}
                height="100%"
                width="100%"
                style={{ background: "rgba(0, 0, 0, 0.6)" }}
              />
            )}
          </div>
        )}
        <Container fluid>
          <BreadCrumb
            title={`${brandDetails?.displayName || ""} (${
              props?.router?.params?.id
            })`}
            isBack={true}
            backClick={() =>
              props.router.navigate(
                subGroupId
                  ? `/groups/${groupId}/subgroups/${subGroupId}/${subgroupnames}`
                  : -1
              )
            }
          />
          <div className="position-relative wrapper">
            {isInit && loading ? (
              <div className={``}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <>
                <Row className="mx-0">
                  <Col xl={7} className="ps-xl-0">
                    <Card>
                      <Card.Header className="d-flex align-items-center justify-content-end">
                        <Button
                          variant="secondary"
                          onClick={() => {
                            dispatch(
                              openModal({
                                path: "EditNameAndSubgroup",
                                data: {
                                  title:
                                    "Edit Legal Company Name and Sub Group ID",
                                  brandDetails,
                                  activeCampaignsData,
                                  campaigns,
                                  ownNumbers,
                                  footer: false,
                                },
                              })
                            );
                          }}
                        >
                          Edit
                        </Button>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Legal Company Name:</h6>
                            <p className="mb-0">
                              {brandDetails?.companyName || "-"}
                            </p>
                          </Col>
                          <Col md={6} />
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Brand ID:</h6>
                            <p className="mb-0">
                              {brandDetails?.brandId} -{" "}
                              {brandDetails?.displayName}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Universal EIN:</h6>
                            <p className="mb-0">
                              {brandDetails?.universalEin || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3 mb-md-0">
                            <h6 className="mb-1">Group ID:</h6>
                            <p className="mb-0">
                              {brandDetails?.groupId || "-"}
                            </p>
                          </Col>
                          <Col md={6}>
                            <h6 className="mb-1">Sub Group ID:</h6>
                            {Array.isArray(brandDetails?.subGroupId) ? (
                              brandDetails?.subGroupId?.length > 0 ? (
                                brandDetails?.subGroupId.map(
                                  (dt: string, i: number) => (
                                    <p className="mb-0" key={i}>
                                      {dt} -{" "}
                                      {subgroupDetails?.data?.subGroupData?.find(
                                        (dt2: any) => dt2?.sub_group_id === dt
                                      )?.sub_group_name || ""}
                                    </p>
                                  )
                                )
                              ) : (
                                <p className="mb-0">-</p>
                              )
                            ) : (
                              <p className="mb-0">
                                {brandDetails?.subGroupId || "-"}
                              </p>
                            )}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col xl={5} className="pe-xl-0">
                    <Card>
                      <Card.Header>
                        <h6 className="card-title mb-2">Identity Status</h6>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Identity Status:</h6>
                            <div className="d-flex align-items-center">
                              <p className="mb-0">
                                {showStatus(brandDetails?.identityStatus)}
                              </p>
                              {props?.router?.params?.id ===
                                brandFeedback?.brandId &&
                              brandFeedback?.category?.length > 0 ? (
                                <HoverPopover>
                                  <ul className="vstack gap-2 list-unstyled mb-0">
                                    {brandFeedback?.category?.map(
                                      (dt: any, i: number) =>
                                        dt?.errors?.length > 0 ? (
                                          dt?.errors?.map(
                                            (dt2: any, i2: number) => (
                                              <li key={i2}>
                                                {dt?.id} - {dt2?.message || "-"}
                                              </li>
                                            )
                                          )
                                        ) : (
                                          <li key={i}>
                                            {dt?.id} - {dt?.description || "-"}
                                          </li>
                                        )
                                    )}
                                  </ul>
                                </HoverPopover>
                              ) : null}
                            </div>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Tax Exempt Status:</h6>
                            <p className="mb-0">
                              {brandDetails?.taxExemptStatus
                                ? brandDetails?.taxExemptStatus
                                : "N/A"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3 mb-md-0">
                            <h6 className="mb-1">Russell3000:</h6>
                            <p className="mb-0">
                              {brandDetails?.russell ? "True" : "False"}
                            </p>
                          </Col>
                          <Col md={6}>
                            <h6 className="mb-1">Government Entity</h6>
                            <p className="mb-0">
                              {brandDetails?.optionalAttributes
                                ?.governmentEntity
                                ? "True"
                                : "False"}
                            </p>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row className="mx-0">
                  <Col xl={7} className="ps-xl-0">
                    <Card>
                      <Card.Header className="d-flex align-items-center justify-content-between">
                        <h6 className="card-title mb-2">Company Details</h6>
                        <Button
                          variant="secondary"
                          className="ms-2"
                          onClick={() => {
                            dispatch(
                              openModal({
                                path: "EditCompanyDetails",
                                data: {
                                  modalClass: "summary_modal",
                                  title: "Company Details",
                                  brandDetails,
                                  activeCampaignsData,
                                  footer: false,
                                },
                              })
                            );
                          }}
                        >
                          Edit
                        </Button>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Brand Name or DBA:</h6>
                            <p className="mb-0">
                              {brandDetails?.displayName || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1 d-flex align-items-center">
                              <i className="bi bi-geo-alt me-2 fs-lg text-secondary" />
                              Address/Street:
                            </h6>
                            <p className="mb-0">
                              {brandDetails?.street || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Entity Type:</h6>
                            <span
                              data-tag="allowRowEvents"
                              className="badge bg-body-secondary border border-secondary text-secondary me-md-4"
                            >
                              {humanizeString(brandDetails?.entityType || "-")}
                            </span>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1 d-flex align-items-center">
                              <i className="bi bi-geo-alt me-2 fs-lg text-secondary" />
                              City:
                            </h6>
                            <p className="mb-0">{brandDetails?.city || "-"}</p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">EIN:</h6>
                            <p className="mb-0">{brandDetails?.ein || "-"}</p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1 d-flex align-items-center">
                              <i className="bi bi-geo-alt me-2 fs-lg text-secondary" />
                              State:
                            </h6>
                            <p className="mb-0">{brandDetails?.state || "-"}</p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1 d-flex align-items-center">
                              <i className="bi bi-geo-alt me-2 fs-lg text-secondary" />
                              EIN Issuing Country:
                            </h6>
                            <p className="mb-0">
                              {brandDetails?.einIssuingCountry || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1 d-flex align-items-center">
                              <i className="bi bi-geo-alt me-2 fs-lg text-secondary" />
                              Postal Code:
                            </h6>
                            <p className="mb-0">
                              {brandDetails?.postalCode || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">LEI Number:</h6>
                            <p className="mb-0">
                              {brandDetails?.altBusinessIdType === "LEI"
                                ? brandDetails?.altBusinessId
                                : "N/A"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1 d-flex align-items-center">
                              <i className="bi bi-geo-alt me-2 fs-lg text-secondary" />
                              Country:
                            </h6>
                            <p className="mb-0">
                              {brandDetails?.country || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Vertical:</h6>
                            <p className="mb-0">
                              {brandDetails?.vertical || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1 d-flex align-items-center">
                              <i className="bi bi-globe me-2 fs-lg text-secondary" />
                              Website/Online Presence:
                            </h6>
                            <p className="mb-0">
                              {brandDetails?.website || "N/A"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1 d-flex align-items-center">
                              <i className="ri ri-stock-line me-2 fs-xl text-secondary" />
                              Stock Symbol:
                            </h6>
                            <p className="mb-0">
                              {brandDetails?.stockSymbol
                                ? brandDetails?.stockSymbol
                                : "N/A"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1 d-flex align-items-center">
                              <i className="ri ri-exchange-funds-line me-2 fs-xl text-secondary" />
                              Stock Exchange:
                            </h6>
                            <p className="mb-0">
                              {brandDetails?.stockExchange
                                ? brandDetails?.stockExchange
                                : "N/A"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1 d-flex align-items-center">
                              <i className="bi bi-phone me-2 fs-lg text-secondary" />
                              Mobile Phone:
                            </h6>
                            <p className="mb-0">
                              {brandDetails?.mobilePhone
                                ? brandDetails?.mobilePhone
                                : "N/A"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1 d-flex align-items-center">
                              <i className="ph ph-user-bold me-2 fs-lg text-secondary" />
                              First Name:
                            </h6>
                            <p className="mb-0">
                              {brandDetails?.firstName
                                ? brandDetails?.firstName
                                : "N/A"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1 d-flex align-items-center">
                              <i className="bi bi-calendar3 me-2 fs-lg text-secondary" />
                              OTP Confirmation Date:
                            </h6>
                            <p className="mb-0">
                              {brandDetails?.otp ? brandDetails?.otp : "N/A"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1 d-flex align-items-center">
                              <i className="ph ph-user-bold me-2 fs-lg text-secondary" />
                              Last Name:
                            </h6>
                            <p className="mb-0">
                              {brandDetails?.lastName
                                ? brandDetails?.lastName
                                : "N/A"}
                            </p>
                          </Col>
                          <Col md={6}>
                            <h6 className="mb-1 d-flex align-items-center">
                              <i className="bi bi-calendar3 me-2 fs-lg text-secondary" />
                              Registered On:
                            </h6>
                            <p className="mb-0">
                              {dateFormat(
                                brandDetails?.createDate,
                                "MM/DD/YYYY, hh:mm:ss A"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col xl={5} className="pe-xl-0">
                    <Card>
                      <Card.Header>
                        <h6 className="card-title mb-2">Brand Suspended</h6>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-0">
                              AT&T:&nbsp;&nbsp;{renderYesNo(false)}
                            </h6>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-0">
                              T-Mobile:&nbsp;&nbsp;{renderYesNo(false)}
                            </h6>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-0">
                              Verizon Wireless:&nbsp;&nbsp;{renderYesNo(false)}
                            </h6>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-0">
                              US Cellular:&nbsp;&nbsp;{renderYesNo(false)}
                            </h6>
                          </Col>
                          <Col md={6} className="mb-3 mb-md-0">
                            <h6 className="mb-0">
                              ClearSky:&nbsp;&nbsp;{renderYesNo(false)}
                            </h6>
                          </Col>
                          <Col md={6}>
                            <h6 className="mb-0">
                              Interop:&nbsp;&nbsp;{renderYesNo(false)}
                            </h6>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>

                    <Card>
                      <Card.Header className="d-flex align-items-center justify-content-between">
                        <h6 className="card-title mb-2">Contact Details</h6>
                        <Button
                          variant="secondary"
                          className="ms-2"
                          onClick={() => {
                            dispatch(
                              openModal({
                                path: "EditContactDetails",
                                data: {
                                  title: "Contact Details",
                                  brandDetails,
                                  activeCampaignsData,
                                  footer: false,
                                },
                              })
                            );
                          }}
                        >
                          Edit
                        </Button>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col md={6} className="mb-xl-0 mb-3">
                            <h6 className="mb-1 d-flex align-items-center">
                              <i className="bi bi-envelope me-2 fs-xl text-secondary" />
                              Support Email Address:
                            </h6>
                            <p className="mb-0">{brandDetails?.email || "-"}</p>
                          </Col>
                          <Col md={6}>
                            <h6 className="mb-1 d-flex align-items-center">
                              <i className="bi bi-phone me-2 fs-lg text-secondary" />
                              Support Phone Number:
                            </h6>
                            <p className="mb-0">{brandDetails?.phone || "-"}</p>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>

                    <Card>
                      <Card.Header>
                        <h6 className="card-title mb-2">Brand Relationship</h6>
                      </Card.Header>
                      <Card.Body>
                        <h6 className="mb-1 d-flex align-items-center">
                          <i className="ph ph-handshake me-2 fs-3xl text-secondary" />
                          Brand Relationship:
                        </h6>
                        <p className="mb-0">
                          {brandDetails?.brandRelationship || "-"}
                        </p>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Card>
                  <Card.Header className="d-flex align-items-center justify-content-between">
                    <h6 className="card-title mb-2">Appeal History</h6>
                    <div className="d-flex">
                      <Button
                        variant="primary"
                        className="ms-2"
                        onClick={openAppealModal}
                      >
                        Identity Status Appeal
                      </Button>
                      <Button
                        variant="secondary"
                        className="ms-2"
                        onClick={openResubmitBrandModal}
                      >
                        Resubmit Brand
                      </Button>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Datatable
                      data={appeals}
                      columns={columns1}
                      pagination
                      paginationPerPage={10}
                    />
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Header className="d-flex align-items-center justify-content-between">
                    <h6 className="card-title mb-2">Vetting Details</h6>
                    <div className="d-flex">
                      <Button
                        variant="primary"
                        className="ms-2"
                        onClick={() => setModal2(true)}
                      >
                        Apply for Vetting
                      </Button>
                      <Button
                        variant="secondary"
                        className="ms-2"
                        onClick={() => setModal3(true)}
                      >
                        Import Vetting
                      </Button>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Datatable data={vettingBrandList} columns={columns2} />
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Header className="d-flex align-items-center justify-content-between">
                    <h6 className="card-title mb-2">
                      {campaignsData?.totalRecords || 0} Registered Campaigns
                    </h6>
                    <div className="d-flex">
                      <Link
                        to={`/campaigns/create?brandId=${props?.router?.params?.id}`}
                      >
                        <Button
                          variant="primary"
                          className="d-flex align-items-center ms-2"
                        >
                          <i className="bx bx-plus me-1"></i> Add new campaign
                        </Button>
                      </Link>
                      <button
                        className="btn btn-secondary ms-2"
                        onClick={toggleFilters}
                      >
                        <i className="bi bi-funnel-fill bi-3xl"> </i>
                        Add Filters
                      </button>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Collapse in={showFilters}>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                        }}
                      >
                        <Row>
                          <Col sm={6} lg={4} xl={3}>
                            <div className="mb-3">
                              <Form.Label htmlFor="usecase">Usecase</Form.Label>
                              <Select
                                name="usecase"
                                styles={styleObj(
                                  validation?.touched?.usecase &&
                                    validation?.errors?.usecase
                                )}
                                theme={customSelectTheme}
                                options={
                                  Object.keys(usecaseObj)?.length > 0
                                    ? Object.keys(usecaseObj)?.map((dt) => ({
                                        label:
                                          usecaseObj?.[dt]?.displayName || dt,
                                        value: dt,
                                      }))
                                    : []
                                }
                                onChange={(e: { value: string }) => {
                                  validation.setFieldValue("usecase", e.value);
                                }}
                                value={{
                                  label:
                                    usecaseObj?.[validation.values.usecase]
                                      ?.displayName,
                                  value: validation.values.usecase,
                                }}
                              />
                            </div>
                          </Col>
                          <Col sm={6} lg={4} xl={3}>
                            <div className="mb-3">
                              <Form.Label htmlFor="description">
                                Campaign Description
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="description"
                                onChange={(e) => {
                                  validation.setFieldValue(
                                    "description",
                                    e.target.value
                                  );
                                }}
                                value={validation.values?.description}
                              />
                            </div>
                          </Col>
                          <Col className="d-flex align-items-end mb-3">
                            <div>
                              <Button
                                className="btn btn-secondary me-2"
                                type="button"
                                disabled={loading2}
                                onClick={() => {
                                  validation.resetForm();
                                  handleGetData(currPage, rowsPerPage);
                                }}
                              >
                                Clear Filters
                              </Button>
                              <Button
                                className="btn btn-primary"
                                type="submit"
                                disabled={loading2}
                              >
                                {loading2 && (
                                  <Spinner size="sm" animation="border" />
                                )}{" "}
                                Apply Filters
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Collapse>
                    <Datatable
                      data={
                        Array.isArray(campaignsData?.records)
                          ? campaignsData?.records
                          : []
                      }
                      columns={columns3}
                      handleRowClick={handleRowClick}
                      handlePageChange={handlePageChange}
                      handleRowsPerPageChange={handleRowsPerPageChange}
                      currPage={currPage}
                      rowsPerPage={rowsPerPage}
                      totalRecords={campaignsData?.totalRecords}
                    />
                  </Card.Body>
                </Card>

                <Card className="mb-0">
                  <Card.Header>
                    <h6 className="card-title mb-2">Events</h6>
                  </Card.Header>
                  <Card.Body className="position-relative">
                    {loading3 ? (
                      <div style={{ height: 60 }}>
                        <img
                          src={Loader}
                          className={`position-absolute top-50 start-50 translate-middle`}
                          alt="loading"
                        />
                      </div>
                    ) : (
                      <Datatable
                        data={
                          Array.isArray(cspWebhookEventsData?.records)
                            ? cspWebhookEventsData?.records
                            : []
                        }
                        columns={columns4}
                        handlePageChange={handlePageChange2}
                        handleRowsPerPageChange={handleRowsPerPageChange2}
                        currPage={currPage2}
                        rowsPerPage={rowsPerPage2}
                        totalRecords={cspWebhookEventsData?.totalRecords}
                      />
                    )}
                  </Card.Body>
                </Card>

                <Modal
                  centered
                  show={modal4.brandId || modal4.vettingId}
                  onHide={() => setModal4({})}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Identity Status Appeal Request</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col xl={12} className="mb-2">
                        <Row className="g-0">
                          <Col md={6}>
                            <h6 className="mb-0">Vetting Status:</h6>
                          </Col>
                          <Col md={6}>
                            <p className="mb-0"> {modal4.appealStatus}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12} className="mb-2">
                        <Row className="g-0">
                          <Col md={6}>
                            <h6 className="mb-0">Request Date: </h6>
                          </Col>
                          <Col md={6}>
                            <p className="mb-0">
                              {dateFormat(
                                modal4.createDate,
                                "MM/DD/YYYY, hh:mm:ss A"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12} className="mb-2">
                        <Row className="g-0">
                          <Col md={6}>
                            <h6 className="mb-0">Status Update Date: </h6>
                          </Col>
                          <Col md={6}>
                            <p className="mb-0">
                              {dateFormat(modal4.appealStatusUpdateDate)}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12} className="mb-2">
                        <Row className="g-0">
                          <Col md={6}>
                            <h6 className="mb-0">
                              Identity Status Appeal Outcome:
                            </h6>
                          </Col>
                          <Col md={6}>
                            <p className="mb-0">
                              {modal4.vettingScore || "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12} className="mb-2">
                        <Row className="g-0">
                          <Col md={6}>
                            <h6 className="mb-0">Tax Exempt Status:</h6>
                          </Col>
                          <Col md={6}>
                            <p className="mb-0">N/A</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12} className="mb-2">
                        <Row className="g-0">
                          <Col md={6}>
                            <h6 className="mb-0">Government Entity:</h6>
                          </Col>
                          <Col md={6}>
                            <p className="mb-0">N/A</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className="mt-3">
                      <h5>Appeal Categories</h5>
                      <h6>
                        {modal4.vettingScore
                          ? "Low score"
                          : "Verify Government status"}
                      </h6>
                      <p>
                        {modal4.vettingScore
                          ? `Select this category if the vetting score is too low based on the Company's characteristics, including size, years in business, business reputation, financial history, civil and criminal legal history, regulatory history, official registration and standing, and business messaging integrity record. This category applies only for Standard external vets.`
                          : `Select this category if the record submitted as a Government entity type is UNVERIFIED or VERIFIED and is missing a "Government Entity" status.`}
                      </p>
                    </div>
                    <div>
                      <h5>Explanation</h5>
                      <p>{modal4.explanation}</p>
                    </div>
                    <h5>Sample Multimedia Files</h5>
                    <ul className="vstack gap-2 list-unstyled my-3">
                      {Array.isArray(evidence) &&
                        evidence?.map(
                          (
                            dt: {
                              fileName: string;
                              mimeType: string;
                            },
                            i: number
                          ) => (
                            <li key={i}>
                              <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                                <div className="d-flex align-items-center gap-2">
                                  <div className="avatar-xs flex-shrink-0 ronded">
                                    <div className="avatar-title bg-body-secondary text-body">
                                      {dt?.mimeType.startsWith("image/") ? (
                                        <i className="ri ri-image-line fs-lg"></i>
                                      ) : dt?.mimeType.startsWith("video/") ? (
                                        <i className="ri ri-video-line fs-lg"></i>
                                      ) : (
                                        <i className="ri ri-file-line fs-lg"></i>
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6
                                      className="mb-0"
                                      style={{ wordBreak: "break-word" }}
                                    >
                                      {dt?.fileName || ""}
                                    </h6>
                                  </div>
                                  <div className="flex-shrink-0 text-muted">
                                    <i
                                      className="ri-download-2-fill cursor-pointer fs-2xl text-secondary ms-3"
                                      onClick={() =>
                                        !loading &&
                                        handleDownloadAttachment(dt, true)
                                      }
                                    />
                                    <i
                                      className="ri-eye-fill cursor-pointer fs-2xl text-secondary ms-3"
                                      onClick={() =>
                                        !loading &&
                                        handleDownloadAttachment(dt, false)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </li>
                          )
                        )}
                    </ul>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-outline-primary"
                      onClick={() => setModal4(false)}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* Modal for Identity Status Appeal  */}
                <Modal show={modal1} onHide={() => setModal1(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <h4>Identity Status Appeal</h4>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      You can submit an Appeal up to 45 days after the
                      submission of a Brand. Appeals are charged $10 regardless
                      of the outcome. Do not use this to appeal a Standard Vet.
                    </p>
                    <h4>Appeal Categories</h4>
                    <p>
                      You are required to select at least one category, but may
                      select more
                    </p>
                    {Array.isArray(brandsData) &&
                      brandsData?.map(
                        (
                          category: {
                            id: string;
                            displayName?: string;
                            description?: string;
                          },
                          i: number
                        ) => (
                          <div key={i}>
                            <Form.Check
                              type="checkbox"
                              id={category?.id}
                              key={category?.id}
                              checked={
                                appealForm?.appealCategories?.indexOf(
                                  category?.id
                                ) !== -1
                                  ? true
                                  : false
                              }
                              label={<strong>{category?.displayName}</strong>}
                              onChange={(e) => {
                                let newValues: any = [];

                                if (
                                  appealForm?.appealCategories?.indexOf(
                                    category?.id
                                  ) !== -1
                                ) {
                                  newValues =
                                    appealForm?.appealCategories?.filter(
                                      (cat: string) => {
                                        return cat !== category.id;
                                      }
                                    );
                                } else {
                                  newValues = [
                                    ...appealForm?.appealCategories,
                                    category?.id,
                                  ];
                                }

                                setAppealForm({
                                  ...appealForm,
                                  appealCategories: [...newValues],
                                });
                              }}
                            />
                            <p>{category?.description}</p>
                          </div>
                        )
                      )}
                    <Form.Group>
                      <Form.Label>
                        <h5>
                          <strong>Explanation</strong>
                        </h5>
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <FormControl
                          as="textarea"
                          value={appealForm?.explanation}
                          onChange={handleExplanationChange}
                          maxLength={MAX_WORDS}
                        />
                        <InputGroup.Text>{MAX_WORDS} words</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      className="border p-3 text-center"
                      style={{ background: "none" }}
                    >
                      <Form.Label>
                        <h5>
                          <strong>Attach Files</strong>
                        </h5>
                      </Form.Label>
                      <p>
                        Max 10 uploads
                        <br />
                        Max file size: 10MB
                        <br />
                        Max total size: 30MB
                      </p>
                      <i className="bi bi-upload fs-3"></i>
                      <Form.Control
                        type="file"
                        id="evidence-file-upload"
                        multiple
                        onChange={handleFileChange}
                      />
                      <Tooltip
                        placement="bottom"
                        overlay={
                          <span>
                            Approved file types: bmp, docx, htm, jpeg, jpg, odt,
                            pdf, png, raw, rtf, tiff, txt, xml
                          </span>
                        }
                      >
                        <p className="mt-2">
                          <em>Approved file types</em>
                        </p>
                      </Tooltip>
                      <div>
                        <ul className="vstack gap-2 list-unstyled my-3">
                          {Array.isArray(evidence) &&
                            evidence?.map(
                              (
                                dt: {
                                  fileName: string;
                                  mimeType: string;
                                },
                                i: number
                              ) => (
                                <li key={i}>
                                  <div
                                    className="d-block p-2 bg-light bg-opacity-50 rounded"
                                    style={{ textAlign: "start" }}
                                  >
                                    <div className="d-flex align-items-center gap-2">
                                      <div className="avatar-xs flex-shrink-0 ronded">
                                        <div className="avatar-title bg-body-secondary text-body">
                                          {dt?.mimeType.startsWith("image/") ? (
                                            <i className="ri ri-image-line fs-lg"></i>
                                          ) : dt?.mimeType.startsWith(
                                              "video/"
                                            ) ? (
                                            <i className="ri ri-video-line fs-lg"></i>
                                          ) : (
                                            <i className="ri ri-file-line fs-lg"></i>
                                          )}
                                        </div>
                                      </div>
                                      <div className="flex-grow-1">
                                        <h6
                                          className="mb-0"
                                          style={{ wordBreak: "break-word" }}
                                        >
                                          {dt?.fileName || ""}
                                        </h6>
                                      </div>
                                      <div className="flex-shrink-0 text-muted">
                                        <i
                                          className="ri-download-2-fill cursor-pointer fs-2xl text-secondary ms-3"
                                          onClick={() =>
                                            !loading &&
                                            handleDownloadAttachment(dt, true)
                                          }
                                        />
                                        <i
                                          className="ri-eye-fill cursor-pointer fs-2xl text-secondary ms-3"
                                          onClick={() =>
                                            !loading &&
                                            handleDownloadAttachment(dt, false)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )
                            )}
                        </ul>
                      </div>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-outline-primary"
                      onClick={() => setModal1(false)}
                    >
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmitAppeal}>
                      Submit Appeal
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* Modal for Identity Status Appeal End...... */}

                {/* Modal for Vetting Details...... */}
                <Modal show={modal2} onHide={() => setModal2(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Apply for Vetting</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      Select the Vetting Type from the drop-down menu. Once you
                      have selected the type, select the vetting partner and
                      click "Submit". By submitting the Brand for external
                      vetting you accept the Terms & Conditions.
                    </p>

                    <Col lg={12} className="mb-3">
                      <Form.Label htmlFor="country">
                        Select Vetting Type
                      </Form.Label>
                      <Select
                        styles={styleObj(false)}
                        theme={customSelectTheme}
                        options={vettingTypeOptions}
                        isSearchable
                        onChange={(value: { value: string }) => {
                          setVettingForm({
                            vettingPartner: "",
                            vettingType: value.value,
                          });
                        }}
                      />
                    </Col>
                    <Col lg={12} className="mb-3">
                      <Form.Label htmlFor="country">
                        Select Vetting Partner
                      </Form.Label>
                      <Select
                        styles={styleObj(false)}
                        theme={customSelectTheme}
                        options={vettingPartnerOptions?.map(
                          (option: { displayName: string; evpId: string }) => ({
                            label: option.displayName,
                            value: option.evpId,
                          })
                        )}
                        isSearchable
                        onChange={(value: { value: string }) => {
                          setVettingForm((pre) => {
                            return { ...pre, vettingPartner: value.value };
                          });
                        }}
                      />
                    </Col>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-outline-primary"
                      onClick={() => setModal2(false)}
                    >
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleVettingFormSubmit}>
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* Modal for Vetting Details End...... */}

                {/* Modal For import Vetting...... */}
                <Modal
                  show={modal3}
                  onHide={() => {
                    setModal3(false);
                    setVettingImportForm({ vettingType: "" });
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Import Vetting</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Col lg={12} className="mb-3">
                      <Form.Label htmlFor="vettingType">
                        Select Vetting Type
                      </Form.Label>
                      <Select
                        name="vettingType"
                        styles={styleObj(false)}
                        theme={customSelectTheme}
                        onChange={(selectedOption: { value: string }) =>
                          setVettingImportForm({
                            vettingType: selectedOption.value,
                          })
                        }
                        options={vettingBrandOptions?.map(
                          (option: { displayName: string; evpId: string }) => ({
                            label: option.displayName,
                            value: option.evpId,
                          })
                        )}
                      />
                    </Col>
                    {vettingImportFields?.importVet?.parameters &&
                      Object.entries(
                        vettingImportFields.importVet.parameters
                      )?.map((keys: any, index: number) => (
                        // keys[1].show &&
                        <Col key={index} lg={12} className="mb-3">
                          <Form.Label htmlFor={keys[1]}>
                            {keys[1].label}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name={keys[0]}
                            id={keys[0]}
                            onChange={(e) => {
                              setVettingImportForm((pre) => {
                                return {
                                  ...pre,
                                  [keys[0]]: e.target.value,
                                };
                              });
                            }}
                            value={vettingImportFields[keys[0]]}
                          />
                        </Col>
                      ))}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-outline-primary"
                      onClick={() => {
                        setModal3(false);
                        setVettingImportForm({ vettingType: "" });
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      // onClick={handleSubmitImportVatting}
                      onClick={handleImportVattingSubmit}
                    >
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* Modal For import Vetting End...... */}
              </>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(BrandDetails);
