import { marketPlaceListApi } from "helpers/apiroute_helper";
import {
  apiLoading,
  marketPlacesDataSuccessful,
  marketPlacesDataFailed,
} from "./reducer";
import { toast } from "react-toastify";

export const getMarketPlaceListThunk =
  (otherParams?: any) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await marketPlaceListApi(otherParams);

      if (data?.code || data?.message) {
        dispatch(marketPlacesDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(marketPlacesDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(marketPlacesDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };
