import {
  analyticsSmsV1Api,
  analyticsMmsV1Api,
  analyticsOutboundApi,
  analyticsInboundApi,
  smsStatusLogsApi,
  mmsStatusLogsApi,
  statusLogsApi,
} from "helpers/apiroute_helper";
import {
  apiLoading,
  apiLoading2,
  analyticsSmsV1Successful,
  analyticsSmsV1Failed,
  analyticsMmsV1Successful,
  analyticsMmsV1Failed,
  analyticsOutboundSuccessful,
  analyticsOutboundFailed,
  analyticsInboundSuccessful,
  analyticsInboundFailed,
  smsStatusLogsSuccessful,
  smsStatusLogsFailed,
  mmsStatusLogsSuccessful,
  mmsStatusLogsFailed,
  statusLogsSuccessful,
  statusLogsFailed,
} from "./reducer";
import { toast } from "react-toastify";

export const getAnalyticsSmsV1Thunk =
  (params?: any, cb?: any) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await analyticsSmsV1Api(params);

      if (data?.code || data?.errorCode) {
        dispatch(analyticsSmsV1Failed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(analyticsSmsV1Successful(data));
        cb && cb();
      }
    } catch (error: any) {
      dispatch(analyticsSmsV1Failed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getAnalyticsMmsV1Thunk =
  (params?: any) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await analyticsMmsV1Api(params);

      if (data?.code || data?.errorCode) {
        dispatch(analyticsMmsV1Failed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(analyticsMmsV1Successful(data));
      }
    } catch (error: any) {
      dispatch(analyticsMmsV1Failed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getAnalyticsOutboundThunk =
  (params?: any, cb?: any,cancelToken?:any) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await analyticsOutboundApi(params,cancelToken);

      if (data?.code || data?.errorCode) {
        dispatch(analyticsOutboundFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(analyticsOutboundSuccessful(data));
        cb && cb();
      }
    } catch (error: any) {
      dispatch(analyticsOutboundFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getAnalyticsInboundThunk =
  (params?: any,cancelToken?:any) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await analyticsInboundApi(params,cancelToken);

      if (data?.code || data?.errorCode) {
        dispatch(analyticsInboundFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(analyticsInboundSuccessful(data));
      }
    } catch (error: any) {
      dispatch(analyticsInboundFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getSmsStatusLogsThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading2());

    try {
      const data: any = await smsStatusLogsApi(
        page,
        recordsPerPage,
        otherParams
      );

      if (data?.code || data?.errorCode) {
        dispatch(smsStatusLogsFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(smsStatusLogsSuccessful(data));
      }
    } catch (error: any) {
      dispatch(smsStatusLogsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getMmsStatusLogsThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading2());

    try {
      const data: any = await mmsStatusLogsApi(
        page,
        recordsPerPage,
        otherParams
      );

      if (data?.code || data?.errorCode) {
        dispatch(mmsStatusLogsFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(mmsStatusLogsSuccessful(data));
      }
    } catch (error: any) {
      dispatch(mmsStatusLogsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getStatusLogsThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any,cancelToken?:any) =>
  async (dispatch: any) => {
    dispatch(apiLoading2());

    try {
      const data: any = await statusLogsApi(page, recordsPerPage, otherParams,cancelToken);
      if(!data){
        return;
      }
      if (data?.code || data?.errorCode) {
        dispatch(statusLogsFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(statusLogsSuccessful(data));
      }
    } catch (error: any) {
      dispatch(statusLogsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };
