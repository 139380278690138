import BreadCrumb from "Common/BreadCrumb";
import Datatable from "Common/Datatable";
import withRouter from "Common/withRouter";
import { useFormik } from "formik";
import {
  customSelectTheme,
  removeEmptyAndNullValues,
  styleObj,
  US_STATES,
} from "helpers/common";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { createSelector } from "reselect";
import {
  fetchNumbersListThunk,
  openModal,
  purchaseNumberThunk,
  updateUserStepsThunk,
  resetAreaCodeThunk,
} from "slices/thunk";
import { City, Country, State } from "country-state-city";
import { submitLoadingSubject } from "Common/modals/Modal";
import Loader from "assets/images/spinner-dark.svg";
import Tooltip from "rc-tooltip";
import { useSearchParams } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import * as Yup from "yup";
import { areaCodeApi } from "helpers/apiroute_helper";

const capabilitiesOptions = [
  { label: "All", value: "all" },
  { label: "SMS", value: "sms" },
  { label: "MMS", value: "mms" },
];

const tfpOpt = ["800", "822", "833", "844", "855", "866", "877", "888"];

const initValues = {
  country: "US",
  state: "",
  city: null,
  areaCode: "",
  capabilities: [],
  contains: "",
};

type OptionType = {
  value: string | number;
  label: string;
  stateCode: string | number;
};

const BuyNumbers: React.FC = (props: any) => {
  document.title = "Signal House Portal Buy Numbers";

  const dispatch: any = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const currPage = Number(searchParams.get("currPage") || 1);
  const rowsPerPage = Number(searchParams.get("rowsPerPage") || 10);
  const groupId = searchParams.get("groupId");
  const subGroupId = searchParams.get("subGroupId");
  const subgroupnames = searchParams.get("subgroupnames");

  const [open, setOpen] = useState(false);
  const [allCountry, setAllCountry] = useState<any>([]);
  const [allState, setAllState] = useState<any>([]);
  const [allCity, setAllCity] = useState<any>(City.getCitiesOfCountry("US"));
  const [isChecked, setIsChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);
  const [loading2, setLoading2] = useState(false);
  const [tempAreaCode, setTempAreaCode] = useState({});
  const [tempACArr, setTempACArr] = useState("");
  const [inputValue, setInputValue] = useState("");

  const numberData = createSelector(
    (state: any) => state.Numbers,
    (state: any) => state.AuthUser,
    (numbers, authUser) => ({
      loading: numbers.loading,
      dataObj: numbers.dataObj,
      error: numbers.error,
      stepsData: authUser.stepsData,
      user: authUser.authUser,
    })
  );

  const { loading, dataObj, stepsData, user } = useSelector(numberData);

  const columns = [
    {
      name: (
        <Form.Check
          type="checkbox"
          checked={
            dataObj?.records?.length > 0 &&
            selectedRows.length === dataObj?.records?.length
          }
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRows(
                dataObj?.records?.map((row: any) => row.phoneNumber) || []
              );
            } else {
              setSelectedRows([]);
            }
          }}
        />
      ),
      width: "50px",
      cell: (row: any) => (
        <Form.Check
          type="checkbox"
          checked={selectedRows.includes(row.phoneNumber)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRows([...selectedRows, row.phoneNumber]);
            } else {
              setSelectedRows(
                selectedRows.filter((id) => id !== row.phoneNumber)
              );
            }
          }}
        />
      ),
    },
    {
      name: <span className="font-weight-bold fs-sm">Number</span>,
      minWidth: "130px",
      selector: (row: { phoneNumber: number | string }) =>
        row?.phoneNumber || "",
      cell: (row: { phoneNumber: number | string }) => (
        <span className="text-primary">{row?.phoneNumber || "-"}</span>
      ),
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const a = String(rowA.phoneNumber || "");
        const b = String(rowB.phoneNumber || "");
        return a.localeCompare(b);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Features</span>,
      minWidth: "150px",
      selector: (row: {
        capabilities?: {
          voice?: boolean;
          sms?: boolean;
          mms?: boolean;
          fax?: boolean;
        };
      }) => {
        const caps = row?.capabilities || {};
        return Object.keys(caps)
          .filter((key) => caps[key as keyof typeof caps])
          .join(",");
      },
      cell: (row: {
        capabilities?: {
          voice?: boolean;
          sms?: boolean;
          mms?: boolean;
          fax?: boolean;
        };
      }) => {
        return (
          <div
            className="d-flex flex-row align-items-center flex-nowrap align-content-center"
            style={{
              gap: "20px",
            }}
          >
            {row?.capabilities?.voice && (
              <Tooltip placement="bottom" overlay={"Voice"}>
                <i className="bi bi-telephone fs-lg"></i>
              </Tooltip>
            )}
            {row?.capabilities?.sms && (
              <Tooltip placement="bottom" overlay={"SMS"}>
                <i className="bi bi-chat-left-text fs-lg"></i>
              </Tooltip>
            )}
            {row?.capabilities?.mms && (
              <Tooltip placement="bottom" overlay={"MMS"}>
                <i className="bi bi-file-image fs-lg"></i>
              </Tooltip>
            )}
            {row?.capabilities?.fax && (
              <Tooltip placement="bottom" overlay={"Fax"}>
                <i className="bi bi-file-earmark-text fs-lg"></i>
              </Tooltip>
            )}
          </div>
        );
      },
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const capA = rowA.capabilities || {};
        const capB = rowB.capabilities || {};
        const featuresA = Object.keys(capA)
          .filter((key) => capA[key])
          .join("");
        const featuresB = Object.keys(capB)
          .filter((key) => capB[key])
          .join("");
        return featuresA.localeCompare(featuresB);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Monthly Fee</span>,
      selector: (row: { monthlyCharges?: number }) => row?.monthlyCharges || 0,
      cell: (row: { monthlyCharges?: number }) =>
        `$${row?.monthlyCharges ? (+row?.monthlyCharges)?.toFixed(2) : "0.00"}`,
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        return (rowA.monthlyCharges || 0) - (rowB.monthlyCharges || 0);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Actions</span>,
      minWidth: "110px",
      selector: (row: any) => row,
      cell: (row: any) => {
        return (
          <Button
            className="btn btn-sm"
            variant="outline-secondary"
            onClick={() =>
              dispatch(
                openModal({
                  path: "BuyMultipleNumbers",
                  data: {
                    title: "Buy Multiple Numbers",
                    footer: false,
                    selectedRows: [row.phoneNumber],
                    stepsData,
                    getData: () => {
                      setSelectedRows([]);
                      handleGetData(
                        currPage,
                        rowsPerPage,
                        getPayload({ ...validation.values })
                      );
                    },
                  },
                })
              )
            }
          >
            Buy Now
          </Button>
        );
      },
    },
  ];

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    onSubmit: async (values: any) => {
      setIsSubmitClicked(true);
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        currPage: String(1),
      });
      if ((values.state || values.city?.tempValue) && !values.areaCode) {
        const getAreaCodeList = areacodeList(
          removeEmptyAndNullValues({
            state: values.state || "",
            city: values.city?.tempValue || "",
          }),
          100
        );

        const temp: any = [];
        validation.setFieldValue("areaCode", "");
        setTempAreaCode({});
        setTempACArr("");
        setLoading2(true);

        const temp2 = async (page: any) => {
          const result = await getAreaCodeList("", [], page);
          temp.push(...result?.options);

          if (result?.hasMore) {
            await temp2(result?.additional);
          } else {
            setLoading2(false);
            setTempACArr(temp?.map((dt: any) => dt?.area_code)?.toString());
            handleGetData(
              1,
              rowsPerPage,
              getPayload({
                ...values,
                areaCode: temp?.map((dt: any) => dt?.area_code)?.toString(),
              })
            );
          }
        };
        temp2({ page: 1 });
      } else {
        handleGetData(1, rowsPerPage, getPayload({ ...values }));
      }
    },
  });

  useEffect(() => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      currPage: String(1),
    });
    handleGetData(1, rowsPerPage);
    setAllCountry(Country.getAllCountries());
  }, []);

  useEffect(() => {
    if (validation.values.country) {
      const temp = State.getStatesOfCountry(validation.values.country);
      setAllState(temp);
    } else {
      setAllState([]);
    }
  }, [validation.values.country]);

  useEffect(() => {
    if (validation.values.country || validation.values.state) {
      const temp = validation.values.state
        ? City.getCitiesOfState(
            validation.values.country,
            validation.values.state
          )
        : City.getCitiesOfCountry(validation.values.country);
      setAllCity(temp);
    } else {
      setAllCity([]);
    }
  }, [validation.values.country, validation.values.state]);

  const allCityOptions = useMemo(() => {
    const options =
      allCity?.length > 0
        ? allCity?.map((dt: { name: string; stateCode: string }) => ({
            label: `${dt?.name} - ${dt?.stateCode}`,
            value: `${dt?.name} - ${dt?.stateCode}`,
            tempValue: dt?.name,
            stateCode: dt?.stateCode,
          }))
        : [];
    return options;
  }, [allCity]);

  const getPayload = (values: any) => {
    let updatedPayload: any = {
      tfn: isChecked,
      inRegion: values.state,
      inLocality: values.city?.tempValue || "",
      areaCode: values.areaCode || tempACArr,
      contains: values.contains,
    };
    if (values.capabilities.find((x: string) => x === "sms")) {
      updatedPayload = { ...updatedPayload, smsEnabled: true };
    }
    if (values.capabilities.find((x: string) => x === "voice")) {
      updatedPayload = { ...updatedPayload, voiceEnabled: true };
    }
    if (values.capabilities.find((x: string) => x === "mms")) {
      updatedPayload = { ...updatedPayload, mmsEnabled: true };
    }
    return removeEmptyAndNullValues(updatedPayload);
  };

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      fetchNumbersListThunk(
        page,
        perPage,
        removeEmptyAndNullValues({ ...otherParams })
      )
    ).then(() => {
      setIsSubmitClicked(false);
    });
  };

  const handlePageChange = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      currPage: String(page),
    });
    setSelectedRows([]);
    handleGetData(page, rowsPerPage, getPayload({ ...validation.values }));
  };

  const handleRowsPerPageChange = (rows: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      currPage: String(1),
      rowsPerPage: String(rows),
    });
    setSelectedRows([]);
    handleGetData(1, rows, getPayload({ ...validation.values }));
  };

  const resetAreaCode = () => {
    // dispatch(resetAreaCodeThunk());
  };

  const sleep = (ms: number) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(undefined);
      }, ms);
    });

  const loadOptions = async (search: string, prevOptions: any) => {
    await sleep(200);

    let filteredOptions: OptionType[];
    if (!search) {
      filteredOptions = allCityOptions;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = allCityOptions.filter((x: OptionType) =>
        x.label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore,
    };
  };

  const areacodeList =
    (dataObj: any, limit: number, serchKey?: string) =>
    async (searchQuery: string, loadedOptions: any, { page }: any) => {
      try {
        let data: any = await areaCodeApi(
          page,
          limit,
          removeEmptyAndNullValues({
            ...dataObj,
            ...(serchKey && { [serchKey]: searchQuery || "" }),
          })
        );

        if (!data?.code && !data?.errorCode) {
          return {
            options: data?.data,
            hasMore: data?.data?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        } else {
          return {
            options: [],
            hasMore: false,
            additional: {
              page: page,
            },
          };
        }
      } catch (error: any) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: page,
          },
        };
      }
    };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Buy Numbers"
            isBack={Boolean(subGroupId)}
            backClick={() =>
              props.router.navigate(
                subGroupId
                  ? `/groups/${groupId}/subgroups/${subGroupId}/${subgroupnames}`
                  : -1
              )
            }
          />

          <Card className="wrapper">
            <Card.Body>
              <div className="border-bottom pb-2 mb-4">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setOpen(!open);
                    validation.handleSubmit();
                  }}
                >
                  <Row>
                    <Col sm={4}>
                      <div className="mb-3">
                        <Form.Label htmlFor="country">Country</Form.Label>
                        <Select
                          isClearable={true}
                          name="country"
                          isDisabled={loading || loading2}
                          styles={styleObj(
                            validation?.touched?.country &&
                              validation?.errors?.country
                          )}
                          theme={customSelectTheme}
                          placeholder="Select Country"
                          options={
                            allCountry?.length > 0
                              ? allCountry?.map(
                                  (dt: { name: string; isoCode: string }) => ({
                                    label: dt?.name,
                                    value: dt?.isoCode,
                                  })
                                )
                              : []
                          }
                          onChange={(e: { value: string }) => {
                            if (e?.value) {
                              validation.setFieldValue(
                                "country",
                                e.value ? e.value : ""
                              );
                            } else {
                              validation.setFieldValue("country", "");
                            }

                            validation.setFieldValue("state", "");
                            validation.setFieldValue("city", null);
                            validation.setFieldValue("areaCode", "");
                            setTempAreaCode({});
                            setTempACArr("");
                          }}
                          value={
                            validation.values.country
                              ? {
                                  label: allCountry?.find(
                                    (dt: { isoCode: string }) =>
                                      dt?.isoCode === validation.values.country
                                  )?.name,
                                  value: validation.values.country,
                                }
                              : null
                          }
                        />
                      </div>
                    </Col>

                    <Col sm={4}>
                      <div className="mb-3">
                        <Form.Label htmlFor="state">States</Form.Label>
                        <Select
                          isClearable={true}
                          name="state"
                          isDisabled={
                            loading || loading2 || !validation.values.country
                          }
                          styles={styleObj(
                            validation?.touched?.state &&
                              validation?.errors?.state
                          )}
                          theme={customSelectTheme}
                          placeholder="Select State"
                          options={
                            validation.values.country === "US"
                              ? US_STATES.map((state) => ({
                                  label: state.name,
                                  value: state.isoCode,
                                }))
                              : allState?.length > 0
                              ? allState?.map(
                                  (dt: { name: string; isoCode: string }) => ({
                                    label: dt?.name,
                                    value: dt?.isoCode,
                                  })
                                )
                              : []
                          }
                          onChange={(e: { value: string }) => {
                            if (e?.value) {
                              validation.setFieldValue("state", e.value);
                            } else {
                              validation.setFieldValue("state", "");
                            }
                            validation.setFieldValue("city", null);
                            validation.setFieldValue("areaCode", "");
                            setTempAreaCode({});
                            setTempACArr("");
                          }}
                          value={
                            validation.values.state
                              ? validation.values.country === "US"
                                ? US_STATES.filter(
                                    (state) =>
                                      state.isoCode === validation.values.state
                                  ).map((state) => ({
                                    label: state.name,
                                    value: state.isoCode,
                                  }))[0]
                                : {
                                    label: allState?.find(
                                      (dt: { isoCode: string }) =>
                                        dt?.isoCode === validation.values.state
                                    )?.name,
                                    value: validation.values.state,
                                  }
                              : null
                          }
                        />
                        {validation.touched.state &&
                          validation.errors.state && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {validation.errors.state}
                            </Form.Control.Feedback>
                          )}
                      </div>
                    </Col>

                    <Col sm={4}>
                      <div className="mb-3">
                        <Form.Label htmlFor="city">City</Form.Label>
                        <AsyncPaginate
                          isClearable={true}
                          key={
                            validation.values.state || validation.values.country
                          }
                          name="city"
                          isDisabled={loading || loading2}
                          styles={styleObj(
                            validation?.touched?.city &&
                              validation?.errors?.city
                          )}
                          theme={customSelectTheme}
                          loadOptions={loadOptions}
                          placeholder="Select City"
                          onChange={(e: {
                            value: string;
                            tempValue: string;
                            stateCode: string;
                          }) => {
                            if (e) {
                              validation.setFieldValue("city", e);
                              validation.setFieldValue("state", e?.stateCode);
                            } else {
                              validation.setFieldValue("city", null);
                            }
                          }}
                          value={validation.values.city}
                        />
                        {validation.touched.city && validation.errors.city && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation.errors.city}
                          </Form.Control.Feedback>
                        )}
                      </div>
                    </Col>

                    <Col sm={4}>
                      <div className="mb-3">
                        <Form.Label htmlFor="areaCode">
                          {isChecked ? "Toll Free Prefix" : "Area Code"}
                        </Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.city?.value +
                            validation.values.state?.stateCode +
                            "areaCode"
                          }
                          name="areaCode"
                          isDisabled={loading || loading2}
                          isMulti={false}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.areaCode &&
                              validation?.errors?.areaCode
                          )}
                          theme={customSelectTheme}
                          placeholder="Select Area Code"
                          value={
                            validation.values.areaCode ? tempAreaCode : null
                          }
                          inputValue={inputValue}
                          onInputChange={(newValue: any, { action }: any) => {
                            if (action === "input-change") {
                              const numericValue = newValue.replace(/\D/g, "");
                              setInputValue(numericValue);
                            }
                          }}
                          loadOptions={areacodeList(
                            removeEmptyAndNullValues({
                              state: validation.values.state || "",
                              city: validation.values.city?.tempValue || "",
                            }),
                            10,
                            "areaCode"
                          )}
                          getOptionValue={(option: any) => option?.area_code}
                          getOptionLabel={(option: any) =>
                            `${option.area_code} - ${option.city}, ${option.state}`
                          }
                          onChange={(e: {
                            area_code: string;
                            city: string;
                            state: string;
                          }) => {
                            if (e) {
                              validation.setFieldValue("areaCode", e.area_code);
                              setTempAreaCode(e);
                              setTempACArr(e.area_code);
                              validation.setFieldValue("city", {
                                label: `${e.city} - ${e.state}`,
                                value: `${e.city} - ${e.state}`,
                                tempValue: e.city,
                                stateCode: e.state,
                              });
                              validation.setFieldValue("state", e?.state);
                            } else {
                              validation.setFieldValue("areaCode", "");
                              setTempAreaCode({});
                              setTempACArr("");
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                        {validation.touched.areaCode &&
                          validation.errors.areaCode && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {validation.errors.areaCode}
                            </Form.Control.Feedback>
                          )}
                      </div>
                    </Col>

                    <Col sm={4}>
                      <div className="mb-3">
                        <Form.Label htmlFor="contains">Ends With</Form.Label>
                        <input
                          type="text"
                          placeholder="Choice Number"
                          className="form-control custom-button"
                          name="contains"
                          value={
                            validation.values.contains === "0" ||
                            validation.values.contains === 0 ||
                            validation.values.contains
                              ? validation.values.contains
                              : ""
                          }
                          onChange={validation.handleChange}
                        />
                      </div>
                    </Col>

                    <Col sm={4}>
                      <div className="mb-3 mt-4">
                        <Dropdown
                          className="form-control form "
                          id="capabilities"
                          style={{ padding: "0px" }}
                        >
                          <Dropdown.Toggle
                            variant=""
                            id="dropdown-capabilities"
                            style={{
                              background: "rgba(0, 0, 0, 0)",
                              color: "#6868ab",
                              border: "1px solid #6868ab",
                              width: "100%",
                              textAlign: "start",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {validation.values.capabilities.length > 0
                              ? validation.values.capabilities
                                  .map((capability: string) =>
                                    capability === "all"
                                      ? "All" // Capitalize "all".
                                      : capability.toUpperCase()
                                  )
                                  .join(", ")
                              : "Advanced Search"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            style={{ width: "100%", padding: "12px" }}
                            id="capabilities"
                          >
                            {capabilitiesOptions.map((key) => (
                              <div className="form-check" key={key.value}>
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  disabled={
                                    validation.values.capabilities.includes(
                                      "all"
                                    )
                                      ? key.value === "sms" ||
                                        key.value === "mms"
                                      : false
                                  }
                                  id={key.value}
                                  checked={validation.values.capabilities.includes(
                                    key.value as never
                                  )}
                                  onChange={() => {
                                    const keyIndex =
                                      validation.values.capabilities.indexOf(
                                        key.value as never
                                      );
                                    if (key.value === "all") {
                                      const tempValues =
                                        validation.values.capabilities
                                          .length === capabilitiesOptions.length
                                          ? []
                                          : capabilitiesOptions.map(
                                              (x) => x.value as never
                                            );
                                      validation.setFieldValue(
                                        "capabilities",
                                        tempValues
                                      );
                                    } else {
                                      if (keyIndex !== -1) {
                                        const updatedOptions = [
                                          ...validation.values.capabilities,
                                        ];
                                        updatedOptions.splice(keyIndex, 1);
                                        validation.setFieldValue(
                                          "capabilities",
                                          updatedOptions
                                        );
                                      } else {
                                        validation.setFieldValue(
                                          "capabilities",
                                          [
                                            ...validation.values.capabilities,
                                            key.value as never,
                                          ]
                                        );
                                      }
                                    }
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={key.value}
                                >
                                  {key.label}
                                </label>
                              </div>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>

                    {/* <Col className="d-flex align-items-center">
                      <div
                        className="form-check form-switch form-switch-md"
                        dir="ltr"
                      >
                        <Form.Check
                          type="checkbox"
                          role="switch"
                          label={
                            <Form.Label>
                              {isChecked
                                ? "Toll Free Numbers Enabled"
                                : "Toll Free Numbers Disabled"}
                            </Form.Label>
                          }
                          checked={isChecked}
                          onChange={(e) => {
                            validation.setFieldValue("areaCode", "");
                            setTempAreaCode({});
                            setTempACArr("");
                            setIsChecked(e.target.checked);
                          }}
                          style={{ marginBottom: "20px" }}
                        />
                      </div>
                    </Col> */}

                    <Col className="mb-2">
                      <div className="d-flex align-items-center justify-content-end">
                        <Button
                          className="btn btn-secondary me-2"
                          type="button"
                          disabled={isSubmitClicked && loading}
                          onClick={() => {
                            setIsChecked(false);
                            validation.resetForm();
                            setTempAreaCode({});
                            setTempACArr("");
                            setOpen(!open);
                            setSearchParams({
                              ...Object.fromEntries(searchParams.entries()),
                              currPage: String(1),
                            });
                            handleGetData(1, rowsPerPage);
                            setAllCity(City.getCitiesOfCountry("US"));
                            resetAreaCode();
                            setInputValue("");
                          }}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Clear
                        </Button>
                        <Button
                          className="btn btn-primary"
                          type="submit"
                          disabled={isSubmitClicked && (loading || loading2)}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {isSubmitClicked && (loading || loading2) && (
                            <Spinner size="sm" animation="border" />
                          )}{" "}
                          Apply
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>

              {loading ? (
                <div style={{ height: 200 }}>
                  <img
                    src={Loader}
                    className={`position-absolute top-50 start-50 translate-middle`}
                  />
                </div>
              ) : (
                <>
                  {selectedRows?.length > 0 && (
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        className={`btn btn-outline-primary me-2 mb-4 ${
                          selectedRows?.length < 1 ? "cursor-not-allowed" : ""
                        }`}
                        type="button"
                        disabled={selectedRows?.length < 1}
                        onClick={() => {
                          dispatch(
                            openModal({
                              path: "BuyMultipleNumbers",
                              data: {
                                title: "Buy Multiple Numbers",
                                footer: false,
                                selectedRows,
                                stepsData,
                                getData: () => {
                                  setSelectedRows([]);
                                  handleGetData(
                                    currPage,
                                    rowsPerPage,
                                    getPayload({ ...validation.values })
                                  );
                                },
                              },
                            })
                          );
                        }}
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        Buy {selectedRows?.length > 1 ? "Numbers" : "Number"}
                      </Button>
                    </div>
                  )}
                  <Datatable
                    data={
                      Array.isArray(dataObj?.records) ? dataObj?.records : []
                    }
                    columns={columns}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={dataObj?.totalRecords}
                  />
                </>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(BuyNumbers);
