import {
  activePhoneNumberApi,
  releasePhoneNumberApi,
  saveTags,
  getEventLogsApi,
  getNumberHistoryApi,
  getRegulatoryInformationApi,
  addFriendlyNameApi,
} from "helpers/apiroute_helper";
import {
  apiLoading,
  apiSuccess,
  apiError,
  releasePhoneNumberSuccessful,
  releasePhoneNumberFailed,
  getEventLogsSuccessful,
  getEventLogsFailed,
  getRegulatoryInformationSuccessful,
  getRegulatoryInformationFailed,
  resetEventLogsData,
  getNumberHistorySuccessful,
  getNumberHistoryFailed,
} from "./reducer";
import { toast } from "react-toastify";

export const activePhoneNumbersThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      const data: any = await activePhoneNumberApi(
        page,
        recordsPerPage,
        otherParams
      );

      if (data?.code || data?.errorCode || data?.message) {
        dispatch(apiError(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(
          apiSuccess({
            ...data,
            data: data.records,
            currentPage: data.page,
          })
        );
      }
    } catch (error: any) {
      dispatch(apiError(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const saveNumberTag =
  (payload: any, cb?: any) => async (dispatch: any) => {
    // dispatch(apiLoading());
    try {
      const data: any = await saveTags(payload);
      if ((!data?.code || data.code === 200) && !data?.errorCode) {
        toast.success(data?.message);
        dispatch(apiSuccess(data));
        cb();
      }
    } catch (error: any) {
      dispatch(apiError(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
      cb();
    }
  };

export const addFriendlyNameThunk =
  (payload: any, cb?: any) => async (dispatch: any) => {
    // dispatch(apiLoading());
    try {
      const data: any = await addFriendlyNameApi(payload);

      if ((!data?.code || data.code === 200) && !data?.errorCode) {
        toast.success(data?.message);
        dispatch(apiSuccess(data));
        cb();
      }
    } catch (error: any) {
      dispatch(apiError(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
      cb();
    }
  };

export const releasePhoneNumberThunk =
  (numberObj: any, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading());

      let data: any = await releasePhoneNumberApi({
        ...numberObj,
      });

      if (data?.code || data?.errorCode) {
        dispatch(releasePhoneNumberFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(releasePhoneNumberSuccessful(data));
        toast.success(data?.message || "Phone Number Released Successfully");
        cb && cb();
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(releasePhoneNumberFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

// Own Numbers Event Logs Thunk
export const getEventLogsThunk =
  (
    pagination: { page: number; recordsPerPage: number },
    phoneNumber: string,
    otherParams?: any
  ) =>
  async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      // Pass additional parameters to getMessageLogsApi function
      const data: any = await getEventLogsApi(
        pagination,
        phoneNumber,
        otherParams
      );
      if (data?.code) {
        dispatch(getEventLogsFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(getEventLogsSuccessful(data));
      }
    } catch (error: any) {
      dispatch(getEventLogsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const resetEventLogsList = () => async (dispatch: any) => {
  dispatch(apiLoading());
  dispatch(resetEventLogsData());
};

export const getNumberHistoryThunk =
  (phoneNumber: string, page: number, recordsPerPage: number) =>
  async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await getNumberHistoryApi(
        phoneNumber,
        page,
        recordsPerPage
      );
      if (data?.code) {
        dispatch(getNumberHistoryFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(getNumberHistorySuccessful(data));
      }
    } catch (error: any) {
      dispatch(getNumberHistoryFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

// Own Numbers Event Logs Thunk
export const getRegulatoryInformationThunk =
  (
    // pagination: { page: number, recordsPerPage: number },
    phoneNumber: string,
    otherParams?: any
  ) =>
  async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      // Pass additional parameters to getMessageLogsApi function
      const data: any = await getRegulatoryInformationApi(
        // pagination,
        phoneNumber,
        otherParams
      );
      if (data?.code) {
        dispatch(getRegulatoryInformationFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(getRegulatoryInformationSuccessful(data));
      }
    } catch (error: any) {
      dispatch(getRegulatoryInformationFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };
