import { useEffect, useState } from "react";
import { Navigate, Route } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import {
  authUserLogoutThunk,
  getAuthSessionStatusThunk,
  // getAllGroupsThunk,
  getAuthUserThunk,
  getUserStepsThunk,
  getWebNotificationsListThunk,
} from "slices/thunk";
import { createSelector } from "reselect";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import LockScreen from "pages/Authentication/LockScreen";
import Cookies from "js-cookie";

// Simple encryption/decryption functions
const encryptState = (state: boolean): string => {
  const timestamp = new Date().getTime();
  const randomKey = Math.random().toString(36).substring(7);
  const value = `${state}-${timestamp}-${randomKey}`;
  return btoa(value); // Base64 encode
};

const decryptState = (encrypted: string | undefined): boolean => {
  if (!encrypted) return false;
  try {
    const decoded = atob(encrypted); // Base64 decode
    const [state] = decoded.split("-");
    return state === "true";
  } catch {
    return false;
  }
};

const AuthProtected = (props: any) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [showLockScreen, setShowLockScreen] = useState(() => {
    // Initialize state from encrypted cookie
    return decryptState(Cookies.get("screenLock"));
  });

  const selectAccountAndLogin = createSelector(
    (state: any) => state.Login,
    (state: any) => state.AuthUser,
    (login, authUser) => ({
      loginData: login.loginData,
      user: authUser.authUser,
      authUserPermissions: authUser.authUserPermissions,
    })
  );
  const { loginData, user, authUserPermissions } = useSelector(
    selectAccountAndLogin
  );

  // Function to handle lock/unlock with encryption
  const handleLockScreen = (locked: boolean) => {
    setShowLockScreen(locked);
    if (locked) {
      const encrypted = encryptState(locked);
      Cookies.set("screenLock", encrypted, {
        secure: true, // Only transmitted over HTTPS
        sameSite: "strict", // Protect against CSRF
      });
    } else {
      Cookies.remove("screenLock");
    }
  };

  useEffect(() => {
    let idleTimeout: any;
    const idleTime = 1800000; // 30 minutes

    const resetTimer = () => {
      clearTimeout(idleTimeout);
      idleTimeout = setTimeout(() => {
        handleLockScreen(true);
      }, idleTime);
    };

    // Only start timer if screen is not locked
    if (!showLockScreen) {
      // Attach event listeners
      window.onload = resetTimer;
      window.onmousemove = resetTimer;
      window.onmousedown = resetTimer;
      window.ontouchstart = resetTimer;
      window.onclick = resetTimer;
      window.onkeydown = resetTimer;
      window.addEventListener("scroll", resetTimer);

      resetTimer(); // Start the timer
    }

    return () => {
      clearTimeout(idleTimeout);
      window.removeEventListener("scroll", resetTimer);
    };
  }, [showLockScreen]);

  useEffect(() => {
    const obj = loginData && JSON.parse(loginData);
    if (obj.apiKey && obj.authToken) {
      setAuthorization(obj.apiKey, obj.authToken);
      dispatch(getAuthUserThunk());
      dispatch(getWebNotificationsListThunk());
      // dispatch(getAllGroupsThunk({ page: 1, recordsPerPage: 10 }));
    } else if (!obj.apiKey && !obj.authToken) {
      dispatch(authUserLogoutThunk());
    }

    let interval =
      obj.apiKey &&
      setInterval(() => {
        dispatch(getAuthSessionStatusThunk(obj.apiKey));
      }, 10000); // 10 sec

    return () => {
      clearInterval(interval);
    };
  }, [loginData]);

  useEffect(() => {
    if (user) dispatch(getUserStepsThunk(user?.completedSteps));
  }, [user]);

  const obj = loginData && JSON.parse(loginData);
  if (!loginData || (!obj?.apiKey && !obj?.authToken)) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  if (!user || Object.keys(user).length === 0) {
    return "";
  }

  const hasPermission = (key: string) => {
    if (!authUserPermissions || !key) return false;
    const permission = key
      .split(".")
      .reduce((obj, k) => obj && obj[k], authUserPermissions);
    return permission && permission.read === true;
  };

  if (
    Object.keys(authUserPermissions).length > 0 &&
    !hasPermission(props.moduleKey)
  ) {
    return (
      <div
        className="page-title-box d-flex flex-column align-items-center justify-content-center"
        style={{
          height: "100vh",
          fontFamily: '"Poppins", sans-serif',
        }}
      >
        <h4
          className="mb-3 d-flex align-items-center"
          style={{
            fontSize: "calc(0.8125rem* 1.5)",
            fontWeight: 600,
            color: "#0f1114",
          }}
        >
          Permission Denied
        </h4>
        <p>You don't have permission to access this page.</p>
        <Button
          variant="secondary"
          className="d-flex align-items-center"
          onClick={() => navigate(-1)}
          style={{ fontSize: "0.8125rem" }}
        >
          <i className="bx bx-arrow-back me-1"></i> Go Back
        </Button>
      </div>
    );
  }

  if (showLockScreen) {
    return (
      <div style={{ fontFamily: '"Poppins", sans-serif' }}>
        <LockScreen
          showLockScreen={showLockScreen}
          setShowLockScreen={(locked: boolean) => handleLockScreen(locked)}
        />
      </div>
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return (
          <>
            <Component {...props} />
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
