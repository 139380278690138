import { FocusError } from "focus-formik-error";
import { asyncSubgroupList, customSelectTheme, styleObj } from "helpers/common";
import { Button, Col, Form, Row, Tab } from "react-bootstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import Select from "react-select";
import Tooltip from "rc-tooltip";
import { useEffect, useRef, useState } from "react";
import Flatpickr from "react-flatpickr";

const Step1 = (props: any) => {
  const {
    validation1,
    subgroupTemp,
    setSubgroupTemp,
    allCountry,
    allState,
    allCity,
    handlePincodeBlur,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const [isValidPhone, setIsValidPhone] = useState<boolean>(true);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (inputRef.current) {
      const autocomplete = new google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["geocode"], // Restrict the search to geographical location types
          // types: ["address"], // Restrict to address types
          // fields: ["address_components"], // Get only address components
          componentRestrictions: {
            country: validation1.values.country || undefined,
          },
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.address_components) {
          const addressComponents = place.address_components;
          const addressDetails = {
            address: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
          };

          addressComponents?.forEach((component: any) => {
            const types = component.types;
            if (types.includes("locality")) {
              addressDetails.city = component.long_name;
            } else if (types.includes("administrative_area_level_1")) {
              addressDetails.state = component.short_name;
            } else if (types.includes("country")) {
              addressDetails.country = component.short_name;
            } else if (types.includes("postal_code")) {
              addressDetails.zipcode = component.long_name;
            } else if (
              types.includes("street_number") ||
              types.includes("route")
              // || types.includes("neighborhood")
            ) {
              addressDetails.address += ` ${component.long_name}`;
            }
          });

          validation1.setFieldValue(
            "address",
            addressDetails.address?.trim() || ""
          );
          validation1.setFieldValue("city", addressDetails.city || "");
          validation1.setFieldValue("state", addressDetails.state || "");
          validation1.setFieldValue(
            "country",
            addressDetails.country ? [addressDetails.country] : []
          );
          validation1.setFieldValue("zip", addressDetails.zipcode || "");
        }
      });
    }

    return () => {
      if (inputRef.current) {
        google.maps.event.clearInstanceListeners(inputRef.current);
      }
    };
  }, [inputRef.current, validation1.values.country]);

  const shouldShowField = (fieldName: string) => {
    switch (fieldName) {
      case "agentDisplayName":
      case "brandCompanyName":
      case "ein":
      case "address":
      case "state":
      case "city":
      case "zip":
      case "sector":
      case "contactPersonPhone":
        return (
          validation1.values.country?.length > 0 &&
          validation1.values.country.includes("US")
        );
      case "companyName":
      case "action":
      case "testingNumber":
      case "anticipatedUsersNumber":
      case "messageCount":
      case "launchDate":
        return (
          validation1.values.country?.length > 0 &&
          validation1.values.country.some((ct: string) => ct !== "US")
        );
      case "subGroupId":
      case "legalCompanyName":
      case "rbmType":
      case "contactPersonName":
      case "contactPersonJobTitle":
      case "contactPersonEmail":
      case "useCase":
      case "optOut":
      case "optIn":
        return validation1.values.country?.length > 0;
      case "typicalResponse":
        return (
          validation1.values.country?.length > 0 && validation1.values.rbmType
        );
      default:
        return false;
    }
  };

  const handleSubmit = () => {
    if (phoneNumber && isValidPhone) {
      validation1.setFieldValue("testingNumber", [
        ...validation1.values.testingNumber,
        phoneNumber,
      ]);
      setPhoneNumber("");
      setError("");
    } else {
      !phoneNumber
        ? setError("Please Enter Phone number")
        : !isValidPhone
        ? setError("Phone number is not valid")
        : setError("");
    }
  };

  return (
    <Tab.Pane
      eventKey="0"
      id="pills-gen-info"
      role="tabpanel"
      aria-labelledby="pills-gen-info-tab"
    >
      <Form
        action="#"
        onSubmit={(e) => {
          e.preventDefault();
          validation1.handleSubmit();
        }}
      >
        <FocusError formik={validation1} />
        <div>
          <div className="mb-4">
            <div>
              <h5 className="mb-1">RBM Company Information</h5>
              <p className="text-muted">
                Input data for the Brand you'd like to register for RCS Business
                Messaging. To autofill company data from an approved 10DLC
                submission, click "Autofill Data" in the top-right.
              </p>
            </div>
          </div>
          <Row>
            <Col lg={6} className="mb-3">
              <Form.Label htmlFor="country">
                Country where traffice to be sent{" "}
                <span className="text-primary">*</span>
              </Form.Label>
              <Select
                name="country"
                isMulti={true}
                isClearable={true}
                styles={styleObj(
                  validation1.touched.country && validation1.errors.country
                )}
                theme={customSelectTheme}
                options={
                  allCountry?.length > 0
                    ? allCountry?.map(
                        (dt: { name: string; isoCode: string }) => ({
                          label: dt?.name,
                          value: dt?.isoCode,
                        })
                      )
                    : []
                }
                onChange={(selectedOptions: any) => {
                  if (selectedOptions && selectedOptions.length > 0) {
                    validation1.setFieldValue(
                      "country",
                      selectedOptions.map((option: any) => option.value)
                    );
                    validation1.setFieldValue("address", "");
                    validation1.setFieldValue("city", "");
                    validation1.setFieldValue("state", "");
                    validation1.setFieldValue("zip", "");
                  } else {
                    validation1.setFieldValue("country", []);
                  }
                }}
                value={
                  validation1.values.country?.length > 0
                    ? validation1.values.country.map((ct: string) => ({
                        label: allCountry?.find(
                          (dt: { isoCode: string }) => dt?.isoCode === ct
                        )?.name,
                        value: ct,
                      }))
                    : null
                }
              />
              {validation1.touched.country && validation1.errors.country ? (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {validation1.errors.country}
                </Form.Control.Feedback>
              ) : null}
            </Col>
            {shouldShowField("subGroupId") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="subGroupId">
                  Sub Group <span className="text-primary">*</span>
                </Form.Label>
                <AsyncPaginate
                  isClearable={true}
                  isSearchable={true}
                  styles={styleObj(
                    validation1.touched.subGroupId &&
                      validation1.errors.subGroupId
                  )}
                  theme={customSelectTheme}
                  components={{
                    Option: (props: any) => {
                      return (
                        <div
                          ref={props.innerRef}
                          {...props.innerProps}
                          className={`selectItemWithCheckbox`}
                        >
                          {props.children}
                        </div>
                      );
                    },
                  }}
                  value={subgroupTemp || []}
                  loadOptions={asyncSubgroupList({}, "subGroupId")}
                  getOptionValue={(option: any) => option?.sub_group_id}
                  getOptionLabel={(option: any) =>
                    `${option?.sub_group_id} - ${option?.sub_group_name}`
                  }
                  onChange={(option: any) => {
                    setSubgroupTemp([option]);
                    validation1.setFieldValue("subGroupId", [
                      option?.sub_group_id,
                    ]);
                  }}
                  additional={{
                    page: 1,
                  }}
                />
                {validation1.touched.subGroupId &&
                validation1.errors.subGroupId ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {validation1.errors.subGroupId}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("legalCompanyName") && (
              <Col lg={6} className="mb-3">
                <Form.Label
                  htmlFor="legalCompanyName"
                  className="position-relative"
                >
                  Legal Company Name <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="Only fill this out if applicable"
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="legalCompanyName"
                  onChange={validation1.handleChange}
                  value={validation1.values.legalCompanyName}
                  isInvalid={
                    validation1.touched.legalCompanyName &&
                    validation1.errors.legalCompanyName
                      ? true
                      : false
                  }
                />
                {validation1.touched.legalCompanyName &&
                validation1.errors.legalCompanyName ? (
                  <Form.Control.Feedback type="invalid">
                    {validation1.errors.legalCompanyName}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("companyName") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="companyName" className="position-relative">
                  Company Name <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="Only fill this out if applicable"
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="companyName"
                  onChange={validation1.handleChange}
                  value={validation1.values.companyName}
                  isInvalid={
                    validation1.touched.companyName &&
                    validation1.errors.companyName
                      ? true
                      : false
                  }
                />
                {validation1.touched.companyName &&
                validation1.errors.companyName ? (
                  <Form.Control.Feedback type="invalid">
                    {validation1.errors.companyName}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("agentDisplayName") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="agentDisplayName">
                  Agent Display Name <span className="text-primary">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="agentDisplayName"
                  onChange={validation1.handleChange}
                  value={validation1.values.agentDisplayName}
                  isInvalid={
                    validation1.touched.agentDisplayName &&
                    validation1.errors.agentDisplayName
                      ? true
                      : false
                  }
                />
                {validation1.touched.agentDisplayName &&
                validation1.errors.agentDisplayName ? (
                  <Form.Control.Feedback type="invalid">
                    {validation1.errors.agentDisplayName}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("brandCompanyName") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="brandCompanyName">
                  Brand Company Name <span className="text-primary">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="brandCompanyName"
                  onChange={validation1.handleChange}
                  value={validation1.values.brandCompanyName}
                  isInvalid={
                    validation1.touched.brandCompanyName &&
                    validation1.errors.brandCompanyName
                      ? true
                      : false
                  }
                />
                {validation1.touched.brandCompanyName &&
                validation1.errors.brandCompanyName ? (
                  <Form.Control.Feedback type="invalid">
                    {validation1.errors.brandCompanyName}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("ein") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="ein">
                  EIN <span className="text-primary">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="ein"
                  onChange={(e) => {
                    const formattedValue = e.target.value
                      .replace(/[^0-9a-zA-Z]/g, "")
                      .replace(/^(.{2})(.{0,7})/, "$1-$2")
                      .slice(0, 10);
                    validation1.setFieldValue("ein", formattedValue);
                  }}
                  value={validation1.values.ein}
                  isInvalid={
                    validation1.touched.ein && validation1.errors.ein
                      ? true
                      : false
                  }
                />
                {validation1.touched.ein && validation1.errors.ein ? (
                  <Form.Control.Feedback type="invalid">
                    {validation1.errors.ein}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("address") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="address">
                  Address <span className="text-primary">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="address"
                  name="address"
                  autoComplete="off"
                  ref={inputRef}
                  placeholder="Search for address"
                  onChange={validation1.handleChange}
                  onBlur={validation1.handleBlur}
                  value={validation1.values.address}
                  isInvalid={
                    validation1.touched.address && validation1.errors.address
                      ? true
                      : false
                  }
                />
                {validation1.touched.address && validation1.errors.address ? (
                  <Form.Control.Feedback type="invalid">
                    {validation1.errors.address}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("state") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="state">
                  State <span className="text-primary">*</span>
                </Form.Label>
                <Select
                  isClearable={true}
                  name="state"
                  styles={styleObj(
                    validation1.touched.state && validation1.errors.state
                  )}
                  theme={customSelectTheme}
                  options={
                    allState?.length > 0
                      ? allState?.map(
                          (dt: { name: string; isoCode: string }) => ({
                            label: dt?.name,
                            value: dt?.isoCode,
                          })
                        )
                      : []
                  }
                  onChange={(e: { value?: string }) => {
                    if (e) {
                      validation1.setFieldValue(
                        "state",
                        e.value ? e.value : ""
                      );
                      validation1.setFieldValue("city", "");
                      validation1.setFieldValue("zip", "");
                    } else {
                      validation1.setFieldValue("state", "");
                    }
                  }}
                  value={{
                    label: allState?.find(
                      (dt: { isoCode: string }) =>
                        dt?.isoCode === validation1.values.state
                    )?.name,
                    value: validation1.values.state,
                  }}
                />
                {validation1.touched.state && validation1.errors.state ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {validation1.errors.state}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("city") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="city">
                  City <span className="text-primary">*</span>
                </Form.Label>
                <Select
                  isClearable={true}
                  name="city"
                  styles={styleObj(
                    validation1.touched.city && validation1.errors.city
                  )}
                  theme={customSelectTheme}
                  options={
                    allCity?.length > 0
                      ? allCity?.map((dt: { name: string }) => ({
                          label: dt?.name,
                          value: dt?.name,
                        }))
                      : []
                  }
                  onChange={(e: { value?: string }) => {
                    if (e) {
                      validation1.setFieldValue("city", e.value ? e.value : "");
                      validation1.setFieldValue("zip", "");
                    } else {
                      validation1.setFieldValue("city", "");
                    }
                  }}
                  value={{
                    label: validation1.values.city,
                    value: validation1.values.city,
                  }}
                />
                {validation1.touched.city && validation1.errors.city ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {validation1.errors.city}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("zip") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="zip">
                  ZIP <span className="text-primary">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="zip"
                  onChange={validation1.handleChange}
                  onBlur={handlePincodeBlur}
                  value={validation1.values.zip}
                  isInvalid={
                    validation1.touched.zip && validation1.errors.zip
                      ? true
                      : false
                  }
                />
                {validation1.touched.zip && validation1.errors.zip ? (
                  <Form.Control.Feedback type="invalid">
                    {validation1.errors.zip}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("sector") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="sector">
                  Sector <span className="text-primary">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="sector"
                  onChange={validation1.handleChange}
                  value={validation1.values.sector}
                  isInvalid={
                    validation1.touched.sector && validation1.errors.sector
                      ? true
                      : false
                  }
                />
                {validation1.touched.sector && validation1.errors.sector ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {validation1.errors.sector}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("rbmType") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="rbmType">
                  RBM Type <span className="text-primary">*</span>
                </Form.Label>
                <Select
                  isClearable={true}
                  name="rbmType"
                  styles={styleObj(
                    validation1.touched.rbmType && validation1.errors.rbmType
                  )}
                  theme={customSelectTheme}
                  options={[
                    {
                      label: "Conversational",
                      value: "Conversational",
                    },
                    {
                      label: "Single/Basic",
                      value: "Single/Basic",
                    },
                  ]}
                  onChange={(e: { value: string }) =>
                    validation1.setFieldValue("rbmType", e.value)
                  }
                  value={{
                    label: validation1.values.rbmType,
                    value: validation1.values.rbmType,
                  }}
                />
                {validation1.touched.rbmType && validation1.errors.rbmType ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {validation1.errors.rbmType}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("contactPersonName") && (
              <Col lg={6} className="mb-3">
                <Form.Label
                  htmlFor="contactPersonName"
                  className="position-relative"
                >
                  Contact First Name and Last Name{" "}
                  <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="Full legal name of Brand point of contact"
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="contactPersonName"
                  onChange={validation1.handleChange}
                  value={validation1.values.contactPersonName}
                  isInvalid={
                    validation1.touched.contactPersonName &&
                    validation1.errors.contactPersonName
                      ? true
                      : false
                  }
                />
                {validation1.touched.contactPersonName &&
                validation1.errors.contactPersonName ? (
                  <Form.Control.Feedback type="invalid">
                    {validation1.errors.contactPersonName}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("contactPersonJobTitle") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="contactPersonJobTitle">
                  Contact Person Job Title{" "}
                  <span className="text-primary">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="contactPersonJobTitle"
                  onChange={validation1.handleChange}
                  value={validation1.values.contactPersonJobTitle}
                  isInvalid={
                    validation1.touched.contactPersonJobTitle &&
                    validation1.errors.contactPersonJobTitle
                      ? true
                      : false
                  }
                />
                {validation1.touched.contactPersonJobTitle &&
                validation1.errors.contactPersonJobTitle ? (
                  <Form.Control.Feedback type="invalid">
                    {validation1.errors.contactPersonJobTitle}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("contactPersonEmail") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="contactPersonEmail">
                  Contact Person Email Address{" "}
                  <span className="text-primary">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  name="contactPersonEmail"
                  onChange={validation1.handleChange}
                  onBlur={validation1.handleBlur}
                  value={validation1.values.contactPersonEmail}
                  isInvalid={
                    validation1.touched.contactPersonEmail &&
                    validation1.errors.contactPersonEmail
                      ? true
                      : false
                  }
                />
                {validation1.touched.contactPersonEmail &&
                validation1.errors.contactPersonEmail ? (
                  <Form.Control.Feedback type="invalid">
                    {validation1.errors.contactPersonEmail}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("contactPersonPhone") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="contactPersonPhone">
                  Contact Person Phone Number{" "}
                  <span className="text-primary">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  name="contactPersonPhone"
                  onChange={validation1.handleChange}
                  value={validation1.values.contactPersonPhone}
                  isInvalid={
                    validation1.touched.contactPersonPhone &&
                    validation1.errors.contactPersonPhone
                      ? true
                      : false
                  }
                />
                {validation1.touched.contactPersonPhone &&
                validation1.errors.contactPersonPhone ? (
                  <Form.Control.Feedback type="invalid">
                    {validation1.errors.contactPersonPhone}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("useCase") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="useCase">
                  Use Case/Traffic Type <span className="text-primary">*</span>
                </Form.Label>
                <Select
                  isClearable={true}
                  name="useCase"
                  styles={styleObj(
                    validation1.touched.useCase && validation1.errors.useCase
                  )}
                  theme={customSelectTheme}
                  options={[
                    {
                      label: "Transactional",
                      value: "Transactional",
                    },
                    {
                      label: "Promotional",
                      value: "Promotional",
                    },
                    {
                      label: "Multiuse (NOT OTP)",
                      value: "Multiuse (NOT OTP)",
                    },
                    {
                      label: "OTP",
                      value: "OTP",
                    },
                  ]}
                  onChange={(e: { value: string }) =>
                    validation1.setFieldValue("useCase", e.value)
                  }
                  value={{
                    label: validation1.values.useCase,
                    value: validation1.values.useCase,
                  }}
                />
                {validation1.touched.useCase && validation1.errors.useCase ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {validation1.errors.useCase}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("action") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="action" className="position-relative">
                  What actions triggers messages to be sent?{" "}
                  <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="What triggers the first message to be sent to a user? When is that message sent? Are they sent at a consistent time/date or for a specific event (e.g., a purchase, package delivery, etc.)?"
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="action"
                  onChange={validation1.handleChange}
                  value={validation1.values.action}
                  isInvalid={
                    validation1.touched.action && validation1.errors.action
                      ? true
                      : false
                  }
                />
                {validation1.touched.action && validation1.errors.action ? (
                  <Form.Control.Feedback type="invalid">
                    {validation1.errors.action}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("testingNumber") && (
              <Col lg={6} className="mb-3">
                <div className="">
                  <Form.Label
                    htmlFor="testingNumber"
                    className="position-relative"
                  >
                    Number Used for Testing{" "}
                    <span className="text-primary">*</span>
                    <Tooltip
                      placement="bottom"
                      overlay="Mobile Phone numbers that will be used to test the RCS rollout; international format (+15551236739)"
                    >
                      <i
                        className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                        style={{ bottom: -2 }}
                      />
                    </Tooltip>
                  </Form.Label>
                  {validation1.errors.testingNumber ? (
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-block mb-1 text-primary"
                    >
                      {validation1.errors.testingNumber}
                    </Form.Control.Feedback>
                  ) : null}
                  <div className="d-flex">
                    <Form.Control
                      type="number"
                      name="testingNumber"
                      placeholder="Enter phone number"
                      onChange={(e) => {
                        const reg = /^[1-9]\d{1,3}[1-9]\d{6,14}$/;
                        const val = e.target.value;
                        const isValid = reg.test(val);

                        !isValid
                          ? setError("Phone Number is Not Valid")
                          : setError("");

                        setPhoneNumber(val);
                        setIsValidPhone(isValid);
                      }}
                      value={phoneNumber || ""}
                    />
                    <Button
                      variant="secondary"
                      className="ms-2 d-flex align-items-center"
                      onClick={handleSubmit}
                    >
                      <i className="bx bx-plus me-1"></i> Add
                    </Button>
                  </div>
                  {error ? (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {error}
                    </Form.Control.Feedback>
                  ) : null}
                  <div className="d-flex flex-wrap mt-2">
                    {validation1.values.testingNumber?.map(
                      (dt: string, i2: number) => {
                        return (
                          <span
                            className="details-tag mt-1 mr-2 d-flex align-items-center"
                            key={i2}
                          >
                            {dt}
                            <i
                              className="ri-close-circle-fill cursor-pointer fs-md text-primary ms-2"
                              onClick={() => {
                                const temp = [
                                  ...validation1.values.testingNumber,
                                ];
                                temp.splice(i2, 1);
                                validation1.setFieldValue(
                                  "testingNumber",
                                  temp
                                );
                              }}
                            />
                          </span>
                        );
                      }
                    )}
                  </div>
                </div>
              </Col>
            )}
            {shouldShowField("anticipatedUsersNumber") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="anticipatedUsersNumber">
                  How many anticipated users?{" "}
                  <span className="text-primary">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  name="anticipatedUsersNumber"
                  onChange={validation1.handleChange}
                  onBlur={validation1.handleBlur}
                  value={validation1.values.anticipatedUsersNumber || ""}
                  isInvalid={
                    validation1.touched.anticipatedUsersNumber &&
                    validation1.errors.anticipatedUsersNumber
                      ? true
                      : false
                  }
                />
                {validation1.touched.anticipatedUsersNumber &&
                validation1.errors.anticipatedUsersNumber ? (
                  <Form.Control.Feedback type="invalid">
                    {validation1.errors.anticipatedUsersNumber}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("messageCount") && (
              <Col lg={6} className="mb-3">
                <Form.Label
                  htmlFor="messageCount"
                  className="position-relative"
                >
                  Estimated Monthly Messages{" "}
                  <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="This figure includes the number of users multiplied by the average number of messages per user; an estimation is acceptable"
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <Form.Control
                  type="number"
                  name="messageCount"
                  onChange={validation1.handleChange}
                  onBlur={validation1.handleBlur}
                  value={validation1.values.messageCount || ""}
                  isInvalid={
                    validation1.touched.messageCount &&
                    validation1.errors.messageCount
                      ? true
                      : false
                  }
                />
                {validation1.touched.messageCount &&
                validation1.errors.messageCount ? (
                  <Form.Control.Feedback type="invalid">
                    {validation1.errors.messageCount}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("launchDate") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="launchDate">
                  Target Launch Date <span className="text-primary">*</span>
                </Form.Label>
                <Flatpickr
                  className={`form-control ${
                    validation1.touched.launchDate &&
                    validation1.errors.launchDate
                      ? "is-invalid"
                      : ""
                  }`}
                  name="launchDate"
                  options={{
                    enableTime: false,
                    altFormat: "F j, Y",
                    dateFormat: "m-d-Y",
                    onChange: (value: any, dateStr: string, instance: any) => {
                      validation1.setFieldValue("launchDate", dateStr || "");
                    },
                  }}
                  value={validation1.values.launchDate || ""}
                />
                {validation1.touched.launchDate &&
                validation1.errors.launchDate ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {validation1.errors.launchDate}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("optOut") && (
              <Col lg={6} className="mb-3">
                <Form.Label
                  htmlFor="optOut"
                  className="position-relative"
                  style={{ width: "calc(100% - 15px)" }}
                >
                  Describe Opt-out Process, including keywords and opt out
                  message <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay={`When a user sends the word "STOP," (including when lowercase), a reply must be sent confirming the user is opted out and won't receive more messages unless the opt-in keyword(s) is/are sent. "STOP" must be recognized in English and all other supported languages.`}
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <textarea
                  id="optOut"
                  name="optOut"
                  onChange={validation1.handleChange}
                  onBlur={validation1.handleBlur}
                  value={validation1.values.optOut || ""}
                  className={`form-control ${
                    validation1.touched.optOut && validation1.errors.optOut
                      ? "is-invalid"
                      : ""
                  }`}
                  rows={3}
                  maxLength={1024}
                  minLength={20}
                />
                {validation1.touched.optOut && validation1.errors.optOut ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {validation1.errors.optOut}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("optIn") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="optIn" className="position-relative">
                  Describe how a user Opts-in, including keywords{" "}
                  <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="Provide details for how opt-in is acquired. If via a website or application, please provide links"
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <textarea
                  id="optIn"
                  name="optIn"
                  onChange={validation1.handleChange}
                  onBlur={validation1.handleBlur}
                  value={validation1.values.optIn || ""}
                  className={`form-control ${
                    validation1.touched.optIn && validation1.errors.optIn
                      ? "is-invalid"
                      : ""
                  }`}
                  rows={3}
                  maxLength={1024}
                  minLength={20}
                />
                {validation1.touched.optIn && validation1.errors.optIn ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {validation1.errors.optIn}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("typicalResponse") && (
              <Col lg={6} className="mb-3">
                <Form.Label
                  htmlFor="typicalResponse"
                  className="position-relative"
                  style={{ width: "calc(100% - 15px)" }}
                >
                  {validation1.values.rbmType === "Conversational"
                    ? "Describe typical responses for conversational messaging"
                    : "Describe agent use case and typical messages sent"}{" "}
                  <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="The most common and least common but still possible interactions an agent will have with recipients"
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <textarea
                  id="typicalResponse"
                  name="typicalResponse"
                  onChange={validation1.handleChange}
                  onBlur={validation1.handleBlur}
                  value={validation1.values.typicalResponse || ""}
                  className={`form-control ${
                    validation1.touched.typicalResponse &&
                    validation1.errors.typicalResponse
                      ? "is-invalid"
                      : ""
                  }`}
                  rows={3}
                  maxLength={1024}
                  minLength={20}
                />
                {validation1.touched.typicalResponse &&
                validation1.errors.typicalResponse ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {validation1.errors.typicalResponse}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
          </Row>
        </div>
        <div className="d-flex align-items-start gap-3 mt-4">
          <button
            type="submit"
            className="btn btn-secondary btn-label right m-auto nexttab nexttab"
          >
            <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
            Next
          </button>
        </div>
      </Form>
    </Tab.Pane>
  );
};

export default Step1;
