import { createSlice } from "@reduxjs/toolkit";

export const initialState:any = {
  loading: false,
  loading2: false,
  loading3: false,
  usageSummaryObj: {},
  usageTransactionsObj: {},
  subTransactions: {},
  loadingSubTransactions: {},
  usageSummaryDashboardObj: {},
  success: false,
  successMsg: "",
  error: false,
  errorMsg: "",
  dashboardAnalyticsData: null,
};

const usageSummaryDataSlice = createSlice({
  name: "usageSummary",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoading2(state) {
      state.loading2 = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoading3(state) {
      state.loading3 = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    emptyUsageTransactionsObj(state) {
      state.usageTransactionsObj = {};
    },
    usageSummaryDataSuccessful(state, action) {
      state.loading = false;
      state.usageSummaryObj = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    usageSummaryDataFailed(state, action) {
      state.loading = false;
      state.usageSummaryObj = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    summaryInvoiceSuccessful(state, action) {
      state.loading2 = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    summaryInvoiceFailed(state, action) {
      state.loading2 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    usageSummaryDashboardDataSuccessful(state, action) {
      state.loading3 = false;
      state.usageSummaryDashboardObj = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    usageSummaryDashboardDataFailed(state, action) {
      state.loading3 = false;
      state.usageSummaryDashboardObj = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    usageTransactionsDataSuccessful(state, action) {
      state.loading2 = false;
      state.usageTransactionsObj = action.payload;
      
      const activeKey = Object.keys(state.subTransactions)
        .find((key: any) => state.subTransactions[key]?.activeKey);
      
      if (activeKey) {
        state.subTransactions[activeKey] = {
          ...state.subTransactions[activeKey],
          records: action.payload.records || [],
          total: action.payload.totalRecords || 0
        };
      }
      
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    usageTransactionsDataFailed(state, action) {
      state.loading2 = false;
      state.usageTransactionsObj = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    dashboardAnalyticsDataSuccessful(state, action) {
      state.loading3 = false;
      state.dashboardAnalyticsData = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    dashboardAnalyticsDataFailed(state, action) {
      state.loading3 = false;
      state.dashboardAnalyticsData = null;
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    setSubTransactionLoading(state, action) {
      const { key, loading } = action.payload;
      state.loadingSubTransactions = {
        ...state.loadingSubTransactions,
        [key]: loading
      };
    },
    setSubTransactionData(state, action) {
      const { key, data } = action.payload;
      state.subTransactions = {
        ...state.subTransactions,
        [key]: {
          ...state.subTransactions[key],
          records: data.records || [],
          total: data.totalRecords || 0,
          activeKey: data.activeKey
        }
      };
    },
    clearSubTransactionData(state, action) {
      const { key } = action.payload;
      if (key) {
        const { [key]: _, ...rest } = state.subTransactions;
        state.subTransactions = rest;
      } else {
        state.subTransactions = {};
      }
    },
    toggleSubTransactionActiveKey(state, action) {
      const { key, activeKey } = action.payload;
      if (state.subTransactions[key]) {
        state.subTransactions[key].activeKey = 
          state.subTransactions[key].activeKey === activeKey ? '' : activeKey;
      } else {
        state.subTransactions[key] = { activeKey };
      }
    },
  },
});

export const {
  apiLoading,
  apiLoading2,
  apiLoading3,
  emptyUsageTransactionsObj,
  usageSummaryDataSuccessful,
  usageSummaryDataFailed,
  summaryInvoiceSuccessful,
  summaryInvoiceFailed,
  usageSummaryDashboardDataSuccessful,
  usageSummaryDashboardDataFailed,
  usageTransactionsDataSuccessful,
  usageTransactionsDataFailed,
  dashboardAnalyticsDataSuccessful,
  dashboardAnalyticsDataFailed,
  setSubTransactionLoading,
  setSubTransactionData,
  clearSubTransactionData,
  toggleSubTransactionActiveKey,
} = usageSummaryDataSlice.actions;

export default usageSummaryDataSlice.reducer;
