import React, { useEffect, useState, useMemo } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Form,
  Dropdown,
  Button,
  Tab,
  Nav,
} from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import {
  // getSubgroupDetailsCountThunk,
  getSubgroupDetailsThunk,
} from "slices/thunk";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import Loader from "assets/images/spinner-dark.svg";
import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import DLC from "./DLC";
import {
  asyncActivePhoneNumberList,
  asyncBrandList,
  asyncCampaignList,
  asyncPortRequestList,
  customSelectTheme,
  dateFormat,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import { useFormik } from "formik";
import { AsyncPaginate } from "react-select-async-paginate";
import { BrandSelect } from "Common/filter/brand-select";

const SubGroupDetails = (props: any) => {
  document.title = "Signal House Portal Sub Group Details";

  const dispatch = useDispatch<any>();
  const { subGroupId, subgroupnames } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeFilters1 = searchParams.get("activeFilters");

  const subGroupName = useMemo(() => {
    if (subgroupnames) {
      return subgroupnames
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }
    return "";
  }, [subgroupnames]);

  const selectAccount = createSelector(
    (state: any) => state.Groups,
    (groups) => ({
      loading3: groups.loading3,
      subgroupDetails: groups.subgroupDetails,
      groupDetails: groups.AllGroups,
      error: groups.error,
    })
  );

  const { loading3, subgroupDetails, groupDetails } =
    useSelector(selectAccount);

  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [activeFilters, setActiveFilters] = useState(
    activeFilters1
      ? JSON.parse(activeFilters1)
      : {
          brandId: [],
          campaignId: [],
          phoneNumber: [],
          portingId: "",
        }
  );

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      brandId: [],
      campaignId: [],
      phoneNumber: [],
      portingId: "",
    },
    onSubmit: (values) => {
      setActiveFilters(values);
      setSearchParams(
        {
          ...Object.fromEntries(searchParams.entries()),
          activeFilters: JSON.stringify(values),
        },
        {
          replace: true,
        }
      );
      setOpen(false);
    },
  });

  useEffect(() => {
    if (subGroupId) {
      // dispatch(getSubgroupDetailsCountThunk(subGroupId));
      dispatch(getSubgroupDetailsThunk(subGroupId));
    }
  }, [subGroupId]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={`${subGroupName || "Sub Group"} (${subGroupId || ""})`}
            style={{ width: "100%", maxWidth: 520 }}
            isFilter={true}
            filterButton={
              <>
                <Form.Control
                  type="text"
                  placeholder="Search by Brand ID, Campaign ID, Phone Number, or Porting ID"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Dropdown
                  align="end"
                  className="filterDropDown2 ms-2"
                  show={open}
                  onToggle={() => {
                    if (!datePickerModal) {
                      setOpen(!open);
                    }
                  }}
                >
                  <Dropdown.Toggle variant="secondary">
                    <i className="bx bx-filter-alt me-1"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu-md p-4">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        setOpen(!open);
                        validation.handleSubmit();
                      }}
                    >
                      <Row>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="brandId">Brand</Form.Label>
                          <BrandSelect
                            key={
                              subGroupId ||
                              groupDetails?.records?.[0]?.group_id ||
                              "brandId"
                            }
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                            styles={styleObj(
                              validation?.touched?.brandId &&
                                validation?.errors?.brandId
                            )}
                            theme={customSelectTheme}
                            value={
                              validation.values.brandId?.length > 0
                                ? validation.values.brandId
                                : null
                            }
                            loadOptions={asyncBrandList(
                              removeEmptyAndNullValues({
                                groupId:
                                  groupDetails?.records?.[0]?.group_id || "",
                                subGroupId: subGroupId || "",
                              }),
                              "brandId"
                            )}
                            getOptionValue={(option: any) => option?.brandId}
                            getOptionLabel={(option: any) =>
                              option?.displayName + " - " + option?.brandId
                            }
                            onChange={(option: any) => {
                              if (
                                option &&
                                option.brandId === validation.values.brandId
                              ) {
                                return;
                              }
                              if (option) {
                                validation.setFieldValue(
                                  "brandId",
                                  option.map((dt: any) => ({
                                    brandId: dt?.brandId || "",
                                    displayName: dt?.displayName || "",
                                  }))
                                );
                                validation.setFieldValue("campaignId", []);
                                validation.setFieldValue("phoneNumber", []);
                                validation.setFieldValue("portingId", "");
                              } else {
                                validation.setFieldValue("brandId", []);
                              }
                            }}
                            additional={{
                              page: 1,
                            }}
                          />
                        </Col>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="campaignId">
                            Campaign Id
                          </Form.Label>
                          <AsyncPaginate
                            key={
                              validation.values.brandId
                                ?.map((dt: any) => dt.brandId)
                                .toString() ||
                              subGroupId ||
                              groupDetails?.records?.[0]?.group_id ||
                              "campaignId"
                            }
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                            styles={styleObj(
                              validation?.touched?.campaignId &&
                                validation?.errors?.campaignId
                            )}
                            theme={customSelectTheme}
                            value={
                              validation.values.campaignId?.length > 0
                                ? validation.values.campaignId
                                : null
                            }
                            loadOptions={asyncCampaignList(
                              removeEmptyAndNullValues({
                                subGroupId: subGroupId || "",
                                groupId:
                                  groupDetails?.records?.[0]?.group_id || "",
                                brandId:
                                  validation.values.brandId.length > 0
                                    ? validation.values.brandId?.map(
                                        (dt: any) => dt.brandId
                                      )
                                    : "",
                              }),
                              "campaignId"
                            )}
                            getOptionValue={(option: any) => option?.campaignId}
                            getOptionLabel={(option: any) => option?.campaignId}
                            onChange={(option: any) => {
                              if (
                                option &&
                                option.campaignId ===
                                  validation.values.campaignId
                              ) {
                                return;
                              }
                              if (option) {
                                validation.setFieldValue(
                                  "brandId",
                                  option.reduce(
                                    (acc: any[], dt: any) => {
                                      const existingItem =
                                        validation.values.brandId.find(
                                          (item: any) =>
                                            item.brandId === dt.brandId
                                        );
                                      if (!existingItem) {
                                        acc.push({
                                          brandId: dt?.brandId || "",
                                        });
                                      }
                                      return acc;
                                    },
                                    [...validation.values.brandId]
                                  )
                                );
                                validation.setFieldValue(
                                  "campaignId",
                                  option.map((dt: any) => ({
                                    brandId: dt?.brandId || "",
                                    campaignId: dt?.campaignId || "",
                                  }))
                                );
                                validation.setFieldValue("phoneNumber", []);
                                validation.setFieldValue("portingId", "");
                              } else {
                                validation.setFieldValue("campaignId", []);
                              }
                            }}
                            additional={{
                              page: 1,
                            }}
                          />
                        </Col>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="phoneNumber">
                            Phone Number
                          </Form.Label>
                          <AsyncPaginate
                            key={
                              validation.values.campaignId
                                ?.map((dt: any) => dt.campaignId)
                                .toString() ||
                              validation.values.brandId
                                ?.map((dt: any) => dt.brandId)
                                .toString() ||
                              subGroupId ||
                              groupDetails?.records?.[0]?.group_id ||
                              "phoneNumber"
                            }
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                            styles={styleObj(
                              validation?.touched?.phoneNumber &&
                                validation?.errors?.phoneNumber
                            )}
                            theme={customSelectTheme}
                            value={
                              validation.values.phoneNumber
                                ? validation.values.phoneNumber
                                : null
                            }
                            loadOptions={asyncActivePhoneNumberList(
                              removeEmptyAndNullValues({
                                groupId:
                                  groupDetails?.records?.[0]?.group_id || "",
                                subGroupId: subGroupId || "",
                                brandId:
                                  validation.values.brandId.length > 0
                                    ? validation.values.brandId?.map(
                                        (dt: any) => dt.brandId
                                      )
                                    : "",
                                campaignId:
                                  validation.values.campaignId.length > 0
                                    ? validation.values.campaignId?.map(
                                        (dt: any) => dt.campaignId
                                      )
                                    : "",
                                tenDLCStatus: "Ready",
                              }),
                              "phoneNumber"
                            )}
                            getOptionValue={(option: any) =>
                              option?.phoneNumber
                            }
                            getOptionLabel={(option: any) =>
                              option?.phoneNumber
                            }
                            onChange={(option: any) => {
                              if (
                                option &&
                                option.phoneNumber ===
                                  validation.values.phoneNumber
                              ) {
                                return;
                              }

                              if (option) {
                                validation.setFieldValue(
                                  "brandId",
                                  option.reduce(
                                    (acc: any[], dt: any) => {
                                      const existingItem =
                                        validation.values.brandId.find(
                                          (item: any) =>
                                            item.brandId === dt.brandId
                                        );
                                      if (!existingItem) {
                                        acc.push({
                                          brandId: dt?.brandId || "",
                                        });
                                      }
                                      return acc;
                                    },
                                    [...validation.values.brandId]
                                  )
                                );
                                validation.setFieldValue(
                                  "campaignId",
                                  option.reduce(
                                    (acc: any[], dt: any) => {
                                      const existingItem =
                                        validation.values.campaignId.find(
                                          (item: any) =>
                                            item.campaignId === dt.campaignId &&
                                            item.brandId === dt.brandId
                                        );
                                      if (!existingItem) {
                                        acc.push({
                                          brandId: dt?.brandId || "",
                                          campaignId: dt.campaignId || "",
                                        });
                                      }
                                      return acc;
                                    },
                                    [...validation.values.campaignId]
                                  )
                                );
                                validation.setFieldValue(
                                  "phoneNumber",
                                  option.map((dt: any) => ({
                                    brandId: dt?.brandId || "",
                                    campaignId: dt.campaignId || "",
                                    phoneNumber: dt?.phoneNumber || "",
                                  }))
                                );
                                validation.setFieldValue("portingId", "");
                              } else {
                                validation.setFieldValue("phoneNumber", []);
                              }
                            }}
                            additional={{
                              page: 1,
                            }}
                          />
                        </Col>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="portingId">
                            Porting Id
                          </Form.Label>
                          <AsyncPaginate
                            key={
                              validation.values.phoneNumber
                                ?.map((dt: any) => dt.phoneNumber)
                                .toString() ||
                              validation.values.campaignId
                                ?.map((dt: any) => dt.campaignId)
                                .toString() ||
                              validation.values.brandId
                                ?.map((dt: any) => dt.brandId)
                                .toString() ||
                              subGroupId ||
                              groupDetails?.records?.[0]?.group_id ||
                              "portingId"
                            }
                            isClearable={true}
                            isSearchable={true}
                            styles={styleObj(
                              validation?.touched?.portingId &&
                                validation?.errors?.portingId
                            )}
                            theme={customSelectTheme}
                            value={
                              validation.values.portingId
                                ? { portingId: validation.values.portingId }
                                : null
                            }
                            loadOptions={asyncPortRequestList(
                              removeEmptyAndNullValues({
                                subGroupId: subGroupId || "",
                                brandId:
                                  validation.values.brandId.length > 0
                                    ? validation.values.brandId?.map(
                                        (dt: any) => dt.brandId
                                      )
                                    : "",
                                campaignId:
                                  validation.values.campaignId.length > 0
                                    ? validation.values.campaignId?.map(
                                        (dt: any) => dt.campaignId
                                      )
                                    : "",
                                phoneNumber:
                                  validation.values.phoneNumber.length > 0
                                    ? validation.values.phoneNumber?.map(
                                        (dt: any) => dt.phoneNumber
                                      )
                                    : "",
                              }),
                              "phoneNumber"
                            )}
                            getOptionValue={(option: any) => option?.portingId}
                            getOptionLabel={(option: any) => option?.portingId}
                            onChange={(option: any) => {
                              if (
                                option &&
                                option.portingId === validation.values.portingId
                              ) {
                                return;
                              }

                              if (option) {
                                validation.setFieldValue(
                                  "brandId",
                                  [option].reduce(
                                    (acc: any[], dt: any) => {
                                      const existingItem =
                                        validation.values.brandId.find(
                                          (item: any) =>
                                            item.brandId === dt.brandId
                                        );
                                      if (!existingItem) {
                                        acc.push({
                                          brandId: dt?.brandId || "",
                                        });
                                      }
                                      return acc;
                                    },
                                    [...validation.values.brandId]
                                  )
                                );
                                validation.setFieldValue(
                                  "campaignId",
                                  [option].reduce(
                                    (acc: any[], dt: any) => {
                                      const existingItem =
                                        validation.values.campaignId.find(
                                          (item: any) =>
                                            item.campaignId === dt.campaignId &&
                                            item.brandId === dt.brandId
                                        );
                                      if (!existingItem) {
                                        acc.push({
                                          brandId: dt?.brandId || "",
                                          campaignId: dt.campaignId || "",
                                        });
                                      }
                                      return acc;
                                    },
                                    [...validation.values.campaignId]
                                  )
                                );
                                // validation.setFieldValue(
                                //   "phoneNumber",
                                //   option.phoneNumber
                                // );
                                validation.setFieldValue(
                                  "portingId",
                                  option.portingId
                                );
                              } else {
                                validation.setFieldValue("portingId", "");
                              }
                            }}
                            additional={{
                              page: 1,
                            }}
                          />
                        </Col>
                      </Row>
                      <div className="d-flex align-items-center justify-content-end">
                        <Button
                          className="btn btn-secondary me-2"
                          type="button"
                          onClick={() => {
                            validation.resetForm();
                            setActiveFilters({
                              brandId: [],
                              campaignId: [],
                              phoneNumber: [],
                              portingId: "",
                            });
                            setSearchParams(
                              {
                                ...Object.fromEntries(searchParams.entries()),
                                activeFilters: "",
                              },
                              {
                                replace: true,
                              }
                            );
                            setDatePickerModal(false);
                          }}
                        >
                          Clear
                        </Button>
                        <Button className="btn btn-primary" type="submit">
                          Apply
                        </Button>
                      </div>
                    </Form>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            }
            isBack={true}
            backClick={() => props.router?.navigate(-1)}
          />
          {loading3 ? (
            <div className="position-relative" style={{ height: 200 }}>
              <img
                src={Loader}
                className={`position-absolute top-50 start-50 translate-middle`}
                alt="loading"
              />
            </div>
          ) : (
            <Card>
              <Card.Header className="d-sm-flex align-items-center justify-content-between">
                <h6 className="card-title mb-2"></h6>
                <p className="mb-0 fs-md">
                  <span className="text-secondary me-1 fw-semibold">
                    Created:
                  </span>
                  {dateFormat(
                    subgroupDetails?.data?.subGroupData?.[0]?.created_datetime
                  )}
                </p>
              </Card.Header>
              <Card.Body>
                <Tab.Container defaultActiveKey="10dlc">
                  <Nav
                    as="ul"
                    variant="tabs"
                    className="nav-justified nav-border-top nav-border-top-primary mb-4"
                    role="tablist"
                  >
                    <Nav.Item as="li">
                      <Nav.Link eventKey="10dlc">
                        {/* <i className="fs-xl ri-phone-fill align-middle me-2" /> */}
                        10DLC
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link disabled={true} eventKey="rcs"></Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link disabled={true} eventKey="toll-free"></Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link
                        disabled={true}
                        eventKey="short-code"
                      ></Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link disabled={true} eventKey="voice"></Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="10dlc">
                      <DLC
                        searchTerm={searchTerm}
                        filterValues={activeFilters}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="rcs">RCS</Tab.Pane>
                    <Tab.Pane eventKey="toll-free">Toll Free</Tab.Pane>
                    <Tab.Pane eventKey="short-code">Short Code</Tab.Pane>
                    <Tab.Pane eventKey="voice">Voice</Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Card.Body>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SubGroupDetails);
