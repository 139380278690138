import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { getNumberHistoryThunk } from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Loader from "assets/images/spinner-dark.svg";
import BreadCrumb from "Common/BreadCrumb";
import { dateFormat } from "helpers/common";
import { getNumberHistorySuccessful } from "slices/active-phone-number/reducer";
import { subgroupListApi } from "helpers/apiroute_helper";

const NumberHistory = (props: any) => {
  document.title = "Signal House Portal Messaging Logs";

  const dispatch = useDispatch<any>();
  const containerRef: any = useRef(null);

  const selectProfile = createSelector(
    (state: any) => state.OwnNumbers,
    (ownNumbers) => ({
      loading: ownNumbers.loading,
      numberHistoryData: ownNumbers.numberHistoryData,
    })
  );

  const { loading, numberHistoryData } = useSelector(selectProfile);
  const [data, setData] = useState<any>([]);
  const [isInit, setIsInit] = useState(false);
  const [subgroupLinks, setSubgroupLinks] = useState<any>({});

  useEffect(() => {
    setIsInit(true);
    fetchMoreData();

    return () => {
      dispatch(getNumberHistorySuccessful({}));
    };
  }, []);

  useEffect(() => {
    if (
      numberHistoryData?.totalRecords &&
      data?.length < numberHistoryData?.totalRecords
    ) {
      setData(
        numberHistoryData?.page && numberHistoryData?.page !== 1
          ? (prev: any) => [...prev, ...(numberHistoryData?.data || [])]
          : numberHistoryData?.data || []
      );
    }
    isInit && setIsInit(false);
  }, [numberHistoryData]);

  useEffect(() => {
    if (
      containerRef?.current &&
      containerRef?.current?.scrollHeight <= containerRef?.current?.clientHeight
    ) {
      fetchMoreData();
    }

    const fetchSubgroupLinks = async () => {
      const links: { [key: string]: string } = {};
      for (const dt of data) {
        if (dt?.metadata?.subGroupId) {
          links[dt.metadata.subGroupId] = await asyncSubgroupLink(
            dt.metadata.subGroupId
          );
        }
      }
      setSubgroupLinks(links);
    };

    fetchSubgroupLinks();
  }, [data]);

  const fetchMoreData = async () => {
    if (loading) return;

    dispatch(
      getNumberHistoryThunk(
        props.phoneNumber,
        !isInit && numberHistoryData?.page ? numberHistoryData?.page + 1 : 1,
        10
      )
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef?.current) return;
      const { scrollTop, scrollHeight, clientHeight } = containerRef?.current;

      if (
        scrollTop + clientHeight >= scrollHeight - 5 &&
        data?.length &&
        numberHistoryData?.totalRecords &&
        data?.length < numberHistoryData?.totalRecords
      ) {
        fetchMoreData();
      }
    };

    const container = containerRef?.current;
    container?.addEventListener("scroll", handleScroll);

    return () => container?.removeEventListener("scroll", handleScroll);
  }, [fetchMoreData]);

  const asyncSubgroupLink = async (subGroupId: string) => {
    try {
      let data: any = await subgroupListApi(1, 1, {
        subGroupId,
      });

      return !data?.code && !data?.errorCode
        ? `/groups/${data?.records?.[0]?.group_id}/subgroups/${
            data?.records?.[0]?.sub_group_id
          }/${data?.records?.[0]?.sub_group_name
            .replace(/\s+/g, "-")
            .toLowerCase()}`
        : "#";
    } catch (error: any) {
      return "#";
    }
  };

  return (
    <React.Fragment>
      <Container fluid>
        <BreadCrumb title="Number History" />

        <div className="listing-table">
          <div className="rdt_TableBody" ref={containerRef}>
            {!isInit ? (
              data?.length > 0 ? (
                <ul className="acitivity-timeline-2 list-unstyled mb-0">
                  {data?.map((dt: any, i: number) => (
                    <li key={i}>
                      <p className="text-primary mb-2">
                        {dateFormat(dt?.createdAt, "MM/DD/YYYY, hh:mm:ss A")}
                      </p>
                      <p
                        className="fs-md mb-0"
                        style={{ lineHeight: "28px" }}
                        dangerouslySetInnerHTML={{
                          __html:
                            dt?.action
                              ?.replaceAll(
                                props.phoneNumber,
                                `<span class="details-tag m-0" style="display: inline;">${props.phoneNumber}</span>`
                              )
                              ?.replaceAll(
                                dt?.metadata?.subGroupId,
                                dt?.metadata?.subGroupId
                                  ? `<span class="details-tag m-0" style="display: inline;"><a href=${
                                      subgroupLinks[dt.metadata.subGroupId] ||
                                      "#"
                                    }>${dt?.metadata?.subGroupId}</a></span>`
                                  : dt?.metadata?.subGroupId
                              )
                              ?.replaceAll(
                                dt?.metadata?.brandId,
                                `<span class="details-tag m-0" style="display: inline;"><a href="/brands/${dt?.metadata?.brandId}">${dt?.metadata?.brandId}</a></span>`
                              )
                              ?.replaceAll(
                                dt?.metadata?.campaignId,
                                `<span class="details-tag m-0" style="display: inline;"><a href="${
                                  dt?.metadata?.campaignId?.startsWith("C")
                                    ? `/campaigns/${dt?.metadata?.campaignId}`
                                    : `/campaigns-partner/${dt?.metadata?.campaignId}`
                                }">${dt?.metadata?.campaignId}</a></span>`
                              ) || "",
                        }}
                      />
                      {/* <p className="mb-0">Product noise evolve smartwatch</p> */}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>There are no records to display</p>
              )
            ) : null}
            {loading && <img src={Loader} />}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default NumberHistory;
