import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import { capitalizeString, dateFormat } from "helpers/common";
import {
  getPortingStatusLogsThunk,
  getFileThunk,
  getSubgroupDetailsThunk,
  getBrandDetailsThunk,
} from "slices/thunk";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import Loader from "assets/images/spinner-dark.svg";
import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import { Document, Page, pdfjs } from "react-pdf";
import Datatable from "Common/Datatable";

const PortingStatusLogs = (props: any) => {
  document.title = "Signal House Portal Messaging Details";
  const [load, setLoad] = useState(false);
  const { portingId } = useParams();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get("groupId");
  const subGroupId = searchParams.get("subGroupId");
  const subgroupnames = searchParams.get("subgroupnames");
  const containerRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch<any>();

  const detailData = useSelector(
    (state: any) => state.Ports?.portingStatusData
  );
  const selectProfile = createSelector(
    (state: any) => state.Groups,
    (state: any) => state.Brands,
    (state: any) => state.Ports,
    (groups, brands, ports) => ({
      subgroupDetails: groups.subgroupDetails,
      brandDetails: brands.brandDetails,
      loading: ports.loading,
      error: ports.error,
    })
  );

  const { subgroupDetails, brandDetails, loading } = useSelector(selectProfile);

  const [prevSrc, setPrevSrc] = useState("");
  const [isOpenDocLightBox, setIsOpenDocLightBox] = useState(false);
  // const [containerDimensions, setContainerDimensions] = useState({
  //   width: 0,
  //   height: 0,
  // });
  const [pageDimensions, setPageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [file, setFile] = useState<File | null>(null);
  const [numPages, setNumPages] = useState<number | null>(null);

  const getStatus = (cell: { status: string; type?: any }) => {
    if (!cell.status) {
      return cell.type === "color" ? (
        "bg-success-subtle"
      ) : (
        <span className="badge text-success fs-sm">
          {capitalizeString(cell.status)}
        </span>
      );
    }

    switch (cell.status.toLowerCase()) {
      case "failed":
        return cell.type === "color" ? (
          "bg-primary-subtle"
        ) : (
          <span className="badge text-primary fs-sm">
            {capitalizeString(cell.status)}
          </span>
        );
      case "success":
        return cell.type === "color" ? (
          "bg-secondary-subtle"
        ) : (
          <span className="badge text-secondary fs-sm">
            {capitalizeString(cell.status)}
          </span>
        );
      default:
        return cell.type === "color" ? (
          "bg-warning-subtle"
        ) : (
          <span className="badge text-warning fs-sm">
            {capitalizeString(cell.status || "-")}
          </span>
        );
    }
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Date</span>,
      minWidth: "220px",
      selector: (row: { date: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-calendar3 me-2 fs-lg text-secondary" />
          {dateFormat(row.date, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        return new Date(rowA.date).getTime() - new Date(rowB.date).getTime();
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      minWidth: "150px",
      selector: (cell: { status: string; description: string }) => {
        switch (cell.status.toLowerCase()) {
          case "failed":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-primary border border-primary text-primary text-start fs-sm"
              >
                {capitalizeString(cell.status)}
              </span>
            );
          case "success":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary text-secondary text-start fs-sm"
              >
                {capitalizeString(cell.status)}
              </span>
            );
          case "pending":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge border text-start fs-sm"
                style={{
                  borderColor: "#6c757d",
                  background: "#d3d3d3e0",
                  color: "#6c757d",
                }}
              >
                {capitalizeString(cell.status)}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-warning text-warning text-start fs-sm"
              >
                {capitalizeString(cell.status)}
              </span>
            );
        }
      },
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        return rowA.status.localeCompare(rowB.status);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Description</span>,
      minWidth: "250px",
      selector: (row: { description: string }) => (
        <React.Fragment>
          <div className="d-flex align-items-start mb-2">{row.description}</div>
        </React.Fragment>
      ),
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        return rowA.description.localeCompare(rowB.description);
      },
    },
  ];

  useEffect(() => {
    if (portingId) {
      dispatch(
        getPortingStatusLogsThunk(portingId, {
          page: 1,
          recordsPerPage: 10,
        })
      );
    }
  }, [portingId]);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

    const handleResize = () => {
      if (containerRef.current) {
        console.log(
          containerRef.current.clientWidth,
          containerRef.current.clientHeight
        );
        // setContainerDimensions({
        //   width: containerRef.current.clientWidth - 50,
        //   height: containerRef.current.clientHeight - 100,
        // });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    detailData.records?.[0]?.subGroupId?.[0] &&
      dispatch(getSubgroupDetailsThunk(detailData.records?.[0]?.subGroupId));
    detailData.records?.[0]?.brandId &&
      dispatch(getBrandDetailsThunk(detailData.records?.[0]?.brandId));
  }, [detailData.records?.[0]]);

  useEffect(() => {
    if (detailData?.records?.[0]?.letterOfAuthorization) {
      const cb = (img: any) => {
        setFile(img);
      };
      dispatch(
        getFileThunk(detailData?.records?.[0]?.letterOfAuthorization, cb)
      );
    }
  }, [detailData?.records?.[0]?.letterOfAuthorization]);

  const handleViewLoa = (loadId: string) => {
    setLoad(true);
    const cb = (img: any) => {
      const temp2 = URL.createObjectURL(img);
      setLoad(false);
      window.open(temp2, "_blank");
      // setIsOpenDocLightBox(true);
    };
    dispatch(getFileThunk(loadId, cb));
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const onPageLoadSuccess = useCallback((page: any) => {
    const viewport = page.getViewport({ scale: 1 });
    const aspectRatio = viewport.width / viewport.height;
    const containerWidth = containerRef.current
      ? containerRef.current.clientWidth
      : 0;
    const containerHeight = containerRef.current
      ? containerRef.current.clientHeight
      : 0;
    const width = containerWidth;
    const height = width / aspectRatio;

    if (height > containerHeight) {
      const scale = containerHeight / height;
      setPageDimensions({
        width: width * scale,
        height: height * scale,
      });
    } else {
      setPageDimensions({
        width: width,
        height: height,
      });
    }
  }, []);

  const statusHistory = detailData?.records?.[0]?.statusHistory;
  const letestStatus = statusHistory?.[statusHistory?.length - 1];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {isOpenDocLightBox && (
            <div
              className="position-fixed top-0 end-0 bottom-0 start-0"
              style={{
                zIndex: 1000,
                // backgroundColor: "rgba(0,0,0,0.5)",
                backgroundColor: "white",
              }}
            >
              <div
                className="position-absolute text-white cursor-pointer"
                style={{
                  zIndex: 1000,
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  borderRadius: "50%",
                  padding: "5px 10px",
                  right: 10,
                  top: 40,
                }}
              >
                <i
                  className="ri-close-circle-fill cursor-pointer fs-5xl text-white"
                  onClick={() => {
                    setIsOpenDocLightBox(false);
                    setPrevSrc("");
                  }}
                />
              </div>
              <iframe
                id="iframe"
                title={prevSrc}
                src={prevSrc}
                height="100%"
                width="100%"
                style={{ background: "rgba(0, 0, 0, 0.6)" }}
              />
            </div>
          )}
          <BreadCrumb
            title="Porting Details"
            isBack={true}
            backClick={() =>
              props.router?.navigate(
                subGroupId
                  ? `/groups/${groupId}/subgroups/${subGroupId}/${subgroupnames}`
                  : -1
              )
            }
          />
          <div className="position-relative">
            {loading ? (
              <div className="position-relative" style={{ height: 200 }}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <Row>
                <Col xl={8}>
                  <Card>
                    <Card.Header>
                      <div className="d-flex justify-content-between">
                        <div>
                          <h6 className="card-title">Porting Updates</h6>
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Body className="position-relative portingwrapper">
                      <Row>
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">Porting ID:</h6>
                          <p className="mb-0">
                            {detailData.records?.[0]?.portingId || "-"}
                          </p>
                        </Col>
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">Created By:</h6>
                          <p className="mb-0">
                            {detailData.records?.[0]?.createdBy || "-"}
                          </p>
                        </Col>
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">Porting RequestType:</h6>
                          <p className="mb-0">
                            {detailData.records?.[0]?.portingRequestType || "-"}
                          </p>
                        </Col>
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">Created Date:</h6>
                          <p className="mb-0">
                            {dateFormat(
                              detailData.records?.[0]?.createdDate,
                              "MM/DD/YYYY, hh:mm:ss A"
                            )}
                          </p>
                        </Col>
                      </Row>
                      <div className="mb-1">
                        <h5>Owner's Account Information</h5>
                      </div>
                      <Col lg={12} className="my-3">
                        <Row className="border bg-light-gray  rounded-3 p-3">
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Owners Name:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.ownersName || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Owners Email:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.ownersEmail || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Owners Street Address:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.ownersStreetAddress ||
                                "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Owners Extended Address:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.ownersExtendedAddress ||
                                "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Owners City:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.ownersCity || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Owners State:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.ownersState || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Owners Country:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.ownersCountry || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Owners Postal Code:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.ownersPostalCode || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3 mb-md-0">
                            <h6 className="mb-1">Authorized Persons Name:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.authorizedPersonsName ||
                                "-"}
                            </p>
                          </Col>
                          <Col md={6}>
                            <h6 className="mb-1">Phone Number:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.phoneNumber || "-"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <div className="mb-1">
                        <h5>Service Address</h5>
                      </div>
                      <Col lg={12} className="my-3">
                        <Row className="border bg-light-gray  rounded-3 p-3">
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Service Address:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.serviceAddress || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Service City:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.serviceCity || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Service State:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.serviceState || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Service Country:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.serviceCountry || "-"}
                            </p>
                          </Col>
                          <Col md={6}>
                            <h6 className="mb-1">Service Postal Code:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.servicePostalCode ||
                                "-"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <div className="mb-1">
                        <h5>Phone Number(s) to port</h5>
                      </div>
                      <Col lg={12} className="my-3">
                        <Row className="border bg-light-gray  rounded-3 p-3">
                          <Col lg={12}>
                            <h6 className="mb-1">Phone Number(s):</h6>
                            <div className="d-flex flex-wrap mt-2">
                              {detailData.records?.[0]?.phoneNumberToBePorted?.map(
                                (number: any, index: any) => {
                                  return (
                                    <span
                                      className="details-tag mt-1 mr-2 d-flex align-items-center"
                                      key={index}
                                    >
                                      {number}
                                    </span>
                                  );
                                }
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <div className="mb-1">
                        <h5>Advanced Settings Details</h5>
                      </div>
                      <Col lg={12} className="my-3">
                        <Row className="border bg-light-gray rounded-3 p-3">
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Group Id:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.groupId || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Sub Groups:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.subGroupId?.length > 0
                                ? detailData.records?.[0]?.subGroupId.map(
                                    (dt: string, index: number) => (
                                      <span key={index} className="me-1 mb-1">
                                        {dt} -{" "}
                                        {subgroupDetails?.data?.subGroupData?.find(
                                          (dt2: any) => dt2?.sub_group_id === dt
                                        )?.sub_group_name || ""}
                                      </span>
                                    )
                                  )
                                : "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Brand Id:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.brandId || "-"} -{" "}
                              {brandDetails?.displayName || ""}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Campaign Id:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.campaignId || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Cell Number or Landline:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.CellNumberOrLandline ||
                                "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Account Number:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.accountNumber || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">
                              Port No Associated With Acc:
                            </h6>
                            <p className="mb-0">
                              {detailData.records?.[0]
                                ?.portNoAssociatedWithAcc || "-"}
                            </p>
                          </Col>
                          <Col md={12}>
                            <h6 className="mb-1">Comments:</h6>
                            <p className="mb-0">
                              {detailData.records?.[0]?.comments || "-"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      {/* <Row>
                        <Col md={6}>
                          <h6 className="mb-1">Porting Status:</h6>
                          <p className="mb-0">
                            {detailData.records?.[0]?.portingStatus || "-"}
                          </p>
                        </Col>
                      </Row> */}
                      <div className="mb-1">
                        <h5>Porting Status Logs</h5>
                      </div>
                      <Col lg={12} className="my-3">
                        <Row className="border bg-light-gray  rounded-3 p-3">
                          <Col md={12} className="">
                            <Datatable
                              data={
                                detailData?.records?.length
                                  ? detailData?.records[0]?.statusHistory
                                  : []
                              }
                              pagination={false}
                              columns={columns}
                              totalRecords={detailData?.totalRecords}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xl={4}>
                  <Card
                    className={`${
                      detailData.records?.[0]?.portingStatus?.toLowerCase() ===
                      "pending"
                        ? ""
                        : getStatus({
                            status: detailData.records?.[0]?.portingStatus,
                            type: "color",
                          })
                    }`}
                    style={
                      detailData.records?.[0]?.portingStatus?.toLowerCase() ===
                      "pending"
                        ? {
                            background: "#d3d3d3e0",
                            height: "auto",
                            overflow: "auto",
                          }
                        : { height: "auto", overflow: "auto" }
                    }
                  >
                    <Card.Header
                      className={`${
                        detailData.records?.[0]?.portingStatus?.toLowerCase() ===
                        "pending"
                          ? ""
                          : getStatus({
                              status: detailData.records?.[0]?.portingStatus,
                              type: "color",
                            })
                      }`}
                      style={
                        detailData.records?.[0]?.portingStatus?.toLowerCase() ===
                        "pending"
                          ? {
                              background: "#d3d3d3e0",
                            }
                          : {}
                      }
                    >
                      <h6 className="card-title">Porting Updates</h6>
                    </Card.Header>
                    <Card.Body className="">
                      <Table className="table-responsive pt-2 mb-0">
                        <tbody>
                          <tr>
                            <td className={`d-flex flex-column border-0`}>
                              <div className="d-flex align-items-center justify-content-between mb-2">
                                <div className="flex-shrink-0">
                                  <span className="d-flex align-items-center">
                                    <i className="bi bi-calendar3 me-2 fs-lg text-secondary" />
                                    {dateFormat(
                                      letestStatus?.date,
                                      "MM/DD/YYYY, hh:mm:ss A"
                                    )}
                                  </span>
                                </div>
                                <span
                                  className={(() => {
                                    switch (
                                      letestStatus?.status?.toLowerCase()
                                    ) {
                                      case "failed":
                                        return "badge bg-primary-subtle text-primary text-start fs-sm";
                                      case "success":
                                        return "badge bg-secondary-subtle text-secondary text-start fs-sm";
                                      case "pending":
                                        return "badge text-start fs-sm";
                                      default:
                                        return "badge bg-warning-subtle text-warning text-start fs-sm";
                                    }
                                  })()}
                                  style={(() => {
                                    switch (
                                      letestStatus?.status?.toLowerCase()
                                    ) {
                                      case "pending":
                                        return {
                                          background: "#d3d3d3e0",
                                          color: "#6c757d",
                                        };
                                      default:
                                        return {};
                                    }
                                  })()}
                                >
                                  {capitalizeString(letestStatus?.status)}
                                </span>
                              </div>
                              <p className="text-muted mb-0">
                                {letestStatus?.description || "-"}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Header className="d-flex align-items-center justify-content-between">
                      <h6 className="card-title">LOA Document</h6>
                      <Button
                        disabled={load}
                        variant="primary"
                        onClick={() =>
                          handleViewLoa(
                            detailData.records?.[0]?.letterOfAuthorization
                          )
                        }
                      >
                        View LOA
                      </Button>
                    </Card.Header>
                    <Card.Body
                      className="position-relative portingwrapper2 pdf-container"
                      ref={containerRef}
                    >
                      {file && (
                        <Document
                          file={file}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          {Array.from(new Array(numPages), (el, index) => (
                            <Page
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                              // width={containerDimensions.width}
                              // height={100}
                              onLoadSuccess={onPageLoadSuccess}
                              // height={containerDimensions.height}
                            />
                          ))}
                        </Document>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PortingStatusLogs);
