import {
  getDashboardAnalyticsApi,
  getUsageSummaryApi,
  getSummaryInvoiceApi,
  getUsageTransactionsApi,
  getFileApi,
} from "helpers/apiroute_helper";
import {
  apiLoading,
  apiLoading2,
  apiLoading3,
  usageSummaryDataSuccessful,
  usageSummaryDataFailed,
  summaryInvoiceSuccessful,
  summaryInvoiceFailed,
  usageSummaryDashboardDataSuccessful,
  usageSummaryDashboardDataFailed,
  usageTransactionsDataSuccessful,
  usageTransactionsDataFailed,
  dashboardAnalyticsDataSuccessful,
  dashboardAnalyticsDataFailed,
  setSubTransactionLoading,
  setSubTransactionData,
} from "./reducer";
import { toast } from "react-toastify";

export const getUsageSummaryThunk =
  (startDate?: string, endDate?: string) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await getUsageSummaryApi(startDate, endDate);

      if (data?.code || data?.message) {
        dispatch(usageSummaryDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(usageSummaryDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(usageSummaryDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getSummaryInvoiceThunk =
  (payload: any) => async (dispatch: any) => {
    dispatch(apiLoading2());

    try {
      const data: any = await getSummaryInvoiceApi(payload);

      if (data?.code || data?.message) {
        dispatch(summaryInvoiceFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        if (data?.fileUrl) {
          try {
            const data2: any = await getFileApi(
              encodeURIComponent(data?.fileUrl)
            );

            if (data2) {
              dispatch(summaryInvoiceSuccessful(data));
              const temp = new File(
                [data2],
                `file.${data2?.type?.split("/").pop()}`,
                {
                  type:
                    data2?.type === "text/xml"
                      ? "application/pdf"
                      : data2?.type,
                }
              );
              window.open(URL.createObjectURL(temp), "_blank");
            } else {
              dispatch(
                summaryInvoiceFailed({ message: "Something went wrong" })
              );
              toast.error("Something went wrong");
            }
          } catch (error: any) {
            dispatch(summaryInvoiceFailed(error));
            toast.error(
              error?.response?.data?.message || "Something went wrong"
            );
          }
        }
      }
    } catch (error: any) {
      dispatch(summaryInvoiceFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getUsageSummaryDashboardThunk =
  (startDate?: string, endDate?: string) => async (dispatch: any) => {
    dispatch(apiLoading3());

    try {
      const data: any = await getUsageSummaryApi(startDate, endDate);

      if (data?.code || data?.message) {
        dispatch(usageSummaryDashboardDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(usageSummaryDashboardDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(usageSummaryDashboardDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getUsageTransactionsThunk =
  (
    serviceType?: string,
    startDate?: string,
    endDate?: string,
    page?: number,
    limit?: number
  ) =>
  async (dispatch: any) => {
    dispatch(apiLoading2());

    try {
      const data: any = await getUsageTransactionsApi(
        serviceType,
        startDate,
        endDate,
        page,
        limit
      );
      if (data?.code || data?.message) {
        dispatch(usageTransactionsDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(usageTransactionsDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(usageTransactionsDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getDashboardAnalyticsThunk =
  (otherParams?: any) => async (dispatch: any) => {
    dispatch(apiLoading3());

    try {
      const data: any = await getDashboardAnalyticsApi(otherParams);

      dispatch(dashboardAnalyticsDataSuccessful(data?.data));
    } catch (error: any) {
      dispatch(dashboardAnalyticsDataFailed(error));
      // toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getSubTransactionDataThunk = 
  (mainCategory: string, subCategory: string, startDate: string, endDate: string) =>
  async (dispatch: any) => {
    const serviceType = `${mainCategory}.${subCategory}`.toLowerCase();
    
    dispatch(setSubTransactionLoading({ key: serviceType, loading: true }));
    
    try {
      const data: any = await getUsageTransactionsApi(
        serviceType,
        startDate,
        endDate,
        1,
        50
      );
      
      if (data?.code || data?.message) {
        dispatch(usageTransactionsDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(setSubTransactionData({ 
          key: serviceType, 
          data: {
            ...data,
            activeKey: subCategory
          }
        }));
      }
    } catch (error: any) {
      dispatch(usageTransactionsDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      dispatch(setSubTransactionLoading({ key: serviceType, loading: false }));
    }
  };
