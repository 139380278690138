import React, { useEffect } from "react";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import { useDispatch, useSelector } from "react-redux";
import { getMarketPlaceListThunk } from "slices/thunk";
import { createSelector } from "reselect";
import Loader from "assets/images/spinner-dark.svg";

const MarketPlaceList = (props: any) => {
  document.title = "Signal House Portal Help Center";
  const dispatch = useDispatch<any>();

  const selectProfile = createSelector(
    (state: any) => state.MarketPlace,
    (marketPlace) => ({
      loading: marketPlace.loading,
      marketPlacesData: marketPlace.marketPlacesData,
    })
  );

  const { loading, marketPlacesData } = useSelector(selectProfile);

  useEffect(() => {
    dispatch(getMarketPlaceListThunk());
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Marketplace" />
          <div className="position-relative wrapper p-3">
            {loading ? (
              <div>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                />
              </div>
            ) : (
              <Row className="row-cols-xl-4 row-cols-sm-2 row-cols-1">
                {marketPlacesData?.length > 0
                  ? marketPlacesData?.map((dt: any, i: number) => (
                      <Col key={i}>
                        <Card className="card-animate text-center bg-secondary-subtle border border-secondary-subtle">
                          <Card.Body className="p-4">
                            <div className="avatar-md mx-auto mb-4">
                              <div className="avatar-title bg-white rounded">
                                <img
                                  src={`${dt?.appIcon}`}
                                  alt=""
                                  className="avatar-sm"
                                />
                              </div>
                            </div>
                            <h5 className="text-truncate">
                              {dt?.title || "-"}
                            </h5>
                            <p className="text-muted mb-3">
                              {dt?.description || "-"}
                            </p>
                            <Button
                              onClick={() => {
                                window.open(
                                  dt?.status?.toLowerCase() === "installed"
                                    ? dt?.appUrl
                                    : dt?.installUrl || "#",
                                  "_blank"
                                );
                              }}
                            >
                              {dt?.status?.toLowerCase() === "installed"
                                ? "Go to app"
                                : "Connect"}
                            </Button>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))
                  : null}
              </Row>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(MarketPlaceList);
