import { Component } from "react";
import Img1 from "../../Common/svg/amex";
import Img2 from "../../Common/svg/diners-club";
import Img3 from "../../Common/svg/discover";
import Img4 from "../../Common/svg/jcb";
import Img5 from "../../Common/svg/mastercard";
import Img6 from "../../Common/svg/visa";
import { Form } from "react-bootstrap";

const accountId = process.env.REACT_APP_TILLED_ACCOUNT_ID;
const publishableKey = process.env.REACT_APP_TILLED_PUBLISHABLE_KEY;

interface CardElementProps {
  tilled: any;
  setIsDisable: (disabled: boolean) => void;
}

interface CardElementState {
  cardType: string;
  isCardBlur: boolean;
  isCardValid: boolean;
  isDateBlur: boolean;
  isDateValid: boolean;
  isCvvBlur: boolean;
  isCvvValid: boolean;
}

class CardElement extends Component<CardElementProps, CardElementState> {
  private script: HTMLScriptElement | null = null;
  private cardForm: any = null;

  state = {
    cardType: "",
    isCardBlur: false,
    isCardValid: false,
    isDateBlur: false,
    isDateValid: false,
    isCvvBlur: false,
    isCvvValid: false,
  };

  componentDidMount() {
    this.cleanup();
    this.loadTilledScript();
  }

  componentWillUnmount() {
    this.cleanup();
  }

  private loadTilledScript = () => {
    let date = Date.now();
    // Remove any existing Tilled script
    const existingScript = document.querySelector(
      `script[src="https://js.tilled.com/v2"]`
    );
    if (existingScript) {
      existingScript.remove();
    }

    // Create and load fresh script
    this.script = document.createElement("script");
    this.script.src = `https://js.tilled.com/v2?v=${date}`;
    this.script.async = true;

    this.script.onload = () => {
      this.initializeTilled();
    };

    document.body.appendChild(this.script);
  };

  private cleanup = async () => {
    (window as any).Tilled = undefined;
    // Cleanup card form
    if (this.cardForm) {
      try {
        await this.cardForm.teardown();
      } catch (error) {
        console.error("Error during form teardown:", error);
      }
      this.cardForm = null;
    }

    // Remove script tag
    if (this.script && document.body.contains(this.script)) {
      document.body.removeChild(this.script);
    }

    // Reset Tilled instance
    if (this.props.tilled.current) {
      this.props.tilled.current = null;
    }
  };

  private initializeTilled = async () => {
    try {
      this.props.setIsDisable(true);

      this.props.tilled.current = new (window as any).Tilled(
        publishableKey,
        accountId,
        {
          sandbox: process.env.REACT_APP_PROD === "true" ? false : true,
          log_level: 0,
        }
      );

      const fieldOptions = {
        styles: {
          base: {
            fontFamily: "Helvetica Neue, Arial, sans-serif",
            color: "#757575",
            fontWeight: "400",
            fontSize: "16px",
          },
          invalid: {
            ":hover": {
              textDecoration: "underline dotted #EA4628",
              color: "#9e2146",
            },
            ":focus": {
              textDecoration: "underline dotted #EA4628",
            },
          },
          valid: {
            color: "#59C288",
          },
        },
      };

      this.cardForm = await this.props.tilled.current.form({
        payment_method_type: "card",
      });

      const cardNumberElement = {
        ...fieldOptions,
        placeholder: "Card Number",
      };

      const cardCvvElement = {
        ...fieldOptions,
        placeholder: "CVV",
      };

      this.cardForm
        .createField("cardNumber", cardNumberElement)
        .inject("#card-number-element");
      this.cardForm
        .createField("cardExpiry", fieldOptions)
        .inject("#card-expiration-element");
      this.cardForm
        .createField("cardCvv", cardCvvElement)
        .inject("#card-cvv-element");

      await this.cardForm.build();

      this.setupEventListeners();
    } catch (error) {
      console.error("Error initializing Tilled:", error);
    }
  };

  private setupEventListeners = () => {
    this.cardForm.on("validation", () =>
      this.props.setIsDisable(this.cardForm?.invalid)
    );

    this.cardForm.fields.cardNumber.on("change", (evt: any) => {
      this.setState({
        cardType: evt?.brand,
        isCardValid: evt?.valid,
      });
    });
    this.cardForm.fields.cardNumber.on("blur", () =>
      this.setState({ isCardBlur: true })
    );

    this.cardForm.fields.cardExpiry.on("change", (evt: any) =>
      this.setState({ isDateValid: evt?.valid })
    );
    this.cardForm.fields.cardExpiry.on("blur", () =>
      this.setState({ isDateBlur: true })
    );

    this.cardForm.fields.cardCvv.on("change", (evt: any) =>
      this.setState({ isCvvValid: evt?.valid })
    );
    this.cardForm.fields.cardCvv.on("blur", () =>
      this.setState({ isCvvBlur: true })
    );
  };

  render() {
    const {
      cardType,
      isCardBlur,
      isCardValid,
      isDateBlur,
      isDateValid,
      isCvvBlur,
      isCvvValid,
    } = this.state;

    return (
      <>
        <div className="mb-3 position-relative">
          <Form.Label htmlFor="card-number-element">
            Card number <span className="text-primary">*</span>
          </Form.Label>
          <div
            className={`form-control ${
              isCardBlur && !isCardValid ? "border-primary" : ""
            }`}
            id="card-number-element"
          ></div>
          <div
            className={`position-absolute credit-card-icons ${
              !cardType || cardType === "unknown" ? "d-none" : ""
            }`}
            style={{ top: 33, right: 15 }}
          >
            {cardType === "amex" ? (
              <Img1 color="#59C288" />
            ) : cardType === "diners" ? (
              <Img2 color="#59C288" />
            ) : cardType === "discover" ? (
              <Img3 color="#59C288" />
            ) : cardType === "jcb" ? (
              <Img4 color="#59C288" />
            ) : cardType === "mastercard" ? (
              <Img5 color="#59C288" />
            ) : cardType === "visa" ? (
              <Img6 color="#59C288" />
            ) : null}
          </div>
          {isCardBlur && !isCardValid ? (
            <Form.Control.Feedback type="invalid" className="d-flex">
              Enter Valid Card Number
            </Form.Control.Feedback>
          ) : null}
        </div>
        <div className="d-flex justify-content-center mb-3">
          <div style={{ width: "50%", marginRight: 20 }}>
            <Form.Label htmlFor="card-expiration-element">
              Expiration <span className="text-primary">*</span>
            </Form.Label>
            <div
              className={`form-control ${
                isDateBlur && !isDateValid ? "border-primary" : ""
              }`}
              id="card-expiration-element"
            ></div>
            {isDateBlur && !isDateValid ? (
              <Form.Control.Feedback type="invalid" className="d-flex">
                Enter Valid Expiration
              </Form.Control.Feedback>
            ) : null}
          </div>
          <div style={{ width: "50%" }}>
            <Form.Label htmlFor="card-cvv-element">
              CVV <span className="text-primary">*</span>
            </Form.Label>
            <div
              className={`form-control ${
                isCvvBlur && !isCvvValid ? "border-primary" : ""
              }`}
              id="card-cvv-element"
            ></div>
            {isCvvBlur && !isCvvValid ? (
              <Form.Control.Feedback type="invalid" className="d-flex">
                Enter Valid CVV
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default CardElement;
