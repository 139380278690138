import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import CountUp from "react-countup";
import SmsAndMmsLogs from "./SmsAndMmsLogs";
import StackedColumn2 from "./StackedColumn2";
import LineChart from "./LineChart";
import Loader from "assets/images/spinner-dark.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios, { CancelTokenSource } from "axios";
import { createSelector } from "reselect";
import { removeEmptyAndNullValues } from "helpers/common";
import moment from "moment";
import {
  getAnalyticsInboundThunk,
  getAnalyticsOutboundThunk,
  getStatusLogsThunk,
} from "slices/thunk";

interface DataObject {
  [key: string]: {
    [key: string]: number;
  };
}

function mergeAndSum(
  a: DataObject,
  b: DataObject,
  dateGraph: boolean
): DataObject {
  const resulta: DataObject = {};
  for (const [key, value] of Object.entries(a)) {
    resulta[dateGraph ? moment(key).format("MM-DD-YYYY") : key] = value;
  }

  const resultb: DataObject = {};
  for (const [key, value] of Object.entries(b)) {
    resultb[dateGraph ? moment(key).format("MM-DD-YYYY") : key] = value;
  }

  const result: DataObject = { ...resulta };

  for (const [key, value] of Object.entries(resultb)) {
    if (result[key]) {
      // Merge and sum nested objects
      result[key] = {
        ...result[key],
        ...Object.fromEntries(
          Object.entries(value).map(([subKey, subValue]) => [
            subKey,
            (result[key][subKey] || 0) + subValue,
          ])
        ),
      };
    } else {
      // Add new keys from `b`
      result[key] = value;
    }
  }

  return result;
}

function convertSeconds(milliseconds: number, showString?: boolean) {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return showString
    ? hours
      ? `${hours} hour, ${minutes} minutes, and ${seconds} seconds`
      : `${minutes} minutes, and ${seconds} seconds`
    : `${hours}h:${minutes}m:${seconds}s`;
}

const CommonAnalytics = forwardRef((props: any, ref: any) => {
  const outboundRequestRef = useRef<CancelTokenSource | null>(null);
  const inboundRequestRef = useRef<CancelTokenSource | null>(null);
  const statusLogsRequestRef = useRef<CancelTokenSource | null>(null);

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const isSortClick = useRef(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const currPage = Number(searchParams.get("currPage") || 1);
  const rowsPerPage = Number(searchParams.get("rowsPerPage") || 10);
  const isSortAsc = searchParams.get("sortDir") || "asc";
  const sortColumn = searchParams.get("sortColumn") || "";

  const [cardObj, setCardObj] = useState<any>({});
  const [dateGraphObj, setDateGraphObj] = useState<any>({});
  const [numberGraphObj, setNumberGraphObj] = useState<any>({});
  const [graphType1, setGraphType1] = useState("Column");
  const [graphType2, setGraphType2] = useState("Column");
  const [graphLoading, setGraphLoading] = useState(false);

  const selectProfile = createSelector(
    (state: any) => state.Analytics,
    (state: any) => state.Groups,
    (state: any) => state.AuthUser,
    (analytics, groups, authUser) => ({
      loading: analytics.loading,
      loading2: analytics.loading2,
      analyticsAllbound: analytics.analyticsAllbound,
      statusLogs: analytics.statusLogs,
      error: analytics.error,
      groupDetails: groups?.AllGroups,
      authUser: authUser.authUser,
    })
  );

  const {
    loading,
    loading2,
    analyticsAllbound,
    statusLogs,
    authUser,
    groupDetails,
  } = useSelector(selectProfile);

  useEffect(() => {
    if (groupDetails?.records?.[0]?.group_id && !props.isInit) {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        currPage: String(1),
      });
      handleStatusGetData(1, rowsPerPage, props.filterObj);
      isSortClick.current = false;
    }
  }, [props.selectedSubType, sortColumn, isSortAsc]);

  useEffect(() => {
    if (Object.keys(analyticsAllbound)?.length) {
      const tempObj = {
        ...(analyticsAllbound?.inbound?.counts || {}),
        ...(analyticsAllbound?.outbound?.counts || {}),
      };
      setCardObj({ ...tempObj });

      const result = mergeAndSum(
        analyticsAllbound?.inbound?.details?.perDay || {},
        analyticsAllbound?.outbound?.details?.perDay || {},
        true
      );
      setDateGraphObj({ ...result });

      const result2 = mergeAndSum(
        analyticsAllbound?.inbound?.details?.perPhoneNumber || {},
        analyticsAllbound?.outbound?.details?.perPhoneNumber || {},
        false
      );
      setNumberGraphObj({ ...result2 });
    }
  }, [analyticsAllbound]);

  // Function to cancel previous requests
  const cancelPreviousRequests = useCallback(() => {
    if (outboundRequestRef.current) {
      outboundRequestRef.current.cancel(
        "Operation canceled due to new request."
      );
      outboundRequestRef.current = null;
    }
    if (inboundRequestRef.current) {
      inboundRequestRef.current.cancel(
        "Operation canceled due to new request."
      );
      inboundRequestRef.current = null;
    }
    if (statusLogsRequestRef.current) {
      statusLogsRequestRef.current.cancel(
        "Operation canceled due to new request."
      );
      statusLogsRequestRef.current = null;
    }
  }, []);

  // Add cleanup on unmount
  useEffect(() => {
    return () => {
      cancelPreviousRequests();
    };
  }, [cancelPreviousRequests]);

  const handleSort = useCallback(
    (column: any) => {
      isSortClick.current = true;
      if (sortColumn === column.fieldName) {
        setSearchParams({
          ...Object.fromEntries(searchParams.entries()),
          sortColumn: column.fieldName || "",
          sortDir: isSortAsc === "asc" ? "desc" : "asc",
        });
      } else {
        setSearchParams({
          ...Object.fromEntries(searchParams.entries()),
          sortColumn: column.fieldName || "",
          sortDir: "asc",
        });
      }
    },
    [sortColumn, isSortAsc, searchParams]
  );

  // Add loading handler for section changes
  const handleSectionChange = useCallback(
    async (subType: string, label: string) => {
      setGraphLoading(true);
      props.setSelectedSubType(subType);
      props.setSelectedSubTypeLabel(label);

      // Simulate loading delay
      await new Promise((resolve) => setTimeout(resolve, 300));
      setGraphLoading(false);
    },
    []
  );

  useImperativeHandle(ref, () => ({
    handleGetData: handleGetData2,
  }));

  const handleGetData2 = useCallback(
    (params?: any) => {
      // Cancel any ongoing requests
      cancelPreviousRequests();

      // Create new cancel tokens
      outboundRequestRef.current = axios.CancelToken.source();
      inboundRequestRef.current = axios.CancelToken.source();

      const params2 = {
        groupId: groupDetails?.records?.[0]?.group_id || "",
        subGroupId: params?.subGroupId?.length > 0 ? params.subGroupId : "",
        brandId:
          params?.brandId?.length > 0
            ? params.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          params?.campaignId?.length > 0
            ? params.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          params?.phoneNumber?.length > 0
            ? params.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
        tag:
          params?.tag?.length > 0 ? params.tag?.map((dt: any) => dt.tag) : "",
        url:
          params?.link?.length > 0
            ? params.link?.map((dt: any) => dt.originalUrl)
            : "",
        startDate: params?.startDate
          ? moment(params.startDate).toISOString()
          : "",
        endDate: params?.endDate ? moment(params.endDate).toISOString() : "",
      };

      const cb = () => {
        if (inboundRequestRef.current) {
          dispatch(
            getAnalyticsInboundThunk(
              removeEmptyAndNullValues({
                ...params2,
                messageType:
                  props.selectedType === "All" ? "BOTH" : props.selectedType,
                timezone: authUser?.timezone || "",
                createdAt: "",
                link: "",
              }),
              inboundRequestRef.current.token
            )
          );
        }
        setSearchParams({
          ...Object.fromEntries(searchParams.entries()),
          currPage: String(1),
        });
        handleStatusGetData(1, rowsPerPage, removeEmptyAndNullValues(params));
      };

      if (outboundRequestRef.current) {
        dispatch(
          getAnalyticsOutboundThunk(
            removeEmptyAndNullValues({
              ...params2,
              messageType:
                props.selectedType === "All" ? "BOTH" : props.selectedType,
              timezone: authUser?.timezone || "",
              createdAt: "",
              link: "",
            }),
            cb,
            outboundRequestRef.current.token
          )
        );
      }
    },
    [
      dispatch,
      props.selectedType,
      authUser?.timezone,
      rowsPerPage,
      searchParams,
      cancelPreviousRequests,
    ]
  );

  const handleStatusGetData = useCallback(
    (page: number, perPage: number, otherParams?: any) => {
      if (statusLogsRequestRef.current) {
        statusLogsRequestRef.current.cancel(
          "Operation canceled due to new request."
        );
      }

      statusLogsRequestRef.current = axios.CancelToken.source();

      const temp = {
        ...otherParams,
        groupId: groupDetails?.records?.[0]?.group_id || "",
        subGroupId:
          otherParams?.subGroupId?.length > 0 ? otherParams?.subGroupId : "",
        brandId:
          otherParams?.brandId?.length > 0
            ? otherParams?.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          otherParams?.campaignId?.length > 0
            ? otherParams?.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          otherParams?.phoneNumber?.length > 0
            ? otherParams?.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
        tag:
          otherParams?.tag?.length > 0
            ? otherParams?.tag?.map((dt: any) => dt.tag)
            : "",
        url:
          otherParams?.link?.length > 0
            ? otherParams?.link?.map((dt: any) => dt.originalUrl)
            : "",
        startDate: otherParams?.startDate
          ? moment(otherParams?.startDate).toISOString()
          : "",
        endDate: otherParams?.endDate
          ? moment(otherParams?.endDate).toISOString()
          : "",
      };

      if (statusLogsRequestRef.current) {
        dispatch(
          getStatusLogsThunk(
            page,
            perPage,
            removeEmptyAndNullValues({
              ...temp,
              createdAt: "",
              link: "",
              sortBy: sortColumn === "shortUrls" ? "clickLog" : sortColumn,
              sortOrder: sortColumn ? isSortAsc : "",
              timezone: authUser?.timezone || "",
              statusFilter:
                props.selectedSubType === "failed" ||
                props.selectedSubType === "failureRate"
                  ? ["failed"]
                  : props.selectedSubType === "totalDelivered" ||
                    props.selectedSubType === "totalSegments" ||
                    props.selectedSubType === "averageSegmentSent" ||
                    props.selectedSubType === "totalSegmentReceived" ||
                    props.selectedSubType === "averageSegmentReceived"
                  ? ["delivered", "sent"]
                  : props.selectedSubType === "totalShortLinksClick" ||
                    props.selectedSubType === "clickThroughRate"
                  ? [
                      "delivered",
                      "sent",
                      "failed",
                      "enqueued",
                      "dequeued",
                      "created",
                      "shortLink",
                    ]
                  : [
                      "delivered",
                      "sent",
                      "failed",
                      "enqueued",
                      "dequeued",
                      "created",
                    ],
              type: props.selectedType === "All" ? "" : props.selectedType,
              direction:
                props.selectedSubType === "totalInbound" ||
                props.selectedSubType === "averageInboundMessage" ||
                props.selectedSubType === "totalSegmentReceived" ||
                props.selectedSubType === "averageSegmentReceived" ||
                props.selectedSubType === "totalOptOut" ||
                props.selectedSubType === "optOutRate" ||
                props.selectedSubType === "responseRate" ||
                props.selectedSubType === "averageResponseTime"
                  ? "inbound"
                  : "outbound",
              hasOptOut:
                props.selectedSubType === "totalOptOut" ||
                props.selectedSubType === "optOutRate"
                  ? "true"
                  : "",
            }),
            statusLogsRequestRef.current.token
          )
        );
      }
    },
    [
      dispatch,
      props.selectedSubType,
      props.selectedType,
      authUser?.timezone,
      sortColumn,
      isSortAsc,
    ]
  );

  const handleRowClick = useCallback(
    (row: { type: string; signalHouseSID: string }) => {
      row.type === "SMS"
        ? navigate(`/sms-logs-details/${row.signalHouseSID}`)
        : navigate(`/mms-logs-details/${row.signalHouseSID}`);
    },
    [navigate]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      if (!isSortClick.current) {
        setSearchParams({
          ...Object.fromEntries(searchParams.entries()),
          currPage: String(page),
        });
        handleStatusGetData(page, rowsPerPage, props.filterObj);
      }
    },
    [searchParams, rowsPerPage, props.filterObj, handleStatusGetData]
  );

  const handleRowsPerPageChange = (rows: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      currPage: String(1),
      rowsPerPage: String(rows),
    });
    handleStatusGetData(1, rows, props.filterObj);
  };

  const graphButtons = useMemo(
    () => (
      <div>
        <Button
          variant={graphType1 === "Column" ? "secondary" : "light"}
          className="ms-2 fs-2xl p-0"
          onClick={() => setGraphType1("Column")}
        >
          <i className="ph ph-chart-bar m-2" />
        </Button>
        <Button
          variant={graphType1 === "Bar" ? "secondary" : "light"}
          className="ms-2 fs-2xl p-0"
          onClick={() => setGraphType1("Bar")}
        >
          <i className="ph ph-chart-line m-2" />
        </Button>
      </div>
    ),
    [graphType1]
  );

  // Update the card click handlers to use the new loading handler
  const cardClickProps = useMemo(
    () => ({
      deliveryRate: () => handleSectionChange("deliveryRate", "Delivery Rate"),
      totalDelivered: () =>
        handleSectionChange("totalDelivered", "Total messages delivered"),
      totalMessages: () =>
        handleSectionChange("totalMessages", "Total messages sent"),
      averageMessagesSent: () =>
        handleSectionChange("averageMessagesSent", "Average messages sent"),
      totalSegments: () =>
        handleSectionChange("totalSegments", "Total segments sent"),
      averageSegmentSent: () =>
        handleSectionChange("averageSegmentSent", "Average segments sent"),
      totalShortLinksClick: () =>
        handleSectionChange("totalShortLinksClick", "Unique Clicks"),
      clickThroughRate: () =>
        handleSectionChange("clickThroughRate", "Clickthrough Rate"),
      failed: () => handleSectionChange("failed", "Failed"),
      failureRate: () => handleSectionChange("failureRate", "Failure rate"),
      responseRate: () => handleSectionChange("responseRate", "Response rate"),
      averageResponseTime: () =>
        handleSectionChange("averageResponseTime", "Average time to response"),
      totalOptOut: () => handleSectionChange("totalOptOut", "Total Opt Out"),
      optOutRate: () => handleSectionChange("optOutRate", "Opt Out rate"),
      totalInbound: () =>
        handleSectionChange("totalInbound", "Total messages received"),
      averageInboundMessage: () =>
        handleSectionChange(
          "averageInboundMessage",
          "Average messages received"
        ),
      totalSegmentReceived: () =>
        handleSectionChange("totalSegmentReceived", "Total segment received"),
      averageSegmentReceived: () =>
        handleSectionChange(
          "averageSegmentReceived",
          "Average segments received"
        ),
    }),
    [handleSectionChange]
  );

  // Add loading state to chart components
  const processedChartData = useMemo(
    () => ({
      dateGraph: graphLoading ? (
        <div className="position-relative" style={{ minHeight: "300px" }}>
          <img
            src={Loader}
            className="position-absolute top-50 start-50 translate-middle"
            alt="Loading..."
          />
        </div>
      ) : graphType1 === "Column" ? (
        <StackedColumn2
          graphObj={dateGraphObj}
          cardObj={cardObj}
          selectedSubType={props.selectedSubType}
          convertSeconds={convertSeconds}
        />
      ) : (
        <LineChart
          graphObj={dateGraphObj}
          cardObj={cardObj}
          selectedSubType={props.selectedSubType}
          convertSeconds={convertSeconds}
        />
      ),
      numberGraph: graphLoading ? (
        <div className="position-relative" style={{ minHeight: "300px" }}>
          <img
            src={Loader}
            className="position-absolute top-50 start-50 translate-middle"
            alt="Loading..."
          />
        </div>
      ) : graphType2 === "Column" ? (
        <StackedColumn2
          graphObj={numberGraphObj}
          cardObj={cardObj}
          selectedSubType={props.selectedSubType}
          convertSeconds={convertSeconds}
          isPhoneGraph={true}
        />
      ) : (
        <LineChart
          graphObj={numberGraphObj}
          cardObj={cardObj}
          selectedSubType={props.selectedSubType}
          convertSeconds={convertSeconds}
          isPhoneGraph={true}
        />
      ),
    }),
    [
      graphLoading,
      graphType1,
      graphType2,
      dateGraphObj,
      numberGraphObj,
      cardObj,
      props.selectedSubType,
    ]
  );

  return (
    <React.Fragment>
      {loading ? (
        <div className="position-relative wrapper listing-table">
          <img
            src={Loader}
            className="position-absolute top-50 start-50 translate-middle"
          />
        </div>
      ) : (
        <>
          {(props.selectedType2 == "All" ||
            props.selectedType2 == "Outbound") && (
            <>
              <h4 className="mb-3">Outbound</h4>
              <Card>
                <Card.Body>
                  <Row className="g-4">
                    <Col
                      xl={2}
                      md={4}
                      sm={6}
                      className={`border-end-sm analytics-card ${
                        props.selectedSubType === "deliveryRate" ? "active" : ""
                      }`}
                      onClick={cardClickProps.deliveryRate}
                    >
                      <div className="d-flex align-items-center gap-2 mb-4">
                        <div className="avatar-xs flex-shrink-0">
                          <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                            <i className="bi bi-check-all fs-2xl" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-0">Delivery Rate</p>
                        </div>
                      </div>
                      <h3>
                        <CountUp
                          suffix="%"
                          decimals={2}
                          start={0}
                          end={(cardObj?.deliveryRate || 0).toFixed(2)}
                        />
                      </h3>
                    </Col>
                    <Col
                      xl={2}
                      md={4}
                      sm={6}
                      className={`border-end-sm analytics-card ${
                        props.selectedSubType === "totalDelivered"
                          ? "active"
                          : ""
                      }`}
                      onClick={cardClickProps.totalDelivered}
                    >
                      <div className="d-flex align-items-center gap-2 mb-4">
                        <div className="avatar-xs flex-shrink-0">
                          <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                            <i className="bi bi-check-all fs-2xl" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-0">
                            Total messages delivered
                          </p>
                        </div>
                      </div>
                      <h3>
                        <CountUp start={0} end={cardObj?.totalDelivered || 0} />
                      </h3>
                    </Col>
                    <Col
                      xl={2}
                      md={4}
                      sm={6}
                      className={`border-end-sm analytics-card ${
                        props.selectedSubType === "totalMessages"
                          ? "active"
                          : ""
                      }`}
                      onClick={cardClickProps.totalMessages}
                    >
                      <div className="d-flex align-items-center gap-2 mb-4">
                        <div className="avatar-xs flex-shrink-0">
                          <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                            <i className="bi bi-check fs-3xl" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-0">Total messages sent</p>
                        </div>
                      </div>
                      <h3>
                        <CountUp start={0} end={cardObj?.totalMessages || 0} />
                      </h3>
                    </Col>
                    <Col
                      xl={2}
                      md={4}
                      sm={6}
                      className={`border-end-sm analytics-card ${
                        props.selectedSubType === "averageMessagesSent"
                          ? "active"
                          : ""
                      }`}
                      onClick={cardClickProps.averageMessagesSent}
                    >
                      <div className="d-flex align-items-center gap-2 mb-4">
                        <div className="avatar-xs flex-shrink-0">
                          <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                            <i className="bi bi-chevron-bar-expand fs-md" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-0">
                            Average messages sent per day
                          </p>
                        </div>
                      </div>
                      <h3>
                        <CountUp
                          decimals={2}
                          start={0}
                          end={(cardObj?.averageMessagesSent || 0).toFixed(2)}
                        />
                      </h3>
                    </Col>
                    <Col
                      xl={2}
                      md={4}
                      sm={6}
                      className={`border-end-sm analytics-card ${
                        props.selectedSubType === "totalSegments"
                          ? "active"
                          : ""
                      }`}
                      onClick={cardClickProps.totalSegments}
                    >
                      <div className="d-flex align-items-center gap-2 mb-4">
                        <div className="avatar-xs flex-shrink-0">
                          <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                            <i className="bi bi-clipboard2-check fs-md" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-0">Total segments sent</p>
                        </div>
                      </div>
                      <h3>
                        <CountUp
                          decimals={0}
                          start={0}
                          end={cardObj?.totalSegments || 0}
                        />
                      </h3>
                    </Col>
                    <Col
                      xl={2}
                      md={4}
                      sm={6}
                      className={`border-end-0 analytics-card ${
                        props.selectedSubType === "averageSegmentSent"
                          ? "active"
                          : ""
                      }`}
                      onClick={cardClickProps.averageSegmentSent}
                    >
                      <div className="d-flex align-items-center gap-2 mb-4">
                        <div className="avatar-xs flex-shrink-0">
                          <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                            <i className="bi bi-boxes fs-lg" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-0">
                            Average segments sent per day
                          </p>
                        </div>
                      </div>
                      <h3>
                        <CountUp
                          decimals={2}
                          start={0}
                          end={(cardObj?.averageSegmentSent || 0).toFixed(2)}
                        />
                      </h3>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card>
                <Card.Body>
                  <Row>
                    <Col
                      xl={2}
                      md={4}
                      sm={6}
                      className={`border-end-sm analytics-card ${
                        props.selectedSubType === "totalShortLinksClick"
                          ? "active"
                          : ""
                      }`}
                      onClick={cardClickProps.totalShortLinksClick}
                    >
                      <div className="d-flex align-items-center gap-2 mb-4">
                        <div className="avatar-xs flex-shrink-0">
                          <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                            <i className="bi bi-mouse-fill fs-md" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-0">Unique Clicks</p>
                        </div>
                      </div>
                      <h3>
                        <CountUp
                          decimals={0}
                          start={0}
                          end={cardObj?.totalShortLinksClick || 0}
                        />
                      </h3>
                    </Col>
                    <Col
                      xl={2}
                      md={4}
                      sm={6}
                      className={`border-end-sm analytics-card ${
                        props.selectedSubType === "clickThroughRate"
                          ? "active"
                          : ""
                      }`}
                      onClick={cardClickProps.clickThroughRate}
                    >
                      <div className="d-flex align-items-center gap-2 mb-4">
                        <div className="avatar-xs flex-shrink-0">
                          <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                            <i className="bi bi-graph-up-arrow fs-md" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-0">Clickthrough Rate</p>
                        </div>
                      </div>
                      <h3>
                        <CountUp
                          suffix="%"
                          decimals={2}
                          start={0}
                          end={(cardObj?.clickThroughRate || 0).toFixed(2)}
                        />
                      </h3>
                    </Col>
                    <Col
                      xl={2}
                      md={4}
                      sm={6}
                      className={`border-end-sm analytics-card ${
                        props.selectedSubType === "failed" ? "active" : ""
                      }`}
                      onClick={cardClickProps.failed}
                    >
                      <div className="d-flex align-items-center gap-2 mb-4">
                        <div className="avatar-xs flex-shrink-0">
                          <div className="avatar-title bg-body-secondary text-primary border border-primary rounded-circle">
                            <i className="bi bi-x-circle-fill fs-md" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-0">Failed</p>
                        </div>
                      </div>
                      <h3>
                        <CountUp
                          decimals={0}
                          start={0}
                          end={cardObj?.failed || 0}
                        />
                      </h3>
                    </Col>
                    <Col
                      xl={2}
                      md={4}
                      sm={6}
                      className={`analytics-card ${
                        props.selectedSubType === "failureRate" ? "active" : ""
                      }`}
                      onClick={cardClickProps.failureRate}
                    >
                      <div className="d-flex align-items-center gap-2 mb-4">
                        <div className="avatar-xs flex-shrink-0">
                          <div className="avatar-title bg-body-secondary text-primary border border-primary rounded-circle">
                            <i className="bi bi-x-circle-fill fs-md" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-0">Failure rate</p>
                        </div>
                      </div>
                      <h3>
                        <CountUp
                          suffix="%"
                          decimals={2}
                          start={0}
                          end={(cardObj?.failureRate || 0).toFixed(2)}
                        />
                      </h3>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </>
          )}

          {(props.selectedType2 == "All" ||
            props.selectedType2 == "Inbound") && (
            <>
              <h4
                className={`${props.selectedType2 == "All" ? "mt-4" : ""} mb-3`}
              >
                Inbound
              </h4>
              <Card>
                <Card.Body>
                  <Row>
                    <Col
                      xl={2}
                      md={4}
                      sm={6}
                      className={`border-end-sm analytics-card ${
                        props.selectedSubType === "totalInbound" ? "active" : ""
                      }`}
                      onClick={cardClickProps.totalInbound}
                    >
                      <div className="d-flex align-items-center gap-2 mb-4">
                        <div className="avatar-xs flex-shrink-0">
                          <div
                            style={{
                              transform: "rotate(180deg)",
                            }}
                            className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle"
                          >
                            <i className="bi bi-send-fill fs-md" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-0">
                            Total messages received
                          </p>
                        </div>
                      </div>
                      <h3>
                        <CountUp
                          decimals={0}
                          start={0}
                          end={cardObj?.totalInbound || 0}
                        />
                      </h3>
                    </Col>
                    <Col
                      xl={2}
                      md={4}
                      sm={6}
                      className={`border-end-sm-0 border-end-md analytics-card ${
                        props.selectedSubType === "averageInboundMessage"
                          ? "active"
                          : ""
                      }`}
                      onClick={cardClickProps.averageInboundMessage}
                    >
                      <div className="d-flex align-items-center gap-2 mb-4">
                        <div className="avatar-xs flex-shrink-0">
                          <div
                            style={{
                              transform: "rotate(180deg)",
                            }}
                            className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle"
                          >
                            <i className="bi bi-send-fill fs-md" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-0">
                            Average messages received
                          </p>
                        </div>
                      </div>
                      <h3>
                        <CountUp
                          key={cardObj?.averageInboundMessage}
                          decimals={2}
                          start={0}
                          end={(cardObj?.averageInboundMessage || 0).toFixed(2)}
                        />
                      </h3>
                    </Col>
                    <Col
                      xl={2}
                      key={cardObj?.totalSegmentReceived}
                      md={4}
                      sm={6}
                      className={`border-end-sm analytics-card ${
                        props.selectedSubType === "totalSegmentReceived"
                          ? "active"
                          : ""
                      }`}
                      onClick={cardClickProps.totalSegmentReceived}
                    >
                      <div className="d-flex align-items-center gap-2 mb-4">
                        <div className="avatar-xs flex-shrink-0">
                          <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                            <i className="bi bi-stack fs-md" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-0">
                            Total segment received
                          </p>
                        </div>
                      </div>
                      <h3>
                        <CountUp
                          decimals={0}
                          start={0}
                          end={cardObj?.totalSegmentReceived || 0}
                        />
                      </h3>
                    </Col>
                    <Col
                      xl={2}
                      md={4}
                      sm={6}
                      className={`border-end-0 analytics-card ${
                        props.selectedSubType === "averageSegmentReceived"
                          ? "active"
                          : ""
                      }`}
                      onClick={cardClickProps.averageSegmentReceived}
                    >
                      <div className="d-flex align-items-center gap-2 mb-4">
                        <div className="avatar-xs flex-shrink-0">
                          <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                            <i className="bi bi-stack fs-md" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-0">
                            Average segments received
                          </p>
                        </div>
                      </div>
                      <h3>
                        <CountUp
                          decimals={2}
                          start={0}
                          end={(cardObj?.averageSegmentReceived || 0).toFixed(
                            2
                          )}
                        />
                      </h3>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card>
                <Card.Body>
                  <Row>
                    <Col
                      xl={2}
                      md={4}
                      sm={6}
                      className={`border-end-sm analytics-card ${
                        props.selectedSubType === "responseRate" ? "active" : ""
                      }`}
                      onClick={cardClickProps.responseRate}
                    >
                      <div className="d-flex align-items-center gap-2 mb-4">
                        <div className="avatar-xs flex-shrink-0">
                          <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                            <i className="bi bi-send-fill fs-md" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-0">
                            Non opt-out response rate
                          </p>
                        </div>
                      </div>
                      <h3>
                        <CountUp
                          suffix="%"
                          decimals={2}
                          start={0}
                          end={(cardObj?.responseRate || 0).toFixed(2)}
                        />
                      </h3>
                    </Col>
                    <Col
                      xl={2}
                      md={4}
                      sm={6}
                      className={`border-end-sm analytics-card ${
                        props.selectedSubType === "averageResponseTime"
                          ? "active"
                          : ""
                      }`}
                      onClick={cardClickProps.averageResponseTime}
                    >
                      <div className="d-flex align-items-center gap-2 mb-4">
                        <div className="avatar-xs flex-shrink-0">
                          <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                            <i className="bi bi-stopwatch-fill fs-lg" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-0">
                            Average time to response
                          </p>
                        </div>
                      </div>
                      <h3>
                        {convertSeconds(cardObj?.averageResponseTime, true)}
                      </h3>
                    </Col>
                    <Col
                      xl={2}
                      md={4}
                      sm={6}
                      className={`border-end-sm analytics-card ${
                        props.selectedSubType === "totalOptOut" ? "active" : ""
                      }`}
                      onClick={cardClickProps.totalOptOut}
                    >
                      <div className="d-flex align-items-center gap-2 mb-4">
                        <div className="avatar-xs flex-shrink-0">
                          <div className="avatar-title bg-body-secondary text-primary border border-primary rounded-circle">
                            <i className="bi bi-send-fill fs-md" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-0">Total Opt Out</p>
                        </div>
                      </div>
                      <h3>
                        <CountUp
                          decimals={0}
                          start={0}
                          end={cardObj?.totalOptOut || 0}
                        />
                      </h3>
                    </Col>
                    <Col
                      xl={2}
                      md={4}
                      sm={6}
                      className={`analytics-card ${
                        props.selectedSubType === "optOutRate" ? "active" : ""
                      }`}
                      onClick={cardClickProps.optOutRate}
                    >
                      <div className="d-flex align-items-center gap-2 mb-4">
                        <div className="avatar-xs flex-shrink-0">
                          <div className="avatar-title bg-body-secondary text-primary border border-primary rounded-circle">
                            <i className="bi bi-send-fill fs-md" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-0">Opt Out rate</p>
                        </div>
                      </div>
                      <h3>
                        <CountUp
                          suffix="%"
                          decimals={2}
                          start={0}
                          end={(cardObj?.optOutRate || 0).toFixed(2)}
                        />
                      </h3>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </>
          )}

          <Card className="card-height-100">
            <Card.Header className="d-flex align-items-center justify-content-between">
              <h6 className="card-title mb-2">{props.selectedSubTypeLabel}</h6>
              {graphButtons}
            </Card.Header>
            <Card.Body>
              <div className="w-100">{processedChartData.dateGraph}</div>
            </Card.Body>
          </Card>

          <Card className="card-height-100">
            <Card.Header className="d-flex align-items-center justify-content-between">
              <h6 className="card-title mb-2">{props.selectedSubTypeLabel}</h6>
              <div>
                <Button
                  variant={graphType2 === "Column" ? "secondary" : "light"}
                  className="ms-2 fs-2xl p-0"
                  onClick={() => setGraphType2("Column")}
                >
                  <i className="ph ph-chart-bar m-2" />
                </Button>
                <Button
                  variant={graphType2 === "Bar" ? "secondary" : "light"}
                  className="ms-2 fs-2xl p-0"
                  onClick={() => setGraphType2("Bar")}
                >
                  <i className="ph ph-chart-line m-2" />
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="w-100">{processedChartData.numberGraph}</div>
            </Card.Body>
          </Card>

          <Card className="card-height-100">
            <Card.Header>
              <h6 className="card-title mb-0">
                {props.selectedSubTypeLabel || ""} logs
              </h6>
            </Card.Header>
            <Card.Body>
              <SmsAndMmsLogs
                key={props.selectedType}
                loading={loading2}
                rowsPerPage={rowsPerPage}
                currPage={currPage}
                logsData={statusLogs}
                handleRowClick={handleRowClick}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                sortColumn={sortColumn}
                isSortAsc={isSortAsc}
                onSort={handleSort}
              />
            </Card.Body>
          </Card>
        </>
      )}
    </React.Fragment>
  );
});

export default CommonAnalytics;
