import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import Loader from "assets/images/spinner-dark.svg";
import {
  asyncBrandList,
  asyncCampaignList,
  asyncSubgroupList,
  capitalizeString,
  customSelectTheme,
  dateFormat,
  getEndOfDay,
  getStartOfDay,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getPortOutListThunk } from "slices/thunk";
import Datatable from "../../Common/Datatable";
import { useFormik } from "formik";
import { AsyncPaginate } from "react-select-async-paginate";
import moment from "moment";
import { GroupSelect } from "Common/filter/group-select";
import { BrandSelect } from "Common/filter/brand-select";
import Tooltip from "rc-tooltip";
import { useSearchParams } from "react-router-dom";

const initialFilterValues = {
  portingId: "",
  createdAt: "",
  startDate: "",
  endDate: "",
  groupId: "",
  subGroupId: [],
  brandId: [],
  campaignId: [],
};

const PortOutRequests = (props: any) => {
  document.title = "Signal House Portal Port Requests";
  const dispatch = useDispatch<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const currPage = Number(searchParams.get("currPage") || 1);
  const rowsPerPage = Number(searchParams.get("rowsPerPage") || 10);
  const isFilter = searchParams.get("isFilter");

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);

  const selector = createSelector(
    (state: any) => state.Ports,
    (state: any) => state.Groups,
    (ports, groups) => ({
      loading: ports.loading,
      portOutRequestsArr: ports.portOutRequestsArr,
      error: ports.error,
      groupDetails: groups?.AllGroups,
    })
  );

  const { loading, portOutRequestsArr, groupDetails } = useSelector(selector);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm"> Date</span>,
      minWidth: "220px",
      selector: (row: { createdDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {row.createdDate
            ? dateFormat(row.createdDate, "MM/DD/YYYY, hh:mm A")
            : "-"}
        </span>
      ),
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const dateA = rowA.createdDate
          ? new Date(rowA.createdDate).getTime()
          : 0;
        const dateB = rowB.createdDate
          ? new Date(rowB.createdDate).getTime()
          : 0;
        return dateA - dateB;
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Phone Number</span>,
      minWidth: "130px",
      selector: (row: { nnsrResponse?: { tn?: string } }) =>
        row?.nnsrResponse?.tn || "-",
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const phoneA = rowA?.nnsrResponse?.tn || "";
        const phoneB = rowB?.nnsrResponse?.tn || "";
        return phoneA?.localeCompare(phoneB);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      minWidth: "150px",
      selector: (row: {
        nnsrResponse?: { reason?: string; contact_phone?: string };
      }) => {
        const reason = row?.nnsrResponse?.reason || "";

        let badgeClass;
        switch (reason.toLowerCase()) {
          case "rejected":
            badgeClass =
              "badge bg-body-body-primary border border-primary text-primary";
            break;
          case "approved":
            badgeClass =
              "badge bg-body-secondary border border-secondary text-secondary";
            break;
          case "deleted":
            badgeClass = "badge bg-body-dark border border-dark text-dark";
            break;
          default:
            badgeClass =
              "badge bg-body-secondary border border-warning text-warning";
            break;
        }
        return (
          <div>
            <span data-tag="allowRowEvents" className={badgeClass}>
              {capitalizeString(reason) || "-"}
            </span>
          </div>
        );
      },
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const reasonA = rowA.nnsrResponse?.reason?.toLowerCase() || "";
        const reasonB = rowB.nnsrResponse?.reason?.toLowerCase() || "";
        return reasonA.localeCompare(reasonB);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Details</span>,
      minWidth: "250px",
      grow: 2,
      wrap: true,
      selector: (row: any) => {
        const reason = row?.nnsrResponse?.reason?.toLowerCase() || "";
        const createdDate = row.createdDate ? dateFormat(row.createdDate) : "-";
        const message =
          row?.nnsrResponse?.message
            ?.toLowerCase()
            ?.replace(/reject(ed)?|due to/gi, "")
            .trim() || "-";

        if (reason === "rejected") {
          return (
            <p className="mb-0">{`Rejected on ${createdDate} due to ${message}`}</p>
          );
        } else if (reason === "deleted") {
          return <p className="mb-0">{`Deleted on ${createdDate}`}</p>;
        } else {
          return <p className="mb-0">{`Completed on ${createdDate}`}</p>;
        }
      },
      sortable: false,
    },
  ];

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: initialFilterValues,
    onSubmit: (values) => {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        ...values,
        subGroupId: JSON.stringify(values.subGroupId),
        brandId: JSON.stringify(values.brandId),
        campaignId: JSON.stringify(values.campaignId),
        currPage: String(1),
        isFilter: "true",
      });
      handleGetData(1, rowsPerPage, values);
    },
  });

  useEffect(() => {
    isFilter !== "true" && handleGetData(currPage, rowsPerPage);
  }, []);

  useEffect(() => {
    if (groupDetails?.records?.[0]?.group_id) {
      validation.setFieldValue("groupId", groupDetails?.records?.[0]?.group_id);
    }
  }, [groupDetails?.records?.[0]?.group_id]);

  useEffect(() => {
    if (isFilter === "true") {
      const temp = { ...Object.fromEntries(searchParams.entries()) };
      const temp2 = {
        ...validation.initialValues,
        portingId: temp?.portingId || "",
        createdAt: temp?.createdAt || "",
        startDate: temp?.startDate || "",
        endDate: temp?.endDate || "",
        groupId: temp?.groupId || groupDetails?.records?.[0]?.group_id || "",
        subGroupId: temp?.subGroupId ? JSON.parse(temp?.subGroupId) : [],
        brandId: temp?.brandId ? JSON.parse(temp?.brandId) : [],
        campaignId: temp?.campaignId ? JSON.parse(temp?.campaignId) : [],
      };
      validation.setValues(temp2);
      handleGetData(currPage, rowsPerPage, temp2);
    }
  }, [isFilter]);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    const temp = {
      ...otherParams,
      subGroupId:
        otherParams?.subGroupId?.length > 0 ? otherParams.subGroupId : "",
      brandId:
        otherParams?.brandId?.length > 0
          ? otherParams.brandId?.map((dt: any) => dt.brandId)
          : "",
      campaignId:
        otherParams?.campaignId?.length > 0
          ? otherParams.campaignId?.map((dt: any) => dt.campaignId)
          : "",
      createdAt: "",
      startDate: otherParams?.startDate
        ? moment(otherParams.startDate).toISOString()
        : "",
      endDate: otherParams?.endDate
        ? moment(otherParams.endDate).toISOString()
        : "",
    };

    dispatch(
      getPortOutListThunk(page, perPage, removeEmptyAndNullValues(temp))
    );
  };

  const handleRowClick = (row: { row: any }) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const handlePageChange = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      currPage: String(page),
    });
    handleGetData(page, rowsPerPage, validation.values);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      currPage: String(1),
      rowsPerPage: String(rows),
    });
    handleGetData(1, rows, validation.values);
  };

  return (
    <Container fluid>
      <BreadCrumb
        title="Port Out Requests"
        isFilter={true}
        filterButton={
          <Dropdown
            align="end"
            className="filterDropDown2"
            show={open}
            onToggle={() => {
              if (!datePickerModal) {
                setOpen(!open);
              }
            }}
          >
            <div
              className="d-flex align-items-center justify-content-end"
              style={{ flex: "1 1 0%" }}
            >
              <div className="d-flex align-items-center flex-wrap">
                {validation.values.portingId && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Porting ID: {validation.values.portingId}
                  </span>
                )}
                {validation.values.createdAt?.length > 0 && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Created At: {validation.values.createdAt}
                  </span>
                )}
                {validation.values.subGroupId?.length > 0 && (
                  <Tooltip
                    placement="top"
                    overlay={validation.values.subGroupId?.join(", ")}
                    overlayClassName="subgroup-tooltip"
                  >
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      {validation.values.subGroupId.length === 1 ? (
                        `Subgroup Id: ${validation.values.subGroupId[0]}`
                      ) : (
                        <>
                          Subgroup Id: {validation.values.subGroupId[0]}
                          {` +${validation.values.subGroupId.length - 1} More`}
                        </>
                      )}
                    </span>
                  </Tooltip>
                )}
                {validation.values.brandId?.length > 0 && (
                  <Tooltip
                    placement="top"
                    overlay={validation.values.brandId
                      ?.map((dt: any) => dt.brandId)
                      ?.join(", ")}
                    overlayClassName="brand-tooltip"
                  >
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      {validation.values.brandId.length === 1 ? (
                        `Brand Id: ${validation.values.brandId[0].brandId}`
                      ) : (
                        <>
                          Brand Id: {validation.values.brandId[0].brandId}
                          {` +${validation.values.brandId.length - 1} More`}
                        </>
                      )}
                    </span>
                  </Tooltip>
                )}
                {validation.values.campaignId?.length > 0 && (
                  <Tooltip
                    placement="top"
                    overlay={validation.values.campaignId
                      ?.map((dt: any) => dt.campaignId)
                      ?.join(", ")}
                    overlayClassName="campaign-tooltip"
                  >
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      {validation.values.campaignId.length === 1 ? (
                        `Campaign Id: ${validation.values.campaignId[0].campaignId}`
                      ) : (
                        <>
                          Campaign Id:{" "}
                          {validation.values.campaignId[0].campaignId}
                          {` +${validation.values.campaignId.length - 1} More`}
                        </>
                      )}
                    </span>
                  </Tooltip>
                )}
              </div>
              <Dropdown.Toggle variant="secondary">
                <i className="bx bx-filter-alt me-1"></i>
              </Dropdown.Toggle>
            </div>

            <Dropdown.Menu className="dropdown-menu-md p-4">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  setOpen(!open);
                  validation.handleSubmit();
                }}
              >
                <Row>
                  <Col sm={12} className="mb-3">
                    <Form.Label htmlFor="portingId">Porting ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="portingId"
                      placeholder="Enter Porting ID"
                      onChange={(e) => {
                        validation.setFieldValue("portingId", e.target.value);
                      }}
                      value={validation.values.portingId}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
                    <GroupSelect
                      isDisabled={!validation.values.groupId}
                      key={validation.values.groupId}
                      isMulti={true}
                      isClearable={true}
                      isSearchable={true}
                      styles={styleObj(
                        validation?.touched?.subGroupId &&
                          validation?.errors?.subGroupId
                      )}
                      theme={customSelectTheme}
                      value={
                        validation.values.subGroupId?.length > 0
                          ? validation.values.subGroupId.map((dt: string) => ({
                              sub_group_id: dt,
                            }))
                          : null
                      }
                      loadOptions={asyncSubgroupList(
                        removeEmptyAndNullValues({
                          groupId: validation.values.groupId || "",
                        }),
                        "subGroupId"
                      )}
                      getOptionValue={(option: any) => option?.sub_group_id}
                      getOptionLabel={(option: any) =>
                        `${option?.sub_group_id} - ${option?.sub_group_name}`
                      }
                      onChange={(option: any) => {
                        if (option) {
                          validation.setFieldValue(
                            "subGroupId",
                            option.map((dt: any) => dt.sub_group_id)
                          );
                          validation.setFieldValue("brandId", []);
                          validation.setFieldValue("campaignId", []);
                        } else {
                          validation.setFieldValue("subGroupId", []);
                        }
                      }}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="brandId">Brand</Form.Label>
                    <BrandSelect
                      key={
                        validation.values.subGroupId.toString() ||
                        validation.values.groupId ||
                        "brandId"
                      }
                      isMulti={true}
                      isClearable={true}
                      isSearchable={true}
                      styles={styleObj(
                        validation?.touched?.brandId &&
                          validation?.errors?.brandId
                      )}
                      theme={customSelectTheme}
                      value={
                        validation.values.brandId?.length > 0
                          ? validation.values.brandId
                          : null
                      }
                      loadOptions={asyncBrandList(
                        removeEmptyAndNullValues({
                          groupId: validation.values.groupId || "",
                          subGroupId:
                            validation.values.subGroupId.length > 0
                              ? validation.values.subGroupId
                              : "",
                        }),
                        "brandId"
                      )}
                      getOptionValue={(option: any) => option?.brandId}
                      getOptionLabel={(option: any) =>
                        `${option?.brandId} - ${option?.displayName}`
                      }
                      onChange={(option: any) => {
                        if (option) {
                          validation.setFieldValue(
                            "subGroupId",
                            option.reduce(
                              (acc: string[], dt: any) => {
                                if (
                                  dt?.subGroupId &&
                                  Array.isArray(dt.subGroupId)
                                ) {
                                  dt.subGroupId.forEach((subGroup: string) => {
                                    if (
                                      !acc.includes(subGroup) &&
                                      !validation.values.subGroupId.includes(
                                        subGroup
                                      )
                                    ) {
                                      acc.push(subGroup);
                                    }
                                  });
                                }
                                return acc;
                              },
                              [...validation.values.subGroupId]
                            )
                          );
                          validation.setFieldValue(
                            "brandId",
                            option.map((dt: any) => ({
                              subGroupId: dt?.subGroupId || [],
                              brandId: dt?.brandId || "",
                            }))
                          );
                          validation.setFieldValue("campaignId", []);
                        } else {
                          validation.setFieldValue("brandId", []);
                        }
                      }}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="campaignId">Campaign</Form.Label>
                    <AsyncPaginate
                      key={
                        validation.values.brandId
                          ?.map((dt: any) => dt.brandId)
                          .toString() ||
                        validation.values.subGroupId.toString() ||
                        validation.values.groupId ||
                        "campaignId"
                      }
                      isMulti={true}
                      isClearable={true}
                      isSearchable={true}
                      styles={styleObj(
                        validation?.touched?.campaignId &&
                          validation?.errors?.campaignId
                      )}
                      theme={customSelectTheme}
                      value={
                        validation.values.campaignId?.length > 0
                          ? validation.values.campaignId.map((dt: any) =>
                              dt?.campaignId?.startsWith("C")
                                ? { campaignId: dt?.campaignId }
                                : { campaignUuid: dt?.campaignId }
                            )
                          : null
                      }
                      loadOptions={asyncCampaignList(
                        removeEmptyAndNullValues({
                          groupId: validation.values.groupId || "",
                          subGroupId:
                            validation.values.subGroupId.length > 0
                              ? validation.values.subGroupId
                              : "",
                          brandId:
                            validation.values.brandId.length > 0
                              ? validation.values.brandId?.map(
                                  (dt: any) => dt.brandId
                                )
                              : "",
                        }),
                        "campaignId",
                        true,
                        true
                      )}
                      getOptionValue={(option: any) =>
                        option?.campaignId?.startsWith("C")
                          ? option?.campaignId
                          : option?.campaignUuid
                      }
                      getOptionLabel={(option: any) =>
                        option?.campaignId?.startsWith("C")
                          ? option?.campaignId
                          : option?.campaignUuid
                      }
                      onChange={(option: any) => {
                        if (option) {
                          validation.setFieldValue(
                            "subGroupId",
                            option.reduce(
                              (acc: string[], dt: any) => {
                                if (
                                  dt?.subGroupId &&
                                  Array.isArray(dt.subGroupId)
                                ) {
                                  dt.subGroupId.forEach((subGroup: string) => {
                                    if (
                                      !acc.includes(subGroup) &&
                                      !validation.values.subGroupId.includes(
                                        subGroup
                                      )
                                    ) {
                                      acc.push(subGroup);
                                    }
                                  });
                                }
                                return acc;
                              },
                              [...validation.values.subGroupId]
                            )
                          );
                          validation.setFieldValue(
                            "brandId",
                            option.reduce(
                              (acc: any[], dt: any) => {
                                const existingItem =
                                  validation.values.brandId.find(
                                    (item: any) =>
                                      item.brandId === dt.brandId &&
                                      JSON.stringify(item.subGroupId) ===
                                        JSON.stringify(dt.subGroupId)
                                  );
                                if (!existingItem) {
                                  acc.push({
                                    subGroupId: dt?.subGroupId || [],
                                    brandId: dt?.brandId || "",
                                  });
                                }
                                return acc;
                              },
                              [...validation.values.brandId]
                            )
                          );
                          validation.setFieldValue(
                            "campaignId",
                            option.map((dt: any) => ({
                              subGroupId: dt?.subGroupId || [],
                              brandId: dt?.brandId || "",
                              campaignId: dt?.campaignId?.startsWith("C")
                                ? dt?.campaignId
                                : dt?.campaignUuid || "",
                            }))
                          );
                        } else {
                          validation.setFieldValue("campaignId", []);
                        }
                      }}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="createdAt">Created At</Form.Label>
                    <Flatpickr
                      className="form-control"
                      name="createdAt"
                      placeholder="Created At"
                      options={{
                        enableTime: false,
                        onOpen: () => {
                          setDatePickerModal(true);
                        },
                        onClose: () => {
                          setDatePickerModal(false);
                        },
                        altFormat: "F j, Y",
                        dateFormat: "m-d-Y",
                        mode: "range",
                        onChange: (
                          value: any,
                          dateStr: string,
                          instance: any
                        ) => {
                          validation.setFieldValue("createdAt", dateStr || "");
                          value?.[0] &&
                            validation.setFieldValue(
                              "startDate",
                              getStartOfDay(value?.[0])
                            );
                          value?.[1] &&
                            validation.setFieldValue(
                              "endDate",
                              getEndOfDay(value?.[1])
                            );
                        },
                      }}
                      value={validation.values.createdAt || ""}
                    />
                  </Col>
                </Row>
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    className="btn btn-secondary me-2"
                    type="button"
                    disabled={loading}
                    onClick={() => {
                      validation.setValues(initialFilterValues);
                      validation.resetForm();
                      setDatePickerModal(false);
                      validation.setFieldValue(
                        "groupId",
                        groupDetails?.records?.[0]?.group_id
                      );
                      setSearchParams({
                        ...Object.fromEntries(searchParams.entries()),
                        ...validation.initialValues,
                        groupId: groupDetails?.records?.[0]?.group_id,
                        currPage: String(1),
                        isFilter: "true",
                      });
                      handleGetData(1, rowsPerPage);
                    }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Clear
                  </Button>
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    disabled={loading}
                  >
                    {loading && <Spinner size="sm" animation="border" />} Apply
                  </Button>
                </div>
              </Form>
            </Dropdown.Menu>
          </Dropdown>
        }
      />
      <div className="position-relative">
        <Card className="wrapper2">
          <Card.Body className="listing-table3">
            {loading ? (
              <div className={``}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <Datatable
                data={
                  Array.isArray(portOutRequestsArr?.records)
                    ? portOutRequestsArr?.records
                    : []
                }
                columns={columns}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                handleRowClick={handleRowClick}
                currPage={currPage}
                rowsPerPage={rowsPerPage}
                totalRecords={portOutRequestsArr?.totalRecords}
              />
            )}
          </Card.Body>
        </Card>

        {selectedRow && (
          <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col lg={6}>
                  <div className="p-2">
                    <div className="d-flex gap-2 mt-2">
                      <div className="text-secondary">Contact Name: </div>
                      <p>{selectedRow?.nnsrResponse?.contact_name ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="p-2">
                    <div className="d-flex gap-2 mt-2">
                      <div className="text-secondary">Contact Business: </div>
                      <p>
                        {selectedRow?.nnsrResponse?.contact_business ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="p-2">
                    <div className="d-flex gap-2 mt-2">
                      <div className="text-secondary">Contact Phone: </div>
                      <p>{selectedRow?.nnsrResponse?.contact_phone ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="p-2">
                    <div className="d-flex gap-2 mt-2">
                      <div className="text-secondary">Contact Email: </div>
                      <p>{selectedRow?.nnsrResponse?.contact_email ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="p-2">
                    <div className="d-flex gap-2 mt-2">
                      <div className="text-secondary">TID: </div>
                      <p>{selectedRow?.nnsrResponse?.tid ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="p-2">
                    <div className="d-flex gap-2 mt-2">
                      <div className="text-secondary">New NNID: </div>
                      <p>{selectedRow?.nnsrResponse?.new_nnid ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="p-2">
                    <div className="d-flex gap-2 mt-2">
                      <div className="text-secondary">Old NNID: </div>
                      <p>{selectedRow?.nnsrResponse?.old_nnid ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="p-2">
                    <div className="d-flex gap-2 mt-2">
                      <div className="text-secondary">PID: </div>
                      <p>{selectedRow?.nnsrResponse?.pid ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="p-2">
                    <div className="d-flex gap-2 mt-2">
                      <div className="text-secondary">Message: </div>
                      <p>{selectedRow?.nnsrResponse?.message ?? "-"}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </Container>
  );
};

export default withRouter(PortOutRequests);
