import React, { useState } from "react";
import { Button, Card, Col } from "react-bootstrap";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import Loader from "assets/images/spinner-dark.svg";
import LineChart from "pages/Analytics/LineChart";
import StackedColumn2 from "pages/Analytics/StackedColumn2";

const DeliveryRate = ({ numberGraphObj }: any) => {
  const selectData = createSelector(
    (state: any) => state.Analytics,
    (analytics) => ({
      loading: analytics.loading,
    })
  );

  const { loading } = useSelector(selectData);

  const [selectedSubType, setSelectedSubType] = useState("totalMessages");
  const [graphType, setGraphType] = useState("Column");

  return (
    <React.Fragment>
      <Col xl={7}>
        <Card className="card-height-100">
          <Card.Header className="border-0 align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">
              Per Number Delivery Rate
            </h4>
            <div className="d-flex gap-2 ms-2">
              <Button
                variant="secondary"
                onClick={() => {
                  setSelectedSubType(
                    selectedSubType === "deliveryRate"
                      ? "totalMessages"
                      : "deliveryRate"
                  );
                }}
              >
                Show{" "}
                {selectedSubType === "deliveryRate" ? "Numbers" : "Percentage"}
              </Button>
              <Button
                variant={graphType === "Column" ? "secondary" : "light"}
                className="fs-2xl p-0"
                onClick={() => setGraphType("Column")}
              >
                <i className="ph ph-chart-bar m-2" />
              </Button>
              <Button
                variant={graphType === "Bar" ? "secondary" : "light"}
                className="fs-2xl p-0"
                onClick={() => setGraphType("Bar")}
              >
                <i className="ph ph-chart-line m-2" />
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="w-100">
              {loading ? (
                <div className="position-relative" style={{ height: 200 }}>
                  <img
                    src={Loader}
                    alt="Loading"
                    className="position-absolute top-50 start-50 translate-middle"
                  />
                </div>
              ) : graphType === "Column" ? (
                <StackedColumn2
                  graphObj={numberGraphObj}
                  selectedSubType={selectedSubType}
                />
              ) : (
                <LineChart
                  graphObj={numberGraphObj}
                  selectedSubType={selectedSubType}
                />
              )}
            </div>
          </Card.Body>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default DeliveryRate;
