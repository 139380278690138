import { createSlice } from "@reduxjs/toolkit";
import { TokenState } from "types/token";

const initialState: TokenState = {
  tokens: [],
  totalCount: 0,
  loading: false,
  error: null,
  newToken: null,
};

const tokenSlice = createSlice({
  name: "tokens",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setTokens: (state, action) => {
      state.tokens = action.payload;
      state.totalCount = action.payload.totalCount;
      state.loading = false;
    },
    setNewToken: (state, action) => {
      state.newToken = action.payload;
      state.loading = false;
    },
    clearNewToken: (state) => {
      state.newToken = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { setLoading, setTokens, setNewToken, clearNewToken, setError } =
  tokenSlice.actions;

export default tokenSlice.reducer;
