import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../slices/modal/reducer";
import { createSelector } from "reselect";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Subject } from "rxjs";

export const onCloseSubject = new Subject();
export const submitLoadingSubject = new Subject();

const CustomModal = () => {
  const dispatch = useDispatch();
  const [modalState, setModel] = useState({
    isLoaded: false,
    module: "",
    modalbody: "",
  });
  const [isSubmitDisable, setIsSubmitDisable] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [modalData, setModalData] = useState({});

  const selectData = createSelector(
    (state: any) => state.Modal,
    (modal) => ({
      state: modal.state,
      data: modal.data,
      path: modal.path,
      modalbody: modal.modalbody,
    })
  );

  const { state, data = {}, path, modalbody: body } = useSelector(selectData);
  const { module: Component, isLoaded, modalbody = "" } = modalState;
  const {
    // confModal = false,
    modalClass = "",
    headerClass = "",
    closeButton = true,
    title,
    bodyClass = "",
    footer,
    footerClass = "",
    buttonText = {},
    cancelBtn = true,
    onCancel,
    onSubmit,
  } = data;

  useEffect(() => {
    onCloseSubject.subscribe(() => onClose());
    submitLoadingSubject.subscribe({
      next: (val: any) => setIsSubmitLoading(val),
    });
  }, []);

  useEffect(() => {
    if (path) {
      import(`./${path}`).then((module) =>
        setModel({
          module: module.default,
          modalbody: "",
          isLoaded: state ? true : false,
        })
      );
    } else {
      setModel({
        module: "",
        modalbody: body,
        isLoaded: state ? true : false,
      });
    }
  }, [state, data, path, body]);

  const onClose = () => {
    submitLoadingSubject.next(false);
    dispatch(closeModal({}));
    setModalData({});
  };

  return (
    <div>
      {isLoaded && (
        <Modal
          centered
          show={state}
          onHide={() => (onCancel ? onCancel({ onClose }) : onClose())}
          className={modalClass}
        >
          {state && (
            <>
              {title && (
                <Modal.Header
                  id="modal-header"
                  className={`${headerClass}`}
                  closeButton={closeButton}
                >
                  <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
              )}
              <Modal.Body className={`p-4 ${bodyClass}`} id="modal-body">
                {isLoaded && Component && (
                  <Component
                    {...data}
                    key={state}
                    onClose={onClose}
                    setIsSubmitDisable={setIsSubmitDisable}
                    isSubmitLoading={isSubmitLoading}
                    setIsSubmitLoading={setIsSubmitLoading}
                    modalData={modalData}
                    setModalData={setModalData}
                  />
                )}
                {isLoaded && modalbody && (
                  <div
                    className=" "
                    dangerouslySetInnerHTML={{ __html: modalbody }}
                  />
                )}
              </Modal.Body>
              {footer && (
                <Modal.Footer id="modal-footer" className={`${footerClass}`}>
                  <div className="hstack gap-2 justify-content-end">
                    {cancelBtn && (
                      <Button
                        // className={` ${buttonText?.cancelClass || ""}`}
                        onClick={(e) => {
                          onCancel
                            ? onCancel({ onClose, isButtonClicked: true })
                            : onClose();
                        }}
                        className="btn btn-outline-primary"
                      >
                        {buttonText.cancel || "Cancel"}
                      </Button>
                    )}
                    {onSubmit && (
                      <Button
                        variant="primary"
                        disabled={isSubmitDisable || isSubmitLoading}
                        onClick={(e) => {
                          onSubmit({
                            e,
                            onClose,
                            modalData,
                          });
                        }}
                      >
                        {isSubmitLoading && (
                          <Spinner size="sm" animation="border" />
                        )}{" "}
                        {buttonText.submit || "Ok"}
                      </Button>
                    )}
                  </div>
                </Modal.Footer>
              )}
            </>
          )}
        </Modal>
      )}
    </div>
  );
};

export default CustomModal;
