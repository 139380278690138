import withRouter from "Common/withRouter";
import { useFormik } from "formik";
import {
  dateRangeArr,
  handleDateFunction,
  selectedTypeArr,
  selectedTypeArr2,
} from "helpers/common";
import { getLocal, setLocal } from "helpers/services/auth/auth";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { createSelector } from "reselect";
import * as Yup from "yup";
import AnalyticsHeader from "./AnalyticsHeader";
import CommonAnalytics from "./CommonAnalytics";

const initValues = {
  groupId: "",
  subGroupId: [],
  brandId: [],
  campaignId: [],
  phoneNumber: [],
  tag: [],
  link: [],
  createdAt: "",
  ...handleDateFunction(dateRangeArr[1]?.value),
};

const Analytics = (props: any) => {
  document.title = "Signal House Portal Analytics";

  const analyticsRef = useRef<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("type") || "";

  const typeMapping: Record<
    string,
    {
      selectedType: string;
      selectedType2: string;
      selectedSubType: string;
    }
  > = {
    all: {
      selectedType: selectedTypeArr[0],
      selectedType2: selectedTypeArr2[0],
      selectedSubType: "totalMessages",
    },
    sms_outbound: {
      selectedType: selectedTypeArr[1],
      selectedType2: selectedTypeArr2[1],
      selectedSubType: "totalMessages",
    },
    sms_inbound: {
      selectedType: selectedTypeArr[1],
      selectedType2: selectedTypeArr2[2],
      selectedSubType: "totalInbound",
    },
    mms_outbound: {
      selectedType: selectedTypeArr[2],
      selectedType2: selectedTypeArr2[1],
      selectedSubType: "totalMessages",
    },
    mms_inbound: {
      selectedType: selectedTypeArr[2],
      selectedType2: selectedTypeArr2[2],
      selectedSubType: "totalInbound",
    },
    "all-d-rate": {
      selectedType: selectedTypeArr[0],
      selectedType2: selectedTypeArr2[1],
      selectedSubType: "deliveryRate",
    },
    "sms_outbound_d-rate": {
      selectedType: selectedTypeArr[1],
      selectedType2: selectedTypeArr2[1],
      selectedSubType: "deliveryRate",
    },
    "mms_outbound_d-rate": {
      selectedType: selectedTypeArr[2],
      selectedType2: selectedTypeArr2[1],
      selectedSubType: "deliveryRate",
    },
    "optout-rate": {
      selectedType: selectedTypeArr[0],
      selectedType2: selectedTypeArr2[2],
      selectedSubType: "optOutRate",
    },
    "sms_optout-rate": {
      selectedType: selectedTypeArr[1],
      selectedType2: selectedTypeArr2[2],
      selectedSubType: "optOutRate",
    },
    "mms_optout-rate": {
      selectedType: selectedTypeArr[2],
      selectedType2: selectedTypeArr2[2],
      selectedSubType: "optOutRate",
    },
    non_optout_rate: {
      selectedType: selectedTypeArr[0],
      selectedType2: selectedTypeArr2[2],
      selectedSubType: "responseRate",
    },
    sms_non_optout_rate: {
      selectedType: selectedTypeArr[1],
      selectedType2: selectedTypeArr2[2],
      selectedSubType: "responseRate",
    },
    mms_non_optout_rate: {
      selectedType: selectedTypeArr[2],
      selectedType2: selectedTypeArr2[2],
      selectedSubType: "responseRate",
    },
  };

  const selectProfile = createSelector(
    (state: any) => state.Analytics,
    (state: any) => state.Groups,
    (analytics, groups) => ({
      loading: analytics.loading,
      groupDetails: groups?.AllGroups,
    })
  );

  const { loading, groupDetails } = useSelector(selectProfile);

  const [selectedType, setSelectedType] = useState(
    typeMapping?.[type]?.selectedType || selectedTypeArr[0]
  );
  const [selectedType2, setSelectedType2] = useState(
    typeMapping?.[type]?.selectedType2 || selectedTypeArr2[1]
  );
  const [selectedSubType, setSelectedSubType] = useState(
    typeMapping?.[type]?.selectedSubType || "totalMessages"
  );
  const [selectedSubTypeLabel, setSelectedSubTypeLabel] = useState(
    "Total messages sent"
  );
  const [open, setOpen] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [dateRangeTemp, setDateRangeTemp] = useState(dateRangeArr[1]);
  const [filterObj, setFilterObj] = useState<any>(initValues);

  const validation: any = useFormik({
    enableReinitialize: false,
    initialValues: initValues,
    validationSchema: Yup.object({
      groupId: Yup.string().required("Please Select Group"),
    }),
    onSubmit: (values) => {
      setOpen(!open);
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        ...values,
        subGroupId: JSON.stringify(values.subGroupId),
        brandId: JSON.stringify(values.brandId),
        campaignId: JSON.stringify(values.campaignId),
        phoneNumber: JSON.stringify(values.phoneNumber),
        tag: JSON.stringify(values.tag),
        link: JSON.stringify(values.link),
        isFilter: "true",
      });
      analyticsRef?.current?.handleGetData(values);
      setFilterObj(values);

      setLocal("analyticsDateRangeTemp", JSON.stringify(dateRangeTemp));
      setLocal("analyticsStartDate", JSON.stringify(values.startDate));
      setLocal("analyticsEndDate", JSON.stringify(values.endDate));
    },
  });

  useEffect(() => {
    const startDate1: any = getLocal("analyticsStartDate")
      ? JSON.parse(getLocal("analyticsStartDate") || "")
      : "";
    const endDate1 = getLocal("analyticsEndDate")
      ? JSON.parse(getLocal("analyticsEndDate") || "")
      : "";

    const parsedDateRangeTemp = getLocal("analyticsDateRangeTemp")
      ? JSON.parse(getLocal("analyticsDateRangeTemp") || "")
      : {};
    const value = parsedDateRangeTemp?.value || "";
    const { startDate, endDate } = value
      ? handleDateFunction(value, startDate1, endDate1)
      : handleDateFunction(dateRangeArr[1]?.value);

    validation.setFieldValue("startDate", startDate);
    validation.setFieldValue("endDate", endDate);

    if (groupDetails?.records?.[0]?.group_id) {
      const temp = getLocal("analyticsDateRangeTemp");
      temp
        ? setDateRangeTemp(JSON.parse(temp))
        : setDateRangeTemp(dateRangeArr[1]);

      const createdAt = `${startDate} to ${endDate}`;

      validation.setFieldValue("groupId", groupDetails?.records?.[0]?.group_id);
      validation.setFieldValue("createdAt", createdAt);

      const temp1 = { ...Object.fromEntries(searchParams.entries()) };
      const temp2 = {
        ...validation.values,
        subGroupId: temp1?.subGroupId ? JSON.parse(temp1?.subGroupId) : [],
        brandId: temp1?.brandId ? JSON.parse(temp1?.brandId) : [],
        campaignId: temp1?.campaignId ? JSON.parse(temp1?.campaignId) : [],
        phoneNumber: temp1?.phoneNumber ? JSON.parse(temp1?.phoneNumber) : [],
        tag: temp1?.tag ? JSON.parse(temp1?.tag) : [],
        link: temp1?.link ? JSON.parse(temp1?.link) : [],
      };
      validation.setValues({
        ...temp2,
        groupId: groupDetails?.records?.[0]?.group_id,
        startDate,
        endDate,
        createdAt,
      });

      setFilterObj({
        ...temp2,
        groupId: groupDetails?.records?.[0]?.group_id,
        startDate,
        endDate,
        createdAt,
      });

      analyticsRef?.current?.handleGetData({
        ...temp2,
        groupId: groupDetails?.records?.[0]?.group_id,
        startDate,
        endDate,
      });
      setIsInit(false);
    }
  }, [groupDetails?.records?.[0]?.group_id]);

  useEffect(() => {
    if (groupDetails?.records?.[0]?.group_id && !isInit) {
      analyticsRef?.current?.handleGetData({ ...filterObj });
    }
  }, [selectedType]);

  const analyticsHeaderProps = useMemo(
    () => ({
      selectedType,
      setSelectedType,
      selectedType2,
      setSelectedType2,
      selectedSubType,
      setSelectedSubType,
      setSelectedSubTypeLabel,
      validation,
      setFilterObj,
      dateRangeTemp,
      setDateRangeTemp,
      datePickerModal,
      setDatePickerModal,
      setOpen,
      open,
      loading,
      handleGetData: analyticsRef?.current?.handleGetData,
      brandId: validation.values.brandId,
      subGroupId: validation.values.subGroupId,
      groupDetails,
    }),
    [
      selectedType,
      selectedType2,
      selectedSubType,
      validation,
      dateRangeTemp,
      datePickerModal,
      open,
      loading,
      analyticsRef?.current?.handleGetData,
      groupDetails,
    ]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <AnalyticsHeader {...analyticsHeaderProps} />
          <CommonAnalytics
            ref={analyticsRef}
            isInit={isInit}
            filterObj={filterObj}
            selectedType={selectedType}
            selectedType2={selectedType2}
            selectedSubType={selectedSubType}
            setSelectedSubType={setSelectedSubType}
            selectedSubTypeLabel={selectedSubTypeLabel}
            setSelectedSubTypeLabel={setSelectedSubTypeLabel}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Analytics);
