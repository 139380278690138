import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  success: false,
  successMsg: "",
  error: false,
  errorMsg: "",
  marketPlacesData: [],
};

const marketPlacesDataSlice = createSlice({
  name: "marketPlaces",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    marketPlacesDataSuccessful(state, action) {
      state.loading = false;
      state.marketPlacesData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    marketPlacesDataFailed(state, action) {
      state.loading = false;
      state.marketPlacesData = [];
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
  },
});

export const {
  apiLoading,
  marketPlacesDataSuccessful,
  marketPlacesDataFailed,
} = marketPlacesDataSlice.actions;

export default marketPlacesDataSlice.reducer;
