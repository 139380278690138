import {
  asyncSubgroupList,
  customSelectTheme,
  humanizeString,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import { Col, Form, Row } from "react-bootstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import Select from "react-select";
import Tooltip from "rc-tooltip";
import { useEffect, useRef } from "react";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { openModal } from "slices/thunk";

const Step1 = (props: any) => {
  const {
    validation,
    subgroupTemp,
    setSubgroupTemp,
    isActiveCamp,
    entityTypeOnSelectionChange,
    allState,
    allCity,
    handlePincodeBlur,
    brandName,
  } = props;

  const dispatch = useDispatch<any>();
  const { brand_id } = useParams();
  const inputRef = useRef<HTMLInputElement>(null);

  const selectEnums = createSelector(
    (state: any) => state.AccountPricing,
    (state: any) => state.Enums,
    (state: any) => state.Groups,
    (accountPricing, enums, groups) => ({
      walletPricingData: accountPricing.walletPricingData,
      verticalObj: enums.verticalObj,
      stockExchangeArr: enums.stockExchangeArr,
      countryObj: enums.countryObj,
      entityTypeArr: enums.entityTypeArr,
      error: enums.error,
      subgroupDetails: groups.subgroupDetails,
    })
  );

  const {
    walletPricingData,
    verticalObj,
    stockExchangeArr,
    countryObj,
    entityTypeArr,
    subgroupDetails,
  } = useSelector(selectEnums);

  const filteredBrand = subgroupDetails?.data?.brands?.filter(
    (dt: any) => dt?.brandId === brand_id
  );
  const campaigns = filteredBrand?.flatMap((dt: any) => dt.campaigns || []);
  const ownNumbers = campaigns?.flatMap((dt: any) => dt.ownNumbers || []);

  useEffect(() => {
    if (inputRef.current) {
      const autocomplete = new google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["geocode"], // Restrict the search to geographical location types
          // types: ["address"], // Restrict to address types
          // fields: ["address_components"], // Get only address components
          componentRestrictions: validation.values.country
            ? {
                country: validation.values.country,
              }
            : undefined,
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.address_components) {
          const addressComponents = place.address_components;
          const addressDetails = {
            street: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
          };

          addressComponents?.forEach((component: any) => {
            const types = component.types;
            if (types.includes("locality")) {
              addressDetails.city = component.long_name;
            } else if (types.includes("administrative_area_level_1")) {
              addressDetails.state = component.short_name;
            } else if (types.includes("country")) {
              addressDetails.country = component.short_name;
            } else if (types.includes("postal_code")) {
              addressDetails.zipcode = component.long_name;
            } else if (
              types.includes("street_number") ||
              types.includes("route")
              // || types.includes("neighborhood")
            ) {
              addressDetails.street += ` ${component.long_name}`;
            }
          });

          validation.setFieldValue(
            "street",
            addressDetails.street?.trim() || ""
          );
          validation.setFieldValue("city", addressDetails.city || "");
          validation.setFieldValue("state", addressDetails.state || "");
          validation.setFieldValue("country", addressDetails.country || "");
          validation.setFieldValue(
            "einIssuingCountry",
            addressDetails.country || ""
          );
          validation.setFieldValue("postalCode", addressDetails.zipcode || "");
        }
      });
    }

    return () => {
      if (inputRef.current) {
        google.maps.event.clearInstanceListeners(inputRef.current);
      }
    };
  }, [inputRef.current, validation.values.country]);

  // Helper function to check if field should be shown
  const shouldShowField = (fieldName: string) => {
    switch (fieldName) {
      // case "companyName":
      //   return validation.values.entityType !== "SOLE_PROPRIETOR";
      case "ein":
        return (
          validation.values.entityType !== "SOLE_PROPRIETOR" &&
          validation.values.entityType !== "GOVERNMENT"
        );
      case "stockSymbol":
      case "stockExchange":
        return validation.values.entityType === "PUBLIC_PROFIT";
      case "firstName":
      case "lastName":
      case "mobilePhone":
        return validation.values.entityType === "SOLE_PROPRIETOR";
      case "altBusinessId":
        return validation.values.altBusinessIdType;
      default:
        return true;
    }
  };

  return (
    <Row>
      <Col lg={6} className="mb-3">
        <Form.Label htmlFor="subGroupId">
          Sub Group <span className="text-primary">*</span>
        </Form.Label>
        <AsyncPaginate
          // isMulti={true}
          isDisabled={!validation.values.groupId}
          // closeMenuOnSelect={false}
          // hideSelectedOptions={false}
          menuPortalTarget={document.body}
          key={validation.values.groupId}
          styles={styleObj(
            validation?.touched?.subGroupId && validation?.errors?.subGroupId
          )}
          theme={customSelectTheme}
          components={{
            Option: (props: any) => {
              return (
                <div
                  ref={props.innerRef}
                  {...props.innerProps}
                  className={`selectItemWithCheckbox`}
                >
                  {props.children}
                </div>
              );
            },
          }}
          value={subgroupTemp || []}
          loadOptions={asyncSubgroupList(
            removeEmptyAndNullValues({
              groupId: validation.values.groupId,
            }),
            "subGroupId"
          )}
          getOptionValue={(option: any) => option?.sub_group_id}
          getOptionLabel={(option: any) =>
            `${option?.sub_group_id} - ${option?.sub_group_name}`
          }
          onChange={(option: any) => {
            if (brand_id) {
              dispatch(
                openModal({
                  path: "UpdateSubgroupWarning",
                  data: {
                    title: "Update Sub Group",
                    footer: true,
                    cancelBtn: true,
                    buttonText: {
                      submit: "Confirm",
                    },
                    onSubmit: ({ onClose }: any) => {
                      setSubgroupTemp([option]);
                      validation.setFieldValue("subGroupId", [
                        option?.sub_group_id,
                      ]);
                      onClose();
                    },
                    brandId: brand_id,
                    brandName: brandName || "",
                    option,
                    campaigns,
                    ownNumbers,
                  },
                })
              );
            } else {
              setSubgroupTemp([option]);
              validation.setFieldValue("subGroupId", [option?.sub_group_id]);
            }
          }}
          isSearchable={true}
          additional={{
            page: 1,
          }}
        />
        {validation?.touched?.subGroupId && validation?.errors?.subGroupId ? (
          <Form.Control.Feedback type="invalid" className="d-block">
            {validation?.errors?.subGroupId}
          </Form.Control.Feedback>
        ) : null}
      </Col>
      {shouldShowField("companyName") && (
        <Col lg={6} className="mb-3">
          <Form.Label htmlFor="companyName">
            Legal Company Name <span className="text-primary">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="companyName"
            disabled={
              validation.values.entityType === "SOLE_PROPRIETOR" || isActiveCamp
            }
            onChange={(e) => {
              validation.setFieldValue("companyName", e.target.value);
              validation.setFieldValue("displayName", e.target.value);
            }}
            onFocus={() => {
              validation.setFieldTouched("companyName", true, true);
            }}
            onBlur={() => {
              validation.setFieldTouched("companyName", true, true);
            }}
            value={validation.values.companyName}
            isInvalid={
              validation.touched.companyName && validation.errors.companyName
                ? true
                : false
            }
          />
          {brand_id && validation.touched.companyName && (
            <p className="my-1 text-primary">
              Updating the Company Legal Name field will require revetting of
              this Brand, charging a ${walletPricingData["brand.create"] || "4"}{" "}
              passthrough vetting fee.
            </p>
          )}
          {isActiveCamp && (
            <p className="my-1 text-primary">
              The legal company name field cannot be updated when there is an{" "}
              <Link
                to={`/campaigns?brandId=${brand_id}&tcrStatus=ACTIVE`}
                style={{ textDecoration: "underline" }}
              >
                Active Campaign.
              </Link>
            </p>
          )}
          {validation.touched.companyName && validation.errors.companyName ? (
            <Form.Control.Feedback type="invalid">
              {validation.errors.companyName}
            </Form.Control.Feedback>
          ) : null}
        </Col>
      )}
      <Col lg={6} className="mb-3">
        <Form.Label htmlFor="displayName">
          DBA or Brand Name, if different from legal name{" "}
          <span className="text-primary">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="displayName"
          onChange={validation?.handleChange}
          value={validation.values?.displayName}
          isInvalid={
            validation?.touched?.displayName && validation?.errors?.displayName
              ? true
              : false
          }
        />
        {validation.touched?.displayName && validation.errors?.displayName ? (
          <Form.Control.Feedback type="invalid">
            {validation.errors?.displayName}
          </Form.Control.Feedback>
        ) : null}
      </Col>
      <Col lg={6} className="mb-3">
        <Form.Label htmlFor="entityType">
          What type of legal form is the organization?{" "}
          <span className="text-primary">*</span>
        </Form.Label>
        <Select
          name="entityType"
          styles={styleObj(
            validation.touched.entityType && validation.errors.entityType
          )}
          theme={customSelectTheme}
          isDisabled={isActiveCamp}
          options={
            Array.isArray(entityTypeArr) && entityTypeArr?.length > 0
              ? entityTypeArr?.map((dt: string) => ({
                  label: humanizeString(dt),
                  value: dt,
                }))
              : []
          }
          onChange={(e: { value: string }) => {
            entityTypeOnSelectionChange(e.value);
          }}
          onFocus={() => {
            validation.setFieldTouched("entityType", true, true);
          }}
          onBlur={() => {
            validation.setFieldTouched("entityType", true, true);
          }}
          value={{
            label: humanizeString(validation?.values?.entityType),
            value: validation?.values?.entityType,
          }}
        />
        {brand_id && validation.touched.entityType && (
          <p className="my-1 text-primary">
            Updating the Entity Type will require revetting of this Brand,
            charging a ${walletPricingData["brand.create"] || "4"} passthrough
            fee.
          </p>
        )}
        {isActiveCamp && (
          <p className="my-1 text-primary">
            The entity type field cannot be updated when there is an{" "}
            <Link
              to={`/campaigns?brandId=${brand_id}&tcrStatus=ACTIVE`}
              style={{ textDecoration: "underline" }}
            >
              Active Campaign.
            </Link>
          </p>
        )}
        {validation.touched.entityType && validation.errors.entityType ? (
          <Form.Control.Feedback type="invalid" className="d-block">
            {validation?.errors?.entityType}
          </Form.Control.Feedback>
        ) : null}
      </Col>
      <Col lg={6} className="mb-3">
        <Form.Label htmlFor="country">
          Country of Registration <span className="text-primary">*</span>
        </Form.Label>
        <Select
          name="country"
          styles={styleObj(
            validation.touched.country && validation.errors.country
          )}
          theme={customSelectTheme}
          isDisabled={
            validation.values.entityType === "" ||
            validation.values.entityType === "GOVERNMENT" ||
            isActiveCamp
          }
          options={
            typeof countryObj === "object" &&
            countryObj !== null &&
            Object.keys(countryObj)?.length > 0
              ? Object.keys(countryObj)?.map((dt: string) => ({
                  label: countryObj[dt],
                  value: dt,
                }))
              : []
          }
          onChange={(e: { value: string }) => {
            validation.setFieldValue("country", e.value);
            validation.setFieldValue("einIssuingCountry", e.value);
            validation.setFieldValue("street", "");
            validation.setFieldValue("city", "");
            validation.setFieldValue("state", "");
            validation.setFieldValue("postalCode", "");
          }}
          onFocus={() => {
            validation.setFieldTouched("country", true, true);
          }}
          onBlur={() => {
            validation.setFieldTouched("country", true, true);
          }}
          value={
            typeof countryObj === "object" &&
            countryObj !== null &&
            Object.keys(countryObj)?.length > 0
              ? {
                  label: countryObj[validation.values.country],
                  value: validation.values.country,
                }
              : ""
          }
        />
        {brand_id && validation.touched.country && (
          <p className="my-1 text-primary">
            Updating the registration country will require revetting of this
            Brand, charging a ${walletPricingData["brand.create"] || "4"}{" "}
            passthrough fee.
          </p>
        )}
        {isActiveCamp && (
          <p className="my-1 text-primary">
            The registration country field cannot be updated when there is an{" "}
            <Link
              to={`/campaigns?brandId=${brand_id}&tcrStatus=ACTIVE`}
              style={{ textDecoration: "underline" }}
            >
              Active Campaign.
            </Link>
          </p>
        )}
        {validation.touched.country && validation.errors.country ? (
          <Form.Control.Feedback type="invalid" className="d-block">
            {validation.errors.country}
          </Form.Control.Feedback>
        ) : null}
      </Col>
      {shouldShowField("ein") && (
        <Col lg={6} className="mb-3">
          <Form.Label htmlFor="ein">
            {validation.values.country === "US"
              ? "EIN"
              : !validation.values.country
              ? "Tax Number/ID/EIN"
              : "Tax Number/ID"}{" "}
            <span className="text-primary">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="ein"
            disabled={
              !validation.values.country ||
              validation.values.entityType === "SOLE_PROPRIETOR" ||
              isActiveCamp
            }
            onChange={(e) => {
              const formattedValue = e.target.value
                .replace(/[^0-9a-zA-Z]/g, "")
                .replace(/^(.{2})(.{0,7})/, "$1-$2")
                .slice(0, 10);
              validation.setFieldValue("ein", formattedValue);
            }}
            onFocus={() => {
              validation.setFieldTouched("ein", true, true);
            }}
            onBlur={() => {
              validation.setFieldTouched("ein", true, true);
            }}
            value={validation.values.ein}
            isInvalid={
              validation.touched.ein && validation.errors.ein ? true : false
            }
          />
          {brand_id && validation.touched.ein && (
            <p className="my-1 text-primary">
              Updating the EIN will require revetting of this Brand, charging a
              ${walletPricingData["brand.create"] || "4"} passthrough fee.
            </p>
          )}
          {isActiveCamp && (
            <p className="my-1 text-primary">
              The EIN field cannot be updated when there is an{" "}
              <Link
                to={`/campaigns?brandId=${brand_id}&tcrStatus=ACTIVE`}
                style={{ textDecoration: "underline" }}
              >
                Active Campaign.
              </Link>
            </p>
          )}
          {validation.touched.ein && validation.errors.ein ? (
            <Form.Control.Feedback type="invalid">
              {validation.errors.ein}
            </Form.Control.Feedback>
          ) : null}
        </Col>
      )}
      <Col lg={6} className="mb-3">
        <Form.Label htmlFor="altBusinessIdType">
          DUNS or GIIN or LEI Number
        </Form.Label>
        <Select
          name="altBusinessIdType"
          styles={styleObj(
            validation.touched.altBusinessIdType &&
              validation.errors.altBusinessIdType
          )}
          theme={customSelectTheme}
          isDisabled={!validation.values.country}
          options={[
            {
              label: "DUNS",
              value: "DUNS",
            },
            {
              label: "GIIN",
              value: "GIIN",
            },
            {
              label: "LEI",
              value: "LEI",
            },
          ]}
          onChange={(e: { value: string }) =>
            validation.setFieldValue("altBusinessIdType", e.value)
          }
          value={{
            label: validation.values.altBusinessIdType,
            value: validation.values.altBusinessIdType,
          }}
        />
        {validation.touched.altBusinessIdType &&
        validation.errors.altBusinessIdType ? (
          <Form.Control.Feedback type="invalid" className="d-block">
            {validation.errors.altBusinessIdType}
          </Form.Control.Feedback>
        ) : null}
      </Col>
      {shouldShowField("altBusinessId") && (
        <Col lg={6} className="mb-3">
          <Form.Label htmlFor="altBusinessId">
            {!validation.values.altBusinessIdType
              ? "DUNS or GIIN or LEI "
              : `${validation.values.altBusinessIdType} `}
            Number
          </Form.Label>
          <Form.Control
            type="text"
            name="altBusinessId"
            disabled={!validation.values.altBusinessIdType}
            onChange={validation.handleChange}
            value={validation.values.altBusinessId}
            isInvalid={
              validation.touched.altBusinessId &&
              validation.errors.altBusinessId
                ? true
                : false
            }
          />
          {validation.touched.altBusinessId &&
          validation.errors.altBusinessId ? (
            <Form.Control.Feedback type="invalid">
              {validation.errors.altBusinessId}
            </Form.Control.Feedback>
          ) : null}
        </Col>
      )}
      <Col lg={6} className="mb-3">
        <Form.Label htmlFor="street">
          Address/Street <span className="text-primary">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          id="street"
          name="street"
          autoComplete="off"
          ref={inputRef}
          disabled={!validation.values.country}
          placeholder="Search for address"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.street}
          isInvalid={
            validation.touched.street && validation.errors.street ? true : false
          }
        />
        {validation.touched.street && validation.errors.street ? (
          <Form.Control.Feedback type="invalid">
            {validation.errors.street}
          </Form.Control.Feedback>
        ) : null}
      </Col>
      <Col lg={6} className="mb-3">
        <Form.Label htmlFor="state">
          State/Region <span className="text-primary">*</span>
        </Form.Label>
        <Select
          menuPortalTarget={document.body}
          name="state"
          styles={styleObj(validation.touched.state && validation.errors.state)}
          theme={customSelectTheme}
          isDisabled={!validation.values.country}
          isClearable={true}
          options={
            typeof allState === "object" &&
            allState !== null &&
            Object.keys(allState)?.length > 0
              ? Object.keys(allState)?.map((dt: string) => ({
                  label: allState[dt],
                  value: dt,
                }))
              : []
          }
          onChange={(e: { value?: string }) => {
            if (e) {
              validation.setFieldValue("state", e.value ? e.value : "");
              validation.setFieldValue("city", "");
              validation.setFieldValue("postalCode", "");
            } else {
              validation.setFieldValue("state", "");
            }
          }}
          value={
            typeof allState === "object" &&
            allState !== null &&
            Object.keys(allState)?.length > 0
              ? {
                  label: allState[validation.values.state],
                  value: validation.values.state,
                }
              : ""
          }
        />
        {validation.touched.state && validation.errors.state ? (
          <Form.Control.Feedback type="invalid" className="d-block">
            {validation.errors.state}
          </Form.Control.Feedback>
        ) : null}
      </Col>
      <Col lg={6} className="mb-3">
        <Form.Label htmlFor="city">
          City <span className="text-primary">*</span>
        </Form.Label>
        <Select
          menuPortalTarget={document.body}
          name="city"
          styles={styleObj(validation.touched.city && validation.errors.city)}
          theme={customSelectTheme}
          isDisabled={!validation.values.state}
          isClearable={true}
          options={
            allCity?.length > 0
              ? allCity?.map((dt: { name: string }) => ({
                  label: dt?.name,
                  value: dt?.name,
                }))
              : []
          }
          onChange={(e: { value?: string }) => {
            if (e) {
              validation.setFieldValue("city", e.value ? e.value : "");
              validation.setFieldValue("postalCode", "");
            } else {
              validation.setFieldValue("city", "");
            }
          }}
          value={{
            label: validation.values.city,
            value: validation.values.city,
          }}
        />
        {validation.touched.city && validation.errors.city ? (
          <Form.Control.Feedback type="invalid" className="d-block">
            {validation.errors.city}
          </Form.Control.Feedback>
        ) : null}
      </Col>
      <Col lg={6} className="mb-3">
        <Form.Label htmlFor="postalCode">
          Postal Code/ZIP Code <span className="text-primary">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="postalCode"
          disabled={!validation.values.country}
          onChange={validation.handleChange}
          onBlur={handlePincodeBlur}
          value={validation.values.postalCode}
          isInvalid={
            validation.touched.postalCode && validation.errors.postalCode
              ? true
              : false
          }
        />
        {validation.touched.postalCode && validation.errors.postalCode ? (
          <Form.Control.Feedback type="invalid">
            {validation.errors.postalCode}
          </Form.Control.Feedback>
        ) : null}
      </Col>
      <Col lg={6} className="mb-3">
        <Form.Label htmlFor="website" className="position-relative">
          Website/Online Presence
          <Tooltip
            placement="bottom"
            overlay={
              "Required if opt-in is gathered on your website. Domain should match the company name or DBA, if present."
            }
          >
            <i
              className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
              style={{ bottom: -2 }}
            />
          </Tooltip>
        </Form.Label>
        <Form.Control
          type="url"
          name="website"
          disabled={!validation.values.country}
          onChange={validation.handleChange}
          value={validation.values.website}
          isInvalid={
            validation.touched.website && validation.errors.website
              ? true
              : false
          }
        />
        {validation.touched.website && validation.errors.website ? (
          <Form.Control.Feedback type="invalid">
            {validation.errors.website}
          </Form.Control.Feedback>
        ) : null}
      </Col>
      {shouldShowField("stockSymbol") && (
        <Col lg={6} className="mb-3">
          <Form.Label htmlFor="stockSymbol">
            Stock Symbol{" "}
            {validation.values.entityType === "PUBLIC_PROFIT" ? (
              <span className="text-primary">*</span>
            ) : null}
          </Form.Label>
          <Form.Control
            type="text"
            name="stockSymbol"
            disabled={
              !validation.values.country ||
              validation.values.entityType !== "PUBLIC_PROFIT"
            }
            onChange={validation.handleChange}
            value={validation.values.stockSymbol}
            isInvalid={
              validation.touched.stockSymbol && validation.errors.stockSymbol
                ? true
                : false
            }
          />
          {validation.touched.stockSymbol && validation.errors.stockSymbol ? (
            <Form.Control.Feedback type="invalid">
              {validation.errors.stockSymbol}
            </Form.Control.Feedback>
          ) : null}
        </Col>
      )}
      {shouldShowField("stockExchange") && (
        <Col lg={6} className="mb-3">
          <Form.Label htmlFor="stockExchange">
            Stock Exchange{" "}
            {validation.values.entityType === "PUBLIC_PROFIT" ? (
              <span className="text-primary">*</span>
            ) : null}
          </Form.Label>
          <Select
            name="stockExchange"
            styles={styleObj(
              validation.touched.stockExchange &&
                validation.errors.stockExchange
            )}
            theme={customSelectTheme}
            isDisabled={
              !validation.values.country ||
              validation.values.entityType !== "PUBLIC_PROFIT"
            }
            options={
              Array.isArray(stockExchangeArr) && stockExchangeArr?.length > 0
                ? stockExchangeArr?.map((dt: string) => ({
                    label: dt,
                    value: dt,
                  }))
                : []
            }
            onChange={(e: { value: string }) =>
              validation.setFieldValue("stockExchange", e.value)
            }
            value={{
              label: validation.values.stockExchange,
              value: validation.values.stockExchange,
            }}
          />
          {validation.touched.stockExchange &&
          validation.errors.stockExchange ? (
            <Form.Control.Feedback type="invalid" className="d-block">
              {validation.errors.stockExchange}
            </Form.Control.Feedback>
          ) : null}
        </Col>
      )}
      <Col lg={6} className="mb-3">
        <Form.Label htmlFor="vertical">
          Vertical Type{" "}
          {validation.values.entityType === "SOLE_PROPRIETOR" ? null : (
            <span className="text-primary">*</span>
          )}
        </Form.Label>
        <Select
          name="vertical"
          styles={styleObj(
            validation.touched.vertical && validation.errors.vertical
          )}
          theme={customSelectTheme}
          isDisabled={!validation.values.country}
          options={
            typeof verticalObj === "object" &&
            verticalObj !== null &&
            Object.keys(verticalObj)?.length > 0
              ? Object.keys(verticalObj)?.map((dt: string) => ({
                  label: verticalObj[dt]?.displayName,
                  value: dt,
                }))
              : []
          }
          onChange={(e: { value: string }) =>
            validation.setFieldValue("vertical", e.value)
          }
          value={
            typeof verticalObj === "object" &&
            verticalObj !== null &&
            Object.keys(verticalObj)?.length > 0
              ? {
                  label: verticalObj[validation.values.vertical]?.displayName,
                  value: validation.values.vertical,
                }
              : ""
          }
        />
        {validation.touched.vertical && validation.errors.vertical ? (
          <Form.Control.Feedback type="invalid" className="d-block">
            {validation.errors.vertical}
          </Form.Control.Feedback>
        ) : null}
      </Col>
      <Col lg={6} className="mb-3">
        <Form.Label htmlFor="ctaLink" className="position-relative">
          Opt-in Link <span className="text-primary">*</span>
          <Tooltip
            placement="bottom"
            overlay={
              "Please provide the link for where a customer Opts into your messaging on your website (contact us, etc.)"
            }
          >
            <i
              className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
              style={{ bottom: -2 }}
            />
          </Tooltip>
        </Form.Label>
        <Form.Control
          type="url"
          name="ctaLink"
          // disabled={!validation.values.country}
          onChange={validation.handleChange}
          value={validation.values.ctaLink}
          isInvalid={
            validation.touched.ctaLink && validation.errors.ctaLink
              ? true
              : false
          }
        />
        {validation.touched.ctaLink && validation.errors.ctaLink ? (
          <Form.Control.Feedback type="invalid">
            {validation.errors.ctaLink}
          </Form.Control.Feedback>
        ) : null}
      </Col>
      <Col lg={6} className="mb-3">
        <Form.Group className="mb-3">
          <Form.Label>
            Privacy Policy Link <span className="text-primary">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="privacyPolicyLink"
            placeholder="Enter Privacy Policy Link"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.privacyPolicyLink}
            isInvalid={
              validation.touched.privacyPolicyLink &&
              validation.errors.privacyPolicyLink
            }
          />
          {validation.touched.privacyPolicyLink &&
          validation.errors.privacyPolicyLink ? (
            <Form.Control.Feedback type="invalid">
              {validation.errors.privacyPolicyLink}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
      </Col>
      {shouldShowField("firstName") && (
        <Col lg={6} className="mb-3">
          <Form.Label htmlFor="firstName" className="position-relative">
            First Name{" "}
            {validation.values.entityType === "SOLE_PROPRIETOR" ? (
              <span className="text-primary">*</span>
            ) : null}
            <Tooltip
              placement="bottom"
              overlay={"Only applicable when entity is Sole Proprietor"}
            >
              <i
                className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                style={{ bottom: -2 }}
              />
            </Tooltip>
          </Form.Label>
          <Form.Control
            type="text"
            name="firstName"
            disabled={
              validation.values.entityType !== "SOLE_PROPRIETOR" ||
              !validation.values.country
            }
            onChange={validation.handleChange}
            value={validation.values.firstName}
            isInvalid={
              validation.touched.firstName && validation.errors.firstName
                ? true
                : false
            }
          />
          {validation.touched.firstName && validation.errors.firstName ? (
            <Form.Control.Feedback type="invalid">
              {validation.errors.firstName}
            </Form.Control.Feedback>
          ) : null}
        </Col>
      )}
      {shouldShowField("lastName") && (
        <Col lg={6} className="mb-3">
          <Form.Label htmlFor="lastName" className="position-relative">
            Last Name{" "}
            {validation.values.entityType === "SOLE_PROPRIETOR" ? (
              <span className="text-primary">*</span>
            ) : null}
            <Tooltip
              placement="bottom"
              overlay={"Only applicable when entity is Sole Proprietor"}
            >
              <i
                className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                style={{ bottom: -2 }}
              />
            </Tooltip>
          </Form.Label>
          <Form.Control
            type="text"
            name="lastName"
            disabled={
              validation.values.entityType !== "SOLE_PROPRIETOR" ||
              !validation.values.country
            }
            onChange={validation.handleChange}
            value={validation.values.lastName}
            isInvalid={
              validation.touched.lastName && validation.errors.lastName
                ? true
                : false
            }
          />
          {validation.touched.lastName && validation.errors.lastName ? (
            <Form.Control.Feedback type="invalid">
              {validation.errors.lastName}
            </Form.Control.Feedback>
          ) : null}
        </Col>
      )}
      {shouldShowField("mobilePhone") && (
        <Col lg={6} className="mb-3">
          <Form.Label htmlFor="mobilePhone" className="position-relative">
            Mobile Phone{" "}
            {validation.values.entityType === "SOLE_PROPRIETOR" ? (
              <span className="text-primary">*</span>
            ) : null}
            <Tooltip
              placement="bottom"
              overlay={"Only applicable when entity is Sole Proprietor"}
            >
              <i
                className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                style={{ bottom: -2 }}
              />
            </Tooltip>
          </Form.Label>
          <Form.Control
            type="text"
            name="mobilePhone"
            disabled={
              validation.values.entityType !== "SOLE_PROPRIETOR" ||
              !validation.values.country
            }
            onChange={validation.handleChange}
            value={validation.values.mobilePhone}
            isInvalid={
              validation.touched.mobilePhone && validation.errors.mobilePhone
                ? true
                : false
            }
          />
          {validation.touched.mobilePhone && validation.errors.mobilePhone ? (
            <Form.Control.Feedback type="invalid">
              {validation.errors.mobilePhone}
            </Form.Control.Feedback>
          ) : null}
        </Col>
      )}
    </Row>
  );
};

export default Step1;
