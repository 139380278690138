// @ts-nocheck

import React, { useEffect, useState, useCallback, useRef } from "react";
import { Col, Form, Row, Button, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { createSelector } from "reselect";
import withRouter from "Common/withRouter";
import BreadCrumb from "Common/BreadCrumb";
import Tooltip from "rc-tooltip";
import { UnknownAction } from "@reduxjs/toolkit";
import {
  GetConfiguredNumber,
  configureNumbers,
  getDetailsOfOwnNumber,
  tenDLCWhitelistThunk,
} from "slices/thunk";
import {
  asyncBrandList,
  asyncCampaignList,
  asyncSubgroupList,
  customSelectTheme,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import { brandListApi, subgroupListApi } from "helpers/apiroute_helper";
import axios from "axios";
import { getLocal } from "helpers/services/auth/auth";
import { getConfigureNumber } from "slices/configureNumbers/reducer";

// Types
interface FormValues {
  sid: string;
  phoneNumber: string;
  groupId: string;
  subGroupId: any[];
  isCnpCampaign: boolean;
  brandId: string;
  campaignId: string;
  urlOne_Message: string;
  urlTwo_Message: string;
}

// Constants
const INITIAL_VALUES: FormValues = {
  sid: "",
  phoneNumber: "",
  groupId: "",
  subGroupId: [],
  isCnpCampaign: false,
  brandId: "",
  campaignId: "",
  urlOne_Message: "",
  urlTwo_Message: "",
};

const VALIDATION_SCHEMA = Yup.object().shape({
  groupId: Yup.string().required("Group is required"),
  subGroupId: Yup.array()
    .min(1, "Subgroup must be selected")
    .required("Subgroups are required"),
  campaignId: Yup.string().when(
    ["isCnpCampaign", "brandId"],
    ([isCnpCampaign, brandId]) => {
      if (isCnpCampaign || brandId) {
        return Yup.string().required("Please select a campaign");
      }
      return Yup.string().notRequired();
    }
  ),
  urlOne_Message: Yup.string().url("Invalid URL").notRequired(),
  urlTwo_Message: Yup.string().url("Invalid URL").notRequired(),
});

const ConfigureNumbers = () => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasSetValues = useRef(false);
  const hasSetBrandValues = useRef(false);
  const { phoneNumber, sid } = useParams();

  // State
  const [brandTemp, setBrandTemp] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubgrpCalling, setIsSubgrpCalling] = useState(false);
  const [isBrandCalling, setIsBrandCalling] = useState(false);
  const [previousValues, setPreviousValues] = useState({
    brandId: null,
    campaignId: null,
    displayName: null,
    brandSubGroupId: [],
  });

  // Selectors
  const selectAccount = createSelector(
    [
      (state) => state.Groups,
      (state) => state.ConfigureNumbers,
      (state) => state.Numbers,
    ],
    (groups, configureNumbers, numbers) => ({
      groupDetails: groups?.AllGroups,
      configNumberDetails: configureNumbers?.data,
      phoneNumberDetails: numbers?.numberDetails,
    })
  );

  const { groupDetails, configNumberDetails, phoneNumberDetails } =
    useSelector(selectAccount);

  // Form Handlers
  const handleSubmit = async (values: FormValues) => {
    const handleDLCSubmit = async () => {
      const whitelistPayload = {
        phoneNumbers: [String(phoneNumber)],
        campaignId: values?.campaignId || "",
        subGroupId: values.subGroupId?.[0]?.sub_group_id || "",
      };

      try {
        await dispatch(
          tenDLCWhitelistThunk(whitelistPayload, true, () => {
            dispatch(
              getDetailsOfOwnNumber({
                phoneNumber: phoneNumber,
              })
            );
          })
        );
        setIsSubmit(false);
      } catch {
        setIsSubmit(false);
      }
    };

    const updateConfigAndDetails = async () => {
      setIsSubmit(true);
      const updatedPayload = {
        sid,
        phoneNumber,
        groupId: values.groupId,
        messagingPrimaryUrl: values.urlOne_Message,
        messagingSecondaryUrl: values.urlTwo_Message,
      };

      try {
        await dispatch(configureNumbers(updatedPayload));
        if (
          phoneNumberDetails?.tenDLCWhiteListStatus?.toLowerCase() !==
          "in-progress"
        ) {
          await handleDLCSubmit();
        } else {
          setIsSubmit(false);
        }
      } catch {
        setIsSubmit(false);
      }
    };

    if (
      (configNumberDetails?.aMessageComesIn?.[0]?.url || "") !==
        values.urlOne_Message ||
      (configNumberDetails?.primaryHandler?.[0]?.url || "") !==
        values.urlTwo_Message
    ) {
      await updateConfigAndDetails();
    } else {
      setIsSubmit(true);
      await handleDLCSubmit();
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: handleSubmit,
  });

  // Effects
  useEffect(() => {
    return () => {
      formik.resetForm();
      dispatch(getConfigureNumber({}));
    };
  }, []);

  useEffect(() => {
    if (phoneNumber) {
      dispatch(GetConfiguredNumber({ phoneNumber }));
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (
      !hasSetBrandValues.current &&
      (phoneNumberDetails?.brandId || phoneNumberDetails?.campaignId) &&
      formik.values.subGroupId?.length > 0
    ) {
      const isCnpCampaign =
        !phoneNumberDetails?.brandId && phoneNumberDetails?.campaignId;
      fetchBrandNames(phoneNumberDetails?.brandId);
      formik.setValues({
        ...formik.values,
        brandId: phoneNumberDetails?.brandId || "",
        campaignId: phoneNumberDetails?.campaignId || "",
        isCnpCampaign: isCnpCampaign,
      });
      hasSetBrandValues.current = true;
    }
  }, [phoneNumberDetails, formik.values.subGroupId]);

  useEffect(() => {
    fetchSubGroupNames(phoneNumberDetails?.subGroupId);
  }, [phoneNumberDetails?.subGroupId]);

  const fetchSubGroupNames = async (ids: any) => {
    if (ids?.length > 0) {
      setIsSubgrpCalling(true);
      try {
        let data: any = await subgroupListApi(
          1,
          2,
          removeEmptyAndNullValues({ subGroupId: ids.length > 0 ? ids : "" })
        );

        if (!data?.code && !data?.errorCode) {
          formik.setFieldValue("subGroupId", data?.records || []);
        }
      } catch (error: any) {
        console.error("Error fetching sub group names:", error);
        formik.setFieldValue("subGroupId", []);
      }
      setIsSubgrpCalling(false);
    } else {
      formik.setFieldValue("subGroupId", []);
    }
  };

  const fetchBrandNames = async (id: any) => {
    if (id) {
      setIsBrandCalling(true);
      try {
        let data: any = await brandListApi(
          1,
          2,
          removeEmptyAndNullValues({ brandId: id })
        );

        if (!data?.code && !data?.errorCode) {
          setBrandTemp(data?.records?.[0] || {});
          formik.setFieldValue("brandId", data?.records?.[0]?.brandId || "");
        }
      } catch (error: any) {
        console.error("Error fetching brand names:", error);
        setBrandTemp({});
        formik.setFieldValue("brandId", "");
      }
      setIsBrandCalling(false);
    } else {
      setBrandTemp({});
      formik.setFieldValue("brandId", "");
    }
  };

  useEffect(() => {
    if (groupDetails?.records?.[0]?.group_id) {
      formik.setValues({
        ...formik.values,
        groupId: groupDetails?.records?.[0]?.group_id || "",
      });
    }
  }, [groupDetails]);

  useEffect(() => {
    if (
      !hasSetValues.current &&
      groupDetails?.records?.[0]?.group_id &&
      configNumberDetails &&
      Object.keys(configNumberDetails)?.length > 0
    ) {
      formik.setValues({
        ...formik.values,
        groupId: groupDetails?.records?.[0]?.group_id || "",
        urlOne_Message: configNumberDetails?.aMessageComesIn?.[0]?.url || "",
        urlTwo_Message: configNumberDetails?.primaryHandler?.[0]?.url || "",
      });
      hasSetValues.current = true;
    }
  }, [configNumberDetails, groupDetails]);

  const handleReset = () => {
    const isCnpCampaign =
      !phoneNumberDetails?.brandId && phoneNumberDetails?.campaignId;
    formik.setValues({
      ...formik.initialValues,
      groupId: groupDetails?.records?.[0]?.group_id || "",
      subGroupId: phoneNumberDetails?.subGroupId || [],
      isCnpCampaign: isCnpCampaign,
      brandId: phoneNumberDetails?.brandId || "",
      campaignId: phoneNumberDetails?.campaignId || "",
      urlOne_Message: configNumberDetails?.aMessageComesIn?.[0]?.url || "",
      urlTwo_Message: configNumberDetails?.primaryHandler?.[0]?.url || "",
    });
    fetchBrandNames(phoneNumberDetails?.brandId);
    fetchSubGroupNames(phoneNumberDetails?.subGroupId);
  };

  // Render Components
  const renderSubGroupSection = () => (
    <Col lg={12} className="mb-4">
      <Row className="border bg-light-gray rounded-3 p-3">
        {formik.touched.groupId && formik.errors.groupId && (
          <Form.Control.Feedback type="invalid" className="d-block mt-0 mb-2">
            {formik.errors.groupId}
          </Form.Control.Feedback>
        )}
        <Col lg={4}>
          <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
          {!isSubgrpCalling &&
            !isBrandCalling &&
            formik.values.brandId &&
            (brandTemp?.subGroupId?.length < 1 ||
              !formik.values.subGroupId?.[0]?.sub_group_id ||
              !brandTemp?.subGroupId?.includes(
                formik.values.subGroupId?.[0]?.sub_group_id
              )) && (
              <p className="text-primary mb-1">
                {formik.values.brandId || "Brand"} is not in a subgroup
              </p>
            )}
          <AsyncPaginate
            isDisabled={
              !formik.values.groupId ||
              phoneNumberDetails?.tenDLCWhiteListStatus?.toLowerCase() ===
                "in-progress"
            }
            menuPortalTarget={document.body}
            key={formik.values.groupId}
            isClearable={true}
            styles={styleObj(
              formik.touched.subGroupId && formik.errors.subGroupId
            )}
            theme={customSelectTheme}
            value={
              formik.values.subGroupId?.[0]?.sub_group_id
                ? formik.values.subGroupId
                : null
            }
            loadOptions={asyncSubgroupList(
              removeEmptyAndNullValues({
                groupId: groupDetails?.records?.[0]?.group_id,
              }),
              "subGroupId"
            )}
            getOptionValue={(option) => option?.sub_group_id}
            getOptionLabel={(option: any) =>
              `${option?.sub_group_id} - ${option?.sub_group_name}`
            }
            onChange={(option) => {
              if (option) {
                formik.setFieldValue("subGroupId", [option]);
              } else {
                formik.setFieldValue("subGroupId", []);
              }
              formik.setFieldValue("isCnpCampaign", false);
              setBrandTemp({});
              formik.setFieldValue("brandId", "");
              formik.setFieldValue("campaignId", "");
            }}
            isSearchable={true}
            additional={{
              page: 1,
            }}
          />
          {phoneNumberDetails?.tenDLCWhiteListStatus?.toLowerCase() ===
            "in-progress" && (
            <p className="my-1 text-primary">
              The sub group field cannot be updated when number status is{" "}
              {(phoneNumberDetails.tenDLCWhiteListStatus || "N/A")?.replace(
                "-",
                " "
              )}
            </p>
          )}
          {formik.touched.subGroupId && formik.errors.subGroupId && (
            <Form.Control.Feedback type="invalid" className="d-block">
              {formik.errors.subGroupId}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>
    </Col>
  );

  const renderTenDLCSection = () => (
    <Col lg={12} className="mb-4">
      <Row className="border bg-light-gray rounded-3 p-3">
        {/* <div className="d-flex align-items-center mb-2">
          <h6 className="mb-0 me-2">CNP Campaign Provisioning</h6>
          <div className="form-check form-switch form-switch-md" dir="ltr">
            <Form.Check
              type="checkbox"
              role="switch"
              disabled={
                phoneNumberDetails?.tenDLCWhiteListStatus?.toLowerCase() ===
                "in-progress"
              }
              checked={formik.values.isCnpCampaign}
              onChange={() => {
                const isCurrentlyCnpCampaign = formik.values.isCnpCampaign;

                if (!isCurrentlyCnpCampaign) {
                  setPreviousValues({
                    brandId: formik.values.brandId,
                    campaignId: formik.values.campaignId,
                    displayName: brandTemp?.displayName,
                    brandSubGroupId: brandTemp?.subGroupId,
                  });
                  setBrandTemp({});
                  formik.setFieldValue("brandId", "");
                  formik.setFieldValue("campaignId", "");
                } else {
                  setBrandTemp({
                    brandId: previousValues.brandId,
                    displayName: previousValues.displayName,
                    subGroupId: previousValues.brandSubGroupId,
                  });
                  formik.setFieldValue("brandId", previousValues.brandId);
                  formik.setFieldValue("campaignId", previousValues.campaignId);
                }

                formik.setFieldValue("isCnpCampaign", !isCurrentlyCnpCampaign);
              }}
            />
          </div>
        </div> */}

        {!formik.values.isCnpCampaign && (
          <Col lg={4} className="mb-lg-0 mb-4">
            <Form.Label htmlFor="brandId">Brand</Form.Label>
            <AsyncPaginate
              key={`${formik.values.subGroupId?.[0]?.sub_group_id}`}
              isDisabled={
                phoneNumberDetails?.tenDLCWhiteListStatus?.toLowerCase() ===
                "in-progress"
              }
              isClearable={true}
              styles={styleObj(
                formik?.touched?.brandId && formik?.errors?.brandId
              )}
              theme={customSelectTheme}
              value={formik.values.brandId ? brandTemp : null}
              loadOptions={asyncBrandList(
                removeEmptyAndNullValues({
                  subGroupId: formik.values.subGroupId?.[0]?.sub_group_id || "",
                }),
                "brandId"
              )}
              getOptionValue={(option: any) => option?.brandId}
              getOptionLabel={(option: any) =>
                `${option?.brandId} - ${option?.displayName}`
              }
              onChange={(option: any) => {
                if (option && option.brandId === formik.values.brandId) {
                  return;
                }
                if (option) {
                  fetchSubGroupNames(option.subGroupId);
                  setBrandTemp(option);
                  formik.setFieldValue("brandId", option.brandId);
                  formik.setFieldValue("campaignId", "");
                } else {
                  setBrandTemp({});
                  formik.setFieldValue("brandId", "");
                  formik.setFieldValue("campaignId", "");
                  setCampaignDetails(null);
                }
              }}
              isSearchable={true}
              additional={{
                page: 1,
              }}
            />
            {phoneNumberDetails?.tenDLCWhiteListStatus?.toLowerCase() ===
              "in-progress" && (
              <p className="my-1 text-primary">
                The brand field cannot be updated when number status is{" "}
                {(phoneNumberDetails.tenDLCWhiteListStatus || "N/A")?.replace(
                  "-",
                  " "
                )}
              </p>
            )}
            {formik?.touched?.brandId && formik?.errors?.brandId && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {formik?.errors?.brandId}
              </Form.Control.Feedback>
            )}
          </Col>
        )}

        <Col lg={4}>
          <Form.Label htmlFor="campaignId">Campaign</Form.Label>
          <AsyncPaginate
            key={
              formik.values.isCnpCampaign || formik.values.brandId
                ? `${formik.values.isCnpCampaign}-${formik.values.brandId}`
                : formik.values.subGroupId?.[0]?.sub_group_id
            }
            isDisabled={
              phoneNumberDetails?.tenDLCWhiteListStatus?.toLowerCase() ===
              "in-progress"
            }
            isClearable={true}
            styles={styleObj(
              formik?.touched?.campaignId && formik?.errors?.campaignId
            )}
            theme={customSelectTheme}
            value={
              formik.values.campaignId
                ? {
                    [formik?.values?.campaignId?.startsWith("C")
                      ? "campaignId"
                      : "campaignUuid"]: formik.values.campaignId,
                  }
                : null
            }
            loadOptions={asyncCampaignList(
              removeEmptyAndNullValues({
                subGroupId: formik.values.subGroupId?.[0]?.sub_group_id || "",
                brandId: formik.values.brandId || "",
                campaignType: "own",
                // validation.values.brandId && !validation.values.isCnpCampaign
                //   ? "own"
                //   : validation.values.isCnpCampaign
                //   ? "partner"
                //   : "",
                // tcrStatus: "ACTIVE",
              }),
              "campaignId",
              true
            )}
            getOptionValue={(option: any) =>
              option?.campaignId?.startsWith("C")
                ? option?.campaignId
                : option?.campaignUuid
            }
            getOptionLabel={(option: any) =>
              option?.campaignId?.startsWith("C")
                ? option?.campaignId
                : option?.campaignUuid
            }
            onChange={(option: any) => {
              if (option && option.campaignId === formik.values.campaignId) {
                return;
              }
              if (option) {
                fetchSubGroupNames(option.subGroupId);
                fetchBrandNames(option.brandId);
                formik.setFieldValue(
                  "campaignId",
                  option?.campaignId?.startsWith("C")
                    ? option?.campaignId
                    : option?.campaignUuid
                );
              } else {
                formik.setFieldValue("campaignId", "");
                setCampaignDetails(null);
              }
            }}
            isSearchable={true}
            additional={{
              page: 1,
            }}
          />
          {phoneNumberDetails?.tenDLCWhiteListStatus?.toLowerCase() ===
            "in-progress" && (
            <p className="my-1 text-primary">
              The campaign field cannot be updated when number status is{" "}
              {(phoneNumberDetails.tenDLCWhiteListStatus || "N/A")?.replace(
                "-",
                " "
              )}
            </p>
          )}
          {formik?.touched?.campaignId && formik?.errors?.campaignId && (
            <Form.Control.Feedback type="invalid" className="d-block">
              {formik?.errors?.campaignId}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>
    </Col>
  );

  const renderMessagingSection = () => (
    <Col lg={12} className="mb-4">
      <Row className="border bg-light-gray rounded-3 p-3">
        <Col lg={8} className="mb-3">
          <Form.Label
            htmlFor="urlOne_Message"
            className="d-flex align-items-center mb-0"
          >
            A message comes in URL
            <Tooltip
              placement="right"
              overlay="A webhook notification when a message is received."
            >
              <i
                className="ri-information-line text-secondary fs-5 ms-2"
                style={{
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </Form.Label>
          <Form.Control
            {...formik.getFieldProps("urlOne_Message")}
            className="form-control"
            placeholder="URL"
            data-choices
            data-choices-search-false
            id="urlOne_Message"
            style={{ width: "100%" }}
            isInvalid={
              formik.touched.urlOne_Message && formik.errors.urlOne_Message
            }
          />
          {formik.touched.urlOne_Message && formik.errors.urlOne_Message && (
            <Form.Control.Feedback type="invalid">
              {formik.errors.urlOne_Message}
            </Form.Control.Feedback>
          )}
        </Col>
        <Col lg={8} className="mb-3">
          <Form.Label
            htmlFor="urlTwo_Message"
            className="d-flex align-items-center mb-0"
          >
            Backup URL
            <Tooltip
              placement="right"
              overlay="A backup webhook URL in the event the primary URL above fails."
            >
              <i
                className="ri-information-line text-secondary fs-5 ms-2"
                style={{
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </Form.Label>
          <Form.Control
            {...formik.getFieldProps("urlTwo_Message")}
            className="form-control"
            placeholder="URL"
            data-choices
            data-choices-search-false
            id="urlTwo_Message"
            style={{ width: "100%" }}
            isInvalid={
              formik.touched.urlTwo_Message && formik.errors.urlTwo_Message
            }
          />
          {formik.touched.urlTwo_Message && formik.errors.urlTwo_Message && (
            <Form.Control.Feedback type="invalid">
              {formik.errors.urlTwo_Message}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>
    </Col>
  );

  return (
    <div className="container-fluid">
      <BreadCrumb title="Configure Your Number" />
      <Row>
        {renderSubGroupSection()}
        {renderTenDLCSection()}
        {renderMessagingSection()}

        <Col lg={12}>
          <div className="hstack gap-2">
            <Button
              className="btn btn-primary"
              style={{ background: "none", color: "#e51cfd" }}
              onClick={() =>
                getLocal("numberSortingRoute")
                  ? navigate(getLocal("numberSortingRoute"))
                  : navigate("/mynumbers")
              }
            >
              Return to Active Numbers
            </Button>
            <Button
              disabled={isSubmit}
              onClick={() => formik.handleSubmit()}
              className="btn btn-primary"
              type="button"
            >
              {isSubmit && <Spinner size="sm" animation="border" />} Save
              Configuration
            </Button>
            <Button
              className="btn btn-secondary w-lg"
              type="button"
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(ConfigureNumbers);
