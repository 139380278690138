import Loader from "assets/images/spinner-dark.svg";
import { useEffect, useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { openModal } from "slices/modal/reducer";
import {
  createTokenThunk,
  getTokensThunk,
  revokeTokenThunk,
  updateTokenDetailThunk,
} from "slices/tokens/thunk";
import Datatable from "../Common/Datatable";
import { clearNewToken } from "slices/tokens/reducer";
import { createSelector } from "reselect";
import { onCloseSubject, submitLoadingSubject } from "Common/modals/Modal";
import BreadCrumb from "Common/BreadCrumb";
import Tooltip from "rc-tooltip";
import { dateFormat } from "helpers/common";
import { useSearchParams } from "react-router-dom";

interface Token {
  apiKey: string;
  authToken: string;
  status: string;
  // Add other fields if they exist in the response
}

const ApiKeys = () => {
  document.title = "Signal House Portal - API Keys";
  const dispatch = useDispatch<any>();

  const [searchParams, setSearchParams] = useSearchParams();
  const currPage = Number(searchParams.get("currPage") || 1);
  const rowsPerPage = Number(searchParams.get("rowsPerPage") || 10);

  const selectData = createSelector(
    (state: any) => state.Tokens,
    (tokens) => ({
      tokens: tokens.tokens,
      loading: tokens.loading,
      newToken: tokens.newToken,
    })
  );

  const { tokens, loading, newToken } = useSelector(selectData);
  const [newlyCreatedToken, setNewlyCreatedToken] = useState<string | null>(
    null
  );

  // Load tokens on component mount
  useEffect(() => {
    handleGetData(currPage, rowsPerPage);
  }, []);

  // Update newlyCreatedToken when newToken changes in store
  useEffect(() => {
    if (newToken) {
      setNewlyCreatedToken(newToken);
      dispatch(clearNewToken());
    }
  }, [newToken, dispatch]);

  const handleGetData = (page: number, perPage: number) => {
    dispatch(getTokensThunk(page, perPage));
  };

  // const handlePageChange = (page: number) => {
  //   setSearchParams({
  //     ...Object.fromEntries(searchParams.entries()),
  //     currPage: String(page),
  //   });
  //   handleGetData(page, rowsPerPage);
  // };

  // const handleRowsPerPageChange = (rows: number) => {
  //   setSearchParams({
  //     ...Object.fromEntries(searchParams.entries()),
  //     currPage: String(1),
  //     rowsPerPage: String(rows),
  //   });
  //   handleGetData(1, rows);
  // };

  const handleCreateToken = () => {
    dispatch(
      openModal({
        path: "AddToken",
        data: {
          title: "Create New Token",
          footer: false,

          handleSubmit: ({ values, onClose }: any) => {
            if (!values.friendlyName?.trim()) {
              toast.error("Please enter a token name");
              onCloseSubject.next({});
              return;
            }
            dispatch(
              createTokenThunk(values.friendlyName, () => {
                handleGetData(currPage, rowsPerPage);
                onClose();
              })
            ).catch((error: any) => {
              onClose();
              toast.error(error.message);
            });
          },
        },
      })
    );
  };

  const handleRevoke = (tokenId: string) => {
    dispatch(
      openModal({
        modalbody: "Are you sure you want to revoke this Api Key?",
        data: {
          title: "Revoke Api Key",
          footer: true,
          cancelBtn: true,
          buttonText: {
            submit: "Revoke",
            cancel: "Cancel",
          },
          onSubmit: ({ onClose }: any) => {
            dispatch(revokeTokenThunk(tokenId)).then(() => {
              handleGetData(currPage, rowsPerPage);
              onClose();
            });
          },
        },
      })
    );
  };

  const copyToken = (token: string, message: string) => {
    navigator.clipboard.writeText(token);
    toast.success(message);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Create Date</span>,
      minWidth: "220px",
      selector: (row: { createdDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.createdDate, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (
          new Date(a.createDate).getTime() - new Date(b.createDate).getTime()
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Token Name</span>,
      minWidth: "150px",
      selector: (row: { friendlyName: string }) => row.friendlyName,
      cell: (row: { friendlyName: string; apiKey: string }) => (
        <Tooltip
          placement="topLeft"
          overlay={
            <p className="mb-0" style={{ wordBreak: "break-word" }}>
              {row.friendlyName || "-"}
            </p>
          }
        >
          <span data-tag="allowRowEvents" className="text-truncate-two-lines">
            {row.friendlyName && row.friendlyName.length > 15
              ? `${row.friendlyName.slice(0, 15)}...`
              : row.friendlyName || "-"}
            <i
              className="bi bi-pencil fs-md cursor-pointer text-secondary ms-2"
              style={{
                WebkitTextStroke: "0.5px",
              }}
              onClick={() => {
                dispatch(
                  openModal({
                    path: "EditTokenName",
                    data: {
                      title: "Edit Token Name",
                      detailsfriendlyName: row.friendlyName || "",
                      apiKey: row?.apiKey || "",
                      handleGetData: () => handleGetData(currPage, rowsPerPage),
                      footer: false,
                    },
                  })
                );
              }}
            />
          </span>
        </Tooltip>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        a.friendlyName.localeCompare(b.friendlyName),
    },
    {
      name: <span className="font-weight-bold fs-sm">API Key</span>,
      selector: (row: Token) => row.apiKey,
      cell: (row: Token) => (
        <div className="d-flex gap-2">
          <span>{"•".repeat(20)}</span>
          <Button
            variant="link"
            disabled={row.status !== "active"}
            className="text-decoration-none p-0 me-3"
            onClick={() =>
              row.status === "active" &&
              copyToken(row.apiKey, "Api Key copied to clipboard")
            }
          >
            <i className="bx bxs-copy"></i> Copy
          </Button>
        </div>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Auth Token</span>,
      selector: (row: Token) => row.authToken,
      cell: (row: Token) => (
        <div className="d-flex gap-2">
          <span>{"•".repeat(20)}</span>
          <Button
            variant="link"
            disabled={row.status !== "active"}
            className="text-decoration-none p-0 me-3"
            onClick={() =>
              row.status === "active" &&
              copyToken(row.authToken, "Auth Token copied to clipboard")
            }
          >
            <i className="bx bxs-copy"></i> Copy
          </Button>
        </div>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Active</span>,
      width: "130px",
      selector: (row: { status: string }) => row.status,
      cell: (row: { status: string; apiKey: string }) => (
        <div className="form-check form-switch form-switch-md" dir="ltr">
          <Form.Check
            type="checkbox"
            role="switch"
            checked={row.status === "active"}
            onChange={() => {
              dispatch(
                openModal({
                  modalbody: "Are you sure you want to change status?",
                  data: {
                    title: "Change Status",
                    footer: true,
                    cancelBtn: true,
                    buttonText: {
                      submit: "Continue",
                    },
                    onSubmit: ({ onClose }: any) => {
                      submitLoadingSubject.next(true);
                      dispatch(
                        updateTokenDetailThunk(
                          row.apiKey,
                          {
                            status:
                              row.status === "active" ? "inActive" : "active",
                          },
                          () => {
                            onClose();
                            handleGetData(currPage, rowsPerPage);
                          }
                        )
                      ).then(() => {
                        submitLoadingSubject.next(false);
                      });
                    },
                  },
                })
              );
            }}
          />
        </div>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => a.status.localeCompare(b.status),
    },
    {
      name: <span className="font-weight-bold fs-sm">Actions</span>,
      cell: (row: Token) => (
        <div className="d-flex gap-2">
          <i
            className="ri-delete-bin-fill align-middle text-muted fs-xl"
            onClick={() => handleRevoke(row.apiKey)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title="API Keys"
          isCreate={true}
          createLabel="Generate New Api Key"
          createClick={handleCreateToken}
        />

        <div className="position-relative">
          <Card className="wrapper">
            <Card.Body className="listing-table">
              {/* {newlyCreatedToken && (
              <div className="alert alert-warning mb-4">
                <p className="mb-2"><strong>New API Token Created</strong></p>
                <p className="mb-2">Make sure to copy your token now. You won't be able to see it again!</p>
                <div className="d-flex align-items-center">
                  <code className="me-2">{newlyCreatedToken.slice(0, 10)}...{newlyCreatedToken.slice(-10)}</code>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => copyToken(newlyCreatedToken,"Newly Created Token copied to clipboard")}
                  >
                    <i className="bx bxs-copy"></i>
                  </Button>
                </div>
              </div>
            )} */}

              <div className="position-relative">
                {loading ? (
                  <div className={``}>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                      alt="loading"
                    />
                  </div>
                ) : (
                  <Datatable
                    data={Array.isArray(tokens) ? tokens : []}
                    columns={columns}
                    // handlePageChange={handlePageChange}
                    // handleRowsPerPageChange={handleRowsPerPageChange}
                    // currPage={currPage}
                    // rowsPerPage={rowsPerPage}
                    // totalRecords={tokens?.totalRecords}
                    pagination={false}
                    noDataComponent={
                      <tr>
                        <td colSpan={4} className="text-center">
                          No API tokens found. Create one to get started.
                        </td>
                      </tr>
                    }
                  />
                )}
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default ApiKeys;
